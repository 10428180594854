import React from 'react';
import { useCookies } from 'react-cookie';
import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { alertsSelector } from '../store/app/selectors';
import { AlertScope } from '../store/notifications/types';
import { COOKIES_EXPIRATION_DATE } from '../constants';

type Props = {
  scopes: AlertScope[];
};

export const AlertDialog: React.FunctionComponent<Props> = (props: Props) => {
  const alerts = useSelector(alertsSelector);
  const [cookies, setCookie] = useCookies(['alerts']);

  const handleClose = (alertId: number) => {
    const cookieAlerts = new Set(cookies['alerts']).add(alertId);
    setCookie('alerts', Array.from(cookieAlerts), { expires: COOKIES_EXPIRATION_DATE });
  };

  return (
    <>
      {alerts
        .filter((alert) => !new Set(cookies['alerts']).has(alert.id) && props.scopes.includes(alert.codename))
        .map((alert) => {
          return (
            <Grid key={`alert-${alert.id}`} item={true} xs={12}>
              <Alert onClose={() => handleClose(alert.id)} severity={alert.severity} elevation={0}>
                <Typography variant={'h6'} style={{ marginTop: -5 }}>
                  {alert.title}
                </Typography>
                {alert.body && (
                  <Typography variant={'body2'} style={{ alignItems: 'center', display: 'flex', minHeight: 70 }}>
                    {alert.body}
                  </Typography>
                )}
              </Alert>
            </Grid>
          );
        })}
    </>
  );
};

export default AlertDialog;
