import Loader from '../loader';
import styled from 'styled-components';

const MapLoader = styled(Loader)`
  margin-top: 45vh;
  position: absolute;
  z-index: 999;
`;

export default MapLoader;
