import { Reducer } from 'redux';
import { acquisitionPlan } from './actions';
import { AcquisitionPlanState } from './types';

const initialState: AcquisitionPlanState = {
  type: 'FeatureCollection',
  features: [],
};

const reducer: Reducer<AcquisitionPlanState> = (state = initialState, action) => {
  switch (action.type) {
    case acquisitionPlan.get.REQUEST: {
      return initialState;
    }
    case acquisitionPlan.get.SUCCESS: {
      return action.payload;
    }
    case acquisitionPlan.get.FAILURE: {
      return { ...state, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as acquisitionPlanReducer };
