import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FaArrowLeft } from 'react-icons/fa';

const SidebarDrawerToggleButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: fixed;
    bottom: 200px;
    border-radius: 5px;
    background: ${() => useTheme().palette.background.paper};

    &.open {
      z-index: 1301;
      transition: ${() =>
        useTheme().transitions.create('left', {
          duration: useTheme().transitions.duration.leavingScreen,
          easing: useTheme().transitions.easing.easeInOut,
        })};
    }

    &.closed {
      z-index: 1200;
      transition: ${() =>
        useTheme().transitions.create('left', {
          duration: useTheme().transitions.duration.enteringScreen,
          easing: useTheme().transitions.easing.easeOut,
        })};

      .MuiIconButton-label {
        transform: rotate(180deg);
      }
      left: -5px;
    }

    &:hover {
      background: ${() => useTheme().palette.background.paper};
    }

    @media (max-width: 1200px) {
      left: calc(40% - 20px);
    }

    @media (max-width: 960px) {
      left: calc(50% - 20px);
    }
    @media (max-width: 600px) {
      left: calc(90% - 20px);
    }
  }
`;

interface Props {
  drawerOpen: boolean;
  handleClick: (open: boolean) => void;
}

export const SidebarDrawerToggle = ({ drawerOpen = true, handleClick }: Props) => {
  return (
    <SidebarDrawerToggleButton
      className={clsx({ open: drawerOpen, closed: !drawerOpen })}
      onClick={() => handleClick(!drawerOpen)}
    >
      <FaArrowLeft size={20} />
    </SidebarDrawerToggleButton>
  );
};

export default SidebarDrawerToggle;
