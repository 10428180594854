import { takeEvery } from 'redux-saga/effects';
import { channel, channelAOIs, channels } from './actions';
import urls from '../urls';
import { genericAPIFunction } from '../../utils/api';

const fetchChannel = genericAPIFunction(channel.get, 'get', urls.channelDetails);
const fetchChannelAois = genericAPIFunction(channelAOIs.get, 'get', urls.channelAois);
const fetchChannels = genericAPIFunction(channels.get, 'get', () => `${urls.channelList()}?public=false`);
const deleteChannel = genericAPIFunction(channel.delete, 'delete', urls.channelDetails);
const createChannel = genericAPIFunction(channels.post, 'post', () => urls.channelList());
const updateChannel = genericAPIFunction(channel.patch, 'patch', (payload) =>
  urls.channelDetails({ channelId: payload.channelId }),
);

function* saga() {
  yield takeEvery(channel.delete.REQUEST, deleteChannel);
  yield takeEvery(channel.get.REQUEST, fetchChannel);
  yield takeEvery(channels.get.REQUEST, fetchChannels);
  yield takeEvery(channels.post.REQUEST, createChannel);
  yield takeEvery(channel.patch.REQUEST, updateChannel);
  yield takeEvery(channelAOIs.get.REQUEST, fetchChannelAois);
}

export default saga;
