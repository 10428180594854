import React from 'react';
import styled from 'styled-components';
import { BsIntersect } from 'react-icons/bs';

import { CircularProgress, LinearProgress, LinearProgressProps, Grid, Typography } from '@material-ui/core';
import { formatDistanceStrict } from 'date-fns';
import { DoubleArrowOutlined, WbSunny } from '@material-ui/icons';

import { Imagery } from '../../store/imageries/types';
import { WallElement, WallContainer, WallItem, Element } from '../wall';
import { CenteredGrid } from '../layout';
import { Observation, ObservationImageriesState } from '../../store/observations/types';
import { areaCoveragePercentage } from '../../utils/geo';
import { useTranslation } from 'react-i18next';

import { enUS as en, pl } from 'date-fns/locale';
import { isTouchDevice } from '../../utils/helpers';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number; label: React.ReactNode }) {
  return (
    <CenteredGrid container={true} spacing={1}>
      <CenteredGrid item={true} xs={3}>
        {props.label}
      </CenteredGrid>
      <Grid item={true} xs={9}>
        <LinearProgress variant="determinate" {...props} />
      </Grid>
    </CenteredGrid>
  );
}

const loaderStyle = `
  ${Element}:hover & {
      color: #dedede;
  }
`;

const Loader = styled(CircularProgress)`
  ${loaderStyle}
`;
Loader.displayName = 'Loader';

interface Props {
  details: boolean;
  imageries: Imagery[];
  observation: Observation;
  imageriesState?: ObservationImageriesState;
  onImageryHover: (event: any, image: Imagery) => void;
  onImageryLeave: (image: Imagery) => void;
  onImageryClick: (image: Imagery) => void;
  tilesLoading: number[];
  selectedImageries: Imagery[];
}

export const ImageryList: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation(['widgets', 'common']);
  const imageries = props.details ? props.imageries : props.imageries.slice(0, 3);

  return (
    <React.Fragment>
      {imageries.map((imagery: Imagery, idx: number) => {
        return (
          (props.selectedImageries.includes(imagery) && (
            <WallElement
              key={`imagery_${imagery.id}`}
              grow={true}
              onMouseEnter={(event) => !isTouchDevice() && props.onImageryHover(event, imagery)}
              onMouseLeave={() => !isTouchDevice() && props.onImageryLeave(imagery)}
            >
              <WallContainer>
                <WallItem xs={8}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontFamily: '"industry-bold", sans-serif',
                      fontSize: '0.95em',
                      cursor: 'pointer',
                    }}
                    noWrap={true}
                    variant={'h6'}
                  >
                    {t('widgets:imageryList.added')}
                  </Typography>
                </WallItem>
                <WallItem xs={4}>
                  <DoubleArrowOutlined />
                </WallItem>
              </WallContainer>
            </WallElement>
          )) || (
            <WallElement
              key={`imagery_${imagery.id}`}
              id={`imagery-${idx}`}
              grow={true}
              onMouseEnter={(event) => !isTouchDevice() && props.onImageryHover(event, imagery)}
              onMouseLeave={() => !isTouchDevice() && props.onImageryLeave(imagery)}
              onClick={() => props.onImageryClick(imagery)}
              square={props.details}
            >
              <WallContainer>
                <>
                  <WallItem xs={4}>
                    <WallContainer>
                      <WallItem>
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontFamily: '"industry-bold", sans-serif',
                            fontSize: '0.95em',
                            cursor: 'pointer',
                          }}
                          noWrap
                        >
                          {imagery.satelliteName}
                        </Typography>
                      </WallItem>
                    </WallContainer>
                    <WallContainer>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '0.95em',
                          cursor: 'pointer',
                        }}
                        noWrap
                      >
                        {formatDistanceStrict(imagery.begin_position_date, new Date(), {
                          locale: {
                            en,
                            pl,
                          }[i18n.language],
                        })}{' '}
                        {t('common:ago')}
                      </Typography>
                    </WallContainer>
                  </WallItem>
                  <WallItem xs={5}>
                    <WallContainer style={{ paddingBottom: 5, cursor: 'pointer' }}>
                      <WallItem
                        title={`${t('widgets:imageries.visibility')}: ${100 - imagery.cloud_cover_percentage}%`}
                      >
                        <LinearProgressWithLabel
                          variant="determinate"
                          value={100 - imagery.cloud_cover_percentage}
                          label={<WbSunny fontSize="small" />}
                        />
                      </WallItem>
                    </WallContainer>
                    <WallContainer>
                      <WallItem
                        title={`${t('widgets:imageries.coverage')}:
                         ${areaCoveragePercentage(imagery.geometry, props.observation.geometry)}%`}
                      >
                        <LinearProgressWithLabel
                          variant="determinate"
                          value={areaCoveragePercentage(imagery.geometry, props.observation.geometry)}
                          color="secondary"
                          label={<BsIntersect fontSize={'small'} />}
                        />
                      </WallItem>
                    </WallContainer>
                  </WallItem>
                </>
              </WallContainer>
            </WallElement>
          )
        );
      })}
    </React.Fragment>
  );
};
