import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMap } from 'react-leaflet';
import { pick, values, join } from 'lodash';
import { Grid, Typography as BaseTypography } from '@material-ui/core';
import booleanIntersects from '@turf/boolean-intersects';
import styled from 'styled-components';

import { commercialMapTilesSelector, mapTilesSelector } from '../../store/app/selectors';
import { leafletBoundsToBBoxPolygon } from '../../utils/geo';
import { TileLayer } from '../../store/app/types';
import { CenteredGrid } from '../layout';

const Typography = styled(BaseTypography)`
  font-size: 0.7rem !important;
  line-height: 1.5 !important;
`;

const TextSeparator = () => (
  <Grid item={true}>
    <Typography color="textSecondary">|</Typography>
  </Grid>
);

const DATA_ATTRIBUTION = {
  PNEO: '©CNES , Distribution AIRBUS DS',
  PHR: '©CNES , Distribution AIRBUS DS',
  SPOT: '©AIRBUS DS',
  'Sentinel-2A': 'Copernicus Sentinel',
  'Sentinel-2B': 'Copernicus Sentinel',
  'Landsat-8': 'USGS Landsat',
};

const ImageryAttribution = () => {
  const map = useMap();
  const mapBounds = map.getBounds();
  const mapTiles = useSelector(mapTilesSelector);
  const commercialMapTiles = useSelector(commercialMapTilesSelector);

  const [attributionsText, setAttributionsText] = useState<string>();

  useEffect(() => {
    const satellites = new Set<string>();

    const findTiles = (tileLayer: TileLayer[]) =>
      tileLayer.filter(
        (t) => booleanIntersects(t.imagery.geometry, leafletBoundsToBBoxPolygon(mapBounds)) && !t.imageryState?.hidden,
      );

    findTiles(commercialMapTiles).forEach((tileLayer) => {
      satellites.add(tileLayer.imagery.satellite);
    });

    findTiles(mapTiles).forEach((tileLayer) => {
      satellites.add(tileLayer.imagery.satelliteName);
    });

    setAttributionsText(join(values(pick(DATA_ATTRIBUTION, Array.from(satellites))), ' & '));
  }, [mapBounds, mapTiles, commercialMapTiles]);

  return (
    <CenteredGrid container={true} spacing={1}>
      <Grid item={true}>
        <Typography color="textSecondary">spectator.earth</Typography>
      </Grid>
      <TextSeparator />
      {attributionsText && (
        <>
          <Grid item={true}>
            <Typography color="textSecondary">{attributionsText}</Typography>
          </Grid>
          <TextSeparator />
        </>
      )}

      <>
        <Grid item={true}>
          <Typography color="textSecondary">Leaflet, &copy;OpenStreetMap, &copy;CARTO</Typography>
        </Grid>
      </>
    </CenteredGrid>
  );
};

export default ImageryAttribution;
