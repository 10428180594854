import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LatLngTuple } from 'leaflet';
import { ImageOverlay, Pane, Polygon, useMap } from 'react-leaflet';
import { LeafletElement, useEventHandlers } from '@react-leaflet/core';
import {
  Badge,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import styled from 'styled-components';
import { area, bbox, bboxPolygon, booleanContains, buffer, Feature, mask, point, Point } from '@turf/turf';
import { Polygon as TurfPolygon } from '@turf/helpers/dist/js/lib/geojson';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isUndefined } from 'lodash';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { format } from 'date-fns';

import { CenteredGrid } from '../layout';
import { convertPolygonToLeafletBBOX, leafletBoundsToBBoxPolygon } from '../../utils/geo';
import {
  highResolutionImageriesByConstellationSelector,
  highResolutionImageriesConstellationsSelector,
} from '../../store/imageries/selectors';
import { DEFAULT_DATETIME_FORMAT, M2_TO_KM2_DENOMINATOR } from '../../constants';
import { searchHighResolutionImageries } from '../../store/imageries/actions';
import { highResolutionImageryCheckoutUrlSelector } from '../../store/payment/selectors';
import { highResolutionImageryCheckout } from '../../store/payment/actions';
import MinimapControl from './mini-map';
import { CommercialConstellation } from '../../store/satellites/types';
import {
  configSelector,
  highResolutionImageryDiscountSelector,
  highResolutionImageryPriceSelector,
} from '../../store/config/selectors';
import { ApplicationState } from '../../store';
import ConditionalRangeIconButton from '../conditional-range-icon-button';
import { useTranslation } from 'react-i18next';
import { userSelector } from '../../store/auth/selectors';

const BACKGROUND_POLYGON = bboxPolygon([-180, -180, 180, 180]);

const COMMERCIAL_CONSTELLATIONS_RESOLUTION = {
  [CommercialConstellation.SPOT]: '1.5m',
  [CommercialConstellation.PHR]: '50cm',
  [CommercialConstellation.PNEO]: '30cm',
};

const getAreaPolygon = (center: Point, size: number): Feature<TurfPolygon> =>
  bboxPolygon(bbox(buffer(center, Math.sqrt(size) / 2)));

const ImageryNotFound = () => (
  <CenteredGrid container={true} style={{ position: 'absolute', zIndex: 500, top: 100 }}>
    <Typography id={'no-imagery-info'} color={'textPrimary'} variant={'h5'} align={'center'}>
      {useTranslation('commercialImageryPicker').t('noImageryFound') + '.'}
    </Typography>
  </CenteredGrid>
);

export const DiscountBadge = styled(Badge)`
  .MuiBadge-badge {
    color: #fff;
    background-color: ${() => useTheme().palette.success.dark};
    right: -30px;
  }
`;

const ChangeConstellation = (props: {
  selectedConstellation: CommercialConstellation;
  constellations: CommercialConstellation[];
  onChange: (constellation: CommercialConstellation) => void;
}) => {
  const { t } = useTranslation(['commercialImageryPicker', 'common']);
  const constellationDisabled = (constellation: CommercialConstellation) =>
    !props.constellations.includes(constellation);

  return (
    <CenteredGrid item={true} xs={12}>
      <FormControl>
        <FormLabel style={{ textTransform: 'capitalize' }}>{t('common:resolution')}</FormLabel>
        <RadioGroup
          value={props.selectedConstellation}
          onChange={(e) => {
            e.stopPropagation();
            props.onChange(e.target.value as CommercialConstellation);
          }}
          row={true}
        >
          <Tooltip
            title={t('commercialImageryPicker:notAvailable')}
            disableHoverListener={!constellationDisabled(CommercialConstellation.SPOT)}
          >
            <FormControlLabel
              disabled={constellationDisabled(CommercialConstellation.SPOT)}
              value={CommercialConstellation.SPOT}
              control={<Radio />}
              label={
                <Typography variant={'h5'} color={'textPrimary'}>
                  {t('common:high')}
                </Typography>
              }
            />
          </Tooltip>
          <Tooltip
            title={t('commercialImageryPicker:notAvailable')}
            disableHoverListener={!constellationDisabled(CommercialConstellation.SPOT)}
          >
            <FormControlLabel
              disabled={constellationDisabled(CommercialConstellation.PHR)}
              value={CommercialConstellation.PHR}
              control={<Radio />}
              label={
                <Typography variant={'h5'} color={'textPrimary'}>
                  {t('common:pro')}
                </Typography>
              }
            />
          </Tooltip>
          <Tooltip
            title={t('commercialImageryPicker:notAvailable')}
            disableHoverListener={!constellationDisabled(CommercialConstellation.PNEO)}
          >
            <FormControlLabel
              disabled={constellationDisabled(CommercialConstellation.PNEO)}
              value={CommercialConstellation.PNEO}
              control={<Radio />}
              label={
                <Typography variant={'h5'} color={'textPrimary'}>
                  {t('common:ultra')}
                </Typography>
              }
            />
          </Tooltip>
        </RadioGroup>
      </FormControl>
    </CenteredGrid>
  );
};

const CommercialImageryPicker = () => {
  const map = useMap();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mapArea = area(leafletBoundsToBBoxPolygon(map.getBounds())) / M2_TO_KM2_DENOMINATOR;
  const { t, i18n } = useTranslation(['commercialImageryPicker', 'common']);

  const config = useSelector(configSelector);
  const highResolutionSizes = [...(config.HIGH_RESOLUTION.HI_RES_SIZES as number[])].reverse();

  const [intersects, setIntersects] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [checkoutLoading, setCheckoutLoading] = React.useState<boolean>(false);
  const [selectedArea, setSelectedArea] = React.useState<Feature<TurfPolygon>>();
  const [selectedImageryIndex, setSelectedImageryIndex] = React.useState<number>(0);
  const [constellation, setConstellation] = React.useState<CommercialConstellation>(CommercialConstellation.SPOT);
  const [size, setSize] = useState<number>(highResolutionSizes.find((s) => s < mapArea) || 1);

  const availableConstellations = useSelector(highResolutionImageriesConstellationsSelector);
  const imageries = useSelector((state) =>
    highResolutionImageriesByConstellationSelector(state as ApplicationState)(constellation),
  );
  const selectedImagery = imageries && imageries.length > 0 ? imageries[selectedImageryIndex] : undefined;
  const checkoutUrl = useSelector(highResolutionImageryCheckoutUrlSelector);
  const price = useSelector((state) =>
    highResolutionImageryPriceSelector(state as ApplicationState)(constellation, size),
  );

  const discount = useSelector((state) =>
    highResolutionImageryDiscountSelector(state as ApplicationState)(constellation, size),
  );

  const user = useSelector(userSelector);

  const positions = useMemo(
    () =>
      mask(BACKGROUND_POLYGON, selectedArea).geometry?.coordinates.map((x) =>
        x.map((p) => [p[1], p[0]]),
      ) as LatLngTuple[][],
    [selectedArea],
  );

  useEffect(() => {
    if (!isUndefined(imageries)) {
      setLoading(false);
    }
  }, [imageries]);

  useEffect(() => {
    if (availableConstellations.length === 1) {
      setConstellation(availableConstellations[0]);
    }
  }, [availableConstellations]);

  useEffect(() => {
    if (checkoutUrl) {
      window.location.replace(checkoutUrl);
    }
  }, [checkoutUrl]);

  const onMapMove = useCallback(() => {
    const center = point([map.getCenter().lng, map.getCenter().lat]).geometry;
    const areaPolygon = getAreaPolygon(center, size);

    if (isUndefined(selectedImagery)) {
      setLoading(true);
      dispatch(searchHighResolutionImageries.get.request({ bbox: areaPolygon?.bbox }));
    } else {
      setIntersects(booleanContains(selectedImagery.geometry, areaPolygon));
    }

    setSelectedArea(areaPolygon);
  }, [map, size, selectedImagery, dispatch]);

  useEffect(onMapMove, [onMapMove]);
  const handlers = useMemo(() => ({ move: onMapMove, zoom: onMapMove }), [onMapMove]);
  useEventHandlers({ instance: map } as LeafletElement<any>, handlers);

  useEffect(() => {
    return function cleanup() {
      dispatch(searchHighResolutionImageries.cleanup());
      dispatch(highResolutionImageryCheckout.cleanup());
    };
  }, [dispatch]);

  return (
    <>
      {!isUndefined(selectedImagery) && (
        <MinimapControl zoom={9}>
          <ImageOverlay
            key={selectedImagery.preview_url}
            url={selectedImagery.preview_url}
            bounds={convertPolygonToLeafletBBOX(selectedImagery.geometry)}
            opacity={1}
          />
        </MinimapControl>
      )}

      {loading && <LinearProgress color={'secondary'} style={{ zIndex: 1000 }} />}
      {!loading && !isEmpty(imageries) && (
        <>
          <CenteredGrid container={true} style={{ position: 'absolute', zIndex: 500, top: 50 }} spacing={2}>
            {!intersects && (
              <>
                <CenteredGrid item={true} xs={12}>
                  <Typography variant={'h6'} color={'textPrimary'}>
                    {t('commercialImageryPicker:areaOutsideImageBounds')}
                  </Typography>
                </CenteredGrid>
                <CenteredGrid item={true}>
                  <Typography variant={'body1'} color={'textPrimary'}>
                    {t('commercialImageryPicker:buyAreaWarning') + '.'}
                  </Typography>
                </CenteredGrid>
              </>
            )}
          </CenteredGrid>
          <Pane name={'commercial-imagery-picker'} style={{ zIndex: 1000 }}>
            {!isUndefined(selectedImagery) && (
              <Polygon
                positions={
                  selectedImagery.geometry.coordinates.map((x) => x.map((c) => [c[1], c[0]])) as LatLngTuple[][]
                }
                pathOptions={{ color: theme.palette.secondary.main, opacity: 0 }}
              />
            )}
            {!isUndefined(selectedArea) && (
              <Polygon
                positions={positions}
                pathOptions={{ color: theme.palette.primary.main, opacity: 1, fillOpacity: 0.8 }}
              />
            )}
          </Pane>

          <CenteredGrid container={true} style={{ position: 'absolute', zIndex: 500, bottom: 50 }} spacing={2}>
            <CenteredGrid item={true}>
              <ButtonGroup id={'change-size-buttons'} size={'large'} variant={'contained'} disableElevation={true}>
                {[...highResolutionSizes].reverse().map((s) => (
                  <Button key={`button-${s}`} color={size === s ? 'primary' : 'default'} onClick={() => setSize(s)}>
                    {s} km<sup>2</sup>
                  </Button>
                ))}
              </ButtonGroup>
            </CenteredGrid>

            <ChangeConstellation
              selectedConstellation={constellation}
              constellations={availableConstellations}
              onChange={(constellation) => {
                setConstellation(constellation);
                setSelectedImageryIndex(0);
              }}
            />

            {imageries && imageries.length > 0 && !isUndefined(selectedImagery) && (
              <CenteredGrid item={true} xs={12}>
                <ConditionalRangeIconButton
                  max={imageries.length - 1}
                  value={selectedImageryIndex}
                  onClick={setSelectedImageryIndex}
                  disabled={imageries.length <= 1}
                  increase={true}
                  icon={<FaChevronLeft color={theme.palette.text.primary} />}
                />
                <Typography variant="h6" color={'textPrimary'} style={{ margin: 'auto 10px' }}>
                  {`${format(selectedImagery.date, DEFAULT_DATETIME_FORMAT)}, `}
                  {t('commercialImageryPicker:resolution')}:{' '}
                  {`${COMMERCIAL_CONSTELLATIONS_RESOLUTION[constellation]}, `}
                  <DiscountBadge
                    badgeContent={
                      discount
                        ? t('commercialImageryPicker:discount', { discount })
                        : t('commercialImageryPicker:noDiscount')
                    }
                    id={'discount-badge'}
                  >
                    {t('commercialImageryPicker:price')}:{' '}
                    {new Intl.NumberFormat(i18n.language, {
                      style: 'currency',
                      currency: config.PAYMENTS.CURRENCY,
                    }).format(price)}
                  </DiscountBadge>
                </Typography>
                <ConditionalRangeIconButton
                  min={0}
                  value={selectedImageryIndex}
                  onClick={setSelectedImageryIndex}
                  disabled={imageries.length <= 1}
                  increase={false}
                  icon={<FaChevronRight color={theme.palette.text.primary} />}
                />
              </CenteredGrid>
            )}
            <CenteredGrid>
              <Button
                id={'checkout-button'}
                disabled={!intersects || !price || !user?.is_verified}
                disableElevation={true}
                size={'large'}
                variant={'contained'}
                onClick={() => {
                  setCheckoutLoading(true);
                  dispatch(
                    highResolutionImageryCheckout.post.request({
                      uuid: selectedImagery?.id,
                      bbox: selectedArea?.bbox,
                      success_url: window.location.href,
                      cancel_url: window.location.href,
                    }),
                  );
                }}
              >
                {(checkoutLoading && <CircularProgress />) || t('commercialImageryPicker:checkoutButton')}
              </Button>
            </CenteredGrid>
            <CenteredGrid container={true} style={{ marginTop: 10 }}>
              <a href={`${process.env.REACT_APP_LANDING_PAGE_URL}/faq`} target="_blank" rel="noreferrer noopener">
                {t('common:goToFaq')}
              </a>
            </CenteredGrid>
          </CenteredGrid>
        </>
      )}

      {!loading && imageries && !imageries.length && <ImageryNotFound />}
    </>
  );
};

export default CommercialImageryPicker;
