import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import urls from '../store/urls';
import { api } from '../utils/api';
import { alerts } from '../store/notifications/actions';
import { useTranslation } from 'react-i18next';
import { user } from '../store/auth/actions';
import { subscription } from '../store/plans/actions';

interface MatchParams {
  key: string;
}

type Props = RouteComponentProps<MatchParams>;

const REDIRECT_URL = '/';

const VerifyEmail = (props: Props) => {
  const { t } = useTranslation('verifyEmail');
  const key = props.match.params.key;
  const dispatch = useDispatch();
  const history = props.history;

  useEffect(() => {
    api
      .post(urls.userVerifyEmail(), { key })
      .then(() => {
        dispatch(
          alerts.patch.success([
            {
              id: Math.random(),
              severity: 'success',
              codename: 'general',
              title: t('success'),
            },
          ]),
        );
      })
      .catch(() => {
        dispatch(
          alerts.patch.success([
            {
              id: Math.random(),
              severity: 'error',
              codename: 'general',
              title: t('failure'),
            },
          ]),
        );
      })
      .finally(() => {
        dispatch(user.get.request());
        dispatch(subscription.get.request());
        history.push(REDIRECT_URL);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default VerifyEmail;
