import { Reducer } from 'redux';
import _ from 'lodash';

import { ImageriesState, Imagery } from './types';
import {
  imagery,
  imageries,
  imageryFiles,
  highResolutionImageries,
  searchHighResolutionImageries,
  pendingImageries,
} from './actions';
import { parseISO } from 'date-fns';

const initialState: ImageriesState = {
  currentPage: 0,
  totalPages: 0,
  data: [],
  files: [],
  recommended: [],
  pending: [],
};

const getPreviewTail = (imagery: Imagery) =>
  `/imagery/${imagery.id}/files/${
    imagery.satelliteName === 'Landsat-8' ? `${imagery.scene_id}_thumb_large.jpg` : 'preview.jpg'
  }`;

const adjustData = (input: Imagery) => {
  const imagery = input;
  imagery.satelliteName = imagery.satellite;
  imagery.preview_url = getPreviewTail(imagery);
  imagery.begin_position_date = parseISO(String(imagery.begin_position_date));
  imagery.area_coverage_percentage = Math.round(imagery.area_coverage_percentage * 100);
  imagery.cloud_cover_percentage = Math.round(imagery.cloud_cover_percentage);

  delete imagery.satellite;

  return imagery;
};

const reducer: Reducer<ImageriesState> = (state = initialState, action) => {
  switch (action.type) {
    case imagery.get.SUCCESS: {
      return {
        ...state,
        data: _.orderBy(_.uniqBy([...state.data, adjustData(action.payload)], 'id'), 'begin_position_date', 'desc'),
      };
    }

    case imageries.CLEANUP: {
      return initialState;
    }

    case imageries.get.SUCCESS: {
      const imageries = action.payload.results.map(adjustData);
      const recommended = _.orderBy(action.payload.recommendations.map(adjustData), 'begin_position_date', 'desc');
      const data = action.payload.page === 1 ? imageries : _.uniqBy([...state.data, ...imageries], 'id');

      return {
        ...state,
        data,
        currentPage: action.payload.page,
        totalPages: action.payload.total_pages,
        recommended,
      };
    }

    case imageries.get.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case pendingImageries.get.SUCCESS: {
      return {
        ...state,
        pending: action.payload.pending.map((o: any) => ({ ...o, date: parseISO(o.date) })),
      };
    }

    case pendingImageries.get.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case imageryFiles.get.REQUEST: {
      return { ...state, files: [] };
    }

    case imageryFiles.get.SUCCESS: {
      return { ...state, files: action.payload };
    }

    case highResolutionImageries.get.SUCCESS: {
      return {
        ...state,
        highResolutionData: action.payload,
      };
    }
    case searchHighResolutionImageries.get.SUCCESS: {
      return {
        ...state,
        highResolutionSearchData: action.payload.map((d: any) => ({ ...d, date: parseISO(d.date) })),
      };
    }

    case searchHighResolutionImageries.CLEANUP: {
      return { ...state, highResolutionSearchData: undefined };
    }

    default: {
      return state;
    }
  }
};

export { reducer as imageryReducer };
