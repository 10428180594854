import { call, put, takeEvery } from 'redux-saga/effects';

import { subscription, subscriptionCheckout, plans, subscriptionManagementLink } from './actions';
import urls from '../urls';
import { callApi, genericAPIFunction } from '../../utils/api';

const fetchPlans = genericAPIFunction(plans.get, 'get', urls.planList);
const fetchSubscription = genericAPIFunction(subscription.get, 'get', urls.subscription);
const fetchSubscriptionManagementLink = genericAPIFunction(
  subscriptionManagementLink.get,
  'get',
  urls.subscriptionManagementLink,
);

export function* deleteSubscription(): any {
  try {
    const result = yield call(callApi, 'delete', urls.subscription());
    yield put(subscription.delete.success(result));
  } catch (err) {
    yield put(subscription.delete.failure(err));
  }
}

export function* checkout({ payload }: any): any {
  const { planId, interval } = payload;
  try {
    const result = yield call(callApi, 'post', urls.subscription(), { plan_id: planId, interval });
    yield put(subscriptionCheckout.post.success(result.url));
  } catch (err) {
    yield put(subscriptionCheckout.post.failure(err));
  }
}

function* saga() {
  yield takeEvery(plans.get.REQUEST, fetchPlans);
  yield takeEvery(subscriptionCheckout.delete.REQUEST, deleteSubscription);
  yield takeEvery(subscriptionCheckout.post.REQUEST, checkout);
  yield takeEvery(subscription.get.REQUEST, fetchSubscription);
  yield takeEvery(subscriptionManagementLink.get.REQUEST, fetchSubscriptionManagementLink);
}

export default saga;
