// An interface for https://github.com/mapbox/rio-color
export class ColorFormula {
  gammaBands: string;
  gammaValue: number;
  sigmoidalBands: string;
  sigmoidalContrast: number;
  sigmoidalBias: number;
  saturationProportion: number;

  constructor(
    gammaBands = 'RGB',
    gammaValue = 0,
    sigmoidalBands = 'RGB',
    sigmoidalContrast = 0,
    sigmoidalBias = 0,
    saturationProportion = 0,
  ) {
    this.gammaBands = gammaBands;
    this.gammaValue = gammaValue;
    this.sigmoidalBands = sigmoidalBands;
    this.sigmoidalContrast = sigmoidalContrast;
    this.sigmoidalBias = sigmoidalBias;
    this.saturationProportion = saturationProportion;
  }

  static fromString(formula: string): ColorFormula {
    const options = formula.split(',');
    let gammaBands, gammaValue, sigmoidalBands, sigmoidalContrast, sigmoidalBias, saturationProportion;

    options.forEach((section) => {
      const [option, v1, v2, v3] = section.trim().split(' ');

      switch (option) {
        case 'gamma':
          gammaBands = v1;
          gammaValue = parseFloat(v2);
          break;
        case 'sigmoidal':
          sigmoidalBands = v1;
          sigmoidalContrast = parseFloat(v2);
          sigmoidalBias = parseFloat(v3);
          break;
        case 'saturation':
          saturationProportion = parseFloat(v1);
          break;
        default:
          break;
      }
    });

    return new ColorFormula(
      gammaBands,
      gammaValue,
      sigmoidalBands,
      sigmoidalContrast,
      sigmoidalBias,
      saturationProportion,
    );
  }

  setContrast = (sigmoidalContrast: number): void => {
    this.sigmoidalContrast = sigmoidalContrast;
  };

  toString = (): string => {
    return (
      `gamma ${this.gammaBands} ${this.gammaValue},` +
      ` sigmoidal ${this.sigmoidalBands} ${this.sigmoidalContrast} ${this.sigmoidalBias},` +
      ` saturation ${this.saturationProportion}`
    );
  };
}
