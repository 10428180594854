import React from 'react';
import styled from 'styled-components';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@material-ui/core';
import { SATELLITE_BANDS_COMBINATIONS } from '../constants';
import { SatellitesWithData } from '../store/satellites/types';
import { TilerState } from '../store/observations/types';
import { isEqual } from 'lodash';

const Container = styled.div`
  height: 47.5vh;
  text-align: center;
  display: grid;
  overflow-y: scroll;
`;

export type Preset = {
  [satelliteName in SatellitesWithData]: TilerState;
} & {
  name: string;
  id: string;
  img: { default: string };
  tags: string[];
};

interface Props {
  selected: TilerState;
  onSelect: (preset: Preset) => void;
  satelliteName: SatellitesWithData;
}

const QuickPresets: React.FC<Props> = ({ selected, onSelect, satelliteName }: Props) => (
  <Container>
    <List dense={true} disablePadding={true}>
      {SATELLITE_BANDS_COMBINATIONS.map((preset: Preset) => (
        <ListItem
          key={`preset-${preset.id}`}
          dense={true}
          button={true}
          onClick={(e) => onSelect(preset)}
          selected={isEqual(selected, preset[satelliteName])}
        >
          <ListItemAvatar>
            <Avatar src={preset.img.default} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" color="textPrimary">
                {preset.name}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography component="span" variant="body2">
                  {preset.tags.map((t) => `#${t} `)}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      ))}
    </List>
  </Container>
);

export default QuickPresets;
