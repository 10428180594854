import Autosuggest, { SuggestionSelectedEventData } from 'react-autosuggest';
import React from 'react';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { debounce, filter, isUndefined } from 'lodash';
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { LinearProgress, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { locations } from '../store/app/actions';
import { ApplicationState } from '../store';
import {
  locationsAsCreateObservationSuggestionsSelector,
  locationsAsSuggestionsSelector,
} from '../store/app/selectors';
import { Suggestion } from '../store/app/types';
import { Observation } from '../store/observations/types';
import { withUser } from '../hoc';
import { UserProps, UserSettings } from '../store/auth/types';
import { channelsAsSuggestionsSelector } from '../store/channels/selectors';
import { observationsAsSuggestionsSelector } from '../store/observations/selectors';
import { userSettingsSelector } from '../store/auth/selectors';

const SUGGESTIONS_CHIP_COLORS: Record<string, string> = {
  channel: 'rgba(31, 108, 119)',
  location: 'purple',
  observation: 'green',
};

const SuggestionChip = styled.span<{ type: string }>`
  background: ${(props) => SUGGESTIONS_CHIP_COLORS[props.type]};
  border-radius: 5px;
  color: white;
  font-size: 0.8em;
  padding: 3px;
`;

const AutosuggestWrapper = styled.div`
  ${() => {
    const theme = useTheme().palette;
    return css`
      height: 33px;

      .react-autosuggest__container {
        height: inherit;
        width: 100%;
        position: relative;
      }
      .react-autosuggest__input {
        font-family: 'industry-bold';
        font-size: larger;
        height: inherit;
        border: 0px;
        border-radius: 5px;
        text-align: center;
        width: 100%;
        background-color: ${theme.background.paper};
        color: ${theme.text.primary};
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
      .react-autosuggest__input--focused {
        border: 1px solid rgba(70, 70, 70, 0.4);
        outline: none;
      }
      .react-autosuggest__container--open .react-autosuggest__input--focused {
        border-radius: 5px 5px 0 0;
      }
      .react-autosuggest__suggestion {
        padding-left: 5px;
      }
      .react-autosuggest__suggestion-highlighted {
        background: rgba(70, 70, 70, 0.4);
      }
      .react-autosuggest__suggestions-container {
        z-index: 99;
        display: none;
      }
      .react-autosuggest__suggestions-container--open {
        text-align: left;
        position: absolute;
        display: block !important;
        border-radius: 0 0 5px 5px;
        background: ${theme.background.paper};
        border: 1px;
        border-color: rgba(70, 70, 70, 0.4);
        border-style: solid;
        border-top-style: none;
        padding: 1px;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 30vh;
        width: 100%;
        box-sizing: border-box;
      }
      .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        & li {
          padding: 10px 1px 10px 5px;
        }
      }

      .react-autosuggest__suggestion--highlighted {
        background: ${theme.action.selected};
      }
    `;
  }}
`;

interface StoreProps {
  channelsSuggestions: Suggestion[];
  locationsSuggestions: Suggestion[];
  observationsSuggestions: Suggestion[];
  observationLocationSuggestions: Suggestion[];
  userSettings: UserSettings;
}

interface DispatchProps {
  dispatchAction: (action: any) => any;
  findLocations: (query: string) => any;
}

interface ComponentProps {
  mapSearch?: boolean;
  onSelect?: (suggestion: Suggestion) => void;
}

type Props = StoreProps &
  DispatchProps &
  ComponentProps &
  RouteComponentProps &
  UserProps &
  WithTranslation<['common', 'search']>;

interface State {
  suggestions: Section[];
  suggestionsOpen: boolean;
  value: string;
  locationLoadingError: boolean;
  loading: boolean;
}

interface Section {
  title: string;
  results: Suggestion[];
}

export class Search extends React.Component<Props, State> {
  _isMounted: boolean;
  autosuggestRef = React.createRef<Autosuggest>();

  readonly state: State = {
    suggestions: [],
    suggestionsOpen: false,
    value: '',
    locationLoadingError: false,
    loading: false,
  };

  readonly debouncedFindLocation: any;

  constructor(props: Props) {
    super(props);
    this._isMounted = false;
    this.debouncedFindLocation = debounce(this.findLocation, 500);
  }

  componentDidMount() {
    this.setState({ suggestions: this.getSuggestions(this.state.value) });
  }

  componentDidUpdate = (prevProps: Props): void => {
    this._isMounted = true;

    if (this.props.locationsSuggestions && prevProps.locationsSuggestions !== this.props.locationsSuggestions) {
      this.setState({ suggestions: this.getSuggestions(this.state.value) });
    }
  };

  componentWillUnmount = (): void => {
    this._isMounted = false;
  };

  onChange = (event: React.SyntheticEvent, { newValue = '', method = '' }) => {
    if (!['escape', 'up', 'down'].includes(method)) {
      this.setState({ value: newValue });
    }
  };

  onFocus = () => {
    if (this.state.value) {
      this.onSuggestionsFetchRequested({ value: this.state.value });
    }
  };

  onSuggestionSelected = (suggestion: Suggestion) => {
    if (
      (isUndefined(this.props.user) || this.props.userSettings.createOnSearchSelection) &&
      suggestion.type !== 'observation'
    ) {
      this.props
        .dispatchAction(suggestion.action)
        .then((observation: Observation) => this.props.history.push(`/observation/${observation.uuid}`));
    } else {
      this.props.dispatchAction(suggestion.action);
    }

    this.onSuggestionsClearRequested();
  };

  getSuggestions = (value: string) => {
    let searchResults,
      suggestions: Section[] = [];
    const locationSuggestions =
      this.props.userSettings.createOnSearchSelection || isUndefined(this.props.user)
        ? this.props.observationLocationSuggestions
        : this.props.locationsSuggestions;

    const filterByName = (suggestion: Suggestion) =>
      suggestion.name.toLowerCase().startsWith(value.toLocaleLowerCase());

    if (value) {
      searchResults = [
        ...filter([...this.props.channelsSuggestions, ...this.props.observationsSuggestions], filterByName),
        ...locationSuggestions,
      ];
    }

    if (searchResults) {
      suggestions = [{ title: this.props.t('search:searchResults'), results: searchResults }];
    }

    return suggestions;
  };

  onSuggestionsFetchRequested = ({ value = '' }) => {
    this.debouncedFindLocation();
    this._isMounted && this.setState({ suggestions: this.getSuggestions(value), loading: Boolean(value) });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [], value: '', loading: false, suggestionsOpen: false });
  };

  findLocation = () => {
    if (this.state.value) {
      this.setState({ suggestions: [], locationLoadingError: false });
      this.props
        .findLocations(this.state.value)
        .then(() => this.setState({ locationLoadingError: false, loading: false }))
        .catch(() => this.setState({ locationLoadingError: true }))
        .finally(() => this.setState({ loading: false }));
    }
  };

  getSuggestionValue = (suggestion: Suggestion) => suggestion.name;

  renderSuggestion = (suggestion: Suggestion) => (
    <div aria-label="suggestion" id={'suggestion'}>
      <SuggestionChip type={suggestion.type}>
        {this.props.t(`common:${suggestion.type}` as const, { count: 1 })}
      </SuggestionChip>{' '}
      <Typography color="textPrimary" component="span">
        {suggestion.name}
      </Typography>
    </div>
  );

  renderSuggestionsContainer = ({ containerProps, children, query }: any) => {
    const suggestionContainerOpenClass = 'react-autosuggest__suggestions-container--open';

    containerProps.className =
      query || this.state.suggestionsOpen
        ? `${containerProps.className} ${suggestionContainerOpenClass}`
        : containerProps.className.replace(suggestionContainerOpenClass, '');

    if (!children && !this.state.locationLoadingError) {
      return null;
    }

    return (
      <div {...containerProps}>
        {this.state.loading && <LinearProgress />}
        {this.state.locationLoadingError && (
          <Typography id="locationLoadingError" align={'center'} color={'error'}>
            {this.props.t('search:error')}
          </Typography>
        )}
        {children}
      </div>
    );
  };

  onKeyUp = ({ key = '' }) => {
    if (key === 'Escape') {
      this.autosuggestRef?.current?.input?.blur();
    }

    if (key === 'Enter' && this.state.suggestions.length > 0) {
      const firstResult = this.state.suggestions.map((section) => section.results).flat()[0];
      this.onSuggestionSelected(firstResult);
    }
  };

  renderSectionTitle = (section: Section) => (
    <Typography variant="body2" color={'textSecondary'} style={{ paddingLeft: 3 }}>
      {section.title}
    </Typography>
  );

  render() {
    const { value } = this.state;

    const inputProps = {
      id: 'search-content',
      onBlur: this.onSuggestionsClearRequested,
      onChange: this.onChange,
      onKeyUp: this.onKeyUp,
      onFocus: this.onFocus,
      onClick: () => this.setState({ suggestionsOpen: true }),
      placeholder: `${
        this.props.mapSearch ? this.props.t('search:searchLocation') : this.props.t('search:searchAll')
      }...`,
      value,
    };

    return (
      <AutosuggestWrapper>
        <Autosuggest
          alwaysRenderSuggestions={true}
          multiSection={true}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={(_, data: SuggestionSelectedEventData<Suggestion>) =>
            this.onSuggestionSelected(data.suggestion)
          }
          getSectionSuggestions={(section: Section) => section.results}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderSectionTitle={this.renderSectionTitle}
          focusInputOnSuggestionClick={true}
          inputProps={inputProps}
          ref={this.autosuggestRef}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
        />
      </AutosuggestWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    channelsSuggestions: channelsAsSuggestionsSelector(state),
    userSettings: userSettingsSelector(state),
    locationsSuggestions: locationsAsSuggestionsSelector(state),
    observationLocationSuggestions: locationsAsCreateObservationSuggestionsSelector(state),
    observationsSuggestions: observationsAsSuggestionsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    dispatchAction: (action) => dispatch(action),
    findLocations: (query: string) => {
      return dispatch({
        ...locations.request(query),
        [WAIT_FOR_ACTION]: locations.SUCCESS,
        [ERROR_ACTION]: locations.FAILURE,
      });
    },
  };
};

export default withTranslation(['common', 'search'])(
  withUser(withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))),
);
