import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CssBaseline,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  Typography,
} from '@material-ui/core';
import { Main } from '../components/layout';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { configSelector } from '../store/config/selectors';
import { CheckCircle } from '@material-ui/icons';
import {
  activePlanSelector,
  changeInProgressSelector,
  checkoutUrlSelector,
  plansSelector,
  subscriptionSelector,
} from '../store/plans/selectors';
import { userSelector } from '../store/auth/selectors';
import { plans as plansActions, subscriptionCheckout, subscription } from '../store/plans/actions';
import { Plan, SubscriptionInterval } from '../store/plans/types';
import { RouteComponentProps } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { findIndex } from 'lodash';

export default function Plans(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const activePlan = useSelector(activePlanSelector);
  const changeInProgress = useSelector(changeInProgressSelector);
  const config = useSelector(configSelector);
  const plans = useSelector(plansSelector);
  const currentSubscription = useSelector(subscriptionSelector);
  const user = useSelector(userSelector);
  const checkoutUrl = useSelector(checkoutUrlSelector);
  const [subscriptionInterval, setSubscriptionInterval] = useState<SubscriptionInterval>(SubscriptionInterval.YEAR);
  const { t } = useTranslation(['common', 'plans']);
  const [selectedPlan, setSelectedPlan] = useState<undefined | Plan>();

  useEffect(() => {
    dispatch(plansActions.get.request());
    dispatch(subscription.get.request());
  }, [dispatch]);

  useEffect(() => {
    if (!changeInProgress && currentSubscription && currentSubscription.interval && !activePlan?.default) {
      setSubscriptionInterval(currentSubscription.interval);
    }
  }, [changeInProgress, currentSubscription, activePlan]);

  useEffect(() => {
    let interval = setInterval(() => null, 0);

    if (changeInProgress) {
      interval = setInterval(() => {
        dispatch(subscription.get.request());
      }, 1000);
    } else {
      clearInterval(interval);
      setSelectedPlan(undefined);
    }

    return () => clearInterval(interval);
  }, [dispatch, changeInProgress]);

  useEffect(() => {
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl]);

  const handleChange = (plan: Plan) => {
    if (plan.default) {
      dispatch(subscriptionCheckout.delete.request());
    } else {
      dispatch(subscriptionCheckout.post.request({ planId: plan.id, interval: subscriptionInterval }));
    }
  };

  const discount = (plan: Plan): number =>
    Math.floor((plan.monthly_price * 12 - plan.annual_price) / plan.monthly_price);

  const purchased = (plan: Plan): boolean => {
    if (activePlan?.default && plan.default) {
      return true;
    }

    return currentSubscription?.plan_id === plan.id && currentSubscription?.interval === subscriptionInterval;
  };

  const downgrade = (plan: Plan): boolean => {
    if (activePlan === undefined || plan.default) {
      return false;
    }

    const isLowerPlan = findIndex(plans, (p) => p.id === activePlan.id) > findIndex(plans, (p) => p.id === plan.id);
    const isShorterPeriod =
      currentSubscription?.interval === SubscriptionInterval.YEAR &&
      subscriptionInterval === SubscriptionInterval.MONTH;
    return isLowerPlan || isShorterPeriod;
  };

  const disablePurchase = (plan: Plan): boolean => {
    return (
      !user?.is_verified ||
      changeInProgress ||
      Boolean(currentSubscription?.end_date) ||
      purchased(plan) ||
      downgrade(plan)
    );
  };

  return (
    <React.Fragment>
      <Dialog open={selectedPlan !== undefined} onClose={() => setSelectedPlan(undefined)}>
        <DialogTitle>{t('plans:confirmTitle', { name: selectedPlan?.name })}</DialogTitle>
        <DialogContent>
          {(selectedPlan?.default && (
            <DialogContentText>
              {t('plans:cancelInfo')} - {new Date(currentSubscription?.reset_date as string).toLocaleDateString()}.
            </DialogContentText>
          )) || <DialogContentText>{t('plans:upgradeInfo')}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedPlan(undefined)}>{t('common:cancel')}</Button>
          <Button
            id={'confirm-button'}
            variant={'contained'}
            color={'primary'}
            onClick={() => selectedPlan && handleChange(selectedPlan)}
          >
            {t('plans:confirm')}
          </Button>
        </DialogActions>
        {changeInProgress && <LinearProgress />}
      </Dialog>
      <CssBaseline />
      <Main style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Grid container={true} spacing={0} justify={'center'} alignItems={'center'} style={{ height: '100%' }}>
          <Grid item={true} xs={12} container={true} justify={'center'} alignItems={'center'}>
            <Grid container={true} justify={'center'} alignContent={'center'}>
              <Grid item={true} xs={12}>
                <Typography variant={'h3'} align={'center'}>
                  {t('plans:title')}
                </Typography>
              </Grid>
              <Grid item={true} xs={6}>
                <Typography variant={'h5'} align={'center'} style={{ fontFamily: 'Didact Gothic' }}>
                  {t('plans:subtitle')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} container={true} justify={'center'}>
              <FormControlLabel
                id={'annual-switch'}
                control={
                  <Switch
                    checked={subscriptionInterval === SubscriptionInterval.YEAR}
                    onChange={(event, checked) =>
                      setSubscriptionInterval(checked ? SubscriptionInterval.YEAR : SubscriptionInterval.MONTH)
                    }
                  />
                }
                label={t('plans:switchLabel')}
                labelPlacement={'bottom'}
              />
            </Grid>
          </Grid>
          <Grid item={true} xs={12} container={true}>
            {currentSubscription?.end_date && (
              <>
                <Grid id={'cancelled-info'} item={true} xs={12}>
                  <Typography variant={'h6'} color={'secondary'} align={'center'}>
                    {t('plans:cancelledInfo')} {new Date(currentSubscription.end_date).toLocaleDateString()}
                  </Typography>
                </Grid>
              </>
            )}

            {user && !user.is_verified && (
              <>
                <Grid id={'verification-info'} item={true} xs={12}>
                  <Typography variant={'h6'} color={'secondary'} align={'center'}>
                    {t('plans:verificationInfo')}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container={true} justify={'center'} spacing={4}>
            {plans.map((plan) => (
              <Grid key={plan.name} item={true} xs={12} md={3}>
                <Badge
                  badgeContent={<CheckCircle />}
                  invisible={plan.id !== activePlan?.id || user === undefined}
                  style={{ width: '100%' }}
                >
                  <Card variant={'outlined'} style={{ width: 'inherit' }}>
                    <CardHeader
                      title={
                        config.PAYMENTS.CURRENCY &&
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: config.PAYMENTS.CURRENCY,
                          minimumFractionDigits: 0,
                        }).format(
                          subscriptionInterval === SubscriptionInterval.YEAR
                            ? plan.annual_price / 12
                            : plan.monthly_price,
                        )
                      }
                      titleTypographyProps={{ align: 'center', variant: 'h4' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      subheader={t('plans:frequency')}
                    />
                    <Typography id={'name'} align={'center'} variant={'h6'}>
                      {plan.name}
                    </Typography>
                    <Typography align={'center'}>{plan.description}</Typography>
                    {subscriptionInterval === SubscriptionInterval.YEAR && !plan.default && (
                      <Grid justify={'center'} container={true}>
                        <Chip
                          size={'small'}
                          color={'secondary'}
                          label={`${discount(plan)} ${t('plans:discount', { count: discount(plan) })}`}
                        />
                      </Grid>
                    )}
                    <CardContent>
                      {plan.features.map((feature) => (
                        <Typography key={feature} component={'li'}>
                          {feature}
                        </Typography>
                      ))}
                    </CardContent>
                    <CardActions>
                      <Button
                        id={`select-plan-button-${plan.id}`}
                        disabled={disablePurchase(plan)}
                        disableElevation={true}
                        startIcon={purchased(plan) && <CheckCircle />}
                        fullWidth={true}
                        variant={'contained'}
                        color={purchased(plan) ? 'secondary' : 'primary'}
                        onClick={() => {
                          if (user === undefined) {
                            props.history.push('/auth/signin?redirect=/pricing');
                          }

                          setSelectedPlan(plan);
                        }}
                      >
                        {purchased(plan) ? t('plans:current') : t('plans:switch')}
                      </Button>
                    </CardActions>
                  </Card>
                </Badge>
              </Grid>
            ))}
          </Grid>
          <Grid item={true} xs={12}>
            <Typography align={'center'}>{t('plans:footer1')}</Typography>
            <Typography align={'center'}>{t('plans:footer2')}</Typography>
          </Grid>
        </Grid>
      </Main>
    </React.Fragment>
  );
}
