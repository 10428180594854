import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as en from './locales/en';
import * as pl from './locales/pl';

export const defaultNS = 'common';

export enum LocaleOption {
  PL = 'pl',
  EN = 'en',
}

export const resources = {
  en,
  pl,
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultNS,
    resources,
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'language',
      caches: ['localStorage'],
    },
  })
  .then();
