import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Supercluster, { PointFeature } from 'supercluster';
import { CircleMarker, SVGOverlay as LeafletSVGOverlay } from 'react-leaflet';
import styled, { keyframes } from 'styled-components';
import { flip, buffer, bbox, featureCollection, bboxPolygon, area } from '@turf/turf';
import { LatLng, LatLngTuple } from 'leaflet';
import { withTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import chroma from 'chroma-js';

import { channelAOIs } from '../store/channels/actions';
import { mapBoundsSelector, mapParametersSelector } from '../store/app/selectors';
import { channelAOIsSelector } from '../store/channels/selectors';
import { geojsonBBoxToLeafletBoundsLiteral, reverseBounds } from '../utils/geo';
import { ApplicationState } from '../store';
import { setMapBounds } from '../store/app/actions';
import MapLoader from '../components/map-components/loader';
import { AOI } from '../store/channels/types';
import { BBox } from 'geojson';
import { MapBounds, MapParameters } from '../store/app/types';

const pulse = keyframes`
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1.1);
    opacity: 0;
    fill: #1f6c77;
  }
`;

const Circle = styled.circle`
  transform-origin: center center;
  animation: ${pulse} 3s linear infinite;
`;

const SVGOverlay = styled(LeafletSVGOverlay)`
  &&& {
    overflow: visible !important;
  }
  
  svg.pulse-svg {
    overflow: inherit;
  }
}
`;
SVGOverlay.displayName = 'SVGOverlay';

interface ComponentProps {
  theme: Theme;
}

interface StoreProps {
  aois: AOI[];
  mapParameters: MapParameters;
  mapBounds: MapBounds;
}

interface DispatchProps {
  fetchChannelAOIs: () => any;
  setMapBounds: (bounds: MapBounds) => void;
}

type Props = ComponentProps & DispatchProps & StoreProps;

interface State {
  loaded: boolean;
}

const COLOR_SCALE = chroma.scale(['#1f6c77', '#f7ff00', '#ff2800']).mode('lch').colors(100);
const MIN_CLUSTER_AREA = 10000000;

export class Trending extends React.Component<Props, State> {
  readonly index = new Supercluster({ radius: 200, maxZoom: 10 });

  readonly state: State = {
    loaded: false,
  };

  componentDidMount() {
    this.props.fetchChannelAOIs().then(() => {
      this.index.load(this.props.aois);
      this.setState({ loaded: true });
    });
  }

  onClick = (bounds: BBox) => {
    this.props.setMapBounds(geojsonBBoxToLeafletBoundsLiteral(bounds));
  };

  renderPoint = (point: PointFeature<any>, idx: number) => (
    <CircleMarker
      key={`circle-${idx}`}
      eventHandlers={{
        click: () => this.onClick(bbox(flip(buffer(point, 50)))),
      }}
      center={new LatLng(point.geometry.coordinates[1], point.geometry.coordinates[0])}
      radius={3}
      fill
      fillOpacity={1}
      color={this.props.theme.palette.primary.main}
    />
  );

  renderCluster = (point: PointFeature<any>, idx: number) => {
    const clusterPoints = featureCollection(this.index.getLeaves(Number(point.id)));
    const clusterBbox = bbox(flip({ ...clusterPoints }));
    const clusterBboxPolygon = bboxPolygon(bbox(clusterPoints));

    let bounds = clusterBboxPolygon.geometry;

    if (area(clusterBboxPolygon) < MIN_CLUSTER_AREA) {
      bounds = buffer(clusterBboxPolygon, 20).geometry;
    }

    const color =
      (this.props.aois.length &&
        COLOR_SCALE[Math.round((Number(point.properties.point_count) / this.props.aois.length) * 100)]) ||
      this.props.theme.palette.primary.main;

    // eslint-disable-next-line max-len
    return (
      <SVGOverlay
        interactive
        key={`cluster-${point.properties.cluster_id}`}
        // @ts-ignore
        bounds={flip(bounds).coordinates as LatLngTuple[][]}
      >
        <svg onClick={() => this.onClick(clusterBbox)} className="pulse-svg">
          <circle cx="50%" cy="50%" r="30%" fillOpacity={0.7} fill={color} />
          <Circle fill={color} cx="50%" cy="50%" r="50%" />
        </svg>
      </SVGOverlay>
    );
  };

  render() {
    return (
      (this.state.loaded &&
        this.index
          .getClusters(reverseBounds(this.props.mapBounds).flat() as BBox, Math.round(this.props.mapParameters.zoom))
          .map((point, idx) => {
            return point.properties.cluster ? this.renderCluster(point, idx) : this.renderPoint(point, idx);
          })) || <MapLoader />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchChannelAOIs: () => {
      return dispatch({
        ...channelAOIs.get.request(),
        [WAIT_FOR_ACTION]: channelAOIs.get.SUCCESS,
      });
    },
    setMapBounds: (bounds) => dispatch(setMapBounds.request(bounds)),
  };
};

const mapStoreToProps = (state: ApplicationState): StoreProps => {
  return {
    aois: channelAOIsSelector(state),
    mapParameters: mapParametersSelector(state),
    mapBounds: mapBoundsSelector(state),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(withTheme(Trending));
