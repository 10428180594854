import { takeLatest, select, delay, put } from 'redux-saga/effects';
import { alerts, notifications, notificationsUnreadCount } from './actions';
import { genericAPIFunction } from '../../utils/api';
import { userSelector } from '../auth/selectors';
import urls from '../urls';

const NOTIFICATIONS_REQUEST_DELAY = 15000;

const fetchAlerts = genericAPIFunction(alerts.get, 'get', urls.alertsList);

const fetchNotifications = genericAPIFunction(notifications.get, 'get', () => urls.notificationsList());

const fetchUnreadNotificationsCount = genericAPIFunction(notificationsUnreadCount.get, 'get', () =>
  urls.notificationsUnreadCount(),
);

function* periodicFetch(): any {
  while (true) {
    const user = yield select(userSelector);

    if (user) {
      yield put(notificationsUnreadCount.get.request());
    }

    yield delay(NOTIFICATIONS_REQUEST_DELAY);
  }
}

function* saga() {
  yield takeLatest(alerts.get.REQUEST, fetchAlerts);
  yield takeLatest(notifications.get.REQUEST, fetchNotifications);
  yield takeLatest(notificationsUnreadCount.get.REQUEST, fetchUnreadNotificationsCount);
  yield periodicFetch();
}

export default saga;
