import React from 'react';
import { Dispatch } from 'redux';
import {
  MapContainer as LeafletMapContainer,
  Pane,
  ScaleControl,
  TileLayer as LeafletTileLayer,
  WMSTileLayer,
  Tooltip,
  ZoomControl,
} from 'react-leaflet';
import L, { LeafletEvent } from 'leaflet';
import { useTheme, withTheme } from '@material-ui/core/styles';
import {
  LinearProgress,
  Tooltip as MuiTooltip,
  Button,
  Grid,
  withWidth,
  WithWidth,
  isWidthDown,
} from '@material-ui/core';
import AlertDialog from './alert-dialog';
import 'leaflet/dist/leaflet.css';
import styled, { css } from 'styled-components';

import {
  APP_MAP_LABELS_URL,
  APP_MAP_MAX_BOUNDS,
  APP_MAP_MIN_ZOOM,
  APP_MAP_TILER_MAX_ZOOM,
  APP_MAP_TILER_MIN_ZOOM,
  APP_MAP_TILES_HIGH_RESOLUTION_SIZE,
  APP_MAP_TILES_SIZE,
  APP_MAP_TILES_URL,
  HIGH_RESOLUTION_PICKER_MIN_ZOOM,
} from '../constants';
import { Satellite } from '../store/satellites/types';
import SatelliteComponent from '../components/map-components/satellite';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';

import {
  commercialMapTilesSelector,
  mapBoundsSelector,
  mapLabelsDisabledSelector,
  mapParametersSelector,
  mapPolygonsSelector,
  mapTilesSelector,
  showTrendingSelector,
} from '../store/app/selectors';
import { MapBounds, MapParameters, PolygonLayer, TileLayer } from '../store/app/types';
import { setMapBounds, setMapParameters } from '../store/app/actions';

import { ThemeProps } from '../theme';
import { UserProps } from '../store/auth/types';
import { withUser } from '../hoc';
import Trending from './trending';
import PrintMapButton from '../components/map-components/print-map-button';
import BoundsControl from '../components/map-components/bounds-control';
import MinimapControl from '../components/map-components/mini-map';
import { selectedSatellitesSelector } from '../store/satellites/selectors';
import { highResolutionEnabledSelector, satelliteColorSelector } from '../store/config/selectors';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import { convertPolygonToLeafletBBOX } from '../utils/geo';
import HdIcon from '@material-ui/icons/Hd';
import CommercialImageryPicker from '../components/map-components/commercial-imagery-picker';
import ImageryAttribution from '../components/map-components/imagery-attribution';
import { CenteredGrid } from '../components/layout';
import CreateObservationButton from './create-observation-button';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AlertScope } from '../store/notifications/types';
import { WrappedPolygon } from '../components/map-components/wrapped-polygon';
import MapButtons from '../components/map-components/map-buttons';
import { activeObservationSelector } from '../store/observations/selectors';
import { Observation } from '../store/observations/types';
import { NAVIGATION_HEIGHT } from './navigation';
import clsx from 'clsx';

export const MapContainer = styled(LeafletMapContainer)`
  width: 100%;
  height: calc(100vh - ${NAVIGATION_HEIGHT});
  z-index: 0;
  padding: 0;

  @supports (height: 100dvh) {
    height: calc(100dvh - ${NAVIGATION_HEIGHT});
  }

  .full-screen & {
    height: 100vh;

    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }
`;

const Container = styled.div`
  left: 0;
  position: relative;
  width: 100%;

  ${() => {
    const theme = useTheme().palette;
    return css`
      .leaflet-control {
        margin-bottom: 30px;
      }
      .leaflet-control-zoom-in {
        background-color: ${theme.custom.contrastElement};
      }
      .leaflet-control-zoom-out {
        background-color: ${theme.custom.contrastElement};
      }
      .leaflet-bar a.leaflet-disabled {
        background-color: ${theme.custom.contrastElement};
      }
      .leaflet-container {
        background: ${() => useTheme().palette.custom.mapBackground};
      }
    `;
  }}
`;
Container.displayName = 'Container';

const MapButtonsContainer = styled.div`
  position: absolute;
  pointer-events: none;
  top: 25px;
  left: 25px;
  width: 30%;
  z-index: 1000;

  ${() => {
    const theme = useTheme();

    return css`
      ${theme.breakpoints.down('xs')} {
        left: 15px;
        top: 15px;
      }
    `;
  }}
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 1000;
  width: 220px;

  ${() => {
    const theme = useTheme();

    return css`
      ${theme.breakpoints.down('xs')} {
        left: 15px;
        width: 30%;
      }
    `;
  }}
`;

const ImageryAttributionContainer = styled.div`
  ${() => {
    return css`
      background-color: ${useTheme().palette.background.paper};
    `;
  }}
  bottom: 0px;
  display: flex;
  justify-content: left;
  position: absolute;
  width: 100%;
  z-index: 999;
  padding-left: 10px;
  padding-right: 10px;
`;

const handleTileError = (event: any) => {
  if (!event.tile.reloadTry) {
    event.tile.reloadTry = 0;
  }

  if (event.tile.reloadTry < 3) {
    event.tile.src = `${event.tile.src}&invalidate=true&${new Date().getTime()}`;
  }

  event.tile.reloadTry += 1;
};

// Fix for https://github.com/Leaflet/Leaflet/issues/3575
const fixTilesForChrome = (event: any) => {
  const regex = /\d+/;
  const tileSize = parseInt(event.tile.style.width.match(regex), 10);
  event.tile.style.width = `${tileSize + 1}px`;
  event.tile.style.height = `${tileSize + 1}px`;
};

interface StoreProps {
  getSatelliteColor: (satelliteName: string) => string;
  highResolutionEnabled: boolean;
  mapBounds: MapBounds;
  mapLabelsDisabled: boolean;
  mapParameters: MapParameters;
  mapPolygons: PolygonLayer[];
  mapTiles: TileLayer[];
  commercialMapTiles: TileLayer[];
  selectedSatellites: Satellite[];
  showTrending: boolean;
  observation: Observation | undefined;
}

interface DispatchProps {
  setMapParameters: (parameters: MapParameters) => void;
  setMapBounds: (bounds: MapBounds) => void;
}

interface ComponentProps {
  children?: React.ReactNode;
  mapActionsOff?: boolean;
}

type Props = StoreProps &
  DispatchProps &
  ComponentProps &
  ThemeProps &
  UserProps &
  RouteComponentProps &
  WithTranslation<['common', 'map', 'observation']> &
  WithWidth;

interface State {
  activeSatellite?: Satellite;
  apiLinksDialogOpen: boolean;
  collapseLayersController: boolean;
  displayHighResolutionPicker: boolean;
  showShareMenu: boolean;
  loading: Array<string>;
}

export class MapComponent extends React.Component<Props, State> {
  readonly state: State = {
    activeSatellite: undefined,
    apiLinksDialogOpen: false,
    collapseLayersController: true,
    displayHighResolutionPicker: false,
    showShareMenu: false,
    loading: [],
  };

  getAlertScope = (): AlertScope => {
    switch (this.props.match.url) {
      case '/':
        return 'main';
      case '/channels':
        return 'channels';
      case '/acquisition-plan':
        return 'acquisition';
      case this.props.match.url.match('observation')?.input:
        return 'observation';
      default:
        return undefined;
    }
  };

  render() {
    const { t } = this.props;

    const tileLayersProps = {
      updateWhenIdle: true,
      updateWhenZooming: false,
      tileSize: APP_MAP_TILES_SIZE,
      minZoom: APP_MAP_TILER_MIN_ZOOM,
      maxZoom: APP_MAP_TILER_MAX_ZOOM,
      eventHandlers: {
        load: (e: LeafletEvent) =>
          this.setState({ loading: this.state.loading.filter((id: string) => id !== e.target.options.id) }),
        loading: (e: LeafletEvent) => this.setState({ loading: [...this.state.loading, e.target.options.id] }),
        tileerror: handleTileError,
        tileload: fixTilesForChrome,
      },
    };

    const commonMapChildren = (
      <>
        <LeafletTileLayer
          key={`tile-layer-${this.props.theme.palette.type}`}
          url={APP_MAP_TILES_URL[this.props.theme.palette.type]}
          maxZoom={APP_MAP_TILER_MAX_ZOOM}
        />
        <Pane name="geometries" style={{ zIndex: 403 }}>
          {this.props.mapPolygons.map(
            (polygon: PolygonLayer, idx: number) =>
              !polygon.hidden && (
                <WrappedPolygon
                  key={`${idx}-${polygon.name}`}
                  eventHandlers={{
                    click: () => (polygon.url ? this.props.history.push(polygon.url) : null),
                  }}
                  positions={polygon.positions}
                  color={
                    polygon.type === 'overpass'
                      ? this.props.theme.palette.secondary.light
                      : this.props.theme.palette.primary.main
                  }
                >
                  <Tooltip direction={'right'} offset={[20, 0]}>
                    {polygon.name.toUpperCase()}
                  </Tooltip>
                </WrappedPolygon>
              ),
          )}
        </Pane>

        <Pane name="satellites" style={{ zIndex: 405 }}>
          {this.props.selectedSatellites?.map((satellite) => (
            <SatelliteComponent
              key={`satellite_${satellite.id}`}
              active={false}
              color={this.props.getSatelliteColor(satellite.properties.name)}
              satellite={satellite}
            />
          ))}
        </Pane>

        <Pane name="tiles" style={{ zIndex: 402 }}>
          {this.props.mapTiles.map(
            (tiles, idx) =>
              !tiles.imageryState?.hidden && (
                <LeafletTileLayer
                  id={`imagery-tile-layer-${idx}`}
                  key={tiles.url + idx}
                  url={tiles.url}
                  bounds={convertPolygonToLeafletBBOX(tiles.imagery.geometry)}
                  crossOrigin={'Anonymous'}
                  {...tileLayersProps}
                />
              ),
          )}

          {this.props.commercialMapTiles.map(
            (tiles, idx) =>
              !tiles.imageryState?.hidden && (
                <WMSTileLayer
                  id={`commercial-imagery-tile-layer-${idx}`}
                  key={tiles.url}
                  url={tiles.url}
                  bounds={convertPolygonToLeafletBBOX(tiles.imagery.geometry)}
                  version={'1.1.0'}
                  layers={'layer_0'}
                  crs={L.CRS.EPSG4326}
                  {...tileLayersProps}
                  tileSize={APP_MAP_TILES_HIGH_RESOLUTION_SIZE}
                  zoomOffset={-1}
                  minZoom={1}
                />
              ),
          )}
        </Pane>
      </>
    );

    let highResolutionPickerDisabled = false;
    let highResolutionPickerDisabledReason = '';

    if (!this.props.highResolutionEnabled) {
      highResolutionPickerDisabled = true;
      highResolutionPickerDisabledReason = t('map:highResolutionDisabled');
    } else if (!this.props.user) {
      highResolutionPickerDisabled = true;
      highResolutionPickerDisabledReason = t('common:signInRequired');
    } else if (!this.props.user.is_verified) {
      highResolutionPickerDisabled = true;
      highResolutionPickerDisabledReason = t('common:verificationRequired');
    } else if (this.props.mapParameters.zoom < HIGH_RESOLUTION_PICKER_MIN_ZOOM) {
      highResolutionPickerDisabled = true;
      highResolutionPickerDisabledReason = t('map:zoomInToOrder');
    }

    return (
      <Container id="map-container" className={clsx({ 'full-screen': document.fullscreenElement !== null })}>
        {!isEmpty(this.state.loading) && (
          <div style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
            <LinearProgress />
          </div>
        )}

        <MapContainer
          zoomControl={false}
          zoom={2}
          center={[0, 0]}
          minZoom={APP_MAP_MIN_ZOOM}
          maxBounds={APP_MAP_MAX_BOUNDS as Array<[number, number]>}
          doubleClickZoom={false}
          attributionControl={false}
          worldCopyJump={true}
          id="MAP"
        >
          {!this.props.mapLabelsDisabled && (
            <Pane name="labels" style={{ zIndex: 403 }}>
              <LeafletTileLayer
                key={`label-layer-${this.props.theme.palette.type}`}
                url={APP_MAP_LABELS_URL[this.props.theme.palette.type]}
              />
            </Pane>
          )}
          <BoundsControl
            bounds={this.props.mapBounds}
            parameters={this.props.mapParameters}
            setBounds={this.props.setMapBounds}
            setParameters={this.props.setMapParameters}
          />
          <div className={'map-minimap'} style={{ pointerEvents: 'none' }}>
            <MinimapControl>{commonMapChildren}</MinimapControl>
          </div>
          <ScaleControl position="bottomright" />
          <ZoomControl position="bottomright" />
          <ImageryAttributionContainer>
            <ImageryAttribution />
          </ImageryAttributionContainer>
          {commonMapChildren}
          {this.props.children}
          {this.props.showTrending && <Trending />}
          <ButtonContainer className="map-buttons">
            <Grid container alignContent="flex-start" direction="column">
              <Grid item>
                {!this.state.displayHighResolutionPicker && (
                  <MuiTooltip
                    arrow={true}
                    disableHoverListener={!highResolutionPickerDisabled}
                    placement={'top'}
                    title={highResolutionPickerDisabledReason}
                  >
                    <span>
                      <Button
                        id={'high-resolution-picker-button'}
                        color={'secondary'}
                        onClick={() => this.setState({ displayHighResolutionPicker: true })}
                        style={{ marginBottom: 5, lineHeight: 1 }}
                        startIcon={!isWidthDown('xs', this.props.width) && <HdIcon />}
                        variant={'contained'}
                        disabled={highResolutionPickerDisabled}
                        size={isWidthDown('xs', this.props.width) ? 'small' : 'large'}
                      >
                        {isWidthDown('xs', this.props.width) ? <HdIcon /> : t('map:order')}
                      </Button>
                    </span>
                  </MuiTooltip>
                )}
                {this.state.displayHighResolutionPicker && (
                  <Button
                    color={'secondary'}
                    onClick={() => this.setState({ displayHighResolutionPicker: false })}
                    variant={'contained'}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {t('common:cancel')}
                  </Button>
                )}
              </Grid>

              <Grid item>
                {!this.state.displayHighResolutionPicker && (
                  <PrintMapButton
                    hideClasses={['map-buttons', 'map-minimap', 'leaflet-control-container', 'map-actions']}
                  />
                )}
              </Grid>
            </Grid>
          </ButtonContainer>
          {this.state.displayHighResolutionPicker && <CommercialImageryPicker />}
          {!this.props.mapActionsOff && !this.state.displayHighResolutionPicker && (
            <CenteredGrid
              className="map-actions"
              container={true}
              style={{
                position: 'absolute',
                bottom: 30,
                zIndex: 1000,
                pointerEvents: 'none',
              }}
            >
              <CenteredGrid item={true} xs={12}>
                <CreateObservationButton />
              </CenteredGrid>
            </CenteredGrid>
          )}
        </MapContainer>
        {!this.state.displayHighResolutionPicker && (
          <MapButtonsContainer>
            <div style={{ position: 'absolute', width: '100%', zIndex: 100, pointerEvents: 'auto' }}>
              <Grid container={true} spacing={1}>
                <AlertDialog scopes={[this.getAlertScope(), 'general']} />
              </Grid>
            </div>
            <MapButtons
              mapBounds={this.props.mapBounds}
              mapPolygons={this.props.mapPolygons}
              mapTiles={this.props.mapTiles}
              user={this.props.user}
              isActiveObservation={this.props.match.url.startsWith('/observation')}
              selectedSatellites={this.props.selectedSatellites}
              observation={this.props.observation}
            />
          </MapButtonsContainer>
        )}
        <div id="fullscreen-dialog-container"></div>
      </Container>
    );
  }
}

const mapStoreToProps = (state: ApplicationState): StoreProps => ({
  getSatelliteColor: satelliteColorSelector(state),
  highResolutionEnabled: highResolutionEnabledSelector(state),
  mapBounds: mapBoundsSelector(state),
  mapLabelsDisabled: mapLabelsDisabledSelector(state),
  mapParameters: mapParametersSelector(state),
  mapPolygons: mapPolygonsSelector(state),
  mapTiles: mapTilesSelector(state),
  commercialMapTiles: commercialMapTilesSelector(state),
  selectedSatellites: selectedSatellitesSelector(state),
  showTrending: showTrendingSelector(state),
  observation: activeObservationSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setMapParameters: (parameters) => dispatch(setMapParameters.request(parameters)),
  setMapBounds: (bounds) => dispatch(setMapBounds.request(bounds)),
});

export default withTranslation(['common', 'map', 'observation'])(
  withUser(withRouter(connect(mapStoreToProps, mapDispatchToProps)(withWidth()(withTheme(MapComponent))))),
);
