import { Input as DefaultInput, InputLabel as DefaultInputLabel, Button as DefaultButton } from '@material-ui/core';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

export const Button = styled(DefaultButton)`
  &.MuiButton-outlined {
    color: ${() => useTheme().palette.custom.colorfulText};
    border: 1px solid ${() => useTheme().palette.custom.colorfulText};
  }
`;

export const InputLabel = styled(DefaultInputLabel)`
  &.MuiFormLabel-root.Mui-focused {
    color: ${() => useTheme().palette.custom.colorfulText};
  }
`;

export const Input = styled(DefaultInput)`
  &.MuiInput-underline:after {
    border-bottom-color: ${() => useTheme().palette.custom.colorfulText};
  }
`;
