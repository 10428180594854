import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const Loader = styled(CircularProgress as React.FunctionComponent<CircularProgressProps>)`
  position: relative;
  left: calc(50% - 20px);
  margin-top: calc(50% - 20px);
`;

export default Loader;
