import { Reducer } from 'redux';

import { ConfigState } from './types';
import { config } from './actions';

const initialState: ConfigState = {
  SATELLITES: {
    DEFAULT_SATELLITE_COLOR: '',
    SATELLITES_COLORS: {},
  },
  AUTH: {
    SOCIAL_ACCOUNTS: {
      google: ''
    },
  },
  HIGH_RESOLUTION: {},
  PAYMENTS: {
    CURRENCY: '',
  },
};

const reducer: Reducer<ConfigState> = (state = initialState, action) => {
  switch (action.type) {
    case config.get.SUCCESS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
export { reducer as configReducer };
