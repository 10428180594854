import styled, { css } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

export const ErrorMessage = styled.div`
  ${() => {
    const theme = useTheme().palette;
    return css`
      border: solid 1px #f44336;
      border-radius: 3px;
      padding: 15px;
      background: #f443360f;
      color: ${theme.custom.contrastText};
    `;
  }}
`;

export const SuccessMessage = styled.div`
  ${() => {
    const theme = useTheme().palette;
    return css`
      border: solid 1px #6ab16d;
      border-radius: 3px;
      padding: 15px;
      background: #92c19452;
      color: ${theme.custom.successMessageText};
    `;
  }}
`;
