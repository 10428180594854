import React from 'react';
import { connect } from 'react-redux';
import Img from 'react-image';
import {
  Chip,
  Grid,
  LinearProgress,
  Link,
  Popover,
  Tooltip,
  Typography,
  IconButton,
  Toolbar,
  WithWidth,
  isWidthUp,
  withWidth,
} from '@material-ui/core';
import { Snackbar } from '../../components/snackbar';
import { debounce, difference, isEmpty, isEqual } from 'lodash';
import { Dispatch } from 'redux';
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { flip, intersect } from '@turf/turf';
import { LatLngExpression } from 'leaflet';
import { RouteComponentProps, withRouter } from 'react-router';

import { ImageState, Observation } from '../../store/observations/types';
import { observation } from '../../store/observations/actions';
import {
  observationImageriesHistorySelector,
  observationImageriesCollectionSelector,
  dateFilterPresetsSelector,
} from '../../store/observations/selectors';
import { ImageryList } from '../../components/widgets/imagery-list';
import { DateFilterPreset, ImageryFilters } from '../../components/widgets/imagery-filters';
import { UserProps } from '../../store/auth/types';
import { Imagery, ImageryQueryParameters, PendingImageries } from '../../store/imageries/types';
import {
  imageriesSelector,
  imageriesTotalPagesSelector,
  imageryByIdSelector,
  pendingImagerySelector,
  recommendedImageriesSelector,
} from '../../store/imageries/selectors';
import { ApplicationState } from '../../store';
import { DEFAULT_DATETIME_FORMAT, ImageriesDisplayOption } from '../../constants';
import { withTheme } from '@material-ui/core/styles';
import { imageries, imagery, pendingImageries } from '../../store/imageries/actions';
import { CenteredGrid, WidgetActions, WidgetContainer, WidgetContent } from '../../components/layout';
import { PendingImageriesCard } from '../../components/widgets/pending-imageries-card';
import { ImageriesMenu } from '../../components/widgets/imageries-menu';
import Skeleton from '../../components/skeleton';
import { formatUTC } from '../../utils/date';
import { ThemeProps } from '../../theme';
import { withUser } from '../../hoc';
import { areaCoveragePercentage } from '../../utils/geo';
import { MdClose } from 'react-icons/md';
import { PolygonLayer, TileLayer } from '../../store/app/types';
import { commercialMapTiles, mapPolygons, mapTiles } from '../../store/app/actions';
import { commercialMapTilesSelector, mapTilesSelector } from '../../store/app/selectors';
import { NotInterested, Warning } from '@material-ui/icons';
import styled from 'styled-components';
import { WithTranslation, withTranslation } from 'react-i18next';

const InformationContainer = styled.div`
  height: 100%;
  display: flex;
  alignitems: center;
  justifycontent: center;
  overflow: hidden;
`;

interface ComponentProps {
  observation: Observation;
  onSelectImagery?: () => void;
  onEditImagery?: (imagery: Imagery) => void;
  displayOption?: ImageriesDisplayOption;
  onChangeDisplayOption?: (value: ImageriesDisplayOption) => void;
}

interface DispatchProps {
  addPolygonToMap: (polygon: PolygonLayer) => any;
  addTilesToMap: (tiles: Omit<TileLayer, 'url'>) => any;
  updateCommercialMapTiles: (uuid: string, parameters: Partial<TileLayer>) => any;
  clearMapPolygon: (id?: string) => any;
  clearImageries: () => void;
  updateObservation: (observationUUID: string, parameters: Partial<Observation>) => void;
  fetchImageries: (observationUUID: string, parameters?: ImageryQueryParameters) => any;
  fetchPendingImageries: (observationUUID: string) => any;
  fetchImagery: (observationUUID: string, imageryId: number) => void;
}

interface StoreProps {
  dateFilterPresets: Array<DateFilterPreset>;
  imageryById: (id: number) => Imagery;
  imageries: Imagery[];
  imageriesTotalPages: number;
  observationImageriesCollection: Imagery[];
  observationImageriesHistory: Imagery[];
  pendingImageries: PendingImageries[];
  recommendedImageries: Imagery[];
  mapTiles: TileLayer[];
  commercialMapTiles: TileLayer[];
}

interface State {
  anchorEl: HTMLElement | null;
  details: boolean;
  downloadInfo: string;
  errorLoading: boolean;
  filters: ImageryQueryParameters;
  hoveredImagery?: Imagery;
  imageriesLoaded: boolean;
  imageriesDisplayOption: ImageriesDisplayOption;
  imageriesNextPageLoading: boolean;
  tilesLoading: number[];
  observationImageriesHistory: Imagery[];
}

type Props = ComponentProps &
  DispatchProps &
  StoreProps &
  RouteComponentProps &
  ThemeProps &
  UserProps &
  WithWidth &
  WithTranslation<'widgets'>;

export class Imageries extends React.Component<Props, State> {
  readonly state: State;
  _debouncedOnImageryMouseEnter: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      details: false,
      downloadInfo: '',
      errorLoading: false,
      filters: {
        ...props.observation.settings.filterParameters,
        page: 1,
      },
      hoveredImagery: undefined,
      imageriesLoaded: false,
      imageriesDisplayOption: props.displayOption || ImageriesDisplayOption.Filtered,
      imageriesNextPageLoading: false,
      tilesLoading: [],
      observationImageriesHistory: [],
    };
  }

  componentDidMount() {
    this.props.fetchPendingImageries(this.props.observation.uuid);

    this.updateImages().then(() => {
      const activeImageriesIds = this.props.observation.settings.activeImageriesIds;
      if (activeImageriesIds) {
        this.fetchImageriesByIds(activeImageriesIds).then(() => {
          activeImageriesIds.forEach((imageryId: number) => {
            const imagery = this.props.imageryById(imageryId);
            this.showImageryOnTheMap(imagery, this.props.observation.imageries_state[imagery.uuid]);
          });
        });
      }

      this.showCommercialImageries();
      this.setState({ observationImageriesHistory: this.props.observationImageriesHistory });
    });
  }

  showCommercialImageries() {
    this.props.commercialMapTiles.forEach((tileLayer) => {
      if (intersect(tileLayer.imagery.geometry, this.props.observation.geometry)) {
        const state = this.props.observation.imageries_state[tileLayer.imagery.uuid] || {};

        this.props.updateCommercialMapTiles(tileLayer.imagery.uuid, {
          imageryState: {
            hidden: false,
            ...state,
          },
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.clearImageries();
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.observation.satellites, this.props.observation.satellites)) {
      this.props.fetchPendingImageries(this.props.observation.uuid);
      this.updateImages();
    }

    const prevImageriesHistory = [...prevProps.observation.imageries_history].sort();
    const imageriesHistory = [...this.props.observation.imageries_history].sort();
    const stateImageriesHistory = [...this.state.observationImageriesHistory.map((i) => i.id)].sort();
    if (!isEqual(prevImageriesHistory, imageriesHistory) && !isEqual(imageriesHistory, stateImageriesHistory)) {
      this.setState({ observationImageriesHistory: this.props.observationImageriesHistory });
    }

    const { page, ...other } = this.state.filters;

    if (this.props.observation.can_edit && !isEqual(this.props.observation.settings.filterParameters, other)) {
      this.props.updateObservation(this.props.observation.uuid, { settings: { filterParameters: other } });
    }
  }

  showImageryOnTheMap = (imagery: Imagery, params?: ImageState) => {
    this.props.clearMapPolygon(`${imagery.id}`);
    this.props.addTilesToMap({
      name: params?.annotation || `${imagery.begin_position_date.toUTCString()}`,
      imageryState: { hidden: false, ...params },
      observationUUID: this.props.observation.uuid,
      imagery,
    });
  };

  onSelectImagery = (imagery: Imagery) => {
    this.props.clearMapPolygon(`${imagery.id}`);
    this.addToImageryHistory(imagery);
    this.showImageryOnTheMap(imagery, this.props.observation.imageries_state[imagery.uuid]);

    if (this.props.onSelectImagery) {
      this.props.onSelectImagery();
    }
  };

  onImageryMouseEnter = (event: React.MouseEvent<HTMLElement>, imagery: Imagery) => {
    event.persist();
    const target = event.currentTarget;

    if (!this._debouncedOnImageryMouseEnter) {
      this._debouncedOnImageryMouseEnter = debounce((target: HTMLElement | null, imagery: Imagery) => {
        this.setState({ hoveredImagery: imagery, anchorEl: target });
        this.props.addPolygonToMap({
          id: `${imagery.id}`,
          positions: flip(imagery.geometry).coordinates as LatLngExpression[][],
          name: this.props.t('imageries.preview'),
        });
      }, 500);
    }

    this._debouncedOnImageryMouseEnter(target, imagery);
  };

  onImageMouseLeave = (imagery: Imagery) => {
    this._debouncedOnImageryMouseEnter && this._debouncedOnImageryMouseEnter.cancel();
    this.props.clearMapPolygon(`${imagery.id}`);

    if (this.state.hoveredImagery) {
      this.setState({ anchorEl: null, hoveredImagery: undefined });
    }
  };

  updateImages = (parameters?: ImageryQueryParameters) => {
    const filters = { ...this.state.filters, ...parameters };
    this.setState({ imageriesLoaded: false });

    return this.props
      .fetchImageries(this.props.observation.uuid, filters)
      .then(() => {
        this.setState({ imageriesLoaded: true, filters });
      })
      .catch(() => {
        this.setState({ errorLoading: true });
      });
  };

  handleScroll = (event: React.UIEvent<HTMLElement>) => {
    if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop === event.currentTarget.clientHeight) {
      if (this.state.filters.page && this.state.filters.page < this.props.imageriesTotalPages) {
        this.setState({ imageriesNextPageLoading: true });
        const page = this.state.filters.page + 1;
        return this.props
          .fetchImageries(this.props.observation.uuid, {
            ...this.state.filters,
            page,
          })
          .then(() => {
            this.setState({
              imageriesNextPageLoading: false,
              filters: { ...this.state.filters, page },
            });
          })
          .catch(() => {
            this.setState({ errorLoading: true });
          });
      }
    }
  };

  fetchImageriesByIds = async (imageriesIds: number[]) => {
    this.setState({ imageriesLoaded: false });

    const observationImageriesToFetch = difference(
      imageriesIds,
      this.props.imageries.map((imagery: Imagery) => imagery.id),
    );

    if (observationImageriesToFetch.length) {
      await Promise.all(
        observationImageriesToFetch.map((imagery: number) =>
          this.props.fetchImagery(this.props.observation.uuid, imagery),
        ),
      ).then(() => this.setState({ imageriesLoaded: true }));
    } else {
      this.setState({ imageriesLoaded: true });
    }
  };

  getImages = (option: ImageriesDisplayOption) => {
    switch (option) {
      case ImageriesDisplayOption.Recommended:
        return this.props.recommendedImageries;

      case ImageriesDisplayOption.History:
        return this.state.observationImageriesHistory;

      default:
        return this.props.imageries;
    }
  };

  onImageriesOptionChange = (option: ImageriesDisplayOption) => {
    this.setState({ imageriesDisplayOption: option });
    this.props.onChangeDisplayOption && this.props.onChangeDisplayOption(option);

    if (option === ImageriesDisplayOption.History) {
      this.fetchImageriesByIds(this.props.observation.imageries_history).then();
    }
  };

  renderActions = (details: boolean) => (
    <WidgetActions style={{ flexShrink: 0, overflowX: 'hidden', zIndex: 0 }}>
      <Grid container justify="center" spacing={1}>
        {this.props.user && details && (
          <ImageryFilters
            presets={this.props.dateFilterPresets}
            parameters={this.state.filters}
            onChange={this.updateImages}
          />
        )}
        <Grid container={true} justify={'space-between'}>
          <CenteredGrid item={true}>
            <ImageriesMenu
              value={this.state.imageriesDisplayOption}
              disabled={!this.props.user}
              onChange={this.onImageriesOptionChange}
            />
          </CenteredGrid>
          {(details && (
            <CenteredGrid item={true} xs={3}>
              <IconButton onClick={() => this.setState({ details: false })}>
                <MdClose />
              </IconButton>
            </CenteredGrid>
          )) ||
            (this.props.user && (
              <CenteredGrid item={true} xs={4}>
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => this.setState({ details: true })}
                  style={{ color: this.props.theme.palette.custom.colorfulText }}
                >
                  {this.props.t('more')}
                </Link>
              </CenteredGrid>
            ))}
        </Grid>
      </Grid>
    </WidgetActions>
  );

  addToImageryHistory = (imagery: Imagery) => {
    const observation = this.props.observation;
    const imageries = [imagery.id, ...observation.imageries_history.filter((id) => id !== imagery.id)];
    this.props.observation.can_edit && this.props.updateObservation(observation.uuid, { imageries_history: imageries });
  };

  render() {
    const { observation, pendingImageries, t } = this.props;
    const imageries = this.getImages(this.state.imageriesDisplayOption);

    return (
      <React.Fragment>
        <Grid container justify="center" alignItems="center">
          <Typography variant="body2" align="center" style={{ fontFamily: '"industry-bold"', marginRight: '10px' }}>
            {t('imageries.previous')}
          </Typography>
          {pendingImageries !== undefined && !isEmpty(pendingImageries) && (
            <Grid item>
              <Tooltip placement="right" title={<PendingImageriesCard pendingImageries={pendingImageries} />}>
                <Chip
                  style={{ marginTop: '-9px' }}
                  size="small"
                  color="primary"
                  label={t('imageries.pending', { count: pendingImageries.length })}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(this.state.hoveredImagery)}
          style={{ pointerEvents: 'none', position: 'absolute' }}
        >
          {this.state.hoveredImagery && (
            <React.Fragment>
              <Img
                src={[
                  process.env.REACT_APP_API_URL +
                    `/observation/${observation.uuid}${this.state.hoveredImagery.preview_url}`,
                ]}
                loader={<LinearProgress />}
                style={{ width: 250 }}
              />
              <Typography variant="body2" align="center">
                {t('imageries.acquired')}{' '}
                {formatUTC(this.state.hoveredImagery.begin_position_date, DEFAULT_DATETIME_FORMAT)} UTC
              </Typography>
              <Typography variant="body2" align="center" style={{ textTransform: 'capitalize' }}>
                {t('imageries.visibility')}: {100 - this.state.hoveredImagery.cloud_cover_percentage}%,{' '}
                {t('imageries.coverage')}:{' '}
                {areaCoveragePercentage(this.state.hoveredImagery.geometry, observation.geometry)}%
              </Typography>
            </React.Fragment>
          )}
        </Popover>

        <WidgetContainer
          id="container"
          detailed={this.state.details}
          elevation={0}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {isWidthUp('md', this.props.width) && this.state.details && <Toolbar />}
          <WidgetContent onScroll={this.handleScroll}>
            {(!this.state.imageriesLoaded || this.state.errorLoading) && (
              <Skeleton height="100%" animation="wave" variant="rect" style={{ zIndex: 0 }} />
            )}
            {this.state.imageriesLoaded && isEmpty(imageries) && (
              <InformationContainer>
                <Grid container={true} spacing={2}>
                  <CenteredGrid item={true} xs={12}>
                    {t('imageries.noData')}
                  </CenteredGrid>
                  <CenteredGrid item={true} xs={12}>
                    <NotInterested fontSize={'large'} />
                  </CenteredGrid>
                  <CenteredGrid item={true} xs={12}>
                    {t('imageries.noDataSuggestion')}
                  </CenteredGrid>
                </Grid>
              </InformationContainer>
            )}
            {this.state.errorLoading && (
              <InformationContainer>
                <CenteredGrid container={true} spacing={2}>
                  <CenteredGrid item={true} xs={12}>
                    {t('imageries.error')}
                  </CenteredGrid>
                  <CenteredGrid item={true} xs={12}>
                    <Warning fontSize={'large'} />
                  </CenteredGrid>
                  <CenteredGrid item={true} xs={12}>
                    {t('imageries.errorSuggestion')}
                  </CenteredGrid>
                </CenteredGrid>
              </InformationContainer>
            )}
            {this.state.imageriesLoaded && (
              <ImageryList
                imageries={imageries}
                imageriesState={this.props.observation.imageries_state}
                observation={this.props.observation}
                onImageryClick={(imagery: Imagery) => this.onSelectImagery(imagery)}
                onImageryHover={this.onImageryMouseEnter}
                onImageryLeave={this.onImageMouseLeave}
                details={this.state.details}
                tilesLoading={this.state.tilesLoading}
                selectedImageries={this.props.mapTiles.map((tiles) => tiles.imagery)}
              />
            )}
            {this.state.imageriesNextPageLoading && (
              <>
                <Typography align={'center'}>{t('imageries.loadingMore')}</Typography>
                <LinearProgress />
              </>
            )}
          </WidgetContent>
          {this.renderActions(this.state.details)}
        </WidgetContainer>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          message={this.state.downloadInfo}
          open={Boolean(this.state.downloadInfo)}
          onClose={() => this.setState({ downloadInfo: '' })}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  addPolygonToMap: (polygon) => dispatch(mapPolygons.add(polygon)),
  addTilesToMap: (tiles) => dispatch(mapTiles.add(tiles)),
  updateCommercialMapTiles: (uuid, parameters) => dispatch(commercialMapTiles.update({ uuid, parameters })),
  clearMapPolygon: (polygon) => dispatch(mapPolygons.delete(polygon)),
  updateObservation: (uuid, parameters) => dispatch(observation.patch.request({ uuid, ...parameters })),
  clearImageries: () => dispatch(imageries.delete.request()),
  fetchImageries: (observationUUID: string, parameters?: ImageryQueryParameters) =>
    dispatch({
      ...imageries.get.request({ observationUUID, parameters }),
      [WAIT_FOR_ACTION]: imageries.get.SUCCESS,
      [ERROR_ACTION]: imageries.get.FAILURE,
    }),
  fetchPendingImageries: (observationUUID: string) => dispatch(pendingImageries.get.request({ observationUUID })),
  fetchImagery: (observationUUID: string, imageryId: number) =>
    dispatch({
      ...imagery.get.request({ observationUUID, imageryId }),
      [WAIT_FOR_ACTION]: (action: any) => action.type === imagery.get.SUCCESS && action.payload.id === imageryId,
    }),
});

const mapStoreToProps = (state: ApplicationState): StoreProps => ({
  dateFilterPresets: dateFilterPresetsSelector(state),
  imageries: imageriesSelector(state),
  imageryById: imageryByIdSelector(state),
  recommendedImageries: recommendedImageriesSelector(state),
  imageriesTotalPages: imageriesTotalPagesSelector(state),
  pendingImageries: pendingImagerySelector(state),
  observationImageriesCollection: observationImageriesCollectionSelector(state),
  observationImageriesHistory: observationImageriesHistorySelector(state),
  mapTiles: mapTilesSelector(state),
  commercialMapTiles: commercialMapTilesSelector(state),
});

export default withTranslation('widgets')(
  withUser(withRouter(connect(mapStoreToProps, mapDispatchToProps)(withTheme(withWidth()(Imageries))))),
);
