import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showTrendingSelector } from '../../store/app/selectors';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import { showTrending } from '../../store/app/actions';
import { useTranslation } from 'react-i18next';

const TrendingLayerSwitcher = () => {
  const dispatch = useDispatch();
  const show = useSelector(showTrendingSelector);
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={<Switch checked={show} color="primary" onChange={() => dispatch(showTrending.set(!show))} />}
      label={
        <Typography color="inherit" style={{ textTransform: 'capitalize' }}>
          {t('trending')}
        </Typography>
      }
      labelPlacement="end"
    />
  );
};

export default TrendingLayerSwitcher;
