import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import Fuse from 'fuse.js';
import { Feature } from 'geojson';
import { TextField, Grid, LinearProgress, Snackbar, Toolbar, Link } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Satellite } from '../store/satellites/types';
import { SatellitesList } from '../components/satellites-list';
import { satellitesSelector, selectedSatellitesSelector, fetchedAllSelector } from '../store/satellites/selectors';
import { ApplicationState } from '../store';
import { setSatellitesSelected } from '../store/satellites/actions';
import { SidebarDrawer } from '../components/layout';
import { satellites as satellitesActions } from '../store/satellites/actions';
import { Alert } from '@material-ui/lab';
import { withUser } from '../hoc';
import { UserProps } from '../store/auth/types';
import { createPortal } from 'react-dom';

interface DispatchProps {
  fetchSatellites: () => any;
  setSatellitesSelected: (satelliteIds: number[]) => void;
}

interface StoreProps {
  satellites: Satellite[];
  selectedSatellites: Satellite[];
  fetchedAllSatellites: boolean;
}

interface State {
  loading: boolean;
  minSatellitesWarning: boolean;
  open: boolean;
  query: string;
}

interface ComponentProps {
  handleSatellitesLoaded(): void;
  onClose(): any;
  open: boolean;
}

type Props = DispatchProps & ComponentProps & StoreProps & UserProps & WithTranslation<['common', 'satellitesMenu']>;

const options: Fuse.FuseOptions<Feature> = {
  keys: ['properties.name'],
};

export class SatellitesMenu extends React.Component<Props, State> {
  readonly state: State = {
    loading: false,
    minSatellitesWarning: false,
    open: false,
    query: '',
  };

  componentDidMount() {
    if (!this.props.fetchedAllSatellites) {
      this.setState({ loading: true });
      this.props.fetchSatellites().then(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentDidUpdate() {
    if (!this.state.loading) {
      this.props.handleSatellitesLoaded();
    }
  }

  onSatelliteChange = (checked: boolean, satellite: Satellite) => {
    if (checked) {
      this.props.setSatellitesSelected([...this.props.selectedSatellites.map((s) => s.id), satellite.id]);
    } else {
      const satellitesLeft = this.props.selectedSatellites.map((s) => s.id).filter((id) => id !== satellite.id);
      if (satellitesLeft.length > 0) {
        this.props.setSatellitesSelected(satellitesLeft);
      } else {
        this.setState({ minSatellitesWarning: true });
      }
    }
  };

  render() {
    const { t } = this.props;
    const fuse = new Fuse(this.props.satellites, options);
    const satellites = this.state.query ? (fuse.search(this.state.query) as Satellite[]) : this.props.satellites;

    return (
      <>
        {createPortal(
          <SidebarDrawer
            open={this.props.open}
            variant="persistent"
            style={this.props.open ? { pointerEvents: 'auto' } : undefined}
            transitionDuration={0}
            className="satellites_menu"
          >
            <Snackbar
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'top',
              }}
              open={this.state.minSatellitesWarning}
              autoHideDuration={6000}
              onClose={() => this.setState({ minSatellitesWarning: false })}
            >
              <Alert severity="warning">You need at least one satellite selected to create an observation.</Alert>
            </Snackbar>
            <Toolbar />
            <Grid container={true} justify="center" direction="column" alignItems="stretch" style={{ marginTop: 10 }}>
              <Grid item style={{ padding: '0 5%', marginBottom: '20px' }}>
                <TextField
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.shiftKey && event.key === 'Enter') {
                      this.props.setSatellitesSelected(satellites.map((s) => s.id));
                    }
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ query: event.target.value })
                  }
                  label={t('common:search') + '...'}
                  fullWidth={true}
                  helperText={
                    <Link
                      className="prompt"
                      href="#"
                      onClick={() => {
                        this.props.setSatellitesSelected(satellites.map((s) => s.id));
                      }}
                      style={{ textAlign: 'center' }}
                      hidden={this.state.query.length < 1 || satellites.length < 1}
                    >
                      {this.props.t('satellitesMenu:promptToSelectVisible')}
                    </Link>
                  }
                />
              </Grid>
              <Grid item>
                {this.state.loading ? (
                  <LinearProgress />
                ) : (
                  <SatellitesList
                    onSatelliteChange={this.onSatelliteChange}
                    satellites={satellites}
                    selected={this.props.selectedSatellites.map((s) => s.id)}
                  />
                )}
              </Grid>
            </Grid>
          </SidebarDrawer>,
          document.getElementById('satellites-menu') as Element,
        )}
      </>
    );
  }
}

const mapStoreToProps = (state: ApplicationState): StoreProps => ({
  satellites: satellitesSelector(state),
  selectedSatellites: selectedSatellitesSelector(state),
  fetchedAllSatellites: fetchedAllSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchSatellites: () =>
      dispatch({
        ...satellitesActions.get.request(),
        [WAIT_FOR_ACTION]: satellitesActions.get.SUCCESS,
      }),
    setSatellitesSelected: (satelliteIds) => dispatch(setSatellitesSelected.request({ satelliteIds })),
  };
};

export default withTranslation(['common', 'satellitesMenu'])(
  withUser(connect(mapStoreToProps, mapDispatchToProps)(SatellitesMenu)),
);
