/* eslint-disable */
import React from 'react';
import Grid, { GridProps, GridSize } from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

export const Wall = styled(Grid as React.FunctionComponent<GridProps>)`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: inherit;
  height: 80%;
`;

export const Element = styled(({ square, hovercolor, ...rest }) => <Grid {...rest} />)<{
  square?: boolean;
  hovercolor?: string;
}>`
  align-items: center;
  border-radius: ${(props) => (props.square ? 0 : '5px')};
  cursor: pointer;
  text-align: center;
  color: ${(props) => (props.selected ? useTheme().palette.secondary.main : useTheme().palette.text.primary)};
  display: grid;
  font-size: 0.9em;
  height: 75px;
  margin: ${(props) => (props.floating ? '7px' : '0px')};
  padding: 0px;
  position: relative;
  &: hover {
    background: ${(props) => props.hovercolor || useTheme().palette.custom.backdropColor};

    #element-actions {
      visibility: visible;
    }
  }
`;
Element.displayName = 'WallElement';

const ElementActions = styled(({ xs, ...rest }) => <Grid xs={xs} {...rest} />)`
  visibility: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  right: 5px;
  height: 75px;
  width: ${(props) => (props.xs ? 'initial' : '100%')};
`;

ElementActions.displayName = 'WallElementActions';

const Item = styled(({ onClick, highlight, ...rest }) => <Grid onClick={onClick} {...rest} />)<{
  highlight?: boolean;
  onClick?: () => any;
}>`
  font-family: ${(props) => (props.highlight ? '"industry-bold", sans-serif' : 'inherit')};
  font-size: 0.95em;
  overflow: hidden;
  margin-bottom: 0px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'initial')};
`;

interface ChildProps {
  children: React.ReactNode;
}

interface WallElementProps extends ChildProps {
  hovercolor?: string;
  square?: boolean;
  grow?: boolean;
}

export const WallElement: React.FunctionComponent<WallElementProps & GridProps> = (props) => {
  const { grow, ...rest } = props;
  return grow ? (
    <Grow in={true} timeout={'auto'}>
      <Element {...rest}>{rest.children}</Element>
    </Grow>
  ) : (
    <Element {...rest}>{rest.children}</Element>
  );
};

export const WallElementActions: React.FunctionComponent<{ xs?: GridSize } & ChildProps & GridProps> = (props) => (
  <ElementActions xs={props.xs} id="element-actions" item={true}>
    {props.children}
  </ElementActions>
);

export const WallContainer: React.FunctionComponent<ChildProps & GridProps> = (props) => (
  <Grid container={true} justify="space-around" alignItems="center" {...props}>
    {props.children}
  </Grid>
);

export const WallItem: React.FunctionComponent<
  {
    xs?: GridSize;
    highlight?: boolean;
  } & ChildProps &
    GridProps
> = (props) => (
  <Item item={true} xs={props.xs || 12} highlight={props.highlight} {...props}>
    {props.children}
  </Item>
);

export default Wall;
