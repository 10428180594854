import { useEffect, useCallback, useRef } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';
import { debounce, isEqual } from 'lodash';

import { MapBounds, MapParameters } from '../../store/app/types';
import { leafletElementToMapBounds } from '../../utils/geo';

interface Props {
  parameters: MapParameters;
  bounds: MapBounds;
  setBounds: (bounds: MapBounds) => void;
  setParameters: (parameters: MapParameters) => void;
}

const BoundsControl = (props: Props) => {
  const map = useMap();
  const mapRef = useRef(map);
  const boundsRef = useRef(props.bounds);
  const parametersRef = useRef(props.parameters);

  const _setMapParameters = (leafletElement: any) => {
    const { lat, lng } = leafletElement.getCenter();
    const mapBounds = leafletElementToMapBounds(leafletElement);

    const mapParameters = {
      center: { lat, lng },
      zoom: leafletElement.getZoom(),
    };

    if (!isEqual(mapBounds, boundsRef.current)) {
      props.setBounds(mapBounds);
      props.setParameters(mapParameters);
      boundsRef.current = mapBounds;
      parametersRef.current = mapParameters;
    }
  };

  const debouncedSetMapParameters = debounce(_setMapParameters, 500);

  const onMoveEnd = useCallback((e) => debouncedSetMapParameters(e.target), [debouncedSetMapParameters]);
  useMapEvent('moveend', onMoveEnd);

  const onMapInit = useCallback(
    (e) => {
      const mapBounds = leafletElementToMapBounds(mapRef.current);
      props.setBounds(mapBounds);
      mapRef.current.panInsideBounds(mapBounds);
    },
    [props],
  );

  useMapEvent('viewreset', onMapInit);

  useEffect(() => {
    return () => {
      // @ts-ignore
      debouncedSetMapParameters.cancel();
    };
  }, [debouncedSetMapParameters]);

  useEffect(() => {
    if (!isEqual(props.bounds, boundsRef.current)) {
      mapRef.current.fitBounds(props.bounds);
    }
  }, [props.bounds]);

  useEffect(() => {
    mapRef.current.setView(props.parameters.center, props.parameters.zoom);
  }, [props.parameters]);

  return null;
};

export default BoundsControl;
