import { createSelector } from 'reselect';
import { ApplicationState } from '../index';
import { Plan, Subscription } from './types';

export const changeInProgressSelector = (state: ApplicationState) => state.plan.changeInProgress;
export const checkoutUrlSelector = (state: ApplicationState) => state.plan.checkoutUrl;
export const subscriptionSelector = (state: ApplicationState) => state.plan.subscription;
export const plansSelector = (state: ApplicationState) => state.plan.plans;
export const activePlanSelector = createSelector(
  subscriptionSelector,
  plansSelector,
  (subscription: Subscription | undefined, plans: Plan[]) =>
    subscription && plans.find((p) => p.id === subscription.plan_id),
);
