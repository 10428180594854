import React from 'react';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { mapLabelsDisabledSelector } from '../../store/app/selectors';
import { mapLabelsDisabled } from '../../store/app/actions';
import { useTranslation } from 'react-i18next';

const LabelsLayerSwitcher = () => {
  const dispatch = useDispatch();
  const labelsDisabled = useSelector(mapLabelsDisabledSelector);
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Switch
          checked={!labelsDisabled}
          color="primary"
          onChange={() => dispatch(mapLabelsDisabled.set(!labelsDisabled))}
        />
      }
      label={
        <Typography color="inherit" style={{ textTransform: 'capitalize' }}>
          {t('label', { count: 0 })}
        </Typography>
      }
      labelPlacement="end"
    />
  );
};

export default LabelsLayerSwitcher;
