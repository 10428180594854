import { Middleware } from 'redux';
import { ApplicationState } from '../store';
import { login } from '../store/auth/actions';
import i18n from 'i18next';

const reduxUserLocaleMiddleware: Middleware<null, ApplicationState> = (store) => (next) => (action) => {
  if (action.type === login.SUCCESS && action.payload.settings.language) {
    i18n.changeLanguage(action.payload.settings.language);
  }
  next(action);
};

export default reduxUserLocaleMiddleware;
