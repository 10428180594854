import React from 'react';
import { Button, Input, InputLabel } from './form';
import { FormControl, FormControlLabel, FormHelperText, Checkbox, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { SignupErrors } from '../store/auth/types';
import { ErrorMessage } from './messages';
import { withTheme, Theme } from '@material-ui/core/styles';
import { WithTranslation, withTranslation } from 'react-i18next';

const Form = styled.form`
  text-align: center;
  padding: 10px;
`;

interface Props {
  onSubmit: (email: string, password1: string, password2: string) => void;
  errors: SignupErrors;
  email?: string | null;
  theme: Theme;
}

interface State {
  email: string;
  password1: string;
  password2: string;
  policyAccepted: boolean;
}

class SignupForm extends React.Component<Props & WithTranslation<['common', 'signupForm']>, State> {
  readonly state: State = {
    email: '',
    password1: '',
    password2: '',
    policyAccepted: false,
  };

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const email = this.props.email ? this.props.email : this.state.email;
    const password1 = this.state.password1;
    const password2 = this.state.password2;
    this.props.onSubmit(email, password1, password2);
  }

  render() {
    const { errors, email, t } = this.props;

    return (
      <div>
        {errors.non_field_errors && <ErrorMessage id="signup-error">{errors.non_field_errors}</ErrorMessage>}

        <Form onSubmit={(formData) => this.onSubmit(formData)}>
          <FormControl required={true} fullWidth={true} error={!isEmpty(errors.email)}>
            <InputLabel>E-mail</InputLabel>
            <Input
              id="signup-email"
              name="email"
              type="email"
              value={email ? email : this.state.email}
              disabled={Boolean(email)}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
            <FormHelperText>{errors.email}</FormHelperText>
          </FormControl>
          <FormControl required={true} fullWidth={true} error={!isEmpty(errors.password1)}>
            <InputLabel style={{ textTransform: 'capitalize' }}>{t('common:password')}</InputLabel>
            <Input
              id="signup-password-1"
              name="password1"
              type="password"
              onChange={(e) => {
                this.setState({ password1: e.target.value });
              }}
            />
            <FormHelperText>{errors.password1}</FormHelperText>
          </FormControl>
          <FormControl required={true} fullWidth={true} error={!isEmpty(errors.password2)}>
            <InputLabel>{t('signupForm:confirm')}</InputLabel>
            <Input
              id="signup-password-2"
              name="password2"
              type="password"
              onChange={(e) => {
                this.setState({ password2: e.target.value });
              }}
            />
            <FormHelperText>{errors.password2}</FormHelperText>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                id="accept-terms-checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ policyAccepted: e.target.checked })
                }
                color="primary"
              />
            }
            label={
              <div style={{ color: this.props.theme.palette.custom.contrastText }}>
                <Typography component={'span'}>{t('signupForm:accept')} </Typography>
                <a
                  href={`${process.env.REACT_APP_LANDING_PAGE_URL}/privacy-policy`}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: this.props.theme.palette.custom.colorfulText, textDecoration: 'none' }}
                >
                  {t('common:privacyPolicy')}
                </a>
                <Typography component={'span'}> {t('common:and')} </Typography>
                <a
                  href={`${process.env.REACT_APP_LANDING_PAGE_URL}/terms-and-conditions`}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: this.props.theme.palette.custom.colorfulText, textDecoration: 'none' }}
                >
                  {t('common:tandc')}
                </a>
              </div>
            }
          />
          <p>
            <Button id="signup-button" disabled={!this.state.policyAccepted} variant="outlined" type="submit">
              {t('signupForm:signUp')}
            </Button>
          </p>
        </Form>
      </div>
    );
  }
}

export default withTranslation(['common', 'signupForm'])(withTheme(SignupForm));
