import React, { useState } from 'react';
import { ImageState, TilerState } from '../store/observations/types';
import { Grid, Typography, Slider } from '@material-ui/core';
import QuickPresets, { Preset } from './quick-presets';
import { SatellitesWithData } from '../store/satellites/types';
import DebouncedTextField from './debounced-text-field';
import styled from 'styled-components';
import { DEFAULT_SATELLITE_BANDS, DEFAULT_COLOR_FORMULA } from '../constants';
import { ColorFormula } from '../utils/rio';
import { useTranslation } from 'react-i18next';

const AnnotationField = styled(DebouncedTextField)`
  border-color: #80808042;
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;

  & input {
    padding: 5px;
  }

  & ::placeholder {
    text-align: center;
  }
`;

interface Props {
  initialImageState?: ImageState;
  onStateChange: (parameters: ImageState) => void;
  satelliteName: SatellitesWithData;
}

export const ImageryEdit: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation('imageryEdit');
  const [annotation, setAnnotation] = useState<string>(
    (props.initialImageState && props.initialImageState.annotation) || '',
  );
  const [tilerState, setTilerState] = useState<TilerState>(
    props.initialImageState?.tilerState || DEFAULT_SATELLITE_BANDS[props.satelliteName],
  );

  const [colorFormula, setColorFormula] = useState<string>(
    props.initialImageState?.tilerState?.color_formula || DEFAULT_COLOR_FORMULA,
  );

  return (
    <>
      <Grid container={true} spacing={2} justify={'center'} alignItems={'center'} style={{ padding: '10px' }}>
        <Grid item xs={6}>
          <Typography gutterBottom color="textPrimary">
            {t('contrast')}
          </Typography>
          <Slider
            disabled={tilerState.hasOwnProperty('expression')}
            getAriaValueText={(value: number) => `${value} %`}
            onChangeCommitted={(_, value: number | number[]) => {
              if (typeof value === 'number') {
                const temp = ColorFormula.fromString(colorFormula);
                temp.setContrast(value);
                setColorFormula(temp.toString());
                props.onStateChange({
                  tilerState: {
                    ...tilerState,
                    color_formula: temp.toString(),
                  },
                });
              }
            }}
            step={2}
            defaultValue={ColorFormula.fromString(colorFormula).sigmoidalContrast}
            valueLabelDisplay="auto"
          />
        </Grid>
        <Grid item xs={6}>
          <AnnotationField
            delay={500}
            fullWidth={true}
            onChange={(value: string) => {
              setAnnotation(value);
              props.onStateChange({ annotation: value });
            }}
            placeholder={annotation || t('annotationPlaceholder')}
            value={annotation}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} justify={'center'} alignItems={'center'}>
        <Grid item xs={12}>
          <QuickPresets
            selected={tilerState}
            onSelect={(preset: Preset) => {
              const satellitePreset = preset[props.satelliteName];
              setTilerState(satellitePreset);
              props.onStateChange({ tilerState: satellitePreset });
            }}
            satelliteName={props.satelliteName}
          />
        </Grid>
      </Grid>
    </>
  );
};
