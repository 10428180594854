import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

const SHARE_ICON_SIZE = 28;

const ShareMenu = () => {
  const { t } = useTranslation('observation');
  const url = window.location.href;

  return (
    <Grid container justify="flex-start" alignItems="center" spacing={1} style={{ lineHeight: 0 }}>
      <Grid item>
        <TwitterShareButton url={url} title={`${t('observationShareMsg')} @spectatorearth`}>
          <TwitterIcon size={SHARE_ICON_SIZE} />
        </TwitterShareButton>
      </Grid>
      <Grid item>
        <FacebookShareButton url={url}>
          <FacebookIcon size={SHARE_ICON_SIZE} />
        </FacebookShareButton>
      </Grid>
      <Grid item>
        <EmailShareButton url={url} subject={`${t('observationShareMsg')} @ ${window.location.host}`}>
          <EmailIcon size={SHARE_ICON_SIZE} />
        </EmailShareButton>
      </Grid>
      <Grid item>
        <WhatsappShareButton url={url} title={`${t('observationShareMsg')} @ ${window.location.host}`}>
          <WhatsappIcon size={SHARE_ICON_SIZE} />
        </WhatsappShareButton>
      </Grid>
      <Grid item>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={SHARE_ICON_SIZE} />
        </LinkedinShareButton>
      </Grid>
    </Grid>
  );
};

export default ShareMenu;
