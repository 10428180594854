import { call, put, takeEvery } from 'redux-saga/effects';
import { nominatimApi } from '../../utils/api';
import { locations } from './actions';

function* findLocations({ payload }: any) {
  const params = {
    q: payload,
    addressdetails: 0,
    'accept-language': 'en',
    polygon_geojson: 1,
    format: 'json',
  };

  try {
    const { data } = yield call(nominatimApi.get, '/search', { params });

    yield put(locations.success(data));
  } catch (err) {
    yield put(locations.failure(err));
  }
}

function* saga() {
  yield takeEvery(locations.REQUEST, findLocations);
}

export default saga;
