import * as styledComponents from 'styled-components';
import IndustryBold from './static/fonts/industry-bold.ttf';
import { useTheme } from '@material-ui/core/styles';

const { default: styled, createGlobalStyle } = styledComponents as styledComponents.ThemedStyledComponentsModule<any>;

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "industry-bold";
    src: url(${IndustryBold}) format("truetype");
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    font-family: 'Didact Gothic',sans-serif;
    height: -webkit-fill-available;
    background-color: ${() => useTheme().palette.background.paper};
  }
  
  :focus-visible {
    outline: 0;
  }
  
  .leaflet-control-zoom {
    border: solid 1px rgba(70, 70, 70, 0.4) !important;
  }
  .leaflet-control-zoom-in {
    border-bottom-color: #666666 !important;
  }
  .leaflet-control-zoom-in, .leaflet-control-zoom-out {
    background-color: ${() => useTheme().palette.background.paper} !important;
    color: ${() => useTheme().palette.text.primary} !important;
    border-color: rgba(70, 70, 70, 0.4);
  }
  
  .leaflet-control-zoom > .leaflet-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
  
  .leaflet-control-scale-line {
    background-color: ${() => useTheme().palette.background.paper} !important;
    color: ${() => useTheme().palette.text.primary} !important;
  } 
  
  .leaflet-control-attribution {
    background-color: ${() => useTheme().palette.background.paper} !important;
    color: ${() => useTheme().palette.text.secondary} !important;
  }
  
  .leaflet-container a {
    color: ${() => useTheme().palette.text.secondary} !important; 
  }
`;

export default styled;
