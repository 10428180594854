import React, { useState } from 'react';
import { Popover, Typography, Slider, Chip } from '@material-ui/core';
import { subDays } from 'date-fns';
import { ImageryQueryParameters } from '../../store/imageries/types';
import { Calendar } from '../calendar';
import { CenteredGrid } from '../layout';
import { useTranslation } from 'react-i18next';

const CLOUD_FILTER_PRESETS = [10, 40, 70];
export type DateRange = { date_from: Date; date_to: Date };
export type PeriodPreset = 'days30' | 'days90' | 'spring' | 'summer' | 'autumn' | 'winter';
export type DateFilterPreset = [PeriodPreset, DateRange];

interface Props {
  parameters: ImageryQueryParameters;
  presets: Array<DateFilterPreset>;
  onChange: (parameters: ImageryQueryParameters) => void;
}

export const ImageryFilters: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation(['common', 'widgets']);
  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
  const [displaySlider, setDisplaySlider] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [parameters, setParameters] = useState<Required<ImageryQueryParameters>>({
    date_from: subDays(new Date(), 30),
    date_to: new Date(),
    cc_less_than: CLOUD_FILTER_PRESETS[2],
    ...props.parameters,
    page: 1,
  });
  const [dateFilter, setDateFilter] = useState<string>(props.parameters?.date_from ? 'custom' : props.presets[0][0]);

  return (
    <React.Fragment>
      <CenteredGrid item={true} xs={12}>
        <Typography gutterBottom style={{ textTransform: 'capitalize' }}>
          {t('common:when')}?
        </Typography>
      </CenteredGrid>

      {props.presets.map(([name, dateRange]) => (
        <CenteredGrid
          key={name}
          item={true}
          onClick={() => {
            const newParameters = { ...parameters, ...dateRange, page: 1 };
            setParameters(newParameters);
            setDateFilter(name);
            props.onChange(newParameters);
          }}
        >
          <Chip
            color={name === dateFilter ? 'primary' : 'default'}
            clickable={true}
            label={t(`widgets:imageryFilters.${name}` as const)}
          />
        </CenteredGrid>
      ))}

      <CenteredGrid item={true}>
        <Chip
          id={'custom-date-button'}
          color={dateFilter === 'custom' ? 'primary' : 'default'}
          clickable={true}
          onClick={(e) => {
            setDisplayCalendar(true);
            setAnchorEl(e.currentTarget);
          }}
          label={t('widgets:imageryFilters.custom')}
        />
      </CenteredGrid>
      <CenteredGrid item={true} xs={12}>
        <Typography gutterBottom>{t('widgets:imageryFilters.acceptableCC')}?</Typography>
      </CenteredGrid>

      {CLOUD_FILTER_PRESETS.map((filter) => (
        <CenteredGrid item={true} key={`cloud-${filter}`}>
          <Chip
            clickable={true}
            color={parameters.cc_less_than === filter ? 'primary' : 'default'}
            label={`${filter}%`}
            onClick={() => {
              const newParameters = { ...parameters, page: 1, cc_less_than: filter };
              setParameters(newParameters);
              props.onChange(newParameters);
            }}
          />
        </CenteredGrid>
      ))}

      <CenteredGrid item={true}>
        <Chip
          clickable={true}
          color={
            parameters.cc_less_than && !CLOUD_FILTER_PRESETS.includes(parameters.cc_less_than) ? 'primary' : 'default'
          }
          onClick={(e) => {
            setDisplaySlider(true);
            setAnchorEl(e.currentTarget);
          }}
          label={t('widgets:imageryFilters.custom')}
        />
      </CenteredGrid>
      <Popover
        open={displayCalendar || displaySlider}
        anchorEl={anchorEl}
        onClose={() => {
          setDisplayCalendar(false);
          setDisplaySlider(false);
          setAnchorEl(null);
        }}
      >
        {displayCalendar ? (
          <Calendar
            locale={i18n.language}
            returnValue="range"
            selectRange={true}
            onChange={(date: Date | Date[]) => {
              const [date_from, date_to] = date as Date[];
              const newParameters = {
                ...parameters,
                date_from,
                date_to,
                page: 1,
              };
              props.onChange(newParameters);
              setParameters(newParameters);
              setDateFilter('custom');
            }}
            value={[parameters.date_from, parameters.date_to]}
          />
        ) : (
          <div style={{ width: 200, padding: 20, paddingTop: 40 }}>
            <Slider
              value={parameters.cc_less_than}
              step={10}
              valueLabelDisplay="on"
              marks={[
                { value: 0, label: '0%' },
                { value: 100, label: '100%' },
              ]}
              onChange={(_, value: number | number[]) => {
                setParameters({ ...parameters, cc_less_than: value as number });
              }}
              onChangeCommitted={(_, value: number | number[]) => {
                props.onChange({ ...parameters, cc_less_than: value as number });
              }}
            />
          </div>
        )}
      </Popover>
    </React.Fragment>
  );
};
