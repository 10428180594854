import ReactCalendar from 'react-calendar';
import styled, { css } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import 'react-calendar/dist/Calendar.css';

export const Calendar = styled(ReactCalendar)`
  ${() => {
    const theme = useTheme().palette;
    return css`
      &.react-calendar {
        font-family: inherit;
        border-radius: 3px;
        border-width: 0px;
        background-color: ${theme.background.paper};
        &--selectRange .react-calendar__tile--hover {
          background-color: ${theme.action.selected} !important;
        }
        .react-calendar__navigation button {
          &: hover {
            background-color: ${theme.action.selected};
          }
          color: ${theme.custom.contrastText};
          background: transparent;
        }

        .react-calendar__century-view {
          .react-calendar__century-view__decades {
            .react-calendar__tile {
              color: ${theme.custom.contrastText};
              &: hover {
                background-color: ${theme.action.selected};
              }
              &--now {
                color: ${theme.custom.calendarTodayText};
                background: transparent;
              }
              &--active,
              &--hasActive {
                background: ${theme.primary.main};
                color: white;
              }
            }
          }
        }
        .react-calendar__decade-view {
          .react-calendar__decade-view__years {
            .react-calendar__tile {
              color: ${theme.custom.contrastText};
              &: hover {
                background-color: ${theme.action.selected};
              }
              &--now {
                color: ${theme.custom.calendarTodayText};
                background: transparent;
              }
              &--active,
              &--hasActive {
                background: ${theme.primary.main};
                color: white;
              }
            }
          }
        }
        .react-calendar__year-view {
          .react-calendar__year-view__months {
            .react-calendar__tile {
              color: ${theme.custom.contrastText};
              &: hover {
                background-color: ${theme.action.selected};
              }
              &--now {
                color: ${theme.custom.calendarTodayText};
                background: transparent;
              }
              &--active,
              &--hasActive {
                background: ${theme.primary.main};
                color: white;
              }
            }
          }
        }
        .react-calendar__month-view {
          color: ${theme.custom.contrastText};
          .react-calendar__month-view__days {
            .react-calendar__month-view__days__day {
              color: ${theme.custom.contrastText};
            }
            .react-calendar__month-view__days__day--weekend {
              color: #d10000;
            }
            .react-calendar__month-view__days__day--neighboringMonth {
              color: #757575;
            }

            .react-calendar__tile {
              &:hover {
                background-color: ${theme.action.selected};
              }
              &--now {
                color: ${theme.custom.calendarTodayText} !important;
                font-weight: bold;
                background: transparent;
              }
              &--active,
              &--hasActive {
                &:hover {
                  background-color: ${theme.primary.main};
                }
                background: ${theme.primary.main};
                color: white;
              }
            }
          }
        }
      }
    `;
  }}
`;
