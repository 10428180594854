import { createSelector } from 'reselect';

import { ApplicationState } from '../index';
import { Overpass } from './types';

export const overpassesSelector = (state: ApplicationState) => state.overpasses.data;

export const overpassesTotalPagesSelector = (state: ApplicationState) => state.overpasses.totalPages;

export const upcomingOverpassesSelector = createSelector([overpassesSelector], (overpasses) => {
  return overpasses.filter((overpass: Overpass) => overpass.date > new Date());
});
