import { Reducer } from 'redux';
import { Overpass, OverpassesState } from './types';
import { overpass } from './actions';
import { parseISO } from 'date-fns';
import { orderBy } from 'lodash';

const initialState: OverpassesState = {
  data: [],
  totalPages: 0,
};

const acquisition = (overpass: Overpass): boolean | null => {
  const acquisitions = new Set(overpass.footprints.features.map((f: any) => f.properties?.acquisition));

  if (acquisitions.has(true)) {
    return true;
  } else if (acquisitions.has(false)) {
    return false;
  }

  return null;
};

const reducer: Reducer<OverpassesState> = (state = initialState, action) => {
  switch (action.type) {
    case overpass.get.SUCCESS: {
      const data = action.payload.results.map((overpass: any) => ({
        ...overpass,
        acquisition: acquisition(overpass),
        date: parseISO(overpass.date),
      }));

      return {
        data: orderBy(action.payload.page === 1 ? data : [...state.data, ...data], 'date', 'asc'),
        totalPages: action.payload.total_pages,
      };
    }
    case overpass.get.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case overpass.delete.REQUEST: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { reducer as overpassReducer };
