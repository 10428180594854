import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Theme, useTheme, withTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { difference, map, zipObject } from 'lodash';
import { format, startOfDay } from 'date-fns';
import { Popup as LeafletPopup } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import { Polygon as GeoPolygon } from 'geojson';
import { flip } from '@turf/turf';
import { Grid, isWidthDown, isWidthUp, Toolbar, withWidth, WithWidth } from '@material-ui/core';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

import Map from '../containers/map';
import MapLoader from '../components/map-components/loader';
import { Main, SidebarDrawer } from '../components/layout';
import { Calendar as ReactCalendar } from '../components/calendar';
import { ApplicationState } from '../store';
import { acquisitionPlan } from '../store/acquisition-plan/actions';
import { acquisitionPlanSelector } from '../store/acquisition-plan/selectors';
import { AcquisitionPlanState, SatelliteNameWithAcquisitionPlan } from '../store/acquisition-plan/types';
import { SATELLITES } from '../store/acquisition-plan/constants';
import { TITLE } from '../constants';
import { NAVIGATION_HEIGHT } from '../containers/navigation';
import { UserProps } from '../store/auth/types';
import { LogoFooter } from '../components/logo-footer';
import { RouteComponentProps } from 'react-router';
import { ThemeProps } from '../theme';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WrappedPolygon } from '../components/map-components/wrapped-polygon';
import SidebarDrawerToggle from '../components/sidebar-drawer-toggle';

const initialSatellites: SatelliteNameWithAcquisitionPlan[] = [
  'Sentinel-2A',
  'Sentinel-2B',
  'Landsat-7',
  'Landsat-8',
  'Landsat-9',
];

export const getSatelliteColors = (theme: Theme): Record<string, string> => {
  const colors = [
    lighten(theme.palette.primary.light, 0.5),
    lighten(theme.palette.primary.light, 0.5),
    theme.palette.primary.main,
    theme.palette.primary.main,
    darken(theme.palette.primary.dark, 0.2),
    darken(theme.palette.primary.dark, 0.2),
  ];

  return zipObject(SATELLITES, colors);
};

const CheckboxSatellite = styled(({ checkedColor: string, ...rest }) => <Checkbox {...rest} />)`
  &.MuiCheckbox-root {
    padding: 6px;
  }

  &.MuiCheckbox-colorPrimary {
    color: ${() => useTheme().palette.primary.main};

    &.Mui-checked {
      color: ${(props) => props.checkedColor};
    }
  }
`;

const Calendar = styled(ReactCalendar)`
  margin: 0 auto;
  padding: 10px;
`;
Calendar.displayName = 'Calendar';

const Popup = styled(({ ...rest }) => <LeafletPopup {...rest} />)`
  .leaflet-popup-content-wrapper {
    opacity: 0.9;
    border-radius: 3px;
    background: #1e6c77;
    color: white;
  }

  .leaflet-popup-tip-container {
    opacity: 0.9;

    .leaflet-popup-tip {
      background: #1e6c77;
    }
  }
`;

const Polygon = styled(WrappedPolygon)`
  fill-opacity: 0.2;
  transition: 0.5s;
  stroke-width: 1;

  &:hover {
    fill-opacity: 0.4;
  }
`;
Polygon.displayName = 'Polygon';

interface State {
  date: Date;
  satellites: Set<string>;
  loading: boolean;
  acqPlannerDrawerOpen: boolean;
}

interface StoreProps {
  acquisitionPlan: AcquisitionPlanState;
}

interface DispatchProps {
  fetchAcquisitionPlan: (result: any) => any;
}

type Props = DispatchProps &
  RouteComponentProps &
  StoreProps &
  ThemeProps &
  UserProps &
  WithTranslation<'acquisitionPlan'> &
  WithWidth;

const SENTINEL_1_ACQUISITION_PLAN_URL =
  'https://sentinel.esa.int/web/sentinel/missions/sentinel-1/observation-scenario/acquisition-segments';
const SENTINEL_2_ACQUISITION_PLAN_URL = 'https://sentinel.esa.int/web/sentinel/missions/sentinel-2/acquisition-plans';
const LANDSAT_ACQUISITION_PLAN_URL = 'https://landsat.usgs.gov/landsat_acq';

export class AcquisitionPlan extends React.Component<Props, State> {
  readonly state: State = {
    date: startOfDay(new Date()),
    satellites: new Set(initialSatellites),
    loading: false,
    acqPlannerDrawerOpen: true,
  };

  componentDidMount(): void {
    this.selectAcquisitionPlan();
    document.title = `${this.props.t('title')} | ${TITLE}`;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const addedSatellite = difference(Array.from(this.state.satellites), Array.from(prevState.satellites));
    const satellitesInStore = this.props.acquisitionPlan.features.map((o) => o.properties?.satellite);

    if (
      (addedSatellite.length > 0 && !satellitesInStore.includes(addedSatellite[0])) ||
      this.state.date.getTime() !== prevState.date.getTime()
    ) {
      this.selectAcquisitionPlan();
    }
  }

  selectAcquisitionPlan = () => {
    if (this.state.satellites.size) {
      this.setState({ loading: true });
      this.props
        .fetchAcquisitionPlan({
          satellites: Array.from(this.state.satellites).join(','),
          datetime: format(this.state.date, 'yyyy-MM-dd') + 'T00:00:00Z',
        })
        .then(() => {
          this.setState({ loading: false });
        });
    }
  };

  componentWillUnmount() {
    document.title = TITLE;
  }

  onSatellitesChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const satellites = new Set(this.state.satellites);
    if (e.target.checked) {
      satellites.add(value);
    } else {
      satellites.delete(value);
    }
    this.setState({ satellites });
  };

  onCalendarChange = (day: Date) => {
    this.setState({ date: day });
  };

  render() {
    const satelliteColors = getSatelliteColors(this.props.theme);
    const { t, i18n } = this.props;

    return (
      <React.Fragment>
        <SidebarDrawer
          variant={isWidthDown('sm', this.props.width) ? 'temporary' : 'permanent'}
          open={this.state.acqPlannerDrawerOpen}
          style={{ marginTop: isWidthUp('sm', this.props.width) ? NAVIGATION_HEIGHT : '' }}
        >
          {isWidthUp('md', this.props.width) && <Toolbar />}
          <Grid
            container
            justify="center"
            spacing={1}
            style={{ padding: '10px 20px', width: '100%', margin: '-4px 0' }}
          >
            <Grid item>
              <Typography variant="h6" align="center" style={{ color: this.props.theme.palette.custom.colorfulText }}>
                {t('title')}
              </Typography>
              <Typography variant="body1" align="justify">
                {t('description')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Calendar
                value={this.state.date}
                onChange={(date: Date) => this.onCalendarChange(date)}
                locale={i18n.language}
              />
              <Grid container={true} justify="flex-start">
                {SATELLITES.map((satelliteName: string) => {
                  return (
                    <Grid key={`${satelliteName}-satellites`} item={true}>
                      <FormControlLabel
                        label={satelliteName}
                        control={
                          <CheckboxSatellite
                            color="primary"
                            checkedColor={satelliteColors[satelliteName]}
                            value={satelliteName}
                            checked={this.state.satellites.has(satelliteName)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              this.onSatellitesChange(e, satelliteName)
                            }
                          />
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="justify">
                {t('additionalInfo')}
                <Link
                  href="https://api.spectator.earth/"
                  target="_blank"
                  style={{ color: this.props.theme.palette.custom.colorfulText }}
                >
                  {' '}
                  API
                </Link>
                .
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" style={{ color: this.props.theme.palette.custom.colorfulText }} variant="h6">
                {t('dataSource')}
              </Typography>
              <Typography variant="body1" align="justify">
                {t('dataSourceInfo')}
                <Link
                  href={SENTINEL_1_ACQUISITION_PLAN_URL}
                  target="_blank"
                  style={{ color: this.props.theme.palette.custom.colorfulText }}
                >
                  {' '}
                  Sentinel-1
                </Link>
                ,
                <Link
                  href={SENTINEL_2_ACQUISITION_PLAN_URL}
                  target="_blank"
                  style={{ color: this.props.theme.palette.custom.colorfulText }}
                >
                  {' '}
                  Sentinel-2
                </Link>
                ,
                <Link
                  href={LANDSAT_ACQUISITION_PLAN_URL}
                  target="_blank"
                  style={{ color: this.props.theme.palette.custom.colorfulText }}
                >
                  {' '}
                  Landsat
                </Link>
              </Typography>
            </Grid>
            <Grid item>{!this.props.user && <LogoFooter />}</Grid>
          </Grid>
        </SidebarDrawer>
        {isWidthDown('sm', this.props.width) && (
          <SidebarDrawerToggle
            drawerOpen={this.state.acqPlannerDrawerOpen}
            handleClick={() => this.setState({ acqPlannerDrawerOpen: !this.state.acqPlannerDrawerOpen })}
          />
        )}
        <Main>
          <Map>
            {this.state.loading && <MapLoader />}
            {this.state.satellites.size &&
              this.props.acquisitionPlan.features
                .filter((o) => this.state.satellites.has(o.properties?.satellite))
                .map((o, index: number) => {
                  const geometry = flip(o.geometry as GeoPolygon);
                  return (
                    <Polygon
                      key={`acq_${o.properties?.satellite}_${o.properties?.begin_time}_${index}`}
                      positions={geometry.coordinates as LatLngTuple[][]}
                      color={satelliteColors[o.properties?.satellite]}
                    >
                      <Popup>
                        {map(o.properties, (value, key) => {
                          return (
                            <div key={`Popup_${key}_${index}`}>
                              {key} : {value || ' -'}{' '}
                            </div>
                          );
                        })}
                      </Popup>
                    </Polygon>
                  );
                })}
          </Map>
        </Main>
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (state: ApplicationState): StoreProps => {
  return {
    acquisitionPlan: acquisitionPlanSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchAcquisitionPlan: (result) => {
      return dispatch({
        ...acquisitionPlan.get.request(result),
        [WAIT_FOR_ACTION]: acquisitionPlan.get.SUCCESS,
      });
    },
  };
};

export default withTranslation('acquisitionPlan')(
  connect(mapStoreToProps, mapDispatchToProps)(withTheme(withWidth()(AcquisitionPlan))),
);
