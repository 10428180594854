import { Reducer } from 'redux';
import { ChannelsState, Channel } from './types';
import { channel, channelAOIs, channels } from './actions';

import _ from 'lodash';
import { logout } from '../auth/actions';

const initialState: ChannelsState = {
  data: [],
  aois: [],
  errors: undefined,
};

const reducer: Reducer<ChannelsState> = (state = initialState, action) => {
  switch (action.type) {
    case channel.patch.SUCCESS:
    case channel.get.SUCCESS: {
      const data = _.sortBy([..._.filter(state.data, (o) => o.id !== action.payload.id), action.payload], (channel) =>
        channel.name.toLowerCase(),
      );
      return { ...state, data };
    }

    case channel.delete.SUCCESS: {
      return { ...state, data: state.data.filter((c) => c.name !== action.request_action.payload.channelName) };
    }

    case channelAOIs.get.SUCCESS: {
      return { ...state, aois: action.payload.features };
    }

    case channels.get.REQUEST: {
      return { ...state };
    }

    case channels.get.SUCCESS: {
      return { ...state, data: action.payload };
    }

    case channels.get.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case channels.post.SUCCESS: {
      const data = _.sortBy([...state.data, action.payload], (channel: Channel) => channel.name.toLowerCase());
      return { ...state, data };
    }

    case channels.post.FAILURE: {
      return { ...state, ...action.payload };
    }

    case logout.SUCCESS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { reducer as channelsReducer };
