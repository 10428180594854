import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button, Input, InputLabel } from '../components/form';
import { resetPasswordConfirm as resetPasswordConfirmAction } from '../store/auth/actions';
import { CALLBACK_ERROR_ARGUMENT, ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ErrorMessage, SuccessMessage } from '../components/messages';

import { isEmpty } from 'lodash';
import { UserProps } from '../store/auth/types';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';

const Form = styled.form`
  text-align: center;
  padding: 10px;
`;
Form.displayName = 'Form';

const Container = styled.div`
  margin: 30vh auto;
`;

const SubmitButton = styled(Button)`
  margin-top: 30px !important;
`;

export interface MatchParams {
  token: string;
  uid: string;
}

interface DispatchProps {
  resetPasswordConfirm: (password1: string, password2: string, token: string, uid: string) => any;
}

interface State {
  errors: any;
  success: boolean;
  password1: string;
  password2: string;
}

type Props = DispatchProps &
  RouteComponentProps<MatchParams> &
  UserProps &
  WithTranslation<['common', 'resetPasswordConfirm']>;

const REDIRECT_URL = '/auth/signin';

const ResetPasswordConfirm: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation(['common', 'resetPasswordConfirm']);
  const [state, setState] = React.useState<State>({
    errors: {},
    success: false,
    password1: '',
    password2: '',
  });

  const { uid, token } = props.match.params;
  if (props.user || !uid || !token) {
    props.history.push(REDIRECT_URL);
    return null;
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props
      .resetPasswordConfirm(state.password1, state.password2, token, uid)
      .then(() => {
        setState((prevState) => ({ ...prevState, success: true, errors: {} }));
      })
      .catch((errors: any) => {
        setState((prevState) => ({ ...prevState, errors }));
      });
  };

  const { errors } = state;
  return (
    <Container>
      {state.success && (
        <SuccessMessage>
          {t('resetPasswordConfirm:successMessage1')}
          <br />
          {t('resetPasswordConfirm:successMessage2')}
          <Link to={REDIRECT_URL}>{t('resetPasswordConfirm:signIn')}</Link>
        </SuccessMessage>
      )}
      {(errors.uid || errors.token || errors.non_field_errors) && (
        <ErrorMessage>{t('resetPasswordConfirm:errorMessage')}</ErrorMessage>
      )}
      <Form onSubmit={handleSubmit}>
        <FormControl fullWidth={true} required={true} error={!isEmpty(errors.new_password1)}>
          <InputLabel style={{ textTransform: 'capitalize' }}>{t('common:password')}</InputLabel>
          <Input name="password1" type="password" onChange={handleChange} />
          <FormHelperText>{errors.new_password1}</FormHelperText>
        </FormControl>

        <FormControl fullWidth={true} required={true} error={!isEmpty(errors.new_password2)}>
          <InputLabel>{t('resetPasswordConfirm:confirmPassword')}</InputLabel>
          <Input name="password2" type="password" onChange={handleChange} />
          <FormHelperText>{errors.new_password2}</FormHelperText>
        </FormControl>
        <SubmitButton variant="outlined" type="submit" color="primary">
          {t('resetPasswordConfirm:changePassword')}
        </SubmitButton>
      </Form>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    resetPasswordConfirm: (password1, password2, token, uid) => {
      return dispatch({
        ...resetPasswordConfirmAction.request({ password1, password2, token, uid }),
        [WAIT_FOR_ACTION]: resetPasswordConfirmAction.SUCCESS,
        [ERROR_ACTION]: resetPasswordConfirmAction.FAILURE,
        [CALLBACK_ERROR_ARGUMENT]: (action: any) => action.payload.errors,
      });
    },
  };
};

export default withTranslation(['common', 'resetPasswordConfirm'])(
  connect(null, mapDispatchToProps)(ResetPasswordConfirm),
);
