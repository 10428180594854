import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { WAIT_FOR_ACTION, ERROR_ACTION, CALLBACK_ERROR_ARGUMENT } from 'redux-wait-for-action';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';

import { Input, InputLabel, Button } from '../components/form';
import { passwordChange } from '../store/auth/actions';
import { withTranslation, WithTranslation } from 'react-i18next';

const Loader = styled(CircularProgress as React.FunctionComponent<CircularProgressProps>)`
  position: absolute;
  left: calc(50% - 20px);
  margin-top: 20%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
Form.displayName = 'Form';

interface State {
  dialogOpen: boolean;
  formShow: boolean;
  loader: boolean;
  newPassword1: string;
  newPassword2: string;
  oldPassword: string;
  errors: string;
}

interface DispatchProps {
  changePassword: (oldPassword: string, newPassword1: string, newPassword2: string) => any;
}

export class ChangePassword extends React.Component<DispatchProps & WithTranslation<'changePassword'>, State> {
  readonly state: State = {
    dialogOpen: false,
    formShow: true,
    loader: false,
    newPassword1: '',
    newPassword2: '',
    oldPassword: '',
    errors: '',
  };

  changePassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ loader: true });
    this.props
      .changePassword(this.state.oldPassword, this.state.newPassword1, this.state.newPassword2)
      .then(() => this.setState({ formShow: false, loader: false }))
      .catch((errors: any) => {
        const error: any = Object.values(errors)[0];
        this.setState({ errors: error[0], loader: false });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Button disableElevation={true} onClick={() => this.setState({ dialogOpen: true })} variant={'outlined'}>
          {t('change')}
        </Button>
        <Dialog
          open={this.state.dialogOpen}
          onClose={() =>
            this.setState({
              dialogOpen: false,
              errors: '',
              formShow: true,
            })
          }
          fullWidth={true}
          maxWidth="xs"
          transitionDuration={0}
        >
          <Box style={{ margin: '30px' }}>
            {this.state.loader && <Loader color="primary" />}
            {this.state.formShow && (
              <Form onSubmit={(event) => this.changePassword(event)}>
                <FormControl fullWidth={true}>
                  <InputLabel>{t('current')}</InputLabel>
                  <Input
                    name="oldPassword"
                    type="password"
                    onChange={(e) => this.setState({ oldPassword: e.target.value })}
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <InputLabel>{t('new')}</InputLabel>
                  <Input
                    name="newPassword1"
                    type="password"
                    onChange={(e) => this.setState({ newPassword1: e.target.value })}
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <InputLabel>{t('repeat')}</InputLabel>
                  <Input
                    name="newPassword2"
                    type="password"
                    onChange={(e) => this.setState({ newPassword2: e.target.value })}
                  />
                </FormControl>
                <Typography color="error" variant="subtitle2">
                  {this.state.errors}
                </Typography>
                <Button
                  variant="outlined"
                  color="inherit"
                  type="submit"
                  disabled={!this.state.newPassword1 || !this.state.newPassword2}
                  style={{ minWidth: '100px', marginTop: '20px' }}
                >
                  {t('change')}
                </Button>
              </Form>
            )}
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {!this.state.formShow && (
                <React.Fragment>
                  <Typography>{t('success')}</Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        dialogOpen: false,
                        formShow: true,
                        errors: '',
                      })
                    }
                    style={{ minWidth: '100px', marginTop: '20px' }}
                  >
                    OK
                  </Button>
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    changePassword: (oldPassword: string, newPassword1: string, newPassword2: string) => {
      return dispatch({
        ...passwordChange.post.request({
          old_password: oldPassword,
          new_password1: newPassword1,
          new_password2: newPassword2,
        }),
        [WAIT_FOR_ACTION]: passwordChange.post.SUCCESS,
        [ERROR_ACTION]: passwordChange.post.FAILURE,
        [CALLBACK_ERROR_ARGUMENT]: (action: any) => action.payload.errors,
      });
    },
  };
};

export default withTranslation('changePassword')(connect(null, mapDispatchToProps)(ChangePassword));
