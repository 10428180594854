import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, Input, InputLabel } from './form';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

import { resetPassword } from '../store/auth/actions';
import { WithTranslation, withTranslation } from 'react-i18next';

const Form = styled.form`
  text-align: center;
  padding: 50px 50px 30px;
`;

Form.displayName = 'Form';

const ForgotPassword = styled(Link)`
  cursor: pointer;
  font-size: 0.8em;
  &.MuiTypography-colorPrimary {
    color: ${() => useTheme().palette.custom.colorfulText};
  }
`;

ForgotPassword.displayName = 'ForgotPassword';

const ButtonReset = styled.div`
  margin: 20px 10px 0px 10px;
`;

const Container = styled.div`
  text-align: center;
  padding: 20px;
`;

Container.displayName = 'Container';

interface State {
  resetPasswordDialog: boolean;
  email: string;
  formShow: boolean;
}

interface DispatchProps {
  sendEmail: (email: string) => any;
}

const INITIAL_STATE = { resetPasswordDialog: false, email: '', formShow: true };

export class ResetPassword extends React.Component<DispatchProps & WithTranslation<'resetPassword'>, State> {
  state: State = INITIAL_STATE;

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.sendEmail(this.state.email);
    this.setState({ formShow: false });
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <ForgotPassword onClick={() => this.setState({ resetPasswordDialog: true })}>
          {t('forgotPassword')}
        </ForgotPassword>

        <Dialog
          open={this.state.resetPasswordDialog}
          onClose={() => this.setState({ resetPasswordDialog: false })}
          fullWidth={true}
          maxWidth="xs"
          transitionDuration={0}
        >
          {this.state.formShow && (
            <Form onSubmit={(event) => this.onSubmit(event)}>
              <FormControl fullWidth={true}>
                <InputLabel>E-mail</InputLabel>
                <Input name="email" type="email" onChange={(e) => this.setState({ email: e.target.value })} />
              </FormControl>
              <ButtonReset>
                <Button variant="outlined" type="submit" disabled={!this.state.email}>
                  {t('send')}
                </Button>
              </ButtonReset>
            </Form>
          )}
          {!this.state.formShow && (
            <Container>
              <Box mb={2}>
                <Typography>{t('info', { email: this.state.email })}</Typography>
              </Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.setState(INITIAL_STATE);
                }}
              >
                OK
              </Button>
            </Container>
          )}
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    sendEmail: (email) => dispatch(resetPassword.post.request({ email })),
  };
};

export default withTranslation('resetPassword')(connect(null, mapDispatchToProps)(ResetPassword));
