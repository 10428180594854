import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';

import { Satellite } from '../store/satellites/types';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { uniq } from 'lodash';

const StyledTableRow = styled(TableRow)`
  cursor: pointer;

  &&&.Mui-selected {
    background-color: ${() => useTheme().palette.primary.main};

    .MuiTableCell-body {
      color: white;
    }
  }
`;

const CapitalizeTableCell = styled(TableCell)`
  text-transform: capitalize;
`;

interface Props {
  satellites: Satellite[];
  onSatelliteChange: (checked: boolean, satellite: Satellite) => void;
  selected: number[];
}

export const SatellitesList: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Table size="small" stickyHeader style={{ backgroundColor: 'white !important' }}>
      <TableHead>
        <TableRow>
          <CapitalizeTableCell>{t('name')}</CapitalizeTableCell>
          <CapitalizeTableCell>{t('openData')}</CapitalizeTableCell>
          <CapitalizeTableCell>{t('sensor', { count: 0 })}</CapitalizeTableCell>
          <TableCell>NORAD ID</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.satellites.map((satellite: Satellite) => (
          <StyledTableRow
            key={satellite.properties.norad_id}
            hover={true}
            selected={props.selected.includes(satellite.id)}
            onClick={() => props.onSatelliteChange(!props.selected.includes(satellite.id), satellite)}
          >
            <TableCell>{satellite.properties.name}</TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>
              {satellite.properties.open ? t('yes') : t('no')}
            </TableCell>
            <TableCell>{uniq(satellite.properties.modes.map((s) => t(s.sensor_type))).join(', ')}</TableCell>
            <TableCell>{satellite.properties.norad_id}</TableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};
