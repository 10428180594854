import React, { useState } from 'react';
import { PolygonLayer, TileLayer } from '../store/app/types';
import { connect, useDispatch } from 'react-redux';
import { ApplicationState } from '../store';
import { commercialMapTilesSelector, mapPolygonsSelector, mapTilesSelector } from '../store/app/selectors';
import { Dispatch } from 'redux';
import { commercialMapTiles, mapTiles, mapPolygons, setMapBounds } from '../store/app/actions';
import { Button, Chip, Grid, Paper, Typography, Divider, Fade } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import { CenterFocusStrong, Visibility, VisibilityOff } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { ImageState } from '../store/observations/types';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import ImageryView from './imagery-view';
import { Imagery } from '../store/imageries/types';
import LabelsLayerSwitcher from '../components/map-components/labels-layer-switcher';
import TrendingLayerSwitcher from '../components/map-components/trending-layer-switcher';
import { CenteredGrid } from '../components/layout';
import { convertPolygonToLeafletBBOX } from '../utils/geo';
import { WithTranslation, withTranslation } from 'react-i18next';

const LayerButton = styled(Button).attrs({ color: 'inherit', size: 'small' })`
  &.MuiButton-root {
    min-width: 20px;
  }
`;

interface DispatchProps {
  updateCommercialMapTiles: (uuid: string, parameters: Partial<TileLayer>) => any;
  updateMapTiles: (uuid: string, parameters: Partial<TileLayer>) => any;
  deleteMapTiles: (uuid?: string) => any;
  updateMapPolygon: (id: string, parameters: Partial<PolygonLayer>) => any;
}

interface State {
  selectedTiles?: TileLayer;
}

interface StoreProps {
  mapPolygons: PolygonLayer[];
  mapTiles: TileLayer[];
  commercialMapTiles: TileLayer[];
}

type Props = DispatchProps & StoreProps;

interface LayerControllerProps<T> {
  title: string;
  layers: T[];
  onChangeVisibility: (id: string, parameters: Partial<T>) => void;
  onEdit?: (id: string, parameters: Partial<T>) => void;
  onDelete?: (id: string) => void;
}

const GeometriesLayerController = (props: LayerControllerProps<PolygonLayer>) => {
  return (
    <>
      <Grid item={true} xs={12}>
        <Typography variant="body2" style={{ fontFamily: '"industry-bold"', textTransform: 'capitalize' }}>
          {props.title}
        </Typography>
      </Grid>

      <Grid container={true} id={`polygons-list`} style={{ overflowY: 'auto', maxHeight: '20vh' }}>
        {props.layers.map((polygon) => (
          <Grid item={true} key={`polygon-${polygon.id}`}>
            <Chip
              deleteIcon={polygon.hidden ? <VisibilityOff /> : <Visibility />}
              title={polygon.name}
              color={'default'}
              size={'small'}
              label={polygon.name}
              onDelete={() => props.onChangeVisibility(polygon.id, { hidden: !polygon.hidden })}
              style={{ maxWidth: 250 }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const TilesLayerController = (props: LayerControllerProps<TileLayer>) => {
  const [selectedLayer, setSelectedLayer] = useState<TileLayer>();
  const dispatch = useDispatch();

  const isLayerEqual = (first?: TileLayer, seconds?: TileLayer): boolean =>
    Boolean(first && seconds && first.imagery.uuid === seconds.imagery.uuid);

  return (
    <>
      <DividerGrid />
      <Grid item={true} xs={12}>
        <Typography variant="body2" style={{ fontFamily: '"industry-bold"' }}>
          {props.title}
        </Typography>
      </Grid>
      <Grid container={true} id={'tiles-list'} style={{ overflowY: 'auto', maxHeight: '40vh' }}>
        {props.layers.map((layer, idx) => (
          <Grid key={`tiles-${idx}`} container={true} alignItems={'center'}>
            <Grid item={true} xs={8}>
              <Typography
                variant={'body2'}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                title={layer.name}
              >
                {layer.name}
              </Typography>
            </Grid>
            <Grid item={true} xs={4} style={{ textAlign: 'center' }}>
              <LayerButton
                onClick={() => {
                  dispatch(setMapBounds.request(convertPolygonToLeafletBBOX(layer.imagery.geometry)));
                }}
              >
                <CenterFocusStrong fontSize={'small'} />
              </LayerButton>
              <LayerButton
                onClick={() => {
                  if (selectedLayer) {
                    setSelectedLayer(layer);
                  }
                  props.onChangeVisibility(layer.imagery.uuid, {
                    imageryState: {
                      ...layer.imageryState,
                      hidden: !layer.imageryState?.hidden,
                    },
                  });
                }}
              >
                {layer.imageryState?.hidden ? <VisibilityOff fontSize={'small'} /> : <Visibility fontSize={'small'} />}
              </LayerButton>
              {props.onEdit && (
                <LayerButton
                  onClick={() => {
                    props.onEdit &&
                      props.onEdit(layer.imagery.uuid, {
                        imageryState: {
                          ...layer.imageryState,
                          hidden: false,
                        },
                      });
                    setSelectedLayer(layer);
                  }}
                >
                  <TuneIcon fontSize={'small'} />
                </LayerButton>
              )}
              {props.onDelete && (
                <LayerButton onClick={() => props.onDelete && props.onDelete(layer.imagery.uuid)}>
                  <DeleteIcon fontSize={'small'} />
                </LayerButton>
              )}
            </Grid>
            {props.onEdit && (
              <Fade in={isLayerEqual(selectedLayer, layer)} timeout={100}>
                <Paper>
                  <ImageryView
                    imagery={layer.imagery as Imagery}
                    onImageryEdit={(parameters: ImageState) => {
                      const name = parameters.annotation || layer.imageryState?.annotation || layer?.name;
                      props.onEdit &&
                        props.onEdit(layer.imagery.uuid, { name, imageryState: { ...parameters, hidden: false } });
                    }}
                    onClose={() => setSelectedLayer(undefined)}
                    imageState={layer.imageryState}
                  />
                </Paper>
              </Fade>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const DividerGrid = () => (
  <Grid item={true} xs={12} style={{ marginTop: '10px', marginBottom: '10px' }}>
    <Divider />
  </Grid>
);

export class LayersController extends React.Component<Props & WithTranslation<['common', 'layersController']>, State> {
  readonly state: State = {
    selectedTiles: undefined,
  };

  render() {
    const { t } = this.props;

    return (
      <Grid container={true}>
        {!isEmpty(this.props.mapPolygons) && (
          <GeometriesLayerController
            title={t('common:geometry', { count: 0 })}
            layers={this.props.mapPolygons}
            onChangeVisibility={this.props.updateMapPolygon}
          />
        )}

        {!isEmpty(this.props.mapTiles) && (
          <TilesLayerController
            title={t('common:image', { count: 0 })}
            layers={this.props.mapTiles}
            onEdit={this.props.updateMapTiles}
            onChangeVisibility={this.props.updateMapTiles}
            onDelete={this.props.deleteMapTiles}
          />
        )}

        {!isEmpty(this.props.commercialMapTiles) && (
          <TilesLayerController
            title={t('layersController:highRes')}
            layers={this.props.commercialMapTiles}
            onChangeVisibility={this.props.updateCommercialMapTiles}
          />
        )}

        {(!isEmpty(this.props.mapTiles) ||
          !isEmpty(this.props.commercialMapTiles) ||
          !isEmpty(this.props.mapPolygons)) && <DividerGrid />}
        <CenteredGrid style={{ margin: '0 auto' }}>
          <LabelsLayerSwitcher />
          <TrendingLayerSwitcher />
        </CenteredGrid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateCommercialMapTiles: (uuid, parameters) => dispatch(commercialMapTiles.update({ uuid, parameters })),
  updateMapTiles: (uuid, parameters) => dispatch(mapTiles.update({ uuid, parameters })),
  deleteMapTiles: (uuid) => dispatch(mapTiles.delete({ uuid })),
  updateMapPolygon: (id, parameters) => dispatch(mapPolygons.update({ id, parameters })),
});

const mapStoreToProps = (state: ApplicationState): StoreProps => ({
  mapPolygons: mapPolygonsSelector(state),
  mapTiles: mapTilesSelector(state),
  commercialMapTiles: commercialMapTilesSelector(state),
});

export default withTranslation(['common', 'layersController'])(
  connect(mapStoreToProps, mapDispatchToProps)(LayersController),
);
