import React, { useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
  ExpansionPanelActions,
  Button,
  DialogContent,
  Dialog,
  Typography,
  DialogActions,
} from '@material-ui/core';
import { MdExpandLess } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

import DebouncedTextField from '../debounced-text-field';
import { useTranslation } from 'react-i18next';

const ExpansionPanelWrapper = styled.div`
  max-height: 80%;
  overflow: auto;
  width: 40%;
  position: absolute;
  bottom: 20px;
  right: 10px;
  padding: 10px;
`;

interface Props {
  canEdit?: boolean;
  content: string;
  onEdit: (content: string) => void;
}

export const Description: React.FC<Props> = (props: Props) => {
  const [content, setContent] = useState<string>(props.content);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation(['widgets', 'common']);

  return (
    <ExpansionPanelWrapper>
      <ExpansionPanel elevation={10} defaultExpanded>
        <ExpansionPanelSummary expandIcon={<MdExpandLess />}>{t('widgets:description.title')}</ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {editMode ? (
            <DebouncedTextField
              color="primary"
              delay={100}
              placeholder={t('widgets:description.placeholder') + '.'}
              multiline
              variant="outlined"
              value={content}
              fullWidth
              onChange={(value: string) => setContent(value)}
            />
          ) : (
            <div>
              <ReactMarkdown source={props.content} />
            </div>
          )}
        </ExpansionPanelDetails>
        {props.canEdit && (
          <React.Fragment>
            <Divider />
            <ExpansionPanelActions>
              {editMode ? (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEditMode(false);
                      props.onEdit(content);
                    }}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {t('common:save')}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setEditMode(false)}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {t('common:cancel')}
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditMode(true)}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {t('common:edit')}
                  </Button>
                  {content && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setConfirmDeleteDialog(true)}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('common:delete')}
                    </Button>
                  )}
                  {!content && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => props.onEdit('')}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('common:close')}
                    </Button>
                  )}
                  <Dialog open={confirmDeleteDialog} onClose={() => setConfirmDeleteDialog(false)}>
                    <DialogContent>
                      <Typography>{t('widgets:description.confirmDeleteDialog')}</Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          props.onEdit('');
                          setConfirmDeleteDialog(false);
                        }}
                        style={{ color: theme.palette.custom.colorfulText }}
                      >
                        {t('widgets:description.confirmDelete')}
                      </Button>
                      <Button autoFocus color="secondary" onClick={() => setConfirmDeleteDialog(false)}>
                        {t('widgets:description.cancelDelete')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </React.Fragment>
              )}
            </ExpansionPanelActions>
          </React.Fragment>
        )}
      </ExpansionPanel>
    </ExpansionPanelWrapper>
  );
};
