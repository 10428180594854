import { Reducer } from 'redux';
import { notifications, notificationsUnreadCount } from './actions';
import { FollowType, Notification, NotificationsState } from './types';

const initialState: NotificationsState = {
  data: [],
  unreadCount: 0,
};

const reducer: Reducer<NotificationsState> = (state = initialState, action) => {
  switch (action.type) {
    case notifications.get.SUCCESS: {
      let data: Notification[] = state.data;

      if (action.request_action.payload?.timestamp_to || action.request_action.payload?.timestamp_from) {
        data =
          action.payload.slice(-1)[0].timestamp > state.data[0].timestamp
            ? [...action.payload, ...state.data]
            : [...state.data, ...action.payload];
      } else {
        data = action.payload;
      }

      const regex = /<b>(.*?)<\/b>/gm;

      data = data.map((n) => {
        let date, observationName, satelliteName;
        switch (n.codename) {
          case FollowType.Imagery:
            [observationName, satelliteName] = Array.from(n.body.matchAll(regex), (m) => m[1]);
            date = n.title.split('! ')[1];

            return {
              ...n,
              date,
              satelliteName,
              observationName,
            };

          case FollowType.Overpass:
            [satelliteName, observationName] = Array.from(n.title.matchAll(regex), (m) => m[1]);
            date = n.title.split('>')[-1];

            return {
              ...n,
              date,
              satelliteName,
              observationName,
            };

          default:
            return n;
        }
      });

      return { ...state, data };
    }

    case notifications.post.REQUEST: {
      return { ...state, data: state.data.map((n: Notification) => ({ ...n, read: true })) };
    }

    case notificationsUnreadCount.get.SUCCESS: {
      return { ...state, unreadCount: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { reducer as notificationReducer };
