import jsCookie from 'js-cookie';

import { ApplicationState } from '../index';
import { ThemeMode, UserSettings } from './types';
import { DARK_MODE_COOKIE_NAME, THEME_COOKIE_NAME } from '../../constants';
import { DEFAULT_USER_SETTINGS } from './constants';

export const actionInProgressSelector = (state: ApplicationState) => state.auth.actionInProgress;
export const userSelector = (state: ApplicationState) => state.auth.user;

export const loginErrorsSelector = (state: ApplicationState) => state.auth.loginErrors;
export const signupErrorsSelector = (state: ApplicationState) => state.auth.signupErrors;

export const userSettingsSelector = (state: ApplicationState): UserSettings => {
  const getThemeValue = () => {
    const themeCookieValue = jsCookie.get(THEME_COOKIE_NAME);
    // additional logic added to cater for exisiting users that had darkMode set to true
    const darkModeCookieValue = jsCookie.get(DARK_MODE_COOKIE_NAME);

    if (typeof themeCookieValue !== 'undefined') {
      return themeCookieValue as ThemeMode;
    } else if (typeof darkModeCookieValue !== 'undefined') {
      return darkModeCookieValue === 'true' ? ThemeMode.DARK : ThemeMode.LIGHT;
    } else if (state.auth.user?.hasOwnProperty('darkMode')) {
      return state.auth.user?._settings.darkMode ? ThemeMode.DARK : ThemeMode.LIGHT;
    } else {
      return ThemeMode.SYSTEM;
    }
  };

  return {
    ...DEFAULT_USER_SETTINGS,
    theme: getThemeValue(),
    ...state.auth.user?._settings,
  };
};
export const verificationEmailResentSelector = (state: ApplicationState) => state.auth.verificationEmailResent;
