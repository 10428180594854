import { createSelector } from 'reselect';

import { ApplicationState } from '../index';
import { convertPolygonToLeafletBBOX, mapBoundsToBboxPolygon } from '../../utils/geo';
import { Location, SuggestionType, RecentlyUsed, Alert } from './types';
import { setMapBounds } from './actions';
import { selectedSatellitesSelector } from '../satellites/selectors';
import { observation } from '../observations/actions';
import { Satellite } from '../satellites/types';
import { activeChannelSelector } from '../channels/selectors';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { push } from 'connected-react-router';
import { userSettingsSelector } from '../auth/selectors';
import { center } from '@turf/turf';

export const locationSelector = (state: ApplicationState) => state.app.locations;
export const recentlyUsedSelector = (state: ApplicationState) => state.app.recentlyUsed;
export const alertsSelector = (state: ApplicationState): Alert[] => state.app.alerts;

export const recentlyUsedAsSuggestionsSelector = createSelector([recentlyUsedSelector], (recentlyUsed) =>
  recentlyUsed.map((recent: RecentlyUsed) => ({
    action: push(`/observation/${recent.uuid}`),
    name: recent.name,
    type: 'observation' as SuggestionType,
  })),
);

export const locationsAsCreateObservationSuggestionsSelector = createSelector(
  [locationSelector, selectedSatellitesSelector, activeChannelSelector, userSettingsSelector],
  (locations, satellites, channel, userSettings) => {
    return locations.map((location: Location) => {
      const geometry = mapBoundsToBboxPolygon(convertPolygonToLeafletBBOX(location.geometry));
      const action = {
        ...observation.post.request({
          geometry: geometry,
          channel: channel ? channel.id : null,
          name: location.name,
          satellites: satellites.map((s: Satellite) => s.id),
          settings: {
            imageriesDisplay: userSettings.defaultImageriesDisplay,
            mapParameters: { zoom: 11, center: center(geometry).geometry.coordinates.reverse() as [number, number] },
          },
        }),
        [WAIT_FOR_ACTION]: observation.post.SUCCESS,
      };

      return {
        action: action,
        name: location.name,
        type: 'location' as SuggestionType,
      };
    });
  },
);

export const locationsAsSuggestionsSelector = createSelector([locationSelector], (locations) => {
  return locations.map((location: Location) => {
    const action = setMapBounds.request(convertPolygonToLeafletBBOX(location.geometry));

    return {
      action: action,
      name: location.name,
      type: 'location' as SuggestionType,
    };
  });
});

export const mapParametersSelector = (state: ApplicationState) => state.app.mapParameters;
export const mapBoundsSelector = (state: ApplicationState) => state.app.mapBounds;
export const mapPolygonsSelector = (state: ApplicationState) => state.app.mapPolygons;
export const mapTilesSelector = (state: ApplicationState) => state.app.mapTiles;
export const mapLabelsDisabledSelector = (state: ApplicationState) => state.app.mapLabelsDisabled;
export const showTrendingSelector = (state: ApplicationState) => state.app.showTrending;
export const commercialMapTilesSelector = (state: ApplicationState) => state.app.commercialMapTiles;
export const querySelector = (state: ApplicationState) => state.app.query;
