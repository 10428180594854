import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Typography, Box } from '@material-ui/core';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { useTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

import history from '../history';
import { commercialMapTiles } from '../store/app/actions';
import { commercialMapTilesSelector } from '../store/app/selectors';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: 'calc(50% + 37px)',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  bgcolor: 'background.paper',
  padding: '20px',
  textAlign: 'center',
  borderRadius: '4px',
};

const ModalContent = (props: { headerText: string; contentText: string; icon: JSX.Element }) => (
  <>
    {props.icon}
    <Typography variant="h5" color="textPrimary">
      {props.headerText}
    </Typography>
    <Typography variant="h6" color="textPrimary">
      {props.contentText}
    </Typography>
  </>
);

const PaymentStatus = () => {
  const { t } = useTranslation('paymentStatus');
  const theme = useTheme();
  const params = queryString.parse(history.location.search);
  const [showModal, setShowModal] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const commercialTiles = useSelector(commercialMapTilesSelector);

  const success = Boolean(params.success && parseInt(params.success.toString()));
  const identifier = params.identifier;

  useEffect(() => {
    if (!loaded && success && identifier) {
      const tile = commercialTiles.find((t) => t.imagery.uuid === identifier);

      if (tile) {
        dispatch(
          commercialMapTiles.update({
            uuid: params.identifier,
            parameters: {
              imageryState: {
                hidden: false,
              },
            },
          }),
        );
        setLoaded(true);
      }
    }
  }, [success, identifier, loaded, params, dispatch, commercialTiles]);

  if (isEmpty(params.success) || isEmpty(params.identifier)) {
    return null;
  }

  return (
    <Modal open={showModal} disableAutoFocus={true} onClose={() => setShowModal(false)}>
      <Box css={style}>
        {success && (
          <ModalContent
            headerText={t('success.headerText')}
            contentText={t('success.contentText')}
            icon={<BsCheckCircle size={60} color={theme.palette.success.main} />}
          />
        )}

        {!success && (
          <ModalContent
            headerText={t('failure.headerText')}
            contentText={t('failure.contentText')}
            icon={<BsXCircle size={50} color={theme.palette.warning.main} />}
          />
        )}
      </Box>
    </Modal>
  );
};

export default PaymentStatus;
