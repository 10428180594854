import React from 'react';
import { withCookies, Cookies } from 'react-cookie';

import { ThemeProvider, Theme } from '@material-ui/core/styles';
import { _lightTheme, _darkTheme } from '../theme';

import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { userSettingsSelector } from '../store/auth/selectors';
import { ThemeMode, UserSettings } from '../store/auth/types';

interface Props {
  cookies: Cookies;
  children: React.ReactNode;
  userSettings: UserSettings;
}

interface State {
  theme: Theme;
}

class ThemeSwitcher extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      theme: this._getTheme(),
    };
  }

  _getTheme = () => {
    switch (this.props.userSettings.theme) {
      case ThemeMode.DARK:
        return _darkTheme;
      case ThemeMode.LIGHT:
        return _lightTheme;
      case ThemeMode.SYSTEM:
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
          ? _darkTheme
          : _lightTheme;
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.userSettings.theme !== this.props.userSettings.theme) {
      this.setState({ theme: this._getTheme() });
    }
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={this.state.theme}>{this.props.children}</ThemeProvider>
      </div>
    );
  }
}

const mapStoreToProps = (state: ApplicationState) => {
  return {
    userSettings: userSettingsSelector(state),
  };
};

export default connect(mapStoreToProps)(withCookies(ThemeSwitcher));
