import { takeEvery } from 'redux-saga/effects';
import { overpass } from './actions';
import urls from '../urls';
import { genericAPIFunction } from '../../utils/api';

const fetchOverpass = genericAPIFunction(overpass.get, 'get', (payload) =>
  urls.overpassList({ observationUUID: payload.observationUUID }),
);

function* saga() {
  yield takeEvery(overpass.get.REQUEST, fetchOverpass);
}

export default saga;
