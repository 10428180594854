import React from 'react';
import { connect } from 'react-redux';
import { GeoJSON, Popup } from 'react-leaflet';
import turfBezier from '@turf/bezier-spline';
import styled from 'styled-components';
import { LineString } from 'geojson';

import { Satellite as SatelliteModel } from '../../store/satellites/types';
import MarkerAnimation from '../marker-animation';
import { useTheme } from '@material-ui/core/styles';
import { Dispatch } from 'redux';
import { satelliteWithTrajectory } from '../../store/satellites/actions';
import { withTranslation, WithTranslation } from 'react-i18next';

const TRAJECTORY_RESOLUTION = 1000000;

const SatellitePopup = styled(({ ...rest }) => <Popup {...rest} />)`
  z-index: 999;
  pointerevents: none;

  .leaflet-popup-content-wrapper {
    opacity: 0.9;
    border-radius: 3px;
    background: ${() => useTheme().palette.primary.main};
    color: white;
  }

  .leaflet-popup-tip-container {
    opacity: 0.9;

    .leaflet-popup-tip {
      background: ${() => useTheme().palette.primary.main};
    }
  }
`;

export interface SatelliteProps {
  active: boolean;
  color: string;
  satellite: SatelliteModel;
}

interface DispatchProps {
  fetchSatelliteDetails: (satellite: SatelliteModel) => any;
}

class Satellite extends React.Component<SatelliteProps & DispatchProps & WithTranslation> {
  componentDidMount() {
    if (!this.props.satellite.trajectory) {
      this.props.fetchSatelliteDetails(this.props.satellite);
    }
  }

  render() {
    const satellite = this.props.satellite;
    const satelliteGeometry = satellite.geometry;
    const trajectory = satellite.trajectory;

    if (satelliteGeometry && trajectory) {
      const trajectoryGeometry = turfBezier(trajectory, { resolution: TRAJECTORY_RESOLUTION }).geometry as LineString;
      const interval =
        (trajectory.properties.update_interval * 1000) /
        (trajectoryGeometry.coordinates.length / trajectory.geometry.coordinates.length);

      return (
        <React.Fragment>
          <MarkerAnimation startPosition={satelliteGeometry} line={trajectoryGeometry} refreshInterval={interval}>
            <SatellitePopup autoClose={false} closeOnClick={false} autoPan={false} background={this.props.color}>
              <div style={{ textTransform: 'capitalize' }}>
                {this.props.t('satellite', { count: 1 })}: <b>{satellite.properties.name}</b>
              </div>
              <div>NORAD ID: {satellite.properties.norad_id}</div>
              {satellite.properties.modes.length > 0 && (
                <div style={{ textTransform: 'capitalize' }}>
                  {this.props.t('sensor', { count: satellite.properties.modes.length })}:
                  {satellite.properties.modes.map((s) => ` ${this.props.t(s.sensor_type)}`).join(', ')}
                </div>
              )}
              <div style={{ textTransform: 'capitalize' }}>
                {this.props.t('openData')}:{' '}
                {satellite.properties.open ? this.props.t('yes').toUpperCase() + '!' : this.props.t('no') + ' ;('}
              </div>
            </SatellitePopup>
          </MarkerAnimation>
          <GeoJSON
            key={`trajectory-${trajectoryGeometry.coordinates[0]}-${trajectoryGeometry.coordinates.slice(-1)[0]}`}
            style={{
              color: this.props.color,
              weight: 1,
              dashArray: '20, 10',
            }}
            data={trajectoryGeometry}
          />
        </React.Fragment>
      );
    }

    return null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchSatelliteDetails: (satellite: SatelliteModel) => dispatch(satelliteWithTrajectory.get.request({ satellite })),
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(Satellite));
