import React, { useCallback, useState, useMemo } from 'react';
import { Map } from 'leaflet';
import { useMap, useMapEvent, Rectangle, MapContainer } from 'react-leaflet';
import { useEventHandlers } from '@react-leaflet/core';
import { useTheme } from '@material-ui/core';
import styled from 'styled-components';

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

const Container = styled.div`
  .leaflet-interactive {
    pointer-events: none !important;
  }
  .leaflet-popup {
    display: none;
  }
`;

interface MiniMapControlProps {
  position?: 'bottomleft' | 'topleft' | 'topright' | 'bottomright';
  children?: React.ReactNode;
  zoom?: number;
  size?: number;
}

interface MinimapBoundsProps {
  map: Map;
  zoom: number;
}

const MinimapBounds = ({ map, zoom }: MinimapBoundsProps) => {
  const minimap = useMap();
  const theme = useTheme();

  const onClick = useCallback(
    (e) => {
      map.setView(e.latlng, map.getZoom());
    },
    [map],
  );
  useMapEvent('click', onClick);

  const [bounds, setBounds] = useState(map.getBounds());
  const onChange = useCallback(() => {
    setBounds(map.getBounds());
    minimap.setView(map.getCenter(), zoom);
  }, [minimap, map, zoom]);

  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [onChange]);

  // @ts-ignore
  useEventHandlers({ instance: map }, handlers);

  return <Rectangle bounds={bounds} pathOptions={{ weight: 1, color: theme.palette.secondary.main, fillOpacity: 0 }} />;
};

const MinimapControl = ({ children, position, zoom, size }: MiniMapControlProps) => {
  const map = useMap();
  const mapZoom = zoom || 0;
  const mapSize = map.getSize();
  const mapSizeRatio = mapSize.x / mapSize.y;
  const containerSize = size || 100;

  const minimap = useMemo(
    () => (
      <MapContainer
        style={{ height: containerSize, width: containerSize * mapSizeRatio, border: 'solid 1px #80808042' }}
        center={map.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
      >
        <MinimapBounds map={map} zoom={mapZoom} />
        {children}
      </MapContainer>
    ),
    [children, containerSize, map, mapSizeRatio, mapZoom],
  );

  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  return (
    <div className={positionClass}>
      <Container className="leaflet-control">{minimap}</Container>
    </div>
  );
};

export default MinimapControl;
