import createSagaMiddleware from 'redux-saga';
import { History } from 'history';
import { applyMiddleware, compose, createStore, Store, AnyAction } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import createReduxWaitForMiddleware from 'redux-wait-for-action';
import reduxCookiesMiddleware, { getStateFromCookies } from 'redux-cookies-middleware';

import { ApplicationState, createReducer, rootSaga } from './store';
import reduxMapUrlStateMiddleware, { getMapStateFromUrl } from './middleware/redux-map-url-state-middleware';
import reduxUserLocaleMiddleware from './middleware/redux-auth-middleware';

export const MAP_PARAMETERS_STORE_PATH = 'app.mapParameters';
export const RECENTLY_USED_STORE_PATH = 'app.recentlyUsed';

export default function configureStore(history: History, initialState: ApplicationState): Store<ApplicationState> {
  const sagaMiddleware = createSagaMiddleware();

  const reduxCookiesPaths = {
    [RECENTLY_USED_STORE_PATH]: { name: 'recently_used' },
  };

  const middleware = [
    sagaMiddleware,
    routerMiddleware(history),
    createReduxWaitForMiddleware(),
    reduxCookiesMiddleware(reduxCookiesPaths),
    reduxMapUrlStateMiddleware(MAP_PARAMETERS_STORE_PATH),
    reduxUserLocaleMiddleware,
  ];
  const enhancers = [];

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }

    middleware.push(createLogger({ collapsed: true, duration: true }));
  }

  const reducer = createReducer(history);

  let state = { ...reducer(undefined, {} as AnyAction), ...(initialState || {}) };

  state = getStateFromCookies(state, reduxCookiesPaths);
  state = getMapStateFromUrl(state, MAP_PARAMETERS_STORE_PATH);

  const store = createStore(reducer, state, compose(applyMiddleware(...middleware), ...enhancers));

  sagaMiddleware.run(rootSaga);
  return store;
}
