import React from 'react';
import { Main } from '../components/layout';
import MapComponent from '../containers/map';
import { useSelector } from 'react-redux';
import { userSelector } from '../store/auth/selectors';
import { Redirect } from 'react-router-dom';

const CreateObservation = () => {
  const user = useSelector(userSelector);

  if (user) {
    return <Redirect to="/channels" />;
  }

  return (
    <>
      <div style={{ display: 'flex', width: '100%' }}>
        <Main>
          <MapComponent />
        </Main>
      </div>
    </>
  );
};

CreateObservation.displayName = 'CreateObservation';

export default CreateObservation;
