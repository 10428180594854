import { takeEvery } from 'redux-saga/effects';

import { genericAPIFunction } from '../../utils/api';
import urls from '../urls';
import { config } from './actions';

const fetchConfig = genericAPIFunction(config.get, 'get', urls.config);

function* saga() {
  yield takeEvery(config.get.REQUEST, fetchConfig);
}

export default saga;
