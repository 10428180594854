import React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Button,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { withRouter } from 'react-router';
import { withTheme, withStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MdDelete, MdEdit } from 'react-icons/md';
import { difference, isEmpty } from 'lodash';
import { Dispatch } from 'redux';
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { connect } from 'react-redux';

import { Channel } from '../store/channels/types';
import { Observation } from '../store/observations/types';
import { RouteComponentProps } from 'react-router-dom';
import { ChangeChannelNameDialog, DeleteChannelDialog, EmptyChannelInfo } from '../components/channel-view-elements';
import { ApplicationState } from '../store';
import { observationsByChannelIdSelector } from '../store/observations/selectors';
import { channel } from '../store/channels/actions';
import { observation } from '../store/observations/actions';
import { setSatellitesSelected } from '../store/satellites/actions';
import EditableText from '../components/editable-text';
import { flip } from '@turf/turf';
import { LatLngExpression } from 'leaflet';

import { UserProps } from '../store/auth/types';
import { withUser } from '../hoc';
import { satelliteByIdSelector } from '../store/satellites/selectors';
import { Satellite } from '../store/satellites/types';
import { ThemeProps } from '../theme';
import { mapPolygons } from '../store/app/actions';
import { PolygonLayer } from '../store/app/types';
import { WithTranslation, withTranslation } from 'react-i18next';

const Accordion = withStyles(
  (theme: Theme) => ({
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  }),
  { withTheme: true },
)(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

interface ComponentProps {
  channel: Channel;
  active: boolean;
  expanded: boolean;
  onClick: (channelId: number) => void;
  onDelete: (channelId: number) => void;
}

interface DispatchProps {
  addPolygonToMap: (data: PolygonLayer) => any;
  clearMapPolygon: (id?: string) => any;
  deleteObservation: (observationUUID: string) => any;
  fetchChannel: (channelId: number) => void;
  fetchObservation: (observationUUID: string) => any;
  setSatellitesSelected: (satelliteIds: number[]) => void;
  updateChannel: (channelId: number, parameters: Partial<Channel>) => void;
}

interface StoreProps {
  observationsByChannelId: (channelId: number) => Observation[];
  satelliteById: (satelliteId: number) => Satellite;
}

type Props = ComponentProps &
  DispatchProps &
  RouteComponentProps &
  StoreProps &
  UserProps &
  ThemeProps &
  WithTranslation<['channelView', 'common']>;

interface State {
  dialog?: 'delete' | 'edit';
  loading: boolean;
  actionButtons: boolean;
  selectedObservationsUUID: Array<string>;
}

export class ChannelView extends React.Component<Props, State> {
  readonly state: State = {
    loading: false,
    actionButtons: false,
    selectedObservationsUUID: [],
  };

  componentDidMount() {
    this.props.setSatellitesSelected([]);
    if (this.props.expanded) {
      this.onExpand();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!prevProps.expanded && this.props.expanded) {
      this.onExpand();
    }
  }

  componentWillUnmount() {
    this.props.clearMapPolygon();
  }

  addObservationsGeometriesToMap = () => {
    this.props.clearMapPolygon();
    const channelObservations = this.props.observationsByChannelId(this.props.channel.id);

    channelObservations.forEach((observation) => {
      this.props.addPolygonToMap({
        name: `${observation.name}`,
        id: observation.uuid,
        positions: flip(observation.geometry).coordinates as LatLngExpression[][],
        url: `/observation/${observation.uuid}`,
      });
    });
  };

  fetchMissingObservations = async () => {
    const observationsToFetch = difference(
      this.props.channel.observations,
      this.props.observationsByChannelId(this.props.channel.id).map((o) => o.uuid),
    );

    return await Promise.all(
      observationsToFetch.map((observationUUID: string) => this.props.fetchObservation(observationUUID)),
    );
  };

  onExpand = async () => {
    const { channel } = this.props;
    this.setState({ loading: true });

    if (channel.observations.length !== this.props.observationsByChannelId(channel.id).length) {
      this.setState({ loading: true, selectedObservationsUUID: [] });

      await this.fetchMissingObservations()
        .then(() => {
          this.addObservationsGeometriesToMap();
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.addObservationsGeometriesToMap();
      this.setState({ loading: false });
    }
  };

  onDialogClose = () => {
    this.setState({ dialog: undefined });
  };

  onObservationSelect = (observationUUID: string, checked: boolean) => {
    let { selectedObservationsUUID } = this.state;

    if (checked) {
      selectedObservationsUUID.push(observationUUID);
    } else {
      selectedObservationsUUID = selectedObservationsUUID.filter((uuid) => uuid !== observationUUID);
    }
    this.setState({ selectedObservationsUUID });
  };

  deleteSelectedObservations = () => {
    this.setState({ loading: true });

    Promise.all(
      this.state.selectedObservationsUUID.map((observationUUID) =>
        this.props.deleteObservation(observationUUID).then(() => this.props.clearMapPolygon(observationUUID)),
      ),
    )
      .then(() => {
        this.props.fetchChannel(this.props.channel.id);
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    this.setState({ selectedObservationsUUID: [] });
  };

  render() {
    const { channel, t } = this.props;

    return (
      <React.Fragment>
        <Accordion
          id={`channel-${channel.name}`}
          elevation={0}
          expanded={this.props.expanded}
          onClick={() => this.props.onClick(channel.id)}
          square={true}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onMouseOver={() => this.setState({ actionButtons: true })}
            onMouseLeave={() => this.setState({ actionButtons: false })}
          >
            <div style={{ flexBasis: '35%' }}>
              <Typography
                variant="body1"
                noWrap={true}
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100px' }}
              >
                <Tooltip title={channel.name}>
                  <span># {channel.name}</span>
                </Tooltip>
              </Typography>
            </div>
            <div style={{ opacity: this.state.actionButtons || this.props.expanded ? 1 : 0, flexBasis: '20%' }}>
              <Tooltip title={t('channelView:editTooltip')}>
                <IconButton
                  id={'channel-edit-button'}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ dialog: 'edit' });
                  }}
                  size={'small'}
                  color={'primary'}
                >
                  <MdEdit />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('channelView:deleteTooltip')}>
                <IconButton
                  id={`delete-channel-${channel.name}-button`}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ dialog: 'delete' });
                  }}
                  size={'small'}
                  color={'primary'}
                >
                  <MdDelete />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Typography variant="body1" color={'textSecondary'}>
                {channel.observations.length} {t('common:observation', { count: channel.observations.length })}
              </Typography>
              {this.props.active && (
                <Tooltip title={t('channelView:activeChannelTooltip')}>
                  <Typography
                    id={`channel-${channel.name}-active-badge`}
                    variant="body2"
                    color="secondary"
                    style={{ paddingLeft: 2 }}
                  >
                    {t('channelView:currentlyActive')}
                  </Typography>
                </Tooltip>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <Grid container={true}>
              {isEmpty(channel.observations) ? (
                <EmptyChannelInfo />
              ) : (
                <Grid container={true}>
                  <Grid item={true} xs={12}>
                    {this.state.loading && <LinearProgress />}
                  </Grid>
                  <List
                    dense={true}
                    style={{ width: '100%' }}
                    subheader={
                      <Grid container={true} alignItems={'center'}>
                        <Grid item={true} xs={6}>
                          {' '}
                        </Grid>
                        <Grid
                          item={true}
                          xs={6}
                          style={{
                            opacity: this.state.selectedObservationsUUID.length > 0 ? 1 : 0,
                            marginTop: -30,
                          }}
                        >
                          <Button
                            id={'delete-selected-observations-button'}
                            variant={'contained'}
                            size={'small'}
                            color={'secondary'}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.deleteSelectedObservations();
                            }}
                          >
                            {t('channelView:deleteSelected')}
                          </Button>
                        </Grid>
                      </Grid>
                    }
                  >
                    {this.props.observationsByChannelId(channel.id).map((observation) => {
                      return (
                        <ListItem
                          key={observation.uuid}
                          button={true}
                          onClick={() => this.props.history.push(`/observation/${observation.uuid}`)}
                        >
                          <Typography
                            id={`observation-${observation.name}-header`}
                            variant={'body2'}
                            style={{ flexBasis: '57%', textOverflow: 'ellipsis' }}
                          >
                            {observation.name}
                          </Typography>
                          <Tooltip
                            title={observation.satellites
                              .map((i) => this.props.satelliteById(i)?.properties.name)
                              .join(', ')}
                          >
                            <Typography variant={'body2'} color="textSecondary">
                              {observation.satellites.length}{' '}
                              {t('common:satellite', { count: observation.satellites.length })}
                            </Typography>
                          </Tooltip>
                          <ListItemSecondaryAction style={{ right: -9 }}>
                            <Checkbox
                              id={`observation-${observation.name}-checkbox`}
                              checked={this.state.selectedObservationsUUID.includes(observation.uuid)}
                              onChange={(e) => this.onObservationSelect(observation.uuid, e.target.checked)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Dialog open={Boolean(this.state.dialog)} onClose={this.onDialogClose}>
          {this.state.dialog === 'delete' && (
            <DeleteChannelDialog
              channel={this.props.channel}
              onNo={this.onDialogClose}
              onYes={() => this.props.onDelete(this.props.channel.id)}
            />
          )}
          {this.state.dialog === 'edit' && (
            <ChangeChannelNameDialog channel={channel} onNo={this.onDialogClose}>
              <EditableText
                text={channel.name}
                canEdit={true}
                editModeOn={true}
                onEdit={async (text: string) => await this.props.updateChannel(channel.id, { name: text })}
                onClose={this.onDialogClose}
              />
            </ChangeChannelNameDialog>
          )}
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (state: ApplicationState): StoreProps => ({
  observationsByChannelId: observationsByChannelIdSelector(state),
  satelliteById: satelliteByIdSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  addPolygonToMap: (data) => dispatch(mapPolygons.add(data)),
  clearMapPolygon: (id) => dispatch(mapPolygons.delete(id)),
  deleteObservation: (observationUUID) => {
    return dispatch({
      ...observation.delete.request({ observationUUID }),
      [WAIT_FOR_ACTION]: (action: any) =>
        action.type === observation.delete.SUCCESS && action.request_action.payload.observationUUID === observationUUID,
    });
  },
  fetchChannel: (channelId) => dispatch(channel.get.request({ channelId })),
  fetchObservation: (observationUUID) =>
    dispatch({
      ...observation.get.request({ observationUUID }),
      [WAIT_FOR_ACTION]: (action: any) =>
        action.type === observation.get.SUCCESS && action.payload.uuid === observationUUID,
      [ERROR_ACTION]: (action: any) =>
        action.type === observation.get.FAILURE && action.request_action.payload.observationUUID === observationUUID,
    }),
  setSatellitesSelected: (satelliteIds) => dispatch(setSatellitesSelected.request({ satelliteIds })),
  updateChannel: (channelId, parameters) => dispatch(channel.patch.request({ channelId, ...parameters })),
});

export default withTranslation(['channelView', 'common'])(
  connect(mapStoreToProps, mapDispatchToProps)(withUser(withRouter(withTheme(ChannelView)))),
);
