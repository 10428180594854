import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  imagery,
  imageries,
  imageryFiles,
  highResolutionImageries,
  searchHighResolutionImageries,
  pendingImageries,
} from './actions';
import urls from '../urls';
import { callApi, genericAPIFunction } from '../../utils/api';
import { login } from '../auth/actions';

function* fetchHighResolutionImageriesSearch({ payload }: any): any {
  const url = urls.highResolutionImageriesSearch();

  try {
    const result = yield call(callApi, 'get', url, { bbox: `${payload.bbox}` });

    yield put(searchHighResolutionImageries.get.success(result));
  } catch (err) {
    yield put(searchHighResolutionImageries.get.failure(err));
  }
}

function* fetchHighResolutionImageries(): any {
  const url = urls.highResolutionImageries();
  const result = yield call(callApi, 'get', url);
  yield put(highResolutionImageries.get.success(result));
}

function* fetchImageries({ payload }: any): any {
  const { observationUUID, parameters } = payload;
  const url = urls.imageryList({ observationUUID });
  const urlForRecommendations = urls.imageriesRecommended({ observationUUID });

  try {
    const result = yield call(callApi, 'get', url, parameters);
    const imageriesRecommended = yield call(callApi, 'get', urlForRecommendations);

    yield put(
      imageries.get.success({
        ...result,
        recommendations: imageriesRecommended,
      }),
    );
  } catch (err) {
    yield put(imageries.get.failure(err));
  }
}

function* fetchPendingImageries({ payload }: any): any {
  const { observationUUID } = payload;
  const imageriesPendingUrl = urls.imageriesPending({ observationUUID });

  try {
    const imageriesPending = yield call(callApi, 'get', imageriesPendingUrl);

    yield put(
      pendingImageries.get.success({
        pending: imageriesPending,
      }),
    );
  } catch (err) {
    yield put(pendingImageries.get.failure(err));
  }
}

const fetchImagery = genericAPIFunction(imagery.get, 'get', urls.imageryDetails);

function* fetchImageryFiles({ payload }: any): any {
  const { observationUUID, imageryId } = payload;
  const url = urls.imageryFilesList({ observationUUID, imageryId });

  try {
    const result = yield call(callApi, 'get', url);

    result.map(
      (o: any) =>
        (o.url = `${process.env.REACT_APP_API_URL}/observation/${observationUUID}/imagery/${imageryId}/files/${o.path}`), // eslint-disable-line max-len
    );

    yield put(imageryFiles.get.success(result));
  } catch (err) {
    yield put(imageryFiles.get.failure(err));
  }
}

function* saga() {
  yield takeEvery(imageries.get.REQUEST, fetchImageries);
  yield takeLatest(pendingImageries.get.REQUEST, fetchPendingImageries);
  yield takeEvery(imagery.get.REQUEST, fetchImagery);
  yield takeEvery(imageryFiles.get.REQUEST, fetchImageryFiles);
  yield takeEvery(login.SUCCESS, fetchHighResolutionImageries);
  yield takeEvery(searchHighResolutionImageries.get.REQUEST, fetchHighResolutionImageriesSearch);
}

export default saga;
