import { call, takeEvery, put } from 'redux-saga/effects';
import { observation, observationFollow } from './actions';
import urls from '../urls';
import { callApi, genericAPIFunction } from '../../utils/api';
import { AUTH_TOKEN_KEY } from '../../constants';

const fetchObservation = genericAPIFunction(observation.get, 'get', urls.observationDetails);

export function* createObservation({ payload }: any): any {
  const url = urls.observationList();
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  try {
    const result = yield call(callApi, 'post', url, { ...payload, token });

    yield put(observation.post.success(result));
  } catch (err) {
    yield put(observation.post.failure(err));
  }
}

const deleteObservation = genericAPIFunction(observation.delete, 'delete', urls.observationDetails);

const updateObservation = genericAPIFunction(observation.patch, 'patch', (payload) =>
  urls.observationDetails({ observationUUID: payload.uuid }),
);

const followObservation = genericAPIFunction(observationFollow, 'post', urls.observationFollow);

function* saga() {
  yield takeEvery(observation.get.REQUEST, fetchObservation);
  yield takeEvery(observation.post.REQUEST, createObservation);
  yield takeEvery(observation.delete.REQUEST, deleteObservation);
  yield takeEvery(observation.patch.REQUEST, updateObservation);
  yield takeEvery(observationFollow.REQUEST, followObservation);
}

export default saga;
