import React from 'react';

import logo from '../static/images/logo.svg';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { THEME_DARK } from '../constants';

const FooterWrapper = styled.div`
  color: #595959;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

  & a {
    text-decoration: none;
    color: #595959;
    display: inherit;
    :visited {
      color: #595959;
    }
  }
`;

const Footer = styled.div`
  align-items: center;
  display: inline-flex;
  padding: 5px;
`;

const Logo = styled.img`
  height: 2.5em;
  margin: 10px;
  filter: invert(${() => (useTheme().palette.type === THEME_DARK ? 1 : 0)});
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: inherit;
`;

export const LogoFooter: React.FC = () => (
  <FooterWrapper>
    <Link to={'/'}>
      <LogoWrapper>
        <Logo src={logo} />
      </LogoWrapper>
      <Footer>
        <Typography variant="body1" style={{ color: useTheme().palette.custom.contrastText, opacity: 0.77 }}>
          powered by spectator.earth
        </Typography>
      </Footer>
    </Link>
  </FooterWrapper>
);
