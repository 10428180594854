import React from 'react';
import { Card, CardContent, Grid, LinearProgress, Typography } from '@material-ui/core';
import { Subscription, UsageName } from '../store/plans/types';
import { AllInclusive } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  subscription: Subscription;
}

export const SubscriptionUsage: React.FC<Props> = ({ subscription }: Props) => {
  const { t } = useTranslation('plans');

  return (
    <>
      {Object.entries(subscription.usage).map(([name, values]) => (
        <Grid key={name} item={true} xs={12}>
          <Card elevation={0}>
            <CardContent style={{ padding: 8 }}>
              <Typography gutterBottom={true}>{t(name as UsageName)}</Typography>
              {values[1] === 0 ? (
                <AllInclusive />
              ) : (
                <>
                  <LinearProgress variant={'determinate'} value={(values[0] / values[1]) * 100} />
                  <Typography id={'usage'}>
                    {`${values[0]} / ${values[1]} (${Math.round((values[0] / values[1]) * 100)}%)`}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};
