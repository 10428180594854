import { History } from 'history';
import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';

import FeedbackFormSaga from './feedback-form/sagas';
import acquisitionPlan from './acquisition-plan/sagas';
import appSaga from './app/sagas';
import authSaga from './auth/sagas';
import channelsSaga from './channels/sagas';
import configSaga from './config/sagas';
import imagerySaga from './imageries/sagas';
import notificationsSaga from './notifications/sagas';
import observationsSaga from './observations/sagas';
import overpassesSaga from './overpasses/sagas';
import paymentSaga from './payment/sagas';
import planSaga from './plans/sagas';
import satellitesSaga from './satellites/sagas';
import { AcquisitionPlanState } from './acquisition-plan/types';
import { AppState } from './app/types';
import { AuthState } from './auth/types';
import { ChannelsState } from './channels/types';
import { ConfigState } from './config/types';
import { FeedbackFormState } from './feedback-form/types';
import { ImageriesState } from './imageries/types';
import { NotificationsState } from './notifications/types';
import { ObservationsState } from './observations/types';
import { OverpassesState } from './overpasses/types';
import { PlanState } from './plans/types';
import { SatellitesState } from './satellites/types';
import { acquisitionPlanReducer } from './acquisition-plan/reducer';
import { appReducer } from './app/reducer';
import { authReducer } from './auth/reducer';
import { channelsReducer } from './channels/reducer';
import { configReducer } from './config/reducers';
import { feedbackFormReducer } from './feedback-form/reducer';
import { imageryReducer } from './imageries/reducer';
import { notificationReducer } from './notifications/reducer';
import { observationsReducer } from './observations/reducer';
import { overpassReducer } from './overpasses/reducer';
import { planReducer } from './plans/reducer';
import { satellitesReducer } from './satellites/reducer';
import { paymentReducer } from './payment/reducer';
import { PaymentState } from './payment/types';

export interface ApplicationState {
  acquisitionPlan: AcquisitionPlanState;
  app: AppState;
  auth: AuthState;
  channels: ChannelsState;
  config: ConfigState;
  feedback: FeedbackFormState;
  imageries: ImageriesState;
  notifications: NotificationsState;
  observations: ObservationsState;
  overpasses: OverpassesState;
  payment: PaymentState;
  plan: PlanState;
  router: RouterState;
  satellites: SatellitesState;
}

export const createReducer = (history: History) =>
  combineReducers<ApplicationState>({
    acquisitionPlan: acquisitionPlanReducer,
    app: appReducer,
    auth: authReducer,
    channels: channelsReducer,
    config: configReducer,
    feedback: feedbackFormReducer,
    imageries: imageryReducer,
    notifications: notificationReducer,
    observations: observationsReducer,
    overpasses: overpassReducer,
    payment: paymentReducer,
    plan: planReducer,
    router: connectRouter(history),
    satellites: satellitesReducer,
  });

export function* rootSaga() {
  yield all([
    fork(notificationsSaga),
    fork(acquisitionPlan),
    fork(appSaga),
    fork(authSaga),
    fork(configSaga),
    fork(channelsSaga),
    fork(imagerySaga),
    fork(observationsSaga),
    fork(overpassesSaga),
    fork(satellitesSaga),
    fork(paymentSaga),
    fork(planSaga),
    fork(FeedbackFormSaga),
  ]);
}
