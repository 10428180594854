import React from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  isWidthDown,
  isWidthUp,
  Snackbar,
  Toolbar,
  Typography,
  WithWidth,
  withWidth,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { Popup } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import { bbox as turfBbox, flip } from '@turf/turf';
import { format } from 'date-fns';
import styled from 'styled-components';
import { MdClose, MdEdit, MdExpandMore, MdNotificationsActive, MdNotificationsOff, MdShortText } from 'react-icons/md';
import { debounce, isEqual } from 'lodash';
import { withTranslation, WithTranslation } from 'react-i18next';

import Map from '../containers/map';
import { CenteredGrid, Main, SidebarDrawer } from '../components/layout';
import { ApplicationState } from '../store';
import {
  activeObservationSelector,
  activeObservationSettingsSelector,
  activeObservationUUIDSelector,
} from '../store/observations/selectors';
import { ImageState, Observation, ObservationImageriesState, ObservationSettings } from '../store/observations/types';
import { activeObservation, observation, observationFollow } from '../store/observations/actions';
import Overpasses from '../containers/widgets/overpasses';
import Imageries from '../containers/widgets/imageries';
import { Satellite } from '../store/satellites/types';
import { NAVIGATION_HEIGHT } from '../containers/navigation';
import { UserProps } from '../store/auth/types';
import { recentlyUsed, setMapBounds, setMapParameters, mapPolygons, mapTiles } from '../store/app/actions';
import { MapBounds, MapParameters, PolygonLayer, RecentlyUsed, TileLayer } from '../store/app/types';
import { useTheme, withTheme } from '@material-ui/core/styles';
import {
  commercialMapTilesSelector,
  mapBoundsSelector,
  mapParametersSelector,
  mapPolygonsSelector,
  mapTilesSelector,
} from '../store/app/selectors';
import { geojsonBBoxToLeafletBoundsLiteral, mapBoundsToBboxPolygon } from '../utils/geo';
import { Description } from '../components/widgets/description';
import { DEFAULT_DATETIME_FORMAT, ImageriesDisplayOption, SIGNIN_URL, TITLE } from '../constants';
import EditableText from '../components/editable-text';
import { LogoFooter } from '../components/logo-footer';

import { OptionsMenu, OptionsMenuItem } from '../components/options-menu';
import { satellitesWithTilesSelector, selectedSatellitesSelector } from '../store/satellites/selectors';
import { FollowAction, FollowType } from '../store/notifications/types';
import { ThemeProps } from '../theme';
import { setSatellitesSelected } from '../store/satellites/actions';
import booleanContains from '@turf/boolean-contains';
import { Alert } from '@material-ui/lab';
import SidebarDrawerToggle from '../components/sidebar-drawer-toggle';

const LocationPopup = styled(Popup)`
  .leaflet-popup-content-wrapper {
    text-align: center;
    border-radius: 3px;
    background: gray;
    color: ${() => useTheme().palette.text.secondary};
  }

  .leaflet-popup-tip-container {
    .leaflet-popup-tip {
      background: gray;
    }
  }
`;
LocationPopup.displayName = 'LocationPopup';

interface DispatchProps {
  addRecentlyUsed: (item: RecentlyUsed) => void;
  addPolygonToMap: (polygon: PolygonLayer) => any;
  clearMap: (obj?: Record<string, string>) => any;
  fetchObservation: (observationUUID: string) => any;
  setMapParameters: (parameters: MapParameters) => void;
  setMapBounds: (bounds: MapBounds) => void;
  setSatellitesSelected: (satelliteIds: number[]) => void;
  updateObservation: (uuid: string, parameters: Partial<Observation>) => void;
  followObservation: (observationUUID: string, dateType: FollowType, action: FollowAction) => void;
  setActiveObservation: (observationUUID: string) => void;
  clearActiveObservation: () => void;
}

export interface MatchParams {
  observationIdentifier: string;
}

interface StoreProps {
  mapBounds: MapBounds;
  mapParameters: MapParameters;
  mapPolygons: PolygonLayer[];
  mapTiles: TileLayer[];
  commercialMapTiles: TileLayer[];
  observation: Observation;
  activeObservationUUID?: string;
  settings: ObservationSettings;
  selectedSatellites: Satellite[];
  satellitesWithTiles: Satellite[];
}

interface Notifications {
  overpasses: boolean;
  imageries: boolean;
}

interface State {
  descriptionOpen: boolean;
  editTitle: boolean;
  notifications: Notifications;
  satellites: number[];
  warningOpen: boolean;
  observationDetailsDrawerOpen: boolean;
}

type Props = DispatchProps &
  StoreProps &
  RouteComponentProps<MatchParams> &
  ThemeProps &
  UserProps &
  WithTranslation<'observation'> &
  WithWidth;

export class ObservationView extends React.Component<Props, State> {
  readonly state: State = {
    descriptionOpen: false,
    editTitle: false,
    notifications: {
      overpasses: false,
      imageries: false,
    },
    satellites: [],
    warningOpen: false,
    observationDetailsDrawerOpen: true,
  };

  readonly debouncedUpdateImageries: any;

  constructor(props: Props) {
    super(props);
    this.debouncedUpdateImageries = debounce(this.updateImageries, 5000);
  }

  componentDidMount() {
    this.props.clearMap();

    if (!this.props.observation || this.props.observation.uuid !== this.props.activeObservationUUID) {
      this.props
        .fetchObservation(this.props.match.params.observationIdentifier)
        .then((observation: Observation) => {
          this.onObservationFetched(observation);
        })
        .catch(() => {
          this.props.history.replace('/404');
        });
    } else {
      this.onObservationFetched(this.props.observation);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { observation, mapParameters, mapTiles, commercialMapTiles } = this.props;

    if (observation) {
      if (!isEqual(prevProps.mapParameters, mapParameters) && this.props.observation.can_edit) {
        this.updateSettings({ mapParameters });
      }

      if (
        (!isEqual(prevProps.mapTiles, mapTiles) || !isEqual(prevProps.commercialMapTiles, commercialMapTiles)) &&
        this.props.observation.can_edit
      ) {
        this.debouncedUpdateImageries(prevProps);
      }

      if (!isEqual(prevProps.selectedSatellites, this.props.selectedSatellites)) {
        this.changeSatellites();
      }
    }
  }

  componentWillUnmount() {
    document.title = TITLE;
    this.props.clearActiveObservation();
    this.props.clearMap();
  }

  updateImageries = () => {
    const { mapTiles, commercialMapTiles } = this.props;

    const activeImageriesIds = mapTiles.map((tiles) => tiles.imagery.id);
    const activeImageriesState: ObservationImageriesState = Object.fromEntries(
      [...mapTiles, ...commercialMapTiles]
        .filter((tiles) => tiles.imageryState !== undefined)
        .map((tiles) => [tiles.imagery.uuid, tiles.imageryState as ImageState]),
    );
    this.props.updateObservation(this.props.observation.uuid, {
      settings: { activeImageriesIds },
      imageries_state: activeImageriesState,
    });
  };

  onObservationFetched = (observation: Observation) => {
    this.props.setActiveObservation(observation.uuid);
    document.title = `${observation.channel_name} > ${observation.name} | ${TITLE}`;
    if (observation.settings.mapParameters && !isEqual(observation.settings.mapParameters, this.props.mapParameters)) {
      this.props.setMapParameters(observation.settings.mapParameters);
    } else {
      this.zoomToSelectedArea();
    }

    this.setState({
      descriptionOpen: Boolean(observation.description),
      notifications: {
        overpasses: observation.user_follows.includes(FollowType.Overpass),
        imageries: observation.user_follows.includes(FollowType.Imagery),
      },
      warningOpen: !observation.can_edit,
    });

    this.props.addRecentlyUsed({
      name: observation.name,
      uuid: observation.uuid,
      created: observation.created,
    });
    this.props.setSatellitesSelected(observation.satellites);
    this.props.addPolygonToMap({
      name: `${observation.name} ${this.props.t('searchArea')}`,
      id: `${observation.uuid}-observation-AOI`,
      positions: flip(observation.geometry).coordinates as LatLngExpression[][],
      hidden: true,
    });
  };

  zoomToSelectedArea = () => {
    const observationPolygon = this.props.observation.geometry;
    const mapViewPolygon = mapBoundsToBboxPolygon(this.props.mapBounds);
    if (!booleanContains(observationPolygon, mapViewPolygon)) {
      const bbox = turfBbox(flip(observationPolygon));
      this.props.setMapBounds(geojsonBBoxToLeafletBoundsLiteral(bbox));
    }
  };

  onDescriptionEdit = (description: string) => {
    if (this.props.observation) {
      this.props.updateObservation(this.props.observation.uuid, { description });

      if (description === '') {
        this.setState({ descriptionOpen: false });
      }
    }
  };

  canAddDescription = () =>
    !this.state.descriptionOpen &&
    this.props.user &&
    this.props.observation &&
    this.props.observation.can_edit &&
    !this.props.observation.description;

  changeSatellites = () => {
    if (this.props.observation.can_edit) {
      const observationSatellites =
        this.props.selectedSatellites.length > 0 ? this.props.selectedSatellites : this.props.satellitesWithTiles;
      this.props.updateObservation(this.props.observation.uuid, { satellites: observationSatellites.map((s) => s.id) });
    }
  };

  updateSettings = (settings: Partial<ObservationSettings>) => {
    this.props.observation.can_edit && this.props.updateObservation(this.props.observation.uuid, { settings });
  };

  render() {
    const { observation, user, t } = this.props;

    return (
      <React.Fragment>
        {observation && (
          <React.Fragment>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={5000}
              open={this.state.warningOpen}
              onClose={() => this.setState({ warningOpen: false })}
            >
              <Alert severity={'warning'}>{t('ownershipWarning')}</Alert>
            </Snackbar>
            <SidebarDrawer
              variant={isWidthDown('sm', this.props.width) ? 'temporary' : 'permanent'}
              open={this.state.observationDetailsDrawerOpen}
              style={{ marginTop: isWidthUp('sm', this.props.width) ? NAVIGATION_HEIGHT : '' }}
              ModalProps={{ keepMounted: true }}
            >
              {isWidthUp('md', this.props.width) && <Toolbar />}
              <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="stretch"
                wrap="nowrap"
                style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}
              >
                <Grid container item>
                  <CenteredGrid item={true} xs={2}>
                    <IconButton onClick={() => this.props.history.push(this.props.user ? '/channels' : '/')}>
                      <MdClose size={30} />
                    </IconButton>
                  </CenteredGrid>
                  <Grid item={true} xs={8}>
                    <EditableText
                      text={observation.name}
                      canEdit={observation.can_edit}
                      editModeOn={this.state.editTitle}
                      disableEditIcon
                      onEdit={async (text: string) =>
                        await this.props.updateObservation(observation.uuid, { name: text })
                      }
                      onClose={() => this.setState({ editTitle: false })}
                      typographyProps={{
                        variant: 'h6',
                        align: 'center',
                        style: { margin: '10px 10px 0px 10px' },
                        noWrap: true,
                      }}
                    />
                    <Typography variant="body2" align="center">
                      {t('createdBy', {
                        date: format(new Date(observation.created), DEFAULT_DATETIME_FORMAT),
                        username: observation.user_name,
                      })}
                    </Typography>
                  </Grid>
                  <CenteredGrid item={true} xs={2}>
                    <OptionsMenu>
                      <Accordion variant="outlined" disabled={Boolean(!this.props.user)}>
                        <AccordionSummary expandIcon={<MdExpandMore />}>
                          <FormControlLabel
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            checked={this.state.notifications.imageries || this.state.notifications.overpasses}
                            onChange={(_, checked: boolean) => {
                              const followAction = (checked && FollowAction.Follow) || FollowAction.Unfollow;
                              this.props.followObservation(
                                this.props.observation.uuid,
                                FollowType.Imagery,
                                followAction,
                              );
                              this.props.followObservation(
                                this.props.observation.uuid,
                                FollowType.Overpass,
                                followAction,
                              );
                              this.setState({ notifications: { overpasses: checked, imageries: checked } });
                            }}
                            control={<Checkbox icon={<MdNotificationsOff />} checkedIcon={<MdNotificationsActive />} />}
                            label={t('turnOnNotifications')}
                          />
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography>{t('notificationsTitle')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                checked={this.state.notifications.overpasses}
                                onChange={(_, checked: boolean) => {
                                  this.props.followObservation(
                                    this.props.observation.uuid,
                                    FollowType.Overpass,
                                    (checked && FollowAction.Follow) || FollowAction.Unfollow,
                                  );
                                  this.setState({
                                    notifications: { ...this.state.notifications, overpasses: checked },
                                  });
                                }}
                                control={<Checkbox />}
                                label={t('overpassesLabel')}
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                onChange={(_, checked: boolean) => {
                                  this.props.followObservation(
                                    this.props.observation.uuid,
                                    FollowType.Imagery,
                                    (checked && FollowAction.Follow) || FollowAction.Unfollow,
                                  );
                                  this.setState({ notifications: { ...this.state.notifications, imageries: checked } });
                                }}
                                checked={this.state.notifications.imageries}
                                control={<Checkbox />}
                                label={t('imagesLabel')}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      {this.props.observation.can_edit && (
                        <OptionsMenuItem
                          onClick={() => this.setState({ editTitle: !this.state.editTitle })}
                          icon={MdEdit}
                          optionTitle={t('editObservationName')}
                        />
                      )}
                      {this.canAddDescription() && (
                        <OptionsMenuItem
                          onClick={() => this.setState({ descriptionOpen: true })}
                          icon={MdShortText}
                          optionTitle={t('addObservationDescription')}
                        />
                      )}
                    </OptionsMenu>
                  </CenteredGrid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  wrap="nowrap"
                  alignItems="stretch"
                  justify="space-evenly"
                  style={{ flexGrow: 1, marginBottom: '10px' }}
                >
                  <Grid item>
                    <Overpasses
                      observation={observation}
                      calendarView={this.props.settings.calendar}
                      onChangeCalendarView={(checked: boolean) => this.updateSettings({ calendar: checked })}
                      imagingOnly={this.props.settings.imagingOnly}
                      onChangeImagingOnly={(checked: boolean) => this.updateSettings({ imagingOnly: checked })}
                    />
                  </Grid>
                  <Grid item>
                    <Imageries
                      displayOption={observation.settings.imageriesDisplay}
                      onChangeDisplayOption={(imageriesDisplay: ImageriesDisplayOption) => {
                        this.updateSettings({ imageriesDisplay });
                      }}
                      observation={observation}
                      onSelectImagery={this.zoomToSelectedArea}
                    />
                  </Grid>
                  {!user && (
                    <Grid item container alignItems="center" justify="center">
                      <CenteredGrid item>
                        <Button
                          disableElevation={true}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => this.props.history.push(`${SIGNIN_URL}`)}
                        >
                          {t('signIn')}
                        </Button>
                      </CenteredGrid>
                      <CenteredGrid item>
                        <LogoFooter />
                      </CenteredGrid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </SidebarDrawer>
            {isWidthDown('sm', this.props.width) && (
              <SidebarDrawerToggle
                drawerOpen={this.state.observationDetailsDrawerOpen}
                handleClick={() =>
                  this.setState({ observationDetailsDrawerOpen: !this.state.observationDetailsDrawerOpen })
                }
              />
            )}
            <Main>
              <Map mapActionsOff={true} />
              {observation && this.state.descriptionOpen && (
                <Description
                  canEdit={observation.can_edit}
                  onEdit={this.onDescriptionEdit}
                  content={observation.description}
                />
              )}
            </Main>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  addRecentlyUsed: (item: RecentlyUsed) => dispatch(recentlyUsed.request(item)),
  addPolygonToMap: (data) => dispatch(mapPolygons.add(data)),
  clearMap: () => {
    dispatch(mapPolygons.delete());
    dispatch(mapTiles.delete());
  },
  fetchObservation: (observationUUID: string) =>
    dispatch({
      ...observation.get.request({ observationUUID }),
      [WAIT_FOR_ACTION]: observation.get.SUCCESS,
      [ERROR_ACTION]: observation.get.FAILURE,
    }),
  setMapParameters: (parameters) => dispatch(setMapParameters.request(parameters)),
  setMapBounds: (bounds) => dispatch(setMapBounds.request(bounds)),
  setSatellitesSelected: (satelliteIds) => dispatch(setSatellitesSelected.request({ satelliteIds })),
  followObservation: (observationUUID: string, dataType: FollowType, action: FollowAction) =>
    dispatch(observationFollow.request({ observationUUID, dataType, action })),
  updateObservation: (uuid: string, parameters: Partial<Observation>) =>
    dispatch(observation.patch.request({ uuid, ...parameters })),
  setActiveObservation: (observationUUID: string) => dispatch(activeObservation.set(observationUUID)),
  clearActiveObservation: () => dispatch(activeObservation.clear()),
});

const mapStoreToProps = (state: ApplicationState): StoreProps => ({
  mapBounds: mapBoundsSelector(state),
  mapParameters: mapParametersSelector(state),
  mapPolygons: mapPolygonsSelector(state),
  mapTiles: mapTilesSelector(state),
  commercialMapTiles: commercialMapTilesSelector(state),
  observation: activeObservationSelector(state),
  activeObservationUUID: activeObservationUUIDSelector(state),
  settings: activeObservationSettingsSelector(state),
  selectedSatellites: selectedSatellitesSelector(state),
  satellitesWithTiles: satellitesWithTilesSelector(state),
});

export default withTranslation('observation')(
  connect(mapStoreToProps, mapDispatchToProps)(withTheme(withWidth()(ObservationView))),
);
