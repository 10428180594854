import {
  Channels,
  CreateObservation,
  Logout,
  NotFound,
  Observation,
  ResetPasswordConfirm,
  SignIn,
  AcquisitionPlan,
  Plans,
  VerifyEmail,
  NewsletterUnsubscribe,
} from './pages';

interface RouteProps {
  path?: string;
  component?: React.ComponentType<any>;
  main?: React.ComponentType<any>;
  header?: boolean;
  satellites_menu?: boolean;
  authenticated?: boolean;
}

const routes: RouteProps[] = [
  {
    path: '/',
    component: CreateObservation,
    header: true,
    satellites_menu: true,
  },
  {
    path: '/channels/:channelName?/:channelId?',
    component: Channels,
    header: true,
    satellites_menu: true,
    authenticated: true,
  },
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '/auth/logout/',
    component: Logout,
  },
  {
    path: '/auth/reset-password-confirm/:uid/:token/',
    component: ResetPasswordConfirm,
  },
  {
    path: '/auth/verify-email/:key/',
    component: VerifyEmail,
  },
  {
    path: '/auth/signin',
    component: SignIn,
  },
  {
    path: `/observation/:observationIdentifier`,
    component: Observation,
    header: true,
    satellites_menu: true,
  },
  {
    path: '/acquisition-plan',
    component: AcquisitionPlan,
    header: true,
    satellites_menu: true,
  },
  {
    path: '/pricing',
    component: Plans,
    header: true,
  },
  {
    path: '/newsletter/unsubscribe/:uid/:token/',
    component: NewsletterUnsubscribe,
  },
  {
    component: NotFound,
  },
];

export default routes;
