import React from 'react';
import styled from 'styled-components';
import { Drawer, Grid, Paper, Dialog as MaterialDialog } from '@material-ui/core';
import { NAVIGATION_HEIGHT } from '../containers/navigation';

export const Container = styled.div<{ withNavbar: boolean }>`
  display: flex;
  height: ${(props) => (props.withNavbar ? `calc(100vh - ${NAVIGATION_HEIGHT})` : '100vh')};

  @supports (height: 100dvh) {
    height: ${(props) => (props.withNavbar ? `calc(100dvh - ${NAVIGATION_HEIGHT})` : '100dvh')};
  }
`;

export const CenteredGrid = styled(Grid)`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const Sidebar = styled(Paper)`
  max-height: 100%;
  width: 27%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 3s;

  & .MuiDrawer-paper {
    width: inherit;
  }

  @media (max-width: 1200px) {
    width: 30%;
  }

  @media (max-width: 900px) {
    width: 40%;
  }

  @media (max-width: 600px) {
    width: 50%;
  }
`;
Sidebar.displayName = 'Sidebar';

export const SidebarSearchContainer = styled.div`
  height: 30px;
  margin: 15px;
  border-radius: 5px;
`;

export const WidgetContent = styled.div`
  height: 100%;
`;
WidgetContent.displayName = 'WidgetContent';

export const WidgetActions = styled.div`
  display: flex;
  z-index: 1;
`;

export const WidgetContainer = styled(({ detailed, ...props }) => <Sidebar {...props} />)`
  height: 100%;
  margin: ${(props) => (props.detailed ? 'none' : '5px 25px 0 25px')};
  position: ${(props) => (props.detailed ? 'absolute' : 'initial')};
  top: ${(props) => (props.detailed ? '0' : 'initial')};
  z-index: ${(props) => (props.detailed ? '1' : 'initial')};
  width: ${(props) => (props.detailed ? '100%' : 'inherit')} !important;

  ${WidgetContent} {
    border: ${(props) => (props.detailed ? 'none' : '1px solid #80808042')};
    border-radius: ${(props) => (props.detailed ? 'none' : '5px')};
    height: ${(props) => (props.detailed ? '100%' : 'auto')};
    min-height: 225px;
    overflow: ${(props) => (props.detailed ? 'auto' : 'hidden')};
  }

  ${WidgetActions} {
    position: ${(props) => (props.detailed ? 'relative' : 'initial')};
    bottom: ${(props) => (props.detailed ? '0px' : 'initial')};
    background: ${(props) => (props.detailed ? 'inherit' : 'initial')};
    width: ${(props) => (props.detailed ? 'inherit !important' : 'initial')};
    margin: ${(props) => (props.detailed ? 'inherit' : 'unset')};
    border-top: ${(props) => (props.detailed ? 'solid 1px lightgrey' : 'initial')};
    height: ${(props) => (props.detailed ? 'fit-content' : 'initial')};
    padding-bottom: ${(props) => (props.detailed ? '10px' : 'unset')};
    padding-top: ${(props) => (props.detailed ? '10px' : 'unset')};
    overflow: hidden;
  }
`;

export const SidebarDrawer = styled(Drawer)`
  position: relative;
  max-height: 100%;
  width: 30%;

  & .MuiDrawer-paper {
    width: inherit;
  }

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 960px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const Main = styled.div`
  position: revert;
  flex: 2.5;

  @media (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 900px) {
    width: 60%;
  }

  @media (max-width: 600px) {
    width: 50%;
  }
`;
Main.displayName = 'Main';

export const SatellitesMenuPlaceholder = styled.div.attrs({
  id: 'satellites-menu',
})`
  position: fixed;
  z-index: 1201;
`;

export const Dialog = styled(MaterialDialog).attrs({
  container: () =>
    document.fullscreenElement !== null ? document.getElementById('fullscreen-dialog-container') : null,
})``;
