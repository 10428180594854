import { Grid, Toolbar, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { THEME_DARK } from '../constants';
import specEarthLogoBlack from '../static/images/spec_earth_logo_black.png';
import { api } from '../utils/api';
import urls from '../store/urls';
import { Skeleton } from '@material-ui/lab';

const Logo = styled.img`
  height: auto;
  max-width: 380px;
  width: 100%;
  filter: invert(${() => (useTheme().palette.type === THEME_DARK ? 1 : 0)});
`;

const StyledTypography = styled(Typography)`
  color: ${() => useTheme().palette.text.primary};
`;

interface MatchParams {
  uid: string;
  token: string;
}

type Props = RouteComponentProps<MatchParams>;

const NewsletterUnsubscribe = ({ match }: Props) => {
  const { t } = useTranslation('newsletter');
  const { uid, token } = match.params;
  const [unsubscribed, setUnsubscribed] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  useEffect(() => {
    api
      .post(urls.newsletterUnsubscribe(), { uid, token })
      .then(() => {
        setUnsubscribed(true);
      })
      .catch((err) => {
        setErrored(true);
        throw err;
      });
  }, [uid, token]);

  return (
    <Grid container justify="flex-start" direction="column" alignItems="center" spacing={10}>
      <Grid item>
        <Toolbar />
        <Link to="/">
          <Logo src={specEarthLogoBlack}></Logo>
        </Link>
      </Grid>
      <Grid item hidden={!unsubscribed || errored}>
        <StyledTypography variant="body1" align="center">
          {t('unsubscribeText')}
        </StyledTypography>
        <StyledTypography variant="body2" align="center">
          {t('unsubscribeSubtext')}
        </StyledTypography>
      </Grid>
      <Grid item hidden={!errored}>
        <StyledTypography variant="body1" align="center">
          {t('unsubscribeError')}
        </StyledTypography>
      </Grid>
      <Grid item hidden={unsubscribed || errored}>
        <Skeleton variant="text" style={{ width: ' 350px', margin: '0 auto' }} />
        <Skeleton variant="text" style={{ width: ' 300px', margin: '0 auto' }} />
      </Grid>
    </Grid>
  );
};

export default NewsletterUnsubscribe;
