import { createSelector } from 'reselect';

import { SuggestionType } from '../app/types';
import { Channel } from './types';
import { ApplicationState } from '../index';
import { user } from '../auth/actions';
import { userSettingsSelector } from '../auth/selectors';

export const channelsSelector = (state: ApplicationState) =>
  state.channels.data.filter((channel) => (state.auth.user ? channel.user === state.auth.user.id : channel));

export const channelsErrorsSelector = (state: ApplicationState): string =>
  state.channels.errors ? state.channels.errors[0] : '';

export const channelByIdSelector = createSelector(
  channelsSelector,
  (channels: Channel[]) => (id: number) => channels.filter((channel) => channel.id === id)[0],
);

export function activeChannelSelector(state: ApplicationState) {
  const userSettings = userSettingsSelector(state);
  return userSettings.activeChannelId ? channelByIdSelector(state)(userSettings.activeChannelId) : undefined;
}

export const channelsAsSuggestionsSelector = createSelector(channelsSelector, (channels: Channel[]) =>
  channels.map((channel) => ({
    action: user.patch.request({ settings: { activeChannelId: channel.id } }),
    name: channel.name,
    type: 'channel' as SuggestionType,
  })),
);

export const channelAOIsSelector = (state: ApplicationState) => state.channels.aois;
