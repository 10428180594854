import React from 'react';
import { List, ListItem, ListItemText, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { ImageriesDisplayOption } from '../../constants';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (option: ImageriesDisplayOption) => void;
  value: ImageriesDisplayOption;
  disabled?: boolean;
}

export const ImageriesMenu: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation('widgets');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const displayOptions = Object.values(ImageriesDisplayOption);
  const selectedDisplayOptionIdx = Object.values(ImageriesDisplayOption).indexOf(props.value);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, option: ImageriesDisplayOption) => {
    props.onChange(option);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <List dense={true}>
        <Tooltip
          title={props.disabled ? t('imageriesMenu.signIn') : t('imageriesMenu.changeOption')}
          placement="bottom"
        >
          <ListItem dense button onClick={handleClickListItem}>
            <ListItemText
              style={{ textTransform: 'capitalize' }}
              primary={t('imageriesMenu.displaying')}
              secondary={t(displayOptions[selectedDisplayOptionIdx])}
            />
          </ListItem>
        </Tooltip>
      </List>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.values(ImageriesDisplayOption).map((option, idx) => (
          <MenuItem
            key={option}
            disabled={props.disabled}
            selected={idx === selectedDisplayOptionIdx}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {t(option)}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};
