import React, { useRef, useEffect } from 'react';
import turfNearestPointOnLine from '@turf/nearest-point-on-line';
import { Point, LineString } from 'geojson';
import { Circle as ReactLeafletCircle, CircleProps } from 'react-leaflet';
import { LatLng } from 'leaflet';
import { withTheme, Theme } from '@material-ui/core/styles';

interface Props {
  startPosition: Point;
  line: LineString;
  refreshInterval: number;
  theme: Theme;
}

interface State {
  index: number;
  position?: LatLng;
}

const Circle = (props: CircleProps) => {
  const leafletRef = useRef<any>();
  useEffect(() => {
    leafletRef.current?.openPopup();
  }, []);
  return <ReactLeafletCircle ref={leafletRef} {...props} radius={5} fill={true} fillOpacity={0.8} />;
};

class MarkerAnimation extends React.Component<Props, State> {
  positionInterval: any;
  readonly state: State = {
    index: 0,
    position: undefined,
  };

  componentDidMount() {
    clearInterval(this.positionInterval);
    const nearestPoint = turfNearestPointOnLine(this.props.line, this.props.startPosition);
    const index = nearestPoint.properties.index as number;

    this.setState({ index }, () => {
      const positionInterval = setInterval(this.tick, this.props.refreshInterval);
      if (positionInterval) {
        this.positionInterval = positionInterval;
      }
    });
  }

  tick = () => {
    const index = this.state.index + 1;
    const coords = this.props.line.coordinates;
    const position = coords[index] ? coords[index].slice().reverse() : coords[coords.length - 1];
    this.setState({ index, position: new LatLng(position[0], position[1]) });
  };

  componentWillUnmount(): void {
    clearInterval(this.positionInterval);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.position && (
          <Circle center={this.state.position} color={this.props.theme.palette.secondary.main}>
            {this.props.children}
          </Circle>
        )}
      </React.Fragment>
    );
  }
}

export default withTheme(MarkerAnimation);
