import React from 'react';
import styled from 'styled-components';
import { Button } from '../components/form';

import Astronaut from '../static/images/not_found/astronaut_c.svg';
import Boom from '../static/images/not_found/boom_c.svg';
import Laser from '../static/images/not_found/laser_c.svg';
import LogoImage from '../static/images/logoGrey.svg';
import LogoLight from '../static/images/logoLight.svg';
import Mars from '../static/images/not_found/moon_b.svg';
import Moon from '../static/images/not_found/moon_b.svg';
import Planet from '../static/images/not_found/moon_b.svg';
import Satellite from '../static/images/not_found/satellite_c.svg';
import Saturn from '../static/images/not_found/moon_b.svg';
import UfoImg from '../static/images/not_found/ufo_c.svg';
import { Typography } from '@material-ui/core';
import { withTheme, Theme, useTheme } from '@material-ui/core/styles';
import { THEME_LIGHT } from '../constants';
import { useTranslation } from 'react-i18next';

const StyledPage = styled.div`
  position: relative;
  overflow: hidden;
  width: 1200px;
  height: 840px;
  margin: 0 auto;
  @media screen and (max-width: 1280px), screen and (max-height: 800px) {
    width: 1010px;
    height: 780px;
  }
  @media screen and (max-width: 1100px), screen and (max-height: 750px) {
    width: 960px;
    height: 700px;
  }
  @media screen and (max-width: 1023px), screen and (max-height: 700px) {
    width: 960px;
    height: 580px;
  }
  @media screen and (max-width: 995px) {
    width: 850px;
  }
  @media screen and (max-width: 870px) {
    width: 740px;
  }
  @media screen and (max-width: 767px) {
    width: 580px;
    height: 880px;
  }
  @media screen and (max-width: 599px) {
    width: 450px;
    height: 750px;
  }
  @media screen and (max-width: 479px) {
    width: 300px;
    height: 460px;
  }
`;

// ------ TEXT 404 & LOST ---------
const StyledTextContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const StyledImgContainer = styled.div`
  position: relative;
  top: 110px;
  left: 140px;
  width: 350px;
  height: 440px;
  font-family: 'industry-bold';
  font-size: 190px;
  line-height: 155px;
  font-weight: 100;
  color: ${() => useTheme().palette.custom.notFoundElement};
  opacity: 1;
  animation: container404 3s linear;
  @keyframes container404 {
    0%,
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1100px), screen and (max-height: 750px) {
    top: 50px;
  }
  @media screen and (max-width: 995px) {
    left: 80px;
  }
  @media screen and (max-width: 870px) {
    left: 40px;
    font-size: 160px;
    line-height: 180px;
    width: 300px;
  }
  @media screen and (max-width: 767px) {
    position: unset;
    line-height: 140px;
    width: 400px;
    height: 160px;
    margin: 90px auto 35px;
  }
  @media screen and (max-width: 599px) {
    margin: 80px auto 15px;
  }
  @media screen and (max-width: 479px) {
    margin: 10px auto 10px;
    width: 280px;
    height: 110px;
    font-size: 100px;
    line-height: 100px;
  }
`;

const StyledDivNumber = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  @media screen and (max-width: 767px) {
    position: unset;
    width: 100px;
    float: left;
  }
  @media screen and (max-width: 479px) {
    width: 65px;
  }
`;

const filterDarkMode = 'brightness(64%) contrast(20%) saturate(97%) hue-rotate(46deg)';

const StyledImgLogo = styled.img`
  filter: ${() => (useTheme().palette.type === 'light' ? 'none' : filterDarkMode)};
  height: 135px;
  position: absolute;
  top: 155px;
  left: 115px;
  @media screen and (max-width: 870px) {
    height: 120px;
    top: 165px;
    left: 95px;
  }
  @media screen and (max-width: 767px) {
    position: unset;
    height: 120px;
    float: left;
    margin: 0 34px 0 46px;
  }
  @media screen and (max-width: 479px) {
    height: 90px;
    margin: 0 26px 0 34px;
  }
`;

const StyledDivNumberTwo = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  @media screen and (max-width: 767px) {
    position: unset;
    width: 100px;
    float: left;
  }
  @media screen and (max-width: 479px) {
    width: 65px;
  }
`;

const StyleText = styled.div`
  font-family: 'industry-bold';
  font-size: 35px;
  color: ${() => useTheme().palette.custom.notFoundElement};
`;

const StyledDivText = styled.div`
  position: absolute;
  right: 270px;
  top: 180px;
  width: 275px;
  opacity: 1;
  animation: lostTextOne 6s linear;
  @keyframes lostTextOne {
    0%,
    40% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1280px) {
    top: 100px;
  }
  @media screen and (max-width: 995px) {
    right: 220px;
  }
  @media screen and (max-width: 870px) {
    right: 150px;
    bottom: 440px;
  }
  @media screen and (max-width: 767px) {
    position: unset;
    margin: 0 auto;
    width: 300px;
    text-align: left;
  }
  @media screen and (max-width: 479px) {
    font-size: 25px;
    width: 255px;
  }
`;

const StyledDivTextTwo = styled.div`
  position: absolute;
  right: 170px;
  top: 230px;
  width: 275px;
  opacity: 1;
  animation: lostTextTwo 6s linear;
  @keyframes lostTextTwo {
    0%,
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1280px) {
    top: 150px;
  }
  @media screen and (max-width: 995px) {
    right: 120px;
  }
  @media screen and (max-width: 870px) {
    right: 50px;
    bottom: 390px;
  }
  @media screen and (max-width: 767px) {
    position: unset;
    margin: 0 auto;
    width: 300px;
    text-align: right;
  }
  @media screen and (max-width: 479px) {
    font-size: 25px;
    text-align: left;
    width: 190px;
  }
`;

const StyledDivTextThree = styled.div`
  position: absolute;
  right: 70px;
  top: 280px;
  width: 275px;
  opacity: 1;
  animation: lostTextThree 6s linear;
  @keyframes lostTextThree {
    0%,
    60% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1280px) {
    top: 200px;
  }
  @media screen and (max-width: 995px) {
    right: 20px;
  }
  @media screen and (max-width: 870px) {
    right: -50px;
    bottom: 340px;
  }
  @media screen and (max-width: 767px) {
    position: unset;
    margin: 0 auto;
    width: 300px;
    text-align: left;
  }
  @media screen and (max-width: 479px) {
    font-size: 25px;
    width: 255px;
  }
`;

// ------ SATELLITE, UFO, LASER, STAR & BOOM --------------------
const StyledBackground = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledSatellite = styled.img`
  height: 220px;
  opacity: 0;
  position: absolute;
  left: 150px;
  top: 130px;
  transform: rotate(260deg);
  animation: satellite 2s linear;
  @keyframes satellite {
    0% {
      opacity: 1;
      transform: rotate(260deg);
    }
    50% {
      transform: rotate(265deg);
    }
    99% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(260deg);
    }
  }
  @media screen and (max-width: 870px) {
    height: 175px;
    top: 115px;
  }
  @media screen and (max-width: 767px) {
    left: 40px;
  }
  @media screen and (max-width: 479px) {
    height: 110px;
    top: 70px;
  }
`;

const StyledUfo = styled.img`
  height: 100px;
  position: absolute;
  right: -300px;
  top: 260px;
  opacity: 1;
  animation: ufo 4s linear;
  @keyframes ufo {
    20% {
      right: -300px;
    }
    21% {
      right: 200px;
      transform: rotate(0deg);
    }
    22% {
      transform: rotate(-10deg);
    }
    23% {
      transform: rotate(5deg);
    }
    24% {
      right: 240px;
      transform: rotate(-5deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26% {
      right: 220px;
    }
    50% {
      right: 220px;
      top: 260px;
    }
    55% {
      right: -300px;
      top: 630px;
    }
  }
  @media screen and (max-width: 870px) {
    height: 80px;
    top: 200px;
    animation: ufo 4s linear;
    @keyframes ufo {
      20% {
        right: -300px;
      }
      21% {
        right: 150px;
        transform: rotate(0deg);
      }
      22% {
        transform: rotate(-10deg);
      }
      23% {
        transform: rotate(5deg);
      }
      24% {
        right: 190px;
        transform: rotate(-5deg);
      }
      25% {
        transform: rotate(0deg);
      }
      26% {
        right: 170px;
      }
      50% {
        right: 170px;
        top: 200px;
      }
      55% {
        right: -300px;
        top: 530px;
      }
    }
  }
  @media screen and (max-width: 599px) {
    animation: ufo 4s linear;
    @keyframes ufo {
      20% {
        right: -300px;
      }
      21% {
        right: 10px;
        transform: rotate(0deg);
      }
      22% {
        transform: rotate(-10deg);
      }
      23% {
        transform: rotate(5deg);
      }
      24% {
        right: 25px;
        transform: rotate(-5deg);
      }
      25% {
        transform: rotate(0deg);
      }
      26% {
        right: 25px;
      }
      50% {
        right: 25px;
        top: 200px;
      }
      55% {
        right: -300px;
        top: 530px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    height: 60px;
    top: 250px;
    animation: ufo 4s linear;
    @keyframes ufo {
      20% {
        right: -300px;
      }
      21% {
        right: 10px;
        transform: rotate(0deg);
      }
      22% {
        transform: rotate(-10deg);
      }
      23% {
        transform: rotate(5deg);
      }
      24% {
        right: 25px;
        transform: rotate(-5deg);
      }
      25% {
        transform: rotate(0deg);
      }
      26% {
        right: 25px;
      }
      50% {
        right: 25px;
        top: 250px;
      }
      55% {
        top: 800px;
      }
    }
  }
`;

const StyledLaser = styled.img`
  height: 15px;
  transform: rotate(5deg);
  position: absolute;
  right: 340px;
  top: 290px;
  opacity: 0;
  animation: laser 4s linear;
  @keyframes laser {
    0%,
    46% {
      opacity: 0;
    }
    47% {
      right: 340px;
      top: 290px;
      opacity: 1;
    }
    50% {
      right: 820px;
      top: 255px;
      opacity: 1;
    }
    51%,
    100% {
      opacity: 0;
    }
  }
  @media screen and (max-width: 1280px), screen and (max-height: 800px) {
    animation: laser 4s linear;
    @keyframes laser {
      0%,
      46% {
        opacity: 0;
      }
      47% {
        right: 340px;
        top: 290px;
        opacity: 1;
      }
      50% {
        right: 620px;
        top: 250px;
        opacity: 1;
      }
      51%,
      100% {
        opacity: 0;
      }
    }
  }
  @media screen and (max-width: 870px) {
    height: 10px;
    right: 260px;
    top: 230px;
    animation: laser 4s linear;
    @keyframes laser {
      0%,
      46% {
        opacity: 0;
      }
      47% {
        right: 260px;
        top: 230px;
        opacity: 1;
      }
      50% {
        right: 430px;
        top: 220px;
        opacity: 1;
      }
      51%,
      100% {
        opacity: 0;
      }
    }
  }
  @media screen and (max-width: 599px) {
    right: 90px;
    top: 230px;
    animation: laser 4s linear;
    @keyframes laser {
      0%,
      46% {
        opacity: 0;
      }
      47% {
        right: 90px;
        top: 230px;
        opacity: 1;
      }
      50% {
        right: 255px;
        top: 215px;
        opacity: 1;
      }
      51%,
      100% {
        opacity: 0;
      }
    }
  }
  @media screen and (max-width: 479px) {
    right: 80px;
    top: 240px;
    transform: rotate(55deg);
    animation: laser 4s linear;
    @keyframes laser {
      0%,
      46% {
        opacity: 0;
      }
      47% {
        right: 80px;
        top: 240px;
        opacity: 1;
      }
      50% {
        right: 140px;
        top: 140px;
        opacity: 1;
      }
      51%,
      100% {
        opacity: 0;
      }
    }
  }
`;

const StyledBoom = styled.img`
  height: 360px;
  opacity: 0;
  position: absolute;
  left: 40px;
  top: 115px;
  animation: boom 4s linear;
  @keyframes boom {
    0%,
    49% {
      opacity: 0;
      height: 360px;
    }
    52% {
      height: 370px;
      opacity: 0.4;
    }
    54% {
      height: 345px;
      opacity: 0.2;
    }
    56% {
      height: 350px;
      opacity: 0.5;
    }
    58% {
      height: 345px;
      opacity: 0.1;
    }
    60% {
      height: 350px;
      opacity: 0.5;
    }
    62% {
      height: 340px;
      opacity: 0.2;
    }
    64% {
      height: 345px;
      opacity: 0.2;
    }
    66% {
      height: 350px;
      opacity: 0.5;
    }
    68%,
    100% {
      opacity: 0;
    }
  }
  @media screen and (max-width: 870px) {
    height: 240px;
    left: 60px;
    animation: boom 4s linear;
    @keyframes boom {
      0%,
      49% {
        opacity: 0;
        height: 260px;
      }
      52% {
        height: 270px;
        opacity: 0.4;
      }
      54% {
        height: 245px;
        opacity: 0.2;
      }
      56% {
        height: 250px;
        opacity: 0.5;
      }
      58% {
        height: 245px;
        opacity: 0.1;
      }
      60% {
        height: 250px;
        opacity: 0.5;
      }
      62% {
        height: 240px;
        opacity: 0.2;
      }
      64% {
        height: 245px;
        opacity: 0.2;
      }
      66% {
        height: 250px;
        opacity: 0.5;
      }
      68%,
      100% {
        opacity: 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    left: 0;
  }
  @media screen and (max-width: 479px) {
    height: 160px;
    top: 50px;
    animation: boom 4s linear;
    @keyframes boom {
      0%,
      49% {
        opacity: 0;
        height: 160px;
      }
      52% {
        height: 170px;
        opacity: 0.4;
      }
      54% {
        height: 145px;
        opacity: 0.2;
      }
      56% {
        height: 150px;
        opacity: 0.5;
      }
      58% {
        height: 145px;
        opacity: 0.1;
      }
      60% {
        height: 150px;
        opacity: 0.5;
      }
      62% {
        height: 140px;
        opacity: 0.2;
      }
      64% {
        height: 145px;
        opacity: 0.2;
      }
      66% {
        height: 150px;
        opacity: 0.5;
      }
      68%,
      100% {
        opacity: 0;
      }
    }
  }
`;

const StyledStarContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 479px) {
    display: none;
  }
`;

const StyledPlanet = styled.img`
  filter: ${() => (useTheme().palette.type === 'light' ? 'none' : filterDarkMode)};
  height: 55px;
  position: absolute;
  left: 30px;
  top: 20px;
  opacity: ${() => (useTheme().palette.type === 'light' ? '0.5' : '1')};
  transform: rotate(10deg);
`;

const StyledSaturn = styled.img`
  filter: ${() => (useTheme().palette.type === 'light' ? 'none' : filterDarkMode)};
  height: 65px;
  position: absolute;
  right: 70px;
  top: 90px;
  opacity: ${() => (useTheme().palette.type === 'light' ? '0.5' : '1')};
  transform: rotate(-5deg);
  @media screen and (max-width: 870px) {
    right: 15px;
  }
  @media screen and (max-width: 767px) {
    top: 10px;
  }
`;

const StyledMoon = styled.img`
  filter: ${() => (useTheme().palette.type === 'light' ? 'none' : filterDarkMode)};
  height: 95px;
  position: absolute;
  left: 125px;
  bottom: 110px;
  opacity: ${() => (useTheme().palette.type === 'light' ? '0.5' : '1')};
  @media screen and (max-width: 995px) {
    left: 90px;
    bottom: 90px;
  }
  @media screen and (max-width: 870px) {
    height: 80px;
    left: 40px;
    bottom: 110px;
  }
  @media screen and (max-width: 767px) {
    height: 70px;
    bottom: 160px;
  }
`;

const StyledMars = styled.img`
  filter: ${() => (useTheme().palette.type === 'light' ? 'none' : filterDarkMode)};
  height: 110px;
  position: absolute;
  right: 40px;
  bottom: 60px;
  opacity: ${() => (useTheme().palette.type === 'light' ? '0.5' : '1')};
  @media screen and (max-width: 870px) {
    height: 90px;
  }
  @media screen and (max-width: 767px) {
    bottom: 65px;
    right: 5px;
    height: 75px;
  }
`;

// ------ ASTRONAUT -------------
const StyledAstronautContainer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 165px;
  height: 200px;
  opacity: 1;
  animation: astronautOpacity 3s linear;
  @keyframes astronautOpacity {
    0%,
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 870px) {
    height: 170px;
  }
  @media screen and (max-width: 767px) {
    bottom: 100px;
    right: 240px;
  }
  @media screen and (max-width: 599px) {
    right: 140px;
    height: 140px;
  }
  @media screen and (max-width: 479px) {
    right: 10px;
    height: 100px;
    bottom: 120px;
  }
`;

const StyledAstronaut = styled.img`
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  animation: astronaut 20s linear infinite;
  @keyframes astronaut {
    0% {
      right: 0;
      bottom: 0;
      transform: rotate(0deg);
    }
    25% {
      right: 20px;
      bottom: 60px;
      transform: rotate(10deg);
    }
    45% {
      right: 45px;
      bottom: 100px;
      transform: rotate(0deg);
    }
    70% {
      right: 25px;
      bottom: 70px;
      transform: rotate(-10deg);
    }
    85% {
      right: 10px;
      bottom: 40px;
      transform: rotate(15deg);
    }
    100% {
      right: 0;
      bottom: 0;
      transform: rotate(0deg);
    }
  }
`;

// ------ BOTTOM ---------------------------
const StyledBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  @media screen and (max-width: 767px) {
    bottom: 5px;
  }
`;

const StyledButton = styled.div`
  width: 129px;
  margin: 10px auto;
  display: block;
  opacity: 1;
  animation: button 3s linear;
  @keyframes button {
    0%,
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 10px auto 20px;
  }
`;

const StyledFooter = styled.div`
  margin: 5px auto;
  width: 550px;
  font-size: 10px;
  color: ${() => useTheme().palette.custom.notFoundElement};
  text-align: center;
  a {
    text-decoration: none;
    color: ${() => useTheme().palette.custom.notFoundElement};
  }
  opacity: 1;
  animation: button 3s linear;
  @keyframes button {
    0%,
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 599px) {
    width: 300px;
  }
`;

interface Props {
  theme: Theme;
}

class Image404 extends React.Component<Props> {
  render() {
    return (
      <StyledImgContainer>
        <StyledDivNumber>4</StyledDivNumber>
        <StyledImgLogo src={this.props.theme.palette.type === THEME_LIGHT ? LogoImage : LogoLight} />
        <StyledDivNumberTwo>4</StyledDivNumberTwo>
      </StyledImgContainer>
    );
  }
}

const TextContainer = (props: Props) => {
  const { t } = useTranslation('notFound');

  return (
    <StyledTextContainer>
      <Image404 theme={props.theme} />
      <StyleText>
        <StyledDivText>{t('textContainer.text1')}</StyledDivText>
        <StyledDivTextTwo>{t('textContainer.text2')}</StyledDivTextTwo>
        <StyledDivTextThree>{t('textContainer.text3')}</StyledDivTextThree>
      </StyleText>
    </StyledTextContainer>
  );
};

const BackgroundContainer = () => {
  return (
    <StyledStarContainer>
      <StyledPlanet src={Planet} />
      <StyledSaturn src={Saturn} />
      <StyledMoon src={Moon} />
      <StyledMars src={Mars} />
    </StyledStarContainer>
  );
};

class BackgroundImageContainer extends React.Component {
  render() {
    return (
      <StyledBackground>
        <StyledSatellite src={Satellite} />
        <StyledUfo src={UfoImg} />
        <StyledBoom src={Boom} />
        <StyledLaser src={Laser} />
        <BackgroundContainer />
      </StyledBackground>
    );
  }
}

const AstronautContainer = () => {
  return (
    <StyledAstronautContainer>
      <StyledAstronaut src={Astronaut} />
    </StyledAstronautContainer>
  );
};

const BottomContainer = () => {
  const { t } = useTranslation('notFound');
  return (
    <StyledBottom>
      <StyledFooter>
        <Typography variant="body1">{t('bottomContainer.title')}</Typography>
        <StyledButton>
          <Button variant="outlined" href="/">
            {t('bottomContainer.goBackHome')}
          </Button>
        </StyledButton>
        <a href="http://www.freepik.com">Icon vector created by brgfx, macrovector - www.freepik.com</a>
      </StyledFooter>
    </StyledBottom>
  );
};

class NotFound extends React.Component<Props> {
  render() {
    return (
      <StyledPage>
        <TextContainer theme={this.props.theme} />
        <BackgroundImageContainer />
        <AstronautContainer />
        <BottomContainer />
      </StyledPage>
    );
  }
}

export default withTheme(NotFound);
