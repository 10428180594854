import { createAction, Action as BaseAction, ActionFunctionAny } from 'redux-actions';

const METHODS = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];
const ACTIONS = ['REQUEST', 'SUCCESS', 'FAILURE', 'CLEANUP', 'SET'];

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';

export type ActionAny = BaseAction<any>;
export type ActionFunction = ActionFunctionAny<ActionAny>;

interface Action {
  request: ActionFunction;
  success: ActionFunction;
  failure: ActionFunction;

  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
}

export type CleanupAction = { cleanup: ActionFunction; CLEANUP: string };
export type SetAction = { set: ActionFunction; SET: string };
export type APIActions = Record<Method, Action>;

export const createAPIActions = (prefix: string): APIActions & CleanupAction => {
  const apiActions = METHODS.reduce((methodsResult: APIActions, method: string) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    methodsResult[method.toLowerCase() as Method] = createActions(`${prefix}/${method}`);
    return methodsResult;
  }, {} as APIActions);

  const cleanup = createAction(`${prefix}/CLEANUP`);
  return { ...apiActions, cleanup, CLEANUP: cleanup.toString() };
};

export const createActions = (prefix: string): Action & CleanupAction & SetAction => {
  const func: any = createAction(`${prefix}/${ACTIONS[0]}`);

  ACTIONS.forEach((action: string) => {
    const actionCreator = createAction(`${prefix}/${action}`);

    func[action.toLowerCase()] = actionCreator;
    func[action] = actionCreator.toString();
  });

  return func;
};

export function createCustomActions<T1 extends string, T2 extends string>(
  prefix: T1,
  actions: T2[],
): Record<Lowercase<T2>, ActionFunction> & Record<T2, string>;

export function createCustomActions(prefix: string, actions: string[]) {
  const func: any = createAction(`${prefix}/${actions[0]}`);

  actions.forEach((action: string) => {
    const actionCreator = createAction(`${prefix}/${action}`);

    func[action.toLowerCase()] = actionCreator;
    func[action] = actionCreator.toString();
  });

  return func;
}
