import { takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import { genericAPIFunction } from '../../utils/api';
import urls from '../urls';

const fetchFeedbackForm = genericAPIFunction(actions.feedbackForm.get, 'get', () => urls.feedbackForm());
const sendFeedbackForm = genericAPIFunction(actions.feedbackForm.post, 'post', () => urls.feedbackForm());

function* saga() {
  yield takeEvery(actions.feedbackForm.get.REQUEST, fetchFeedbackForm);
  yield takeEvery(actions.feedbackForm.post.REQUEST, sendFeedbackForm);
}

export default saga;
