import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Button, Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { UserProps } from '../store/auth/types';
import { user } from '../store/auth/actions';
import { withTheme } from '@material-ui/core/styles';
import { MdSave } from 'react-icons/md';
import { CenteredGrid } from '../components/layout';
import { ThemeProps } from '../theme';
import { withUser } from '../hoc';
import { withTranslation, WithTranslation } from 'react-i18next';

const Image = styled.div`
  display: flex;
  justify-content: center;
`;
Image.displayName = 'Image';

const EditAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 40px;
  transition: 0.5s;
  background-color: rgb(0, 0, 0);
  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

interface State {
  dialogOpen: boolean;
  newAvatar: boolean;
  avatar?: File | string;
}

interface DispatchProps {
  editAvatar: (avatar: File | string) => any;
}

type Props = DispatchProps & ThemeProps & UserProps & WithTranslation;

export class ChangeAvatar extends React.Component<Props, State> {
  readonly state: State = {
    dialogOpen: false,
    newAvatar: false,
    avatar: undefined,
  };

  changeAvatar = (avatar: File | string) => {
    this.props.editAvatar(avatar).then(() => this.setState({ dialogOpen: false, newAvatar: false }));
  };

  fileSelect = (event: any) => {
    this.setState({ avatar: event.target.files[0], newAvatar: true });
  };

  getAvatarURL = (): string => {
    if (this.state.newAvatar && this.state.avatar) {
      if (this.state.avatar instanceof File) {
        return URL.createObjectURL(this.state.avatar);
      } else {
        return this.state.avatar;
      }
    }

    return this.props.user?.avatar;
  };

  render() {
    const { t, user } = this.props;
    return (
      <React.Fragment>
        {user && (
          <React.Fragment>
            <Image>
              <Avatar
                name={user.username}
                size="70"
                color={this.props.theme.palette.primary.main}
                src={user.avatar}
                round
              />
              <EditAvatar>
                <IconButton onClick={() => this.setState({ dialogOpen: true })}>
                  <EditIcon style={{ color: '#ffffff' }} />
                </IconButton>
              </EditAvatar>
            </Image>
            <Dialog
              open={this.state.dialogOpen}
              onClose={() => this.setState({ dialogOpen: false, newAvatar: false })}
              fullWidth={true}
              maxWidth="xs"
              transitionDuration={0}
            >
              <Grid container spacing={2} style={{ padding: 20, margin: 0, width: '100%' }}>
                <CenteredGrid item xs={12}>
                  <Avatar
                    name={user.username}
                    size="100"
                    color={this.props.theme.palette.primary.main}
                    src={this.getAvatarURL()}
                  />
                </CenteredGrid>
                <CenteredGrid item xs={12}>
                  <Grid container justify="center" spacing={2}>
                    <CenteredGrid item>
                      {(!this.state.newAvatar && (
                        <Button
                          component="label"
                          variant="contained"
                          color="primary"
                          disableElevation
                          startIcon={<CloudUploadIcon />}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('upload')}
                          <input type="file" accept="image/*" style={{ display: 'none' }} onChange={this.fileSelect} />
                        </Button>
                      )) || (
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={() => this.state.avatar && this.changeAvatar(this.state.avatar)}
                          startIcon={<MdSave />}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('save')}
                        </Button>
                      )}
                    </CenteredGrid>
                    <CenteredGrid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => {
                          this.props.editAvatar('');
                          this.setState({ avatar: undefined, newAvatar: false });
                        }}
                        startIcon={<DeleteIcon />}
                        disabled={Boolean(!this.state.avatar) && !user.avatar}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {t('remove')}
                      </Button>
                    </CenteredGrid>
                  </Grid>
                </CenteredGrid>
              </Grid>
            </Dialog>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    editAvatar: (avatar: File | string) => {
      return dispatch({
        ...user.patch.request({ avatar }),
        [WAIT_FOR_ACTION]: user.patch.SUCCESS,
      });
    },
  };
};

export default withTranslation()(withUser(connect(null, mapDispatchToProps)(withTheme(ChangeAvatar))));
