import { createSelector } from 'reselect';

import { Satellite } from './types';
import { ApplicationState } from '../index';
import { SATELLITES_WITH_TILED_PREVIEW } from '../../constants';
import { isEmpty } from 'lodash';

export const satellitesSelector = (state: ApplicationState): Satellite[] => state.satellites.data;
export const fetchedAllSelector = (state: ApplicationState): boolean => state.satellites.fetchedAll;
export const selectedIdsSelector = (state: ApplicationState) => state.satellites.selected;

export const satellitesWithTilesSelector = createSelector(satellitesSelector, (satellites: Satellite[]) =>
  satellites.filter((satellite: Satellite) => {
    return SATELLITES_WITH_TILED_PREVIEW.includes(satellite.properties.name);
  }),
);

export const selectedSatellitesSelector = createSelector(
  satellitesSelector,
  selectedIdsSelector,
  satellitesWithTilesSelector,
  (satellites, selectedIds, satellitesWithTiles) => {
    if (selectedIds && !isEmpty(selectedIds)) {
      return satellites.filter((s) => selectedIds.includes(s.id));
    } else {
      return satellitesWithTiles;
    }
  },
);

export const satelliteByIdSelector = createSelector(
  satellitesSelector,
  (satellites: Satellite[]) => (id: number) => satellites.filter((satellite) => satellite.id === id)[0],
);
