import { createSelector } from 'reselect';
import { uniqBy } from 'lodash';

import { ApplicationState } from '../index';
import { activeObservationSelector } from '../observations/selectors';
import { SATELLITES_WITH_TILED_PREVIEW } from '../../constants';
import { Imagery, PendingImageries } from './types';
import { CommercialConstellation } from '../satellites/types';

export function imageriesSelector(state: ApplicationState) {
  return state.imageries.data.filter((imagery) => SATELLITES_WITH_TILED_PREVIEW.includes(imagery.satelliteName));
}

export const highResolutionImageriesSelector = (state: ApplicationState) => state.imageries.highResolutionSearchData;
export const highResolutionImageriesConstellationsSelector = createSelector(
  highResolutionImageriesSelector,
  (imageries) => uniqBy(imageries, 'constellation').map((im) => im.constellation),
);
export const highResolutionImageriesByConstellationSelector = createSelector(
  highResolutionImageriesSelector,
  (imageries) => (constellation: CommercialConstellation) =>
    (imageries && imageries.filter((imagery) => imagery.constellation === constellation)) || undefined,
);

export const imageryByIdSelector = createSelector(imageriesSelector, (imageries) => (id: number) => {
  return imageries.filter((imagery) => imagery.id === id)[0];
});

export const imageriesTotalPagesSelector = (state: ApplicationState) => state.imageries.totalPages;
export const imageryFilesSelector = (state: ApplicationState) => state.imageries.files;
export const pendingImagerySelector = (state: ApplicationState): PendingImageries[] => state.imageries.pending;

export const observationImagerySelector = createSelector(
  [imageriesSelector, activeObservationSelector],
  (imageries, observation) => (added: boolean) =>
    added ? imageries.filter((i) => observation.imageries.includes(i.id)) : imageries,
);

export const recommendedImageriesSelector = (state: ApplicationState): Imagery[] => state.imageries.recommended;
