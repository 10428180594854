import * as Leaflet from 'leaflet';
import { bbox, bboxPolygon, intersect, area, Feature } from '@turf/turf';
import { BBox, Polygon } from 'geojson';
import { flatten } from 'lodash';
import { BoundsLiteral, LatLngBounds } from 'leaflet';
import { MapBounds } from '../store/app/types';

export const convertPolygonToLeafletBBOX = (polygon: Polygon): Leaflet.LatLngBoundsLiteral => {
  const bb = bbox(polygon);
  return [
    [bb[1], bb[0]],
    [bb[3], bb[2]],
  ];
};

type Bounds = number[][];

export const reverseBounds = (bounds: Bounds): Bounds => {
  return [[...bounds[0]].reverse(), [...bounds[1]].reverse()];
};

export const leafletElementToMapBounds = (element: Leaflet.Map): MapBounds => {
  const leafletBounds = element.getBounds();

  return [
    [leafletBounds.getSouthWest().lat, leafletBounds.getSouthWest().lng],
    [leafletBounds.getNorthEast().lat, leafletBounds.getNorthEast().lng],
  ] as MapBounds;
};

export const leafletBoundsToBBoxPolygon = (bounds: LatLngBounds): Feature<Polygon> => {
  const mapBBox = bounds
    .toBBoxString()
    .split(',')
    .map((coord) => parseFloat(coord));
  return bboxPolygon(mapBBox as BBox);
};

export const mapBoundsToBboxPolygon = (bounds: Bounds): Polygon => {
  const bbox = flatten(reverseBounds(bounds)) as [number, number, number, number];
  return bboxPolygon(bbox).geometry as Polygon;
};

export const geojsonBBoxToLeafletBoundsLiteral = (bbox: BBox): BoundsLiteral => [
  [bbox[0], bbox[1]],
  [bbox[2], bbox[3]],
];

export const areaCoveragePercentage = (geo1: Polygon, geo2: Polygon): number => {
  const intersection = intersect(geo1, geo2);
  if (intersection) {
    return parseFloat(((area(intersection) / area(geo2)) * 100).toFixed(2));
  }
  return 0;
};
