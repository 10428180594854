import React from 'react';
import {
  Card,
  CardActions,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { MdClose, MdFolder, MdInsertDriveFile } from 'react-icons/md';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Imagery, ImageryFile } from '../store/imageries/types';
import { Theme, useTheme, withTheme } from '@material-ui/core/styles';
import { ApplicationState } from '../store';
import { imageryFilesSelector } from '../store/imageries/selectors';
import { UserProps } from '../store/auth/types';
import { ImageState, Observation } from '../store/observations/types';
import { ImageryEdit } from '../components/imagery-edit';
import { mapBoundsSelector } from '../store/app/selectors';
import { Satellite } from '../store/satellites/types';
import { selectedSatellitesSelector } from '../store/satellites/selectors';
import { withUser } from '../hoc';
import { activeObservationSelector } from '../store/observations/selectors';
import { RouteComponentProps, withRouter } from 'react-router';
import { imageryFiles } from '../store/imageries/actions';
import { Dispatch } from 'redux';
import { formatUTC } from '../utils/date';
import { DEFAULT_DATETIME_FORMAT } from '../constants';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dialog } from '../components/layout';

const Container = styled.div`
  background: ${() => useTheme().palette.background.paper};
  color: ${() => useTheme().palette.primary.contrastText};
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`;

interface ComponentProps {
  imagery: Imagery;
  imageState?: ImageState;
  onImageryEdit: (input: ImageState) => void;
  onClose: () => void;
  theme: Theme;
}

interface DispatchProps {
  fetchImageryFiles: (observationUUID: string, imageryId: number) => void;
}

interface StoreProps {
  imageryFiles: ImageryFile[];
  satellites: Satellite[];
  mapBounds: number[][];
  observation: Observation;
}

type Props = ComponentProps &
  DispatchProps &
  RouteComponentProps &
  StoreProps &
  UserProps &
  WithTranslation<['common', 'imageryView']>;

interface State {
  apiLinksDialogOpen: boolean;
  filesDialogOpen: boolean;
  open: boolean;
  imageState?: ImageState;
}

export class ImageryView extends React.Component<Props, State> {
  readonly state: State = {
    apiLinksDialogOpen: false,
    filesDialogOpen: false,
    open: true,
  };

  render() {
    const { imagery, t } = this.props;

    return (
      <Container>
        <Grid container={true} justify="center" spacing={0}>
          <Grid item={true} xs={12}>
            <Card elevation={1}>
              <CardHeader
                style={{
                  background: this.props.theme.palette.primary.main,
                  color: this.props.theme.palette.primary.contrastText,
                }}
                action={
                  <IconButton onClick={this.props.onClose} color="inherit">
                    <MdClose />
                  </IconButton>
                }
                title={imagery.satelliteName + ' image'}
                titleTypographyProps={{ color: 'inherit' }}
                subheader={
                  `${formatUTC(imagery.begin_position_date, DEFAULT_DATETIME_FORMAT)} UTC, ` +
                  `${imagery.cloud_cover_percentage}% ${t('imageryView:coveredByClouds')}`
                }
                subheaderTypographyProps={{ color: 'inherit' }}
              />
              <CardActions
                disableSpacing
                style={{
                  background: this.props.theme.palette.primary.main,
                  color: this.props.theme.palette.primary.contrastText,
                }}
              >
                <Tooltip title={t('imageryView:downloadTooltip')}>
                  <IconButton
                    disabled={Boolean(!this.props.user)}
                    onClick={() => this.setState({ filesDialogOpen: true })}
                    color="inherit"
                  >
                    <MdFolder />
                  </IconButton>
                </Tooltip>
              </CardActions>
              <ImageryEdit
                key={`imagery-edit-${imagery.id}`}
                satelliteName={imagery.satelliteName}
                onStateChange={(parameters: ImageState) => {
                  this.props.onImageryEdit({ ...this.props.imageState, ...parameters });
                }}
                initialImageState={this.props.imageState}
              />
            </Card>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.filesDialogOpen}
          onClose={() => this.setState({ filesDialogOpen: false })}
          onEnter={() => this.props.fetchImageryFiles(this.props.observation.uuid, this.props.imagery.id)}
        >
          <List disablePadding={true}>
            {this.props.imageryFiles.map((file) => (
              <ListItem key={`file_${file.path}`} button={true} onClick={() => window.open(file.url, '_blank')}>
                <ListItemIcon>
                  <MdInsertDriveFile />
                </ListItemIcon>
                <ListItemText
                  inset={true}
                  primary={`${file.path}`}
                  primaryTypographyProps={{ color: 'textPrimary' }}
                  secondary={`size: ${(file.size * 10 ** -6).toFixed(2)}MB`}
                  secondaryTypographyProps={{ color: 'textPrimary' }}
                />
              </ListItem>
            ))}
          </List>
        </Dialog>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchImageryFiles: (observationUUID, imageryId) => dispatch(imageryFiles.get.request({ observationUUID, imageryId })),
});

const mapStoreToProps = (state: ApplicationState, ownProps: RouteComponentProps) => ({
  imageryFiles: imageryFilesSelector(state),
  mapBounds: mapBoundsSelector(state),
  satellites: selectedSatellitesSelector(state),
  observation: activeObservationSelector(state),
});

export default withTranslation(['common', 'imageryView'])(
  withUser(withRouter(connect(mapStoreToProps, mapDispatchToProps)(withTheme(ImageryView)))),
);
