import { DEFAULT_DATETIME_FORMAT, Hemisphere } from '../constants';
import { DateRange } from '../components/widgets/imagery-filters';

export const SEASONS = ['winter', 'autumn', 'summer', 'spring'];
type SeasonTuple = typeof SEASONS;
export type Season = SeasonTuple[number];

const SEASONS_MONTHS_RANGE: Record<Hemisphere, Record<Season, [number, number]>> = {
  northern: {
    spring: [3, 5],
    summer: [6, 8],
    autumn: [9, 11],
    winter: [12, 2],
  },
  southern: {
    spring: [9, 11],
    summer: [12, 2],
    autumn: [3, 5],
    winter: [6, 8],
  },
};

export const formatUTC = (date: Date, formatString?: string): string => {
  if (!formatString) {
    formatString = DEFAULT_DATETIME_FORMAT;
  }

  const isoString = date.toISOString();

  const year = isoString.substr(0, 4);
  const month = isoString.substr(5, 2);
  const day = isoString.substr(8, 2);

  const hour = isoString.substr(11, 2);
  const minute = isoString.substr(14, 2);
  const second = isoString.substr(17, 2);

  const mapping = {
    yyyy: year,
    MM: month,
    dd: day,
    HH: hour,
    mm: minute,
    ss: second,
  };
  let result = formatString;

  for (const [key, value] of Object.entries(mapping)) {
    result = result.replace(key, value);
  }

  return result;
};

export const getLastSeasonDates = (season: Season, hemisphere: Hemisphere): DateRange => {
  const [fromMonth, toMonth] = SEASONS_MONTHS_RANGE[hemisphere][season];
  const dt = new Date();
  const currentMonth = dt.getMonth() + 1;
  let fromYear = dt.getFullYear();
  let toYear = dt.getFullYear();

  if (toMonth > currentMonth) {
    fromYear -= 1;
    toYear -= 1;
  }

  if (toMonth < fromMonth) {
    fromYear = toYear - 1;
  }

  const dateFrom = new Date(Date.UTC(fromYear, fromMonth - 1, 1));
  const dateTo = new Date(Date.UTC(toYear, toMonth, 0, 23, 59, 59));

  return { date_from: dateFrom, date_to: dateTo };
};
