import React from 'react';
import GoogleAnalytics from 'react-ga4';
import { connect } from 'react-redux';

import { userSelector } from './store/auth/selectors';
import { ApplicationState } from './store';
import { Redirect } from 'react-router-dom';

export const withPathBasedKey = (WrappedComponent: any) => {
  return class PathBasedKeyHOC extends React.Component<any> {
    render() {
      return <WrappedComponent key={this.props.location.pathname} {...this.props} />;
    }
  };
};

export const withUser = (WrappedComponent: any) => {
  class UserHOC extends React.Component<any> {
    render() {
      const isLoggedIn = Boolean(this.props.user || localStorage.getItem('auth_token'));
      const key = `logged_in_${isLoggedIn}`;
      return <WrappedComponent key={key} isLoggedIn={isLoggedIn} user={this.props.user} {...this.props} />;
    }
  }

  const mapStoreToProps = (state: ApplicationState) => {
    return {
      user: userSelector(state),
    };
  };

  return connect(mapStoreToProps)(UserHOC);
};

export const IsLoggedIn = (WrappedComponent: any) => {
  const IsLoggedInHOC = (props: any) => {
    if (!props.isLoggedIn) {
      return <Redirect to={`/auth/signin?redirect=${props.match.url}`} />;
    }

    return <WrappedComponent {...props} />;
  };

  return IsLoggedInHOC;
};

export const trackerHOC = (WrappedComponent: any, options = {}) => {
  const trackPage = (page: string) => {
    const analyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

    if (analyticsKey) {
      GoogleAnalytics.initialize(analyticsKey);

      GoogleAnalytics.set({
        page,
        ...options,
      });
    }
  };

  class Tracked extends React.Component<any> {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentDidUpdate(prevProps: any) {
      const previousPage = prevProps.location.pathname;
      const currentPage = this.props.location.pathname;

      if (previousPage !== currentPage) {
        trackPage(currentPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return Tracked;
};
