export type AlertScope = 'general' | 'observation' | 'acquisition' | 'main' | 'channels' | undefined;

export enum FollowType {
  Imagery = 'imagery',
  Overpass = 'overpass',
}

export enum FollowAction {
  Follow = 'follow',
  Unfollow = 'unfollow',
}

export interface Notification {
  date: string;
  satelliteName: string;
  observationName: string;
  id: number;
  title: string;
  timestamp: Date;
  body: string;
  read: boolean;
  url: string | null;
  codename: FollowType.Imagery | FollowType.Overpass;
}

export interface NotificationsState {
  data: Notification[];
  unreadCount: number;
}

export interface NotificationQueryParameters {
  readonly timestamp_from?: Date;
  readonly timestamp_to?: Date;
  readonly limit?: number;
}
