import { Reducer } from 'redux';
import { find, isUndefined, uniqBy, orderBy, castArray, startsWith } from 'lodash';
import { parseISO } from 'date-fns';
import queryString from 'query-string';

import { AppState, TileLayer } from './types';
import {
  locations,
  mapLabelsDisabled,
  recentlyUsed,
  setMapBounds,
  setMapParameters,
  mapPolygons,
  mapTiles,
  showTrending,
  commercialMapTiles,
} from './actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import routes from '../../routes';
import { matchPath } from 'react-router';
import { area, buffer } from '@turf/turf';
import { point } from '@turf/helpers';
import { observation } from '../observations/actions';
import { channel, channels } from '../channels/actions';
import { Channel } from '../channels/types';
import { DEFAULT_SATELLITE_BANDS, M2_TO_KM2_DENOMINATOR, MIN_OBSERVATION_AREA } from '../../constants';
import { highResolutionImageries } from '../imageries/actions';
import urls from '../urls';
import { alerts } from '../notifications/actions';

const computeTileLayerUrl = (tiles: TileLayer) => {
  const tilerState = tiles.imageryState?.tilerState || DEFAULT_SATELLITE_BANDS[tiles.imagery.satelliteName];
  const tilerParameters = queryString.stringify(tilerState);

  return (
    `${process.env.REACT_APP_API_URL}/observation/${tiles.observationUUID}/imagery/${tiles.imagery.id}` +
    `/tiles/{z}/{x}/{y}?${tilerParameters}`
  );
};

const initialState: AppState = {
  commercialMapTiles: [],
  alerts: [],
  locations: [],
  mapBounds: [
    [-90, -180],
    [90, 180],
  ],
  mapLabelsDisabled: false,
  mapParameters: {
    center: { lat: 0, lng: 0 },
    zoom: 2,
  },
  mapPolygons: [],
  mapTiles: [],
  routeParams: {
    channelName: undefined,
    observationId: undefined,
  },
  query: undefined,
  recentlyUsed: [],
  showTrending: false,
};

const reducer: Reducer<AppState> = (state = initialState, action) => {
  switch (action.type) {
    case alerts.get.SUCCESS: {
      return {
        ...state,
        alerts: action.payload,
      };
    }

    case alerts.patch.SUCCESS: {
      return {
        ...state,
        alerts: [...state.alerts, ...action.payload],
      };
    }

    case locations.REQUEST: {
      if (action.payload) {
        return { ...state, query: action.payload };
      }
      return state;
    }

    case locations.SUCCESS: {
      const payload = uniqBy(action.payload, 'display_name').filter(
        (o: any) => o.geojson && area(o.geojson) <= MIN_OBSERVATION_AREA * M2_TO_KM2_DENOMINATOR,
      );

      return {
        ...state,
        locations: payload.map((location: Json) => {
          const geojson = location.geojson;
          return {
            name: location.display_name,
            geometry: geojson.type === 'Point' ? buffer(geojson, 10) : geojson,
            center: point([location.lat, location.lon]).geometry,
          };
        }),
      };
    }

    case mapPolygons.DELETE: {
      if (!action.payload) {
        return { ...state, mapPolygons: [] };
      }

      return {
        ...state,
        mapPolygons: state.mapPolygons.filter((polygon) => !startsWith(polygon.id, action.payload)),
      };
    }

    case mapPolygons.ADD: {
      return { ...state, mapPolygons: uniqBy([...state.mapPolygons, ...castArray(action.payload)], 'id') };
    }

    case mapPolygons.UPDATE: {
      const patchedPolygons = state.mapPolygons.map((polygon) => {
        if (polygon.id === action.payload.id) {
          return { ...polygon, ...action.payload.parameters };
        } else {
          return polygon;
        }
      });

      return { ...state, mapPolygons: patchedPolygons };
    }

    case mapTiles.DELETE: {
      if (isUndefined(action.payload)) {
        return { ...state, mapTiles: [] };
      }

      return {
        ...state,
        mapTiles: state.mapTiles.filter((tiles) => tiles.imagery.uuid !== action.payload.uuid),
      };
    }

    case highResolutionImageries.get.SUCCESS: {
      const commercialMapTiles = action.payload.map((imagery: any) => ({
        imagery: {
          ...imagery,
          date: parseISO(imagery.date),
        },
        imageryState: { hidden: true },
        name: `${parseISO(imagery.date).toUTCString()} (${imagery.satellite})`,
        url: `${process.env.REACT_APP_API_URL}${urls.highResolutionImageryWMS({ imageryId: imagery.uuid })}`,
      }));

      return { ...state, commercialMapTiles };
    }

    case mapTiles.ADD: {
      let mapTiles = uniqBy([action.payload, ...state.mapTiles], 'imagery.uuid');

      mapTiles = mapTiles.map((tiles) => {
        const url = computeTileLayerUrl(tiles);

        return { ...tiles, url };
      });

      return { ...state, mapTiles: orderBy(mapTiles, 'imagery.begin_position_date', 'desc') };
    }

    case mapTiles.UPDATE: {
      const patchedTiles = state.mapTiles.map((tiles) => {
        if (tiles.imagery.uuid === action.payload.uuid) {
          const patched = { ...tiles, ...action.payload.parameters };
          return { ...patched, url: computeTileLayerUrl(patched) };
        } else {
          return tiles;
        }
      });

      return { ...state, mapTiles: patchedTiles };
    }

    case commercialMapTiles.UPDATE: {
      const commercialMapTiles = state.commercialMapTiles.map((tiles) => {
        if (tiles.imagery.uuid === action.payload.uuid) {
          return { ...tiles, ...action.payload.parameters };
        } else {
          return tiles;
        }
      });

      return { ...state, commercialMapTiles };
    }

    case setMapParameters.REQUEST: {
      return { ...state, mapParameters: action.payload };
    }

    case setMapBounds.REQUEST: {
      return { ...state, mapBounds: action.payload };
    }

    case LOCATION_CHANGE: {
      let routeParams = initialState.routeParams;
      for (const route of routes) {
        const match = matchPath(action.payload.location.pathname, route);
        if (match && match.isExact) {
          routeParams = match.params;
        }
      }

      const query = action.payload.action === 'PUSH' ? undefined : state.query;
      return { ...state, query, routeParams };
    }

    case recentlyUsed.REQUEST: {
      return { ...state, recentlyUsed: uniqBy([action.payload, ...state.recentlyUsed], 'name').slice(0, 3) };
    }

    case observation.delete.SUCCESS: {
      return {
        ...state,
        recentlyUsed: state.recentlyUsed.filter((o) => o.uuid !== action.request_action.payload.observationUUID),
      };
    }

    case channels.get.SUCCESS: {
      const defaultChannel: Channel = find(action.payload, 'default');

      if (defaultChannel && state.activeChannelId === undefined) {
        return { ...state, activeChannelId: defaultChannel.id };
      }

      return state;
    }

    case channel.delete.SUCCESS: {
      if (action.request_action.payload.channelId === state.activeChannelId) {
        return { ...state, activeChannelId: undefined };
      }

      return state;
    }

    case mapLabelsDisabled.SET: {
      return { ...state, mapLabelsDisabled: action.payload };
    }

    case showTrending.SET: {
      return { ...state, showTrending: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { reducer as appReducer };
