import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { Polygon } from 'geojson';
import { push } from 'connected-react-router';
import { MdPanoramaFishEye } from 'react-icons/md';
import { Paper, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation, withTranslation } from 'react-i18next';

import { Channel } from '../store/channels/types';
import { channelByIdSelector } from '../store/channels/selectors';
import { UserProps, UserSettings } from '../store/auth/types';
import { Satellite } from '../store/satellites/types';
import { satellitesWithTilesSelector, selectedSatellitesSelector } from '../store/satellites/selectors';
import PulseIcon from '../components/pulse-icon';
import { ApplicationState } from '../store';
import { mapBoundsSelector, mapParametersSelector, querySelector } from '../store/app/selectors';
import { observation } from '../store/observations/actions';
import { mapBoundsToBboxPolygon } from '../utils/geo';
import { Observation, ObservationSettings } from '../store/observations/types';
import { withUser } from '../hoc';
import { MapBounds, MapParameters } from '../store/app/types';
import { userSettingsSelector } from '../store/auth/selectors';
import { CenteredGrid } from '../components/layout';
import area from '@turf/area';
import { M2_TO_KM2_DENOMINATOR, MIN_OBSERVATION_AREA } from '../constants';

interface DispatchProps {
  createObservation: (
    channel: number | null,
    name: string,
    satellites: number[],
    geometry: Polygon,
    settings: Partial<ObservationSettings>,
  ) => any;
  push: (url: string) => void;
}

interface StoreProps {
  channelById: (id: number) => Channel;
  mapParameters: MapParameters;
  mapBounds: MapBounds;
  userSettings: UserSettings;
  satellitesWithTiles: Satellite[];
  selectedSatellites: Satellite[];
  query?: string;
}

type Props = DispatchProps & StoreProps & UserProps;

export const CreateObservationButton = ({
  mapBounds,
  userSettings,
  channelById,
  selectedSatellites,
  satellitesWithTiles,
  createObservation,
  query,
  push,
}: Props) => {
  const { t } = useTranslation(['createObservationButton', 'common']);
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const create = () => {
    const geometry = mapBoundsToBboxPolygon(mapBounds);
    let channelId = null;

    if (userSettings.activeChannelId) {
      const channel = channelById(userSettings.activeChannelId);
      channelId = channel && channel.can_edit ? channel.id : null;
    }

    const observationSatellites = selectedSatellites.length > 0 ? selectedSatellites : satellitesWithTiles;
    const satellitesIds = observationSatellites.map((satellite) => satellite.id);

    const settings = {
      imageriesDisplay: userSettings.defaultImageriesDisplay,
    };

    createObservation(channelId, query || t('common:observation'), satellitesIds, geometry, settings).then(
      (observation: Observation) => {
        push(`/observation/${observation.uuid}`);
      },
    );
  };

  const disabled = area(mapBoundsToBboxPolygon(mapBounds)) > MIN_OBSERVATION_AREA * M2_TO_KM2_DENOMINATOR;
  return (
    <CenteredGrid container={true}>
      <CenteredGrid item={true} style={{ marginBottom: '20px', marginLeft: '50px', marginRight: '50px' }}>
        <Paper
          elevation={0}
          style={{ background: 'black', opacity: '80%', padding: 10, color: 'white', textAlign: 'center' }}
        >
          <Typography variant={isSmallerScreen ? 'subtitle2' : 'h6'} color="inherit" align="center">
            {(disabled && t('createObservationButton:areaTooBig')) ||
              t('createObservationButton:createObservation') + '!'}
          </Typography>
        </Paper>
      </CenteredGrid>
      <CenteredGrid item={true} xs={12} id={'create-observation-button'}>
        <PulseIcon disabled={disabled} onClick={create}>
          <MdPanoramaFishEye
            size={70}
            color={useTheme().palette.custom.contrastElement}
            style={{ pointerEvents: 'all' }}
          />
        </PulseIcon>
      </CenteredGrid>
    </CenteredGrid>
  );
};

const mapStoreToProps = (state: ApplicationState): StoreProps => {
  return {
    mapParameters: mapParametersSelector(state),
    mapBounds: mapBoundsSelector(state),
    channelById: channelByIdSelector(state),
    userSettings: userSettingsSelector(state),
    satellitesWithTiles: satellitesWithTilesSelector(state),
    selectedSatellites: selectedSatellitesSelector(state),
    query: querySelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    createObservation: (channel, name, satellites, geometry, settings) => {
      return dispatch({
        ...observation.post.request({ channel, name, satellites, geometry, settings }),
        [WAIT_FOR_ACTION]: observation.post.SUCCESS,
        [ERROR_ACTION]: observation.post.FAILURE,
      });
    },
    push: (url: string) => {
      dispatch(push(url));
    },
  };
};

export default withTranslation(['common', 'createObservationButton'])(
  withUser(connect(mapStoreToProps, mapDispatchToProps)(CreateObservationButton)),
);
