import { Reducer } from 'redux';
import { FeedbackFormState } from './types';
import { feedbackForm } from './actions';

const initialState: FeedbackFormState = {
  data: [],
  formId: undefined,
  errors: undefined,
};

const reducer: Reducer<FeedbackFormState> = (state = initialState, action) => {
  switch (action.type) {
    case feedbackForm.get.SUCCESS: {
      return { ...state, data: action.payload.fields, formId: action.payload.form_id };
    }
    case feedbackForm.get.FAILURE: {
      return { ...state, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as feedbackFormReducer };
