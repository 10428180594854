import { createSelector } from 'reselect';
import { push } from 'connected-react-router';

import { Observation, ObservationSettings } from './types';
import { ApplicationState } from '../index';
import { SuggestionType } from '../app/types';
import { activeChannelSelector } from '../channels/selectors';
import { imageriesSelector } from '../imageries/selectors';
import { Channel } from '../channels/types';
import { Imagery } from '../imageries/types';
import { DEFAULT_OBSERVATION_SETTINGS } from './constants';
import { userSettingsSelector } from '../auth/selectors';
import { centroid } from '@turf/turf';
import { Feature, Point } from 'geojson';
import { Hemisphere } from '../../constants';
import { getLastSeasonDates, SEASONS } from '../../utils/date';
import { DateFilterPreset, PeriodPreset } from '../../components/widgets/imagery-filters';
import { subDays } from 'date-fns';

export function observationsSelector(state: ApplicationState): Observation[] {
  return state.observations.data;
}

export const observationByUUIDSelector = createSelector(
  [observationsSelector],
  (observations: Observation[]) => (uuid: string) => observations.filter((observation) => observation.uuid === uuid)[0],
);

export const observationsByChannelIdSelector = createSelector(
  [observationsSelector],
  (observations: Observation[]) => (channelId: number) =>
    observations.filter((observation) => observation.channel === channelId),
);

export function activeObservationSelector(state: ApplicationState) {
  return observationByUUIDSelector(state)(state.observations.activeObservationUUID as string);
}

export function activeObservationUUIDSelector(state: ApplicationState) {
  return state.observations.activeObservationUUID;
}

export const observationImageriesCollectionSelector = createSelector(
  [activeObservationSelector, imageriesSelector],
  (observation: Observation, imageries) => {
    return imageries.filter((imagery: Imagery) => observation.imageries_collection.includes(imagery.id));
  },
);

export const observationImageriesHistorySelector = createSelector(
  [activeObservationSelector, imageriesSelector],
  (observation: Observation, imageries) => {
    return imageries
      .filter((imagery: Imagery) => observation.imageries_history.includes(imagery.id))
      .sort((a, b) => {
        return observation.imageries_history.indexOf(a.id) - observation.imageries_history.indexOf(b.id);
      });
  },
);

export const observationsAsSuggestionsSelector = createSelector(
  [observationsSelector, activeChannelSelector],
  (observations: Observation[], channel?: Channel) =>
    channel
      ? observations
          .filter((observation: Observation) => observation.channel === channel.id)
          .map((observation: Observation) => {
            return {
              action: push(`/observation/${observation.uuid}`),
              name: observation.name,
              type: 'observation' as SuggestionType,
            };
          })
      : [],
);

export function activeObservationSettingsSelector(state: ApplicationState): ObservationSettings {
  const observation = activeObservationSelector(state);
  const userSettings = userSettingsSelector(state);

  const observationSettings = observation ? observation.settings : {};
  return {
    ...{ imageriesDisplay: userSettings.defaultImageriesDisplay },
    ...DEFAULT_OBSERVATION_SETTINGS,
    ...observationSettings,
  };
}

export const activeObservationHemisphereSelector = createSelector(
  [activeObservationSelector],
  (observation: Observation) => {
    const observationCentroid = centroid(observation.geometry) as Feature<Point>;
    const hasPositiveLatitude = observationCentroid?.geometry?.coordinates[1] > 0;
    return hasPositiveLatitude ? 'northern' : 'southern';
  },
);

export const dateFilterPresetsSelector = createSelector(
  [activeObservationHemisphereSelector],
  (hemisphere: Hemisphere): Array<DateFilterPreset> => [
    ['days30', { date_from: subDays(new Date(), 30), date_to: new Date() }],
    ['days90', { date_from: subDays(new Date(), 90), date_to: new Date() }],
    ...SEASONS.map((season): DateFilterPreset => [season as PeriodPreset, getLastSeasonDates(season, hemisphere)]),
  ],
);
