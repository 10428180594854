import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { v4 as uuid4 } from 'uuid';

import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { GlobalStyles } from './styled-components';
import configureStore from './configure-store';
import { loginVerify } from './store/auth/actions';
import routes from './routes';
import { Container, SatellitesMenuPlaceholder } from './components/layout';
import { IsLoggedIn, trackerHOC, withPathBasedKey, withUser } from './hoc';
import { config } from './store/config/actions';
import ThemeSwitcher from './containers/theme-switcher';
import { APP_SESSION } from './constants';
import history from './history';
import PaymentStatus from './containers/payment-status';
import { alerts } from './store/notifications/actions';
import Navigation from './containers/navigation';

import './i18n/config';

const initialState = window.initialReduxState;

const store = configureStore(history, initialState);
localStorage.setItem(APP_SESSION, uuid4());

store.dispatch(config.get.request());
store.dispatch(alerts.get.request());
store.dispatch(loginVerify.request());

const Root = (
  <Provider store={store}>
    <ThemeSwitcher>
      <GlobalStyles />
      <ConnectedRouter history={history}>
        <Switch>
          {routes.map((route, index) => {
            const Comp = trackerHOC(
              withPathBasedKey(withUser(route.authenticated ? IsLoggedIn(route.component) : route.component)),
            );

            return (
              <Route
                key={index}
                path={route.path}
                exact={true}
                render={(props) => {
                  return (
                    <>
                      {route.header && <Navigation />}
                      <Container withNavbar={route.header !== undefined}>
                        {route.satellites_menu && <SatellitesMenuPlaceholder />}
                        <Comp {...props} />
                      </Container>
                    </>
                  );
                }}
              />
            );
          })}
        </Switch>
      </ConnectedRouter>
      <PaymentStatus />
    </ThemeSwitcher>
  </Provider>
);

ReactDOM.render(Root, document.getElementById('root'));
