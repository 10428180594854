import React from 'react';
import { List, ListItem, Typography, Paper } from '@material-ui/core';
import { formatUTC } from '../../utils/date';
import { DEFAULT_DATETIME_FORMAT } from '../../constants';
import { PendingImageries } from '../../store/imageries/types';
import { useTranslation } from 'react-i18next';

interface Props {
  pendingImageries: PendingImageries[];
}

export const PendingImageriesCard: React.FC<Props> = (props: Props) => (
  <Paper elevation={10} style={{ margin: '-4px -8px', padding: 5, fontSize: '0.8rem' }}>
    <Typography variant="body1" align="center">
      {useTranslation('widgets').t('pendingImageriesCard.title')}
    </Typography>
    <List>
      {props.pendingImageries.map((pendingImagery: PendingImageries, idx: number) => (
        <ListItem key={idx}>
          <Typography variant="body2" align="center">
            {`${pendingImagery.satellite}, ${formatUTC(pendingImagery.date, DEFAULT_DATETIME_FORMAT)} UTC`}
          </Typography>
        </ListItem>
      ))}
    </List>
  </Paper>
);
