import { call, put, takeEvery } from 'redux-saga/effects';

import { highResolutionImageryCheckout } from './actions';
import urls from '../urls';
import { callApi } from '../../utils/api';

function* checkoutHighResolutionImagery({ payload }: any): any {
  const url = urls.highResolutionImageryCheckout();
  const result = yield call(callApi, 'post', url, payload);
  yield put(highResolutionImageryCheckout.post.success(result.url));
}

function* saga() {
  yield takeEvery(highResolutionImageryCheckout.post.REQUEST, checkoutHighResolutionImagery);
}

export default saga;
