import React, { useState } from 'react';
import { useMap } from 'react-leaflet';
import { Button, CircularProgress, isWidthDown, useTheme, withWidth } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { domToPng } from 'modern-screenshot';
import { intersection as _intersection } from 'lodash';
import { saveAs } from 'file-saver';
import L from 'leaflet';

const DownloadButton = styled(Button)`
  &.Mui-disabled {
    background-color:${() => useTheme().palette.custom.colorfulText};
};
  }
`;

export const Spinner = styled(CircularProgress).attrs({
  size: 20,
})`
  &.MuiCircularProgress-colorPrimary {
    color: ${() => useTheme().palette.custom.contrastElement};
  }
`;

interface PrintMapButtonProps {
  hideClasses?: Array<string>;
  width: Breakpoint;
}

export const PrintMapButton = (props: PrintMapButtonProps) => {
  const { t } = useTranslation();
  const map = useMap();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [screenshotProgress, setScreenshotProgress] = useState<number>(0);

  const generateScreenshot = () => {
    setIsGenerating(true);
    const imageryLayers: L.TileLayer[] = [];

    map.eachLayer((layer: L.Layer) => {
      if (layer instanceof L.TileLayer && layer.options?.pane === 'tiles') {
        imageryLayers.push(layer);
      }
    });

    if (imageryLayers.length > 0) {
      const maxTopLayerBounds = Array.prototype.slice
        .call(imageryLayers[imageryLayers.length - 1].getContainer()?.getElementsByClassName('leaflet-tile'))
        .map((el) => {
          return el.getBoundingClientRect();
        })
        .reduce((prev, curr) => {
          const { bottom, top, left, right } = curr;
          return {
            bottom: bottom < prev.bottom ? prev.bottom : bottom,
            top: top > prev.top ? prev.top : top,
            right: right < prev.right ? prev.right : right,
            left: left > prev.left ? prev.left : left,
          };
        });

      imageryLayers.slice(0, -1).forEach((layer: L.TileLayer) => {
        Array.prototype.slice
          .call(layer.getContainer()?.getElementsByTagName('img'))
          .forEach((img: HTMLImageElement) => {
            const { top, bottom, left, right } = img.getBoundingClientRect();
            if (
              top > maxTopLayerBounds.top &&
              bottom < maxTopLayerBounds.bottom &&
              left > maxTopLayerBounds.left &&
              right < maxTopLayerBounds.right
            ) {
              img.classList.add('skip-for-screenshot');
            }
          });
      });
    }

    domToPng(map.getContainer() as HTMLElement, {
      filter: (node: any) => {
        return _intersection(node?.classList, [...(props.hideClasses ?? []), 'skip-for-screenshot']).length === 0;
      },
      progress: (current: number, total: number) => {
        setScreenshotProgress(Math.round((current / total) * 100));
      },
      timeout: 500000,
    }).then((dataUrl) => {
      if (dataUrl) {
        saveAs(dataUrl, `spectator_earth_${new Date().toISOString()}.png`);
        setIsGenerating(false);
        setScreenshotProgress(0);
        Array.from(map.getContainer().getElementsByClassName('skip-for-screenshot')).forEach((el: any) =>
          el.classList.remove('skip-for-screenshot'),
        );
      }
    });
  };

  return (
    <DownloadButton
      variant="contained"
      color="primary"
      style={{ width: '100%', marginTop: 5 }}
      startIcon={!isWidthDown('xs', props.width) && (isGenerating ? <Spinner /> : <ImageIcon />)}
      onClick={!isGenerating ? generateScreenshot : void 0}
    >
      {isWidthDown('xs', props.width) ? (
        isGenerating ? (
          `${screenshotProgress}%`
        ) : (
          <ImageIcon />
        )
      ) : isGenerating ? (
        `${screenshotProgress}%`
      ) : (
        t('screenshot')
      )}
    </DownloadButton>
  );
};

export default withWidth()(PrintMapButton);
