import { Reducer } from 'redux';
import { PlanState } from './types';
import { subscriptionCheckout, plans, subscription, subscriptionManagementLink } from './actions';
import { isEqual, pick } from 'lodash';
import { logout } from '../auth/actions';

const initialState: PlanState = {
  changeInProgress: false,
  checkoutUrl: undefined,
  plans: [],
  subscription: undefined,
  errors: undefined,
};

const reducer: Reducer<PlanState> = (state = initialState, action) => {
  switch (action.type) {
    case logout.SUCCESS: {
      return initialState;
    }

    case plans.get.SUCCESS: {
      return { ...state, plans: action.payload };
    }

    case subscriptionCheckout.delete.SUCCESS:
    case subscriptionCheckout.post.SUCCESS: {
      return { ...state, checkoutUrl: action.payload };
    }

    case subscriptionCheckout.delete.REQUEST:
    case subscriptionCheckout.post.REQUEST: {
      return { ...state, changeInProgress: true };
    }

    case subscriptionCheckout.post.FAILURE: {
      return { ...state, changeInProgress: false, errors: action.payload.errors };
    }

    case subscriptionManagementLink.get.SUCCESS: {
      return { ...state, subscription: { ...state.subscription, billingManagementUrl: action.payload.url } };
    }

    case subscription.get.SUCCESS: {
      return {
        ...state,
        subscription: {
          ...action.payload,
          usage: pick(action.payload.usage, ['api_throttle'])
        },
        changeInProgress: state.changeInProgress && isEqual(state.subscription, action.payload),
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as planReducer };
