import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { logout as logoutAction } from '../store/auth/actions';
import { UserProps } from '../store/auth/types';
import { DEFAULT_REDIRECT_URL } from '../constants';
import { withUser } from '../hoc';

interface Props extends RouteComponentProps {
  logout: () => any;
}

const Logout: React.FunctionComponent<Props & UserProps> = (props) => {
  if (props.user) {
    props.logout().then(() => {
      props.history.push(DEFAULT_REDIRECT_URL);
    });
  } else {
    props.history.push(DEFAULT_REDIRECT_URL);
  }

  return null;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logout: () => {
      return dispatch({
        ...logoutAction.request(),
        [WAIT_FOR_ACTION]: logoutAction.SUCCESS,
      });
    },
  };
};

export default withUser(connect(null, mapDispatchToProps)(Logout));
