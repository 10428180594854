import { takeLatest, takeLeading, call, put, select } from 'redux-saga/effects';
import { isObject } from 'lodash';
import * as actions from './actions';
import urls from '../urls';
import { callApi, genericAPIFunction } from '../../utils/api';
import { AUTH_TOKEN_KEY } from '../../constants';
import { userSelector } from './selectors';

function* login({ payload }: any): any {
  const url = urls.userLogin();

  try {
    const result = yield call(callApi, 'post', url, payload);
    localStorage.setItem(AUTH_TOKEN_KEY, result.access);
    yield put(actions.login.success(result.user));
  } catch (err) {
    yield put(actions.login.failure(err));
  }
}

function* loginSocial({ payload }: any): any {
  const { type, email, params } = payload;
  const url = urls[type]();

  try {
    const result = yield call(callApi, 'post', url, { email, ...params });
    localStorage.setItem(AUTH_TOKEN_KEY, result.access);
    yield put(actions.login.success(result.user));
  } catch (err) {
    yield put(actions.login.failure(err));
  }
}

function* verify(): any {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  try {
    if (token) {
      yield call(callApi, 'post', urls.userVerify(), { token });
      const userResponse = yield call(callApi, 'get', urls.user());
      yield put(actions.login.success(userResponse));
    } else {
      throw Error();
    }
  } catch (err) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    yield put(actions.loginVerify.failure());
  }
}

function* logout() {
  const url = urls.userLogout();
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(AUTH_TOKEN_KEY);
  yield call(callApi, 'post', url, { token });
  yield put(actions.logout.success());
}

function* signup({ payload }: any): any {
  const url = urls.userSignup();

  try {
    const result = yield call(callApi, 'post', url, payload);
    localStorage.setItem(AUTH_TOKEN_KEY, result.access);
    yield put(actions.signup.success(result.user));
  } catch (err) {
    yield put(actions.signup.failure(err));
  }
}

function* resetPasswordConfirm({ payload }: any) {
  const url = urls.resetPasswordConfirm();

  try {
    yield call(callApi, 'post', url, {
      new_password1: payload.password1,
      new_password2: payload.password2,
      token: payload.token,
      uid: payload.uid,
    });
    yield put(actions.resetPasswordConfirm.success());
  } catch (err) {
    yield put(actions.resetPasswordConfirm.failure(err));
  }
}

const resetPassword = genericAPIFunction(actions.resetPassword.post, 'post', () => urls.resetPassword());

const fetchUser = genericAPIFunction(actions.user.get, 'get', () => urls.user());

function* patchUser({ payload }: any): any {
  if (payload.hasOwnProperty('settings')) {
    const user = yield select(userSelector);
    payload.settings = { ...user._settings, ...payload.settings };
  }

  const url = urls.user();
  const formData = new FormData();

  for (const [key, value] of Object.entries(payload)) {
    formData.append(key, isObject(value) ? JSON.stringify(value) : (value as any));
  }

  try {
    const result = yield call(callApi, 'patch', url, payload);
    yield put(actions.user.patch.success(result));
  } catch (err) {
    yield put(actions.user.patch.failure(err));
  }
}

const passwordChange = genericAPIFunction(actions.passwordChange.post, 'post', () => urls.passwordChange());

const verifyEmail = genericAPIFunction(actions.emailVerify.post, 'post', () => urls.userVerifyEmail());
const resendVerificationEmail = genericAPIFunction(actions.resendVerificationEmail.post, 'post', () =>
  urls.resendVerificationEmail(),
);

function* saga() {
  yield takeLatest(actions.login.REQUEST, login);
  yield takeLatest(actions.loginSocial.REQUEST, loginSocial);
  yield takeLatest(actions.loginVerify.REQUEST, verify);
  yield takeLatest(actions.logout.REQUEST, logout);
  yield takeLeading(actions.signup.REQUEST, signup);
  yield takeLatest(actions.resetPasswordConfirm.REQUEST, resetPasswordConfirm);
  yield takeLatest(actions.resetPassword.post.REQUEST, resetPassword);
  yield takeLatest(actions.user.get.REQUEST, fetchUser);
  yield takeLatest(actions.user.patch.REQUEST, patchUser);
  yield takeLatest(actions.passwordChange.post.REQUEST, passwordChange);
  yield takeLatest(actions.emailVerify.post.REQUEST, verifyEmail);
  yield takeLatest(actions.resendVerificationEmail.post.REQUEST, resendVerificationEmail);
}

export default saga;
