import { call, put } from 'redux-saga/effects';
import Axios from 'axios';
import i18n from 'i18next';

import { URLFunction } from '../store/urls';
import { AUTH_TOKEN_KEY, NOMINATIM_URL, APP_SESSION } from '../constants';

export const api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  transformRequest: (data, headers) => {
    const transformRequest = Axios.defaults.transformRequest;
    if (transformRequest && Array.isArray(transformRequest)) {
      data = transformRequest[0](data, headers);
    }

    const token = localStorage.getItem(AUTH_TOKEN_KEY);

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const session = localStorage.getItem(APP_SESSION);

    if (session) {
      headers['x-app-session'] = session;
    }

    headers['Accept-Language'] = i18n.language;

    return data;
  },
});

export const nominatimApi = Axios.create({
  baseURL: NOMINATIM_URL,
});

export type HTTPMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

export function callApi(method: HTTPMethod, url: string, data?: any) {
  return api
    .request({
      method,
      url,
      params: (method === 'get' && data) || undefined,
      data: (method !== 'get' && data) || undefined,
    })
    .then((response) => {
      return response.data;
    })
    .catch((errors) => {
      throw errors.response.data;
    });
}

export const genericAPIFunction = (action: any, method: HTTPMethod, urlFunction: URLFunction) => {
  return function* (_action: any): any {
    const payload = { ..._action.payload };
    const url = urlFunction(payload);

    if (payload && typeof payload == 'object') {
      // Remove values used for generating url
      for (const [key, value] of Object.entries(payload)) {
        if (url.includes(`/${value}/`)) {
          delete payload[key];
        }
      }
    }

    try {
      const result = yield call(callApi, method, url, payload);
      const success = action.success(result);
      success.request_action = _action;
      yield put(success);
    } catch (error) {
      const failure = action.failure(error);
      failure.request_action = _action;
      yield put(failure);
    }
  };
};
