import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Button,
  Divider,
  Grid,
  Typography,
  Link,
  FormControlLabel,
  Switch,
  MenuItem,
  FormControl,
  Select,
  Chip,
  Avatar,
  IconButton,
} from '@material-ui/core';
import { withCookies, ReactCookieProps } from 'react-cookie';
import { Theme, withTheme } from '@material-ui/core/styles';

import { THEME_COOKIE_NAME, SIGNIN_URL, COOKIES_EXPIRATION_DATE, ImageriesDisplayOption } from '../constants';
import { Snackbar } from '../components/snackbar';

import { ApplicationState } from '../store';
import { user } from '../store/auth/actions';
import { ThemeMode, User, UserSettings } from '../store/auth/types';
import { userSelector, userSettingsSelector } from '../store/auth/selectors';
import ChangeAvatar from './change-avatar';
import ChangePassword from './change-password';
import ChangeUserName from './change-username';
import { CenteredGrid } from '../components/layout';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LocaleOption } from '../i18n/config';
import { SubscriptionUsage } from '../components/subscription-usage';
import { Alert } from '@material-ui/lab';
import { Plan, Subscription } from '../store/plans/types';
import { activePlanSelector, subscriptionSelector } from '../store/plans/selectors';
import { plans, subscription, subscriptionManagementLink } from '../store/plans/actions';
import TextField from '@material-ui/core/TextField';
import { FileCopyOutlined } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ResendVerificationEmailButton } from '../components/resend-verification-email-button';
import styled from 'styled-components';

const PreferencesControlLabel = styled(FormControlLabel).attrs({
  labelPlacement: 'start',
})`
  justify-content: space-between;

  &.MuiFormControlLabel-labelPlacementStart {
    margin-left: 0;
  }
`;

interface State {
  copied: boolean;
}

interface StoreProps {
  activePlan?: Plan;
  user?: User;
  userSettings: UserSettings;
  subscription?: Subscription;
}

interface ThemeProps {
  theme: Theme;
}

interface DispatchProps {
  updateUserSettings: (settings: Partial<UserSettings>) => void;
  updateUser: (data: Partial<User>) => void;
  getPlansAndSubscription: () => void;
}

type Props = StoreProps &
  RouteComponentProps &
  ReactCookieProps &
  ThemeProps &
  DispatchProps &
  WithTranslation<['common', 'userProfile', 'widgets', 'newsletter']>;

export class UserProfile extends React.Component<Props, State> {
  readonly state: State = {
    copied: false,
  };

  componentDidMount() {
    this.props.getPlansAndSubscription();
  }

  changeCreateOnSearchSelection = () => {
    const createOnSearchSelection = !this.props.userSettings.createOnSearchSelection;
    this.props.updateUserSettings({ createOnSearchSelection });
  };

  changeTheme = (value: ThemeMode) => {
    this.props.cookies?.set(THEME_COOKIE_NAME, value, { expires: COOKIES_EXPIRATION_DATE });
    this.props.updateUserSettings({ theme: value });
  };

  changeDefaultImageriesDisplay = (value: ImageriesDisplayOption) => {
    this.props.updateUserSettings({ defaultImageriesDisplay: value });
  };

  changeLocale = (value: LocaleOption) => {
    this.props.updateUserSettings({ language: value });
    this.props.i18n.changeLanguage(value);
  };

  changeNewsletterSubscription = () => {
    this.props.updateUser({ newsletter_subscribed: !this.props.user?.newsletter_subscribed });
  };

  render() {
    const { activePlan, subscription, user, userSettings, theme, t } = this.props;

    return (
      <Grid id="user-profile" container={true} spacing={2} justify="center" style={{ height: '100%', padding: 16 }}>
        {user && !subscription && (
          <Grid item={true} xs={12} container={true} justify={'center'}>
            <CircularProgress />
          </Grid>
        )}
        {user && subscription && (
          <React.Fragment>
            <Grid
              item={true}
              container={true}
              xs={12}
              justify={'space-evenly'}
              alignContent={'center'}
              alignItems={'center'}
            >
              <Grid item={true}>
                <ChangeAvatar />
              </Grid>
              <Grid item={true} xs={6}>
                <ChangeUserName />
              </Grid>
              <Grid item={true}>
                <Chip
                  color={(activePlan?.default && 'default') || 'secondary'}
                  avatar={<Avatar style={{ textTransform: 'capitalize' }}>{subscription.plan_name[0]}</Avatar>}
                  label={subscription.plan_name}
                  onClick={() => this.props.history.push('/pricing')}
                />
              </Grid>
            </Grid>
            <Grid item={true} xs={12}>
              <Divider variant={'middle'} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t('userProfile:planDetails')}</Typography>
            </Grid>
            <Grid item={true} xs={12} container={true} alignContent={'center'}>
              <Grid item={true} xs={10}>
                <TextField
                  id={'api-key'}
                  style={{ width: '100%' }}
                  variant="outlined"
                  defaultValue={user.api_key}
                  label={t('userProfile:apiKey')}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <CenteredGrid item={true} xs={2}>
                <CopyToClipboard text={user.api_key} onCopy={() => this.setState({ copied: true })}>
                  <IconButton>
                    <FileCopyOutlined />
                  </IconButton>
                </CopyToClipboard>
              </CenteredGrid>
            </Grid>
            <Grid item={true} xs={12} container={true}>
              <SubscriptionUsage subscription={subscription} />
            </Grid>
            <Grid item={true} xs={12}>
              <Link
                align={'center'}
                href={process.env.REACT_APP_API_URL}
                target="_blank"
                style={{ color: theme.palette.custom.colorfulText }}
              >
                <Typography>{t('userProfile:apiDocs')}</Typography>
              </Link>
            </Grid>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={this.state.copied}
              autoHideDuration={3000}
              onClose={() => this.setState({ copied: false })}
            >
              <Alert severity={'success'}>{t('userProfile:copySuccess')}</Alert>
            </Snackbar>
            <Grid item={true} xs={12}>
              <Button
                id={'change-plan-button'}
                disableElevation={true}
                variant={'contained'}
                color={'primary'}
                fullWidth={true}
                onClick={() => this.props.history.push('/pricing')}
              >
                {t('userProfile:changePlan')}
              </Button>
            </Grid>
            <Grid item={true} xs={12} container={true} justify={'center'}>
              {(user.is_verified && (
                <Link
                  id={'billing-management'}
                  href={subscription.billingManagementUrl}
                  target="_blank"
                  style={{ color: theme.palette.custom.colorfulText }}
                >
                  <Typography>{t('userProfile:billingManagement')}</Typography>
                </Link>
              )) || <ResendVerificationEmailButton />}
            </Grid>
            <Grid item={true} xs={12}>
              <Divider variant={'middle'} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t('userProfile:preferences')}</Typography>
            </Grid>
            <CenteredGrid item xs={12}>
              <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
                <PreferencesControlLabel
                  id={'create-on-selection'}
                  control={
                    <Switch
                      color="primary"
                      checked={userSettings.createOnSearchSelection}
                      onChange={this.changeCreateOnSearchSelection}
                    />
                  }
                  label={t('userProfile:createOnSelection')}
                />

                <FormControl
                  style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                  id="theme-switch"
                >
                  <span>{t('userProfile:theme')}</span>
                  <Select
                    value={this.props.userSettings.theme}
                    onChange={(e) => this.changeTheme(e.target.value as ThemeMode)}
                    style={{ width: '40%', textAlign: 'center', height: '38px' }}
                  >
                    {Object.values(ThemeMode).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`userProfile:themeMode.${option}` as const)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  id={'form-imageries-display'}
                  style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <span>{t('userProfile:defaultDisplay')}</span>
                  <Select
                    inputProps={{ id: 'imageries-display' }}
                    value={this.props.userSettings.defaultImageriesDisplay}
                    onChange={(e) => this.changeDefaultImageriesDisplay(e.target.value as ImageriesDisplayOption)}
                    style={{ width: '40%', textAlign: 'center', height: '38px' }}
                  >
                    {Object.values(ImageriesDisplayOption).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`widgets:${option}` as const)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  id={'form-locale'}
                  style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <span style={{ textTransform: 'capitalize' }}>{t('common:language')}</span>
                  <Select
                    inputProps={{ id: 'locale' }}
                    value={this.props.userSettings.language || this.props.i18n.language}
                    onChange={(e) => this.changeLocale(e.target.value as LocaleOption)}
                    style={{ width: '40%', textAlign: 'center', height: '38px' }}
                  >
                    {Object.values(LocaleOption).map((option) => (
                      <MenuItem key={option} value={option}>
                        {this.props.t(`common:${option}` as const)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <PreferencesControlLabel
                  control={
                    <Switch
                      color="primary"
                      onChange={this.changeNewsletterSubscription}
                      checked={user.newsletter_subscribed}
                    />
                  }
                  label={t('newsletter:subscribeLabel')}
                />
              </Grid>
            </CenteredGrid>

            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
            {!user.is_social && (
              <CenteredGrid item={true} xs={6}>
                <ChangePassword />
              </CenteredGrid>
            )}
            <CenteredGrid item={true} xs={user.is_social ? 12 : 6}>
              <Button
                fullWidth={true}
                color={'secondary'}
                id="logout-button"
                disableElevation={true}
                onClick={() => this.props.history.push('/auth/logout')}
                variant="contained"
              >
                {t('common:signOut')}
              </Button>
            </CenteredGrid>
          </React.Fragment>
        )}
        {!user && (
          <CenteredGrid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              onClick={() => this.props.history.push(`${SIGNIN_URL}`)}
              style={{ textTransform: 'capitalize' }}
            >
              {t('common:signIn')}
            </Button>
          </CenteredGrid>
        )}
      </Grid>
    );
  }
}

const mapStoreToProps = (state: ApplicationState): StoreProps => {
  return {
    activePlan: activePlanSelector(state),
    subscription: subscriptionSelector(state),
    user: userSelector(state),
    userSettings: userSettingsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    updateUserSettings: (settings) => dispatch(user.patch.request({ settings })),
    updateUser: (data) => dispatch(user.patch.request(data)),
    getPlansAndSubscription: () => {
      dispatch(subscription.get.request());
      dispatch(subscriptionManagementLink.get.request());
      dispatch(plans.get.request());
    },
  };
};

export default withTranslation(['common', 'userProfile', 'widgets', 'newsletter'])(
  withRouter(connect(mapStoreToProps, mapDispatchToProps)(withTheme(withCookies(UserProfile)))),
);
