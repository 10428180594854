import React, { useState } from 'react';
import { reverseBounds } from '../utils/geo';
import { Satellite } from '../store/satellites/types';
import { Typography, Divider, Chip, Snackbar, Grid, DialogTitle, DialogContent, Box } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { TileLayer } from '../store/app/types';
import { DEFAULT_SATELLITE_BANDS } from '../constants';

export const LinkText = styled(Typography).attrs((props) => ({
  variant: 'body2',
  color: 'textSecondary',
  ...props,
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 6px !important;
  cursor: pointer;
`;

interface Props {
  satellites: Satellite[];
  mapBounds: number[][];
  apiKey: string;
  mapTiles: TileLayer[];
}

export const ApiLinksList: React.FC<Props> = (props: Props) => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const satellitesNames = props.satellites.map((s) => s.properties.name);
  const reversedBounds = reverseBounds(props.mapBounds).flat();
  const imageriesLink = `${process.env.REACT_APP_API_URL}/imagery/?bbox=${reversedBounds}&satellites=${satellitesNames}&api_key=${props.apiKey}`; // eslint-disable-line max-len
  const overpassesLink = `${process.env.REACT_APP_API_URL}/overpass/?bbox=${reversedBounds}&satellites=${satellitesNames}&api_key=${props.apiKey}`; // eslint-disable-line max-len

  const { t } = useTranslation(['apiLinks', 'common']);
  const getXYZMapTilesLink = (mapTiles: TileLayer) => {
    const imageryID = mapTiles.imagery.id;
    const bandsFormulaSrc =
      mapTiles.imageryState?.tilerState || DEFAULT_SATELLITE_BANDS[mapTiles.imagery.satelliteName];
    const bandsFormulaQueryString =
      bandsFormulaSrc.bands?.map((b) => b.toLowerCase()).join(',') ||
      bandsFormulaSrc.expression + '&rescale=' + bandsFormulaSrc.rescale;

    return `${process.env.REACT_APP_API_URL}/imagery/${imageryID}/tiles/{z}/{x}/{y}/?bands_formula=${bandsFormulaQueryString}&api_key=${props.apiKey}`; // eslint-disable-line max-len
  };

  return (
    <React.Fragment>
      <DialogTitle disableTypography={true}>
        <Typography variant="h5">{t('apiLinks:title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={3} style={{ marginBottom: '12px' }}>
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="body1">
              <Trans
                t={t}
                i18nKey="apiLinks:description"
                components={[
                  <a key="a" href={process.env.REACT_APP_API_URL} target="_blank" rel="noreferrer">
                    API docs
                  </a>,
                ]}
              />
            </Typography>
          </Grid>
          <Divider />
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="body1">
                {t('apiLinks:howToCopy')}
              </Typography>
              <Chip size="small" color="primary" label={t('common:apiKey')} />
              <CopyToClipboard text={props.apiKey} onCopy={() => setSnackbarOpen(true)}>
                <LinkText>{props.apiKey}</LinkText>
              </CopyToClipboard>
              <Chip size="small" color="primary" label={t('common:bbox')} />
              <CopyToClipboard text={JSON.stringify(reversedBounds)} onCopy={() => setSnackbarOpen(true)}>
                <LinkText>{JSON.stringify(reversedBounds)}</LinkText>
              </CopyToClipboard>
              <Chip size="small" color="primary" label={t('common:imageries')} />
              <CopyToClipboard text={imageriesLink} onCopy={() => setSnackbarOpen(true)}>
                <LinkText>{imageriesLink}</LinkText>
              </CopyToClipboard>
              <Chip size="small" color="primary" label={t('common:overpass', { count: 0 })} />
              <CopyToClipboard text={overpassesLink} onCopy={() => setSnackbarOpen(true)}>
                <LinkText>{overpassesLink}</LinkText>
              </CopyToClipboard>
            </Grid>
            {props.mapTiles.length > 0 && (
              <Grid item xs={12} className="xyz-map-tiles">
                <Typography variant="h6">{t('apiLinks:xyzMapTiles')}</Typography>
                {props.mapTiles.map((mt) => {
                  const link = getXYZMapTilesLink(mt);
                  return (
                    <Box key={mt.imagery.id}>
                      <Typography variant="subtitle2">{mt.name}</Typography>
                      <CopyToClipboard text={link} onCopy={() => setSnackbarOpen(true)}>
                        <LinkText>{link}</LinkText>
                      </CopyToClipboard>
                    </Box>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <Snackbar
        autoHideDuration={1000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t('apiLinks:linkCopied') + '!'}
      />
    </React.Fragment>
  );
};
