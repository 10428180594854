import React from 'react';
import { MdEdit, MdClose, MdCheck } from 'react-icons/md';
import { IconButton, TextField, TypographyProps, Typography, Grid, RootRef, Tooltip } from '@material-ui/core';
import { withTheme, Theme } from '@material-ui/core/styles';

interface State {
  editModeOn: boolean;
  text: string;
  errors: string;
  tooLongText: boolean;
}

interface Props {
  canEdit?: boolean;
  editModeOn?: boolean;
  disableEditIcon?: boolean;
  onEdit: (text: string) => Promise<any>;
  onClose?: () => void;
  text: string;
  typographyProps?: TypographyProps;
  theme: Theme;
}

export class EditableText extends React.Component<Props, State> {
  private textRef = React.createRef<HTMLDivElement>();

  readonly state: State = {
    editModeOn: false,
    text: this.props.text,
    errors: '',
    tooLongText: false,
  };

  componentDidMount() {
    this.updateTooLongText();

    if (this.props.editModeOn) {
      this.setState({ editModeOn: this.props.editModeOn });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.editModeOn && this.props.editModeOn !== prevProps.editModeOn) {
      this.setState({ editModeOn: this.props.editModeOn });
    }
  }

  updateTooLongText() {
    const textRef = this.textRef;

    if (textRef.current) {
      this.setState({ tooLongText: textRef.current.offsetWidth < textRef.current.scrollWidth });
    }
  }

  save = () => {
    this.props
      .onEdit(this.state.text)
      .then(() => {
        this.setState({ editModeOn: false, errors: '' }, this.updateTooLongText);
        this.props.onClose?.();
      })
      .catch((errors: Record<string, any>) => this.setState({ errors: Object.values(errors)[0][0] as string }));
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      this.save();
      e.preventDefault();
    }
  };

  renderEditMode = (props: Props) => (
    <React.Fragment>
      <TextField
        defaultValue={props.text}
        onChange={(e) => this.setState({ text: e.target.value })}
        error={Boolean(this.state.errors)}
        helperText={this.state.errors}
        onKeyDown={this.onKeyDown}
      />
      <IconButton onClick={this.save} disabled={this.state.text === props.text}>
        <MdCheck color={this.state.text !== props.text ? 'green' : 'lightgrey'} />
      </IconButton>
      <IconButton onClick={() => this.setState({ editModeOn: false, errors: '' }, this.props.onClose)}>
        <MdClose color="red" />
      </IconButton>
    </React.Fragment>
  );

  renderText = (props: Props) => (
    <Typography {...props.typographyProps} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} noWrap>
      {props.text}
    </Typography>
  );

  renderNonEditMode = (props: Props) => (
    <Grid container justify="center" alignItems="center" style={{ position: 'relative', padding: 12 }}>
      <Grid item xs={2} />
      <Grid item xs={8}>
        <RootRef rootRef={this.textRef}>
          <React.Fragment>
            {this.state.tooLongText && <Tooltip title={this.state.text}>{this.renderText(props)}</Tooltip>}
            {!this.state.tooLongText && this.renderText(props)}
          </React.Fragment>
        </RootRef>
      </Grid>
      <Grid item xs={2}>
        {props.canEdit && !props.disableEditIcon && (
          <IconButton style={{ paddingLeft: '10px' }} onClick={() => this.setState({ editModeOn: true })}>
            <MdEdit color={this.props.theme.palette.text.secondary} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );

  render() {
    return (
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {(this.state.editModeOn && this.renderEditMode(this.props)) || this.renderNonEditMode(this.props)}
      </span>
    );
  }
}

export default withTheme(EditableText);
