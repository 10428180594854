import { Point, LineString, Feature } from 'geojson';

interface SatelliteProperties {
  name: string;
  norad_id: number;
  modes: any[];
  open: boolean;
  platform: string;
}

interface TrajectoryProperties {
  update_interval: number;
}

export interface Satellite extends Feature<Point, SatelliteProperties> {
  id: number;
  selected?: boolean;
  color?: string;
  trajectory: Feature<LineString, TrajectoryProperties>;
}

export interface SatellitesState {
  readonly data: Satellite[];
  readonly errors?: string;
  readonly fetchedAll: boolean;
  readonly selected?: number[];
}

export type SatellitesWithData = 'Sentinel-2A' | 'Sentinel-2B' | 'Landsat-8';

export enum CommercialConstellation {
  PHR = 'PHR',
  SPOT = 'SPOT',
  PNEO = 'PNEO',
}
