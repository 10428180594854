import React from 'react';
import { LoginErrors, SignupErrors, UserProps } from '../store/auth/types';
import { ApplicationState } from '../store';
import { loginErrorsSelector, signupErrorsSelector } from '../store/auth/selectors';
import { Dispatch } from 'redux';
import { login, loginSocial, logout as logoutAction, signup } from '../store/auth/actions';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { IconButtonProps } from '@material-ui/core/IconButton';
import LoginForm from '../components/login-form';
// @ts-ignore
import { GoogleOAuthProvider, useGoogleLogin, CodeResponse } from '@react-oauth/google';
import { FaGoogle } from 'react-icons/fa';
import SignupForm from '../components/signup-form';
import specEarthLogoBlack from '../static/images/spec_earth_logo_black.png';
import { AUTH_LOGIN_TYPE_GOOGLE, DEFAULT_REDIRECT_URL, THEME_DARK } from '../constants';
import { RouteComponentProps } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import ResetPassword from '../components/reset-password';
import { configSelector } from '../store/config/selectors';
import { ConfigState } from '../store/config/types';
import { useTheme, withTheme } from '@material-ui/core/styles';
import { ThemeProps } from '../theme';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

const SocialLoginButton = styled(IconButton as React.FunctionComponent<IconButtonProps>)`
  margin-right: 2px;
  margin-bottom: 10px;

  * {
    text-transform: none !important;
  }
`;

const Logo = styled.img`
  height: auto;
  max-width: 380px;
  width: 100%;
  filter: invert(${() => (useTheme().palette.type === THEME_DARK ? 1 : 0)});
`;

const FormBox = styled(Grid)`
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 10px;
  border-color: lightgrey;
`;

interface State {
  emailNotMatching: boolean;
}

interface DispatchProps {
  login: (email: string, password: string) => any;
  logout: () => any;
  push: (url: string) => void;
  signup: (email: string, password1: string, password2: string) => any;
  socialLogin: (
    type: string,
    email: string | undefined,
    params: Partial<Record<'code' | 'access_token', string>>
  ) => any;
}

interface StoreProps {
  config: ConfigState;
  loginErrors: LoginErrors;
  signupErrors: SignupErrors;
}

type Props = DispatchProps &
  StoreProps &
  RouteComponentProps &
  ThemeProps &
  UserProps &
  WithTranslation<['common', 'signIn']>;

const INVITE_CONFIRMATION_URL = '/auth/invite';

const GoogleLoginButton = ({ onSuccess, onFailure }: any) => {
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: onSuccess,
    onError: onFailure,
  });

  return (
    <SocialLoginButton id="google-login-button" onClick={() => login()}>
      <FaGoogle style={{ color: useTheme().palette.text.secondary }} />
    </SocialLoginButton>
  );
};

export class SignIn extends React.Component<Props, State> {
  parsedQueryString: ParsedQuery;

  readonly state: State = {
    emailNotMatching: false,
  };

  constructor(props: Props) {
    super(props);
    this.parsedQueryString = queryString.parse(props.location.search);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.user && !prevProps.user) {
      if (this.parsedQueryString.mail && this.parsedQueryString.mail !== this.props.user.email) {
        this.setState({ emailNotMatching: true });
      } else {
        this.redirectAfterVerified();
      }
    }
  }

  redirectAfterVerified = () => {
    if (this.parsedQueryString.token) {
      this.props.history.push(`${INVITE_CONFIRMATION_URL}?${queryString.stringify(this.parsedQueryString)}`);
    } else if (this.parsedQueryString.redirect) {
      this.props.history.push(`${this.parsedQueryString.redirect}`);
    } else {
      this.props.history.push(DEFAULT_REDIRECT_URL);
    }
  };

  onLoginFormSubmit = (email: string, password: string) => {
    this.props.login(email, password).then(() => {
      this.redirectAfterVerified();
    });
  };

  socialLoginFailure = () => {
    this.setState((prevState) => ({
      ...prevState,
      signupErrors: {
        non_field_errors: [this.props.t('signIn:unableToLogIn')],
      },
    }));
  };

  googleLoginSuccess = (response: CodeResponse) => {
    this.props
      .socialLogin(AUTH_LOGIN_TYPE_GOOGLE, undefined, { code: response['code'] })
      .then(this.redirectAfterVerified);
  };

  onSignupFormSubmit = (email: string, password1: string, password2: string) => {
    this.props.signup(email, password1, password2).then(() => {
      this.redirectAfterVerified();
    });
  };

  renderEmailNotMatching = () => (
    <Grid container={true} justify="center">
      <Grid item={true} xs={12}>
        <Typography variant="h4" align="center" color="error">
          {this.props.t('signIn:loggedInWith', { email: this.props.user?.email })}
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <Typography variant="h5" align="center">
          {this.props.t('signIn:logInWith', { email: this.parsedQueryString.mail })}
        </Typography>
      </Grid>
      <Grid item={true} xs={1}>
        <Button onClick={() => this.props.logout()} color="primary" variant="outlined">
          {this.props.t('common:logout')}
        </Button>
      </Grid>
      <Grid item={true} xs={1}>
        <Button onClick={() => this.props.push(DEFAULT_REDIRECT_URL)} color="primary" variant="outlined">
          {this.props.t('common:cancel')}
        </Button>
      </Grid>
    </Grid>
  );

  renderSignInOptions = () => {
    const socialAccounts = this.props.config.AUTH.SOCIAL_ACCOUNTS;
    const googleClientID = socialAccounts?.google;

    return (
      <React.Fragment>
        <Grid item={true} xs={12} sm={6} style={{ maxWidth: 600 }}>
          <FormBox container={true} justify="center" alignItems="center">
            <Grid item={true}>
              <Typography style={{ color: this.props.theme.palette.custom.contrastText, textTransform: 'capitalize' }}>
                {this.props.t('signIn:signIn')}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <LoginForm
                errors={this.props.loginErrors}
                onSubmit={this.onLoginFormSubmit}
                email={this.parsedQueryString.mail ? String(this.parsedQueryString.mail) : null}
              />
            </Grid>
            {!this.parsedQueryString.mail && (
              <Grid
                container={true}
                alignItems="center"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {googleClientID && (
                  <GoogleOAuthProvider clientId={googleClientID}>
                    <GoogleLoginButton
                      onSuccess={(response: CodeResponse) => {
                        this.googleLoginSuccess(response);
                      }}
                      onFailure={this.socialLoginFailure}
                    />
                  </GoogleOAuthProvider>
                )}
                <br />
                <ResetPassword />
              </Grid>
            )}
          </FormBox>
        </Grid>
        <Grid item={true} xs={12} sm={6} style={{ maxWidth: 600 }}>
          <FormBox container={true} justify="center" alignItems="center">
            <Grid item={true}>
              <Typography style={{ color: this.props.theme.palette.custom.contrastText }}>
                {this.props.t('signIn:create')}
              </Typography>
            </Grid>
            <Grid item={true}>
              <SignupForm
                errors={this.props.signupErrors}
                onSubmit={this.onSignupFormSubmit}
                email={this.parsedQueryString.mail ? String(this.parsedQueryString.mail) : null}
              />
            </Grid>
          </FormBox>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Grid
        style={{ backgroundColor: this.props.theme.palette.background.paper, padding: '8px' }}
        container={true}
        justify="center"
      >
        <Grid item style={{ alignSelf: 'center' }}>
          <Link to={'/'}>
            <Logo src={specEarthLogoBlack} />
          </Link>
        </Grid>
        <Grid item container={true} xs={12} justify="center" spacing={2}>
          {this.state.emailNotMatching ? this.renderEmailNotMatching() : this.renderSignInOptions()}
        </Grid>
      </Grid>
    );
  }
}

const mapStoreToProps = (state: ApplicationState): StoreProps => {
  return {
    config: configSelector(state),
    loginErrors: loginErrorsSelector(state),
    signupErrors: signupErrorsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    login: (email, password) =>
      dispatch({
        ...login.request({ email, password }),
        [WAIT_FOR_ACTION]: login.SUCCESS,
      }),
    socialLogin: (type, email, params) =>
      dispatch({
        ...loginSocial.request({ type, email, params }),
        [WAIT_FOR_ACTION]: login.SUCCESS,
      }),
    signup: (email, password1, password2) =>
      dispatch({
        ...signup.request({ email, password1, password2 }),
        [WAIT_FOR_ACTION]: signup.SUCCESS,
      }),
    push: (url: string) => dispatch(push(url)),
    logout: () => {
      return dispatch({
        ...logoutAction.request(),
        [WAIT_FOR_ACTION]: logoutAction.SUCCESS,
      });
    },
  };
};

export default withTranslation(['common', 'signIn'])(connect(mapStoreToProps, mapDispatchToProps)(withTheme(SignIn)));
