import React, { memo } from 'react';
import { Typography, Button, Grid, Fade } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const UpgradeTeaser = () => {
  const { t } = useTranslation('plans');

  return (
    <Fade in={true}>
      <Grid container direction="column" spacing={1} alignItems="center" style={{ padding: 30 }}>
        <Grid item>
          <Typography variant="h5" component="p" align="center">
            {t('upgradeTeaserLine1')}
          </Typography>
          <Typography variant="h6" component="p" align="center">
            {t('upgradeTeaserLine2')}
          </Typography>
        </Grid>
        <Grid item>
          <Link to="/pricing">
            <Button disableElevation={true} fullWidth={true} variant={'contained'} color={'primary'}>
              {t('upgrade')}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default memo(UpgradeTeaser);
