import { takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import urls from '../urls';
import { genericAPIFunction } from '../../utils/api';

const fetchAcquisitionPlan = genericAPIFunction(actions.acquisitionPlan.get, 'get', () => urls.acquisitionPlan());

function* saga() {
  yield takeEvery(actions.acquisitionPlan.get.REQUEST, fetchAcquisitionPlan);
}

export default saga;
