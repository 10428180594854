import { AUTH_LOGIN_TYPE_GOOGLE } from '../constants';

export type URLFunction = (parameters: any) => string;

const url = (literals: TemplateStringsArray, ...keys: string[]): URLFunction => {
  return (parameters: any = {}) => {
    const result = [literals[0]];

    if (Object.keys(parameters).length !== keys.length) {
      throw Error('Invalid parameters');
    }

    keys.forEach((key, i) => {
      result.push(parameters[key], literals[i + 1]);
    });

    return result.join('');
  };
};

const urls: Record<string, any> = {
  channelList: url`/channel/`,
  channelDetails: url`/channel/${'channelId'}/`,
  channelAois: url`/channel/trending-aois/`,
  satelliteList: url`/satellite/`,
  satelliteDetails: url`/satellite/${'satelliteId'}/`,
  trajectoryDetails: url`/satellite/${'satelliteId'}/trajectory/`,
  observationList: url`/observation/`,
  observationDetails: url`/observation/${'observationUUID'}/`,
  observationPublish: url`/observation/${'observationUUID'}/publish/`,
  observationFollow: url`/observation/${'observationUUID'}/${'dataType'}/${'action'}/`,
  overpassList: url`/observation/${'observationUUID'}/overpass/`,
  highResolutionImageries: url`/imagery-high-resolution/`,
  highResolutionImageriesSearch: url`/imagery-high-resolution/search/`,
  highResolutionImageryCheckout: url`/imagery-high-resolution/checkout/`,
  highResolutionImageryWMS: url`/imagery-high-resolution/${'imageryId'}/wms`,
  imageryList: url`/observation/${'observationUUID'}/imagery/`,
  imageriesRecommended: url`/observation/${'observationUUID'}/imagery/recommendation/`,
  imageriesPending: url`/observation/${'observationUUID'}/imagery/pending/`,
  imageryDetails: url`/observation/${'observationUUID'}/imagery/${'imageryId'}/`,
  imageryFilesList: url`/observation/${'observationUUID'}/imagery/${'imageryId'}/files/`,
  contactForm: url`/messages/contact_form/`,
  userLogin: url`/auth/login/`,
  [AUTH_LOGIN_TYPE_GOOGLE]: url`/auth/social/google/`,
  userLogout: url`/auth/logout/`,
  userSignup: url`/auth/registration/`,
  userVerifyEmail: url`/auth/registration/verify-email/`,
  userVerify: url`/auth/verify/`,
  userChangePassword: url`/auth/password/change/`,
  resendVerificationEmail: url`/auth/registration/resend-email/`,
  resetPasswordConfirm: url`/auth/password/reset/confirm/`,
  resetPassword: url`/auth/password/reset/`,
  planList: url`/plan/`,
  subscription: url`/subscription/`,
  subscriptionManagementLink: url`/subscription/management_link/`,
  singlePaymentCheckout: url`/single-payment-checkout`,
  config: url`/config/`,
  acquisitionPlan: url`/acquisition-plan/`,
  feedbackForm: url`/feedback/`,
  user: url`/auth/user/`,
  passwordChange: url`/auth/password/change/`,
  notificationsList: url`/notification/`,
  notificationsUnreadCount: url`/notification/unread_count/`,
  alertsList: url`/notification/alerts`,
  newsletterUnsubscribe: url`/mail/unsubscribe/`,
};

export default urls;
