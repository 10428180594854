import React, { Suspense, useEffect, useState } from 'react';
import {
  AppBar,
  Avatar,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  Link,
  Popover,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import styled from 'styled-components';
import { default as UserAvatar } from 'react-avatar';
import { MdNotifications, MdNotificationsNone, MdSatellite, MdSearch } from 'react-icons/md';
import Badge from '@material-ui/core/Badge';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation, WithTranslation } from 'react-i18next';
import { ExitToApp } from '@material-ui/icons';

import { ApplicationState } from '../store';
import { UserProps } from '../store/auth/types';
import { Channel } from '../store/channels/types';
import { Observation } from '../store/observations/types';
import { activeChannelSelector } from '../store/channels/selectors';
import { unreadNotificationSelector } from '../store/notifications/selectors';
import { useTheme } from '@material-ui/core/styles';
import logoWhite from '../static/images/logo_white.svg';
import Notifications from './notifications';
import { withUser } from '../hoc';
import Search from './search';
import { plansSelector } from '../store/plans/selectors';
import { plans } from '../store/plans/actions';
import { fadeInAnimation } from '../theme';

export const NAVIGATION_HEIGHT = '66px';

const UserProfile = React.lazy(() => import('./user-profile'));

export const Logo = styled.div<{ text: string }>`
  width: 46px;
  height: 46px;

  &::after {
    content: ${(props) => `"${props.text}"`};
    display: ${(props) => (props.text ? 'flex' : 'none')};
    background: ${() => useTheme().palette.custom.contrastElement};
    color: ${() => useTheme().palette.custom.navigationColor};
    font-family: 'industry-bold';
    border-radius: 2px;
    padding: 1px 2px 0 2px;
    height: 10px;
    line-height: 10px;
    font-size: 10px;
    position: absolute;
    left: 70%;
    bottom: 15%;
    text-transform: uppercase;
    animation: ${fadeInAnimation} 0.2s ease-out;
    align-items: center;

    @supports (-moz-appearance: none) {
      padding: 1px 2px;
    }
  }
`;

const MobileSearchContainer = styled(Grid).attrs({
  container: true,
  justify: 'center',
  alignItems: 'center',
})`
  position: absolute;
  top: ${NAVIGATION_HEIGHT};
  z-index: 1001;
  height: 50px;
  background: ${({ theme }) => theme.palette.custom.navigationColor};
  transition: all 0.3s ease-in-out;
`;

const NavIconButton = styled(IconButton)`
  &.MuiIconButton-root:hover {
    background-color: unset;
    filter: brightness(1.1);
    transition: filter 0.2s ease-in-out;
  }
`;

interface MatchParams {
  channelId?: string;
}

interface StoreProps {
  unreadNotificationCount: number;
  channel?: Channel;
  observation?: Observation;
}

type Props = StoreProps & RouteComponentProps<MatchParams> & UserProps & WithTranslation<['common', 'navigation']>;

export const Navigation = ({ unreadNotificationCount, user }: Props) => {
  const { t } = useTranslation(['common', 'navigation']);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [showSearch, setShowSearch] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const plansList = useSelector(plansSelector);

  const NotificationIcon = unreadNotificationCount === 0 ? MdNotificationsNone : MdNotifications;

  useEffect(() => {
    dispatch(plans.get.request());
  }, [dispatch]);

  const currentPlan = plansList?.find((plan) => plan.id === user?.plan_id);

  return (
    <>
      <AppBar
        position="relative"
        style={{
          backgroundColor: theme.palette.custom.navigationColor,
          height: NAVIGATION_HEIGHT,
          zIndex: theme.zIndex.drawer + 2,
          boxShadow: 'none',
          justifyContent: 'center',
        }}
      >
        <Toolbar>
          <Grid container direction="row" alignItems="center">
            <Grid item container direction="row" xs sm={3} alignItems="center" justify="space-between" wrap="nowrap">
              <Grid item>
                <Tooltip title={t('navigation:startObservation') as string} placement="bottom">
                  <Link id="go-to-create-observation" onClick={() => history.push('/')} component="button">
                    <Logo text={(!currentPlan?.default ? currentPlan?.name : '') as string}>
                      <img src={logoWhite} alt="Spectator" style={{ width: 46, height: 46 }} />
                    </Logo>
                  </Link>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item style={{ flex: 1 }} xs>
              {!isSmallerScreen ? (
                <Search mapSearch={true} />
              ) : (
                <NavIconButton style={{ float: 'right' }} onClick={() => setShowSearch(true)} disableRipple>
                  {!showSearch && <MdSearch style={{ color: theme.palette.custom.contrastElement }} size={34} />}
                </NavIconButton>
              )}
            </Grid>
            <Grid
              item
              container
              xs
              sm={3}
              justify="flex-end"
              style={{ textAlign: 'right' }}
              spacing={0}
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item>
                <Tooltip title={t('navigation:acquisitionPlanViewer')} placement="bottom">
                  <NavIconButton
                    size="small"
                    id="acquisition-plan-button"
                    onClick={() => history.push('/acquisition-plan')}
                  >
                    <MdSatellite size="40" color={theme.palette.custom.contrastElement} />
                  </NavIconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                {user && (
                  <Tooltip title={t('navigation:notifications')} placement="bottom">
                    <NavIconButton
                      id="notifications-button"
                      style={{ justifyContent: 'center', margin: '4px' }}
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      size="small"
                    >
                      <Badge
                        color="primary"
                        badgeContent={unreadNotificationCount}
                        style={{ zIndex: 0 }}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <NotificationIcon size="30" color={theme.palette.custom.contrastElement} />
                      </Badge>
                    </NavIconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid item>
                <Tooltip title={user ? t('navigation:yourProfile') : t('common:signIn')} placement="bottom">
                  <NavIconButton
                    id="profile-button"
                    style={{ justifyContent: 'center' }}
                    size="small"
                    onClick={(event) => {
                      if (user) {
                        setAnchorEl(event.currentTarget);
                      } else {
                        history.push('/auth/signin');
                      }
                    }}
                  >
                    {(user && (
                      <UserAvatar
                        name={user?.username}
                        size="38"
                        color={theme.palette.primary.main}
                        src={user?.avatar}
                        round
                      />
                    )) || (
                      <Avatar
                        id={'signin-button'}
                        variant={'rounded'}
                        style={{
                          backgroundColor: theme.palette.secondary.main,
                          color: theme.palette.primary.contrastText,
                        }}
                      >
                        <ExitToApp />
                      </Avatar>
                    )}
                  </NavIconButton>
                </Tooltip>
              </Grid>
              <Popover
                open={Boolean(anchorEl && anchorEl.id === 'notifications-button')}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                style={{ maxHeight: '50%' }}
                PaperProps={{ style: { width: '30%' } }}
              >
                <Notifications />
              </Popover>
              <Popover
                open={Boolean(anchorEl && anchorEl.id === 'profile-button')}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: {
                    width: isSmallerScreen ? '90%' : '30%',
                    height: 'calc(100vh - 70px)',
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
              >
                <Suspense fallback={<CircularProgress />}>
                  <UserProfile />
                </Suspense>
              </Popover>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {isSmallerScreen && showSearch && (
        <ClickAwayListener onClickAway={() => setShowSearch(false)}>
          <MobileSearchContainer theme={theme}>
            <Grid item style={{ padding: '0 5%' }} xs={12}>
              <Search mapSearch={true} />
            </Grid>
          </MobileSearchContainer>
        </ClickAwayListener>
      )}
    </>
  );
};

const mapStoreToProps = (state: ApplicationState): StoreProps => {
  return {
    channel: activeChannelSelector(state),
    unreadNotificationCount: unreadNotificationSelector(state),
  };
};

export default withUser(connect(mapStoreToProps, null)(Navigation));
