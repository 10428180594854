import React from 'react';
import InputBase, { InputBaseComponentProps } from '@material-ui/core/InputBase';
import _ from 'lodash';

interface Props extends Omit<InputBaseComponentProps, 'onChange'> {
  value?: string;
  onChange: (value: string) => void;
  delay: number;
}

interface State {
  value: string;
}

class DebouncedTextField extends React.Component<Props, State> {
  readonly state: State;
  readonly debouncedOnChange: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.value || '',
    };
    this.debouncedOnChange = _.debounce(this.change, this.props.delay);
  }

  change = () => {
    this.props.onChange(this.state.value);
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onChange, delay, value, ...rest } = this.props;
    return (
      <InputBase
        value={this.state.value}
        onChange={(e) => {
          this.setState({ value: e.target.value });
        }}
        onKeyUp={this.debouncedOnChange}
        {...rest}
      />
    );
  }
}

export default DebouncedTextField;
