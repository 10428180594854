import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CALLBACK_ERROR_ARGUMENT, ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { UserProps } from '../store/auth/types';
import { user } from '../store/auth/actions';
import EditableText from '../components/editable-text';
import { withTheme } from '@material-ui/core/styles';
import { ThemeProps } from '../theme';
import { withUser } from '../hoc';

interface DispatchProps {
  editUserName: (username: string) => any;
}

interface State {
  edit: boolean;
}

type Props = DispatchProps & ThemeProps & UserProps;

export class ChangeUserName extends React.Component<Props, State> {
  readonly state: State = {
    edit: false,
  };

  render() {
    const user = this.props.user;

    return (
      user && (
        <div onClick={() => this.setState({ edit: true })} style={{ cursor: 'pointer' }}>
          <EditableText
            editModeOn={this.state.edit}
            disableEditIcon={true}
            canEdit={Boolean(user)}
            onEdit={(username) => this.props.editUserName(username)}
            text={user?.username}
            typographyProps={{ variant: 'h6', align: 'center' }}
            onClose={() => this.setState({ edit: false })}
          />
        </div>
      )
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    editUserName: (username) => {
      return dispatch({
        ...user.patch.request({ username }),
        [WAIT_FOR_ACTION]: user.patch.SUCCESS,
        [ERROR_ACTION]: user.patch.FAILURE,
        [CALLBACK_ERROR_ARGUMENT]: (action: any) => action.payload.errors,
      });
    },
  };
};

export default withUser(connect(null, mapDispatchToProps)(withTheme(ChangeUserName)));
