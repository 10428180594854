import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import {
  Grid,
  IconButton,
  TextField,
  isWidthDown,
  isWidthUp,
  withWidth,
  WithWidth,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import Map from '../containers/map';
import { Channel } from '../store/channels/types';
import ChannelView from '../containers/channel-view';
import { ApplicationState } from '../store';
import { channelsErrorsSelector, channelsSelector } from '../store/channels/selectors';
import { Main, SidebarDrawer } from '../components/layout';
import { NAVIGATION_HEIGHT } from '../containers/navigation';
import { channel, channels } from '../store/channels/actions';
import { ThemeProps } from '../theme';
import { userSettingsSelector } from '../store/auth/selectors';
import { UserProps, UserSettings } from '../store/auth/types';
import { user } from '../store/auth/actions';
import { withTranslation, WithTranslation } from 'react-i18next';
import SidebarDrawerToggle from '../components/sidebar-drawer-toggle';
import { recentlyUsedSelector } from '../store/app/selectors';
import { RecentlyUsed } from '../store/app/types';
import { RecentlyUsedCard } from '../components/recently-used-card';

interface DispatchProps {
  createChannel: (name: string) => any;
  deleteChannel: (channelId: number) => any;
  fetchChannels: () => any;
  setActiveChannel: (activeChannelId: number) => void;
}

interface StoreProps {
  channels: Channel[];
  channelsError: string;
  userSettings: UserSettings;
  recentlyUsed: RecentlyUsed[];
}

type Props = DispatchProps &
  StoreProps &
  RouteComponentProps &
  ThemeProps &
  UserProps &
  WithWidth &
  WithTranslation<['channels', 'common', 'observation']>;

interface State {
  addChannelFormError?: string;
  expandedChannelId?: number;
  newChannelName: string;
  channelsSidebarOpen: boolean;
}

export class Channels extends React.Component<Props> {
  readonly state: State = {
    newChannelName: '',
    channelsSidebarOpen: true,
  };

  componentDidMount() {
    this.props.fetchChannels();

    if (this.props.user) {
      this.setState({ expandedChannelId: this.props.userSettings.activeChannelId });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.userSettings.activeChannelId !== this.props.userSettings.activeChannelId &&
      this.props.userSettings.activeChannelId !== this.state.expandedChannelId &&
      this.props.user
    ) {
      this.setState({ expandedChannelId: this.props.userSettings.activeChannelId });
    }
  }

  onChannelDelete = (channelId: number) => {
    this.props.deleteChannel(channelId).then(() => this.props.fetchChannels());
  };

  createNewChannel = (name: string) => {
    this.props
      .createChannel(name)
      .then(() =>
        this.setState({
          addChannelFormError: null,
          newChannelName: '',
        }),
      )
      .catch(() => this.setState({ addChannelFormError: this.props.channelsError, message: this.props.channelsError }));
  };

  onAddChannelKeyUp = (event: any) => {
    if (event.key === 'Enter') {
      this.createNewChannel(event.target.value);
    }
  };

  onAddChannelChange = (event: any) => {
    this.setState({ newChannelName: event.target.value });
  };

  onChannelClick = (channelId: number) => {
    if (this.state.expandedChannelId === channelId) {
      this.setState({ expandedChannelId: undefined });
    } else {
      this.setState({ expandedChannelId: channelId }, () => this.props.setActiveChannel(channelId));
    }
  };

  renderRecentlyUsed = () => {
    return (
      <Grid className="recently-used-section" container justify="center" style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center" style={{ color: this.props.theme.palette.custom.colorfulText }}>
            {this.props.t('observation:recentlyUsed')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems="stretch"
          spacing={1}
          style={{ width: '100%', height: 130, overflow: 'hidden', margin: '0 10px 0 10px' }}
          justify="space-evenly"
        >
          {this.props.recentlyUsed.map((recentlyUsedObservation, index) => (
            <RecentlyUsedCard key={index} index={index} recentlyUsedObservation={recentlyUsedObservation} />
          ))}
        </Grid>
      </Grid>
    );
  };

  render() {
    return (
      <React.Fragment>
        <SidebarDrawer
          variant={isWidthDown('sm', this.props.width) ? 'temporary' : 'permanent'}
          open={this.state.channelsSidebarOpen}
          style={{ marginTop: isWidthUp('sm', this.props.width) ? NAVIGATION_HEIGHT : '' }}
        >
          {isWidthUp('md', this.props.width) && <Toolbar />}
          {this.props.recentlyUsed.length > 0 && (
            <>
              {this.renderRecentlyUsed()}
              <Typography variant="h6" align="center" style={{ color: this.props.theme.palette.custom.colorfulText }}>
                {this.props.t('channels:channels')}
              </Typography>
            </>
          )}
          <div id={'channels-list'} style={{ height: '85%', overflowY: 'auto', overflowX: 'hidden' }}>
            {this.props.channels.map((channel) => (
              <ChannelView
                key={channel.id}
                active={this.props.userSettings.activeChannelId === channel.id}
                channel={channel}
                onDelete={this.onChannelDelete}
                onClick={this.onChannelClick}
                expanded={channel.id === this.state.expandedChannelId}
              />
            ))}
          </div>
          {this.props.user && (
            <Grid
              container={true}
              justify="center"
              style={{
                position: 'absolute',
                bottom: '0',
                backgroundColor: this.props.theme.palette.background.paper,
                borderTop: `1px solid ${this.props.theme.palette.divider}`,
              }}
            >
              <Grid item={true} xs={10}>
                <Grid container={true} justify="center" alignItems="center">
                  <Grid item={true} xs={10}>
                    <TextField
                      id="add-channel-input"
                      error={Boolean(this.state.addChannelFormError)}
                      autoFocus={false}
                      placeholder={this.props.t('channels:add')}
                      onKeyUp={this.onAddChannelKeyUp}
                      onChange={this.onAddChannelChange}
                      variant="outlined"
                      style={{ width: '100%' }}
                      margin="dense"
                      color="primary"
                      value={this.state.newChannelName}
                    />
                  </Grid>
                  <Grid item={true} xs={2} style={{ paddingTop: 5, paddingLeft: 5 }}>
                    <IconButton
                      id="add-channel-button"
                      disabled={!this.state.newChannelName}
                      onClick={() => this.createNewChannel(this.state.newChannelName)}
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </SidebarDrawer>
        {isWidthDown('sm', this.props.width) && (
          <SidebarDrawerToggle
            drawerOpen={this.state.channelsSidebarOpen}
            handleClick={() => this.setState({ channelsSidebarOpen: !this.state.channelsSidebarOpen })}
          />
        )}

        <Main>
          <Map />
        </Main>
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (state: ApplicationState): StoreProps => ({
  channels: channelsSelector(state),
  channelsError: channelsErrorsSelector(state),
  userSettings: userSettingsSelector(state),
  recentlyUsed: recentlyUsedSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createChannel: (name) => {
    return dispatch({
      ...channels.post.request({ name }),
      [WAIT_FOR_ACTION]: channels.post.SUCCESS,
      [ERROR_ACTION]: channels.post.FAILURE,
    });
  },
  deleteChannel: (channelId) =>
    dispatch({
      ...channel.delete.request({ channelId }),
      [WAIT_FOR_ACTION]: channel.delete.SUCCESS,
    }),
  fetchChannels: () => dispatch(channels.get.request()),
  setActiveChannel: (activeChannelId: number) => dispatch(user.patch.request({ settings: { activeChannelId } })),
});

export default withTranslation('channels')(
  connect(mapStoreToProps, mapDispatchToProps)(withTheme(withWidth()(Channels))),
);
