import { createActions, createCustomActions } from '../../utils/redux-actions';

export const locations = createActions('LOCATIONS');
export const setMapParameters = createActions('SET_MAP_PARAMETERS');
export const setMapBounds = createActions('SET_MAP_BOUNDS');
export const mapPolygons = createCustomActions('MAP_POLYGONS', ['ADD', 'UPDATE', 'DELETE']);
export const mapTiles = createCustomActions('MAP_TILES', ['ADD', 'UPDATE', 'DELETE']);
export const commercialMapTiles = createCustomActions('COMMERCIAL_MAP_TILES', ['ADD', 'UPDATE', 'DELETE']);
export const recentlyUsed = createActions('RECENTLY_USED');
export const mapLabelsDisabled = createActions('MAP_LABELS_DISABLED');
export const showTrending = createCustomActions('SHOW_TRENDING', ['SET']);
