import React from 'react';
import { IconButton } from '@material-ui/core';

import { isUndefined } from 'lodash';

type ConditionalRangeIconButtonProps = {
  value: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  onClick: (idx: number) => void;
  increase: boolean;
  icon: any;
};

const ConditionalRangeIconButton = (props: ConditionalRangeIconButtonProps) => {
  const min = isUndefined(props.min) ? -Infinity : props.min;
  const max = isUndefined(props.max) ? +Infinity : props.max;
  const disabled = props.disabled === true || props.value >= max || props.value <= min;
  const delta = props.increase ? 1 : -1;

  return (
    <IconButton
      disableFocusRipple={true}
      disabled={disabled}
      onClick={(e) => {
        props.onClick(props.value + delta);
      }}
      style={{ visibility: disabled ? 'hidden' : 'inherit' }}
    >
      {props.icon}
    </IconButton>
  );
};

export default ConditionalRangeIconButton;
