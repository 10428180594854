import { createPathComponent, LeafletContextInterface } from '@react-leaflet/core';
import { PolygonProps as ReactLeafletPolygonProps } from 'react-leaflet';
import { Polygon } from 'leaflet.antimeridian/src/vector/Wrapped.Polygon.js';

function createPolygon(props: ReactLeafletPolygonProps, context: LeafletContextInterface) {
  const instance = new Polygon(props.positions, props);
  return { instance, context: { ...context, overlayContainer: instance } };
}

export const WrappedPolygon = createPathComponent(createPolygon);
