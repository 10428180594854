import styled from 'styled-components';
import { Skeleton as Loader } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { THEME_DARK } from '../constants';

const Skeleton = styled(Loader)`
  &.MuiSkeleton-wave::after {
    background: ${() =>
      useTheme().palette.type === THEME_DARK
        ? 'linear-gradient(90deg, rgba(80, 80, 80, 0), rgba(75, 75, 75, .7), rgba(80, 80, 80, 0))'
        : 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(250, 250, 250, .7), rgba(255, 255, 255, 0))'};
  }
  &.MuiSkeleton-root {
    background-color: rgba(0, 0, 0, 0.09);
  }
`;

export default Skeleton;
