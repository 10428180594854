import { Middleware } from 'redux';
import { get, set, isEqual } from 'lodash';

import { ApplicationState } from '../store';
import history from '../history';

const LON_LAT_ZOOM_REGEX = /&@-?\d*\.?\d*,-?\d*\.?\d*,?\d*\.?\d+z/g;

const reduxMapUrlStateMiddleware = (pathToState: string) => {
  const _middleware: Middleware<null, ApplicationState> = (store) => (next) => (action) => {
    const prevState = store.getState();
    const prevValue = get(prevState, pathToState);

    const result = next(action);

    const nextState = store.getState();
    const nextValue = get(nextState, pathToState);

    if (!isEqual(prevValue, nextValue) || !history.location.search.includes('@')) {
      const location = history.location.pathname;
      let search = history.location.search || '?';

      if (search.includes('@')) {
        search = search.replace(LON_LAT_ZOOM_REGEX, '');
      }

      search += `&@${nextValue.center.lat},${nextValue.center.lng},${nextValue.zoom}z`;
      history.replace(`${location}${search}`);
    }

    return result;
  };
  return _middleware;
};

export const getMapStateFromUrl = (state: ApplicationState, pathToState: string) => {
  const search = history.location.search;
  const match = search.match(LON_LAT_ZOOM_REGEX);

  if (match) {
    const params_str = match[0].substring(match[0].indexOf('@') + 1, match[0].lastIndexOf('z'));
    const [lat, lon, zoom] = params_str.split(',').map((o) => parseFloat(o));

    if (lon && lat && zoom) {
      set(state, pathToState, {
        ...get(state, pathToState),
        center: {
          lat: lat,
          lng: lon,
        },
        zoom,
        fromUrl: true,
      });
    }
  }

  return state;
};

export default reduxMapUrlStateMiddleware;
