import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

const pulse = keyframes`
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
`;

const Container = styled.div`
  z-index: 2;
  height: 80px;
  width: 80px;
`;

const Element = styled.div<{ disabled: boolean }>`
  border-radius: 100%;
  background-color: ${(props) =>
    props.disabled ? useTheme().palette.custom.createObservationDisabled : useTheme().palette.primary.main};
  height: inherit;
  width: inherit;
  position: absolute;
`;

const Button = styled(Element)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  border: none;
  background-size: 18px;
  cursor: pointer;
  outline: none;
`;

const Background = styled(Element)`
  animation: ${pulse} 1.2s ease infinite;
  visibility: ${(props) => (props.disabled ? 'hidden' : 'visible')};
  position: fixed;
`;

const Icon = styled.div`
  position: relative;
  color: #fff;
  font-size: 10px;
  padding-top: 3px;
`;

interface Props {
  onClick: () => void;
  disabled: boolean;
  children: React.ReactChild;
}

const PulseIcon = React.forwardRef((props: Props, ref: any) => (
  <Container ref={ref}>
    <Background disabled={props.disabled} />
    <Button onClick={() => !props.disabled && props.onClick()} disabled={props.disabled}>
      <Icon>{props.children}</Icon>
    </Button>
  </Container>
));
PulseIcon.displayName = 'PulseIcon';

export default PulseIcon;
