import React from 'react';
import { FollowAction, FollowType, Notification, NotificationQueryParameters } from '../store/notifications/types';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText as MuiListItemText,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { GiOrbit } from 'react-icons/gi';
import { MdImage, MdNotificationsOff } from 'react-icons/md';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { OptionsMenu } from '../components/options-menu';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { notifications } from '../store/notifications/actions';
import { observationFollow } from '../store/observations/actions';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { notificationsSelector, unreadNotificationSelector } from '../store/notifications/selectors';
import { Skeleton } from '@material-ui/lab';
import { WithTranslation, withTranslation } from 'react-i18next';

const ListItemText = styled(MuiListItemText)`
  b {
    color: ${() => useTheme().palette.custom.colorfulText};
  }
`;

interface DispatchProps {
  fetchNotifications: (parameters?: NotificationQueryParameters) => any;
  followObservation: (observationUUID: string, dateType: FollowType, action: FollowAction) => any;
  markNotificationsAsRead: () => any;
}

interface StoreProps {
  notifications: Notification[];
  unreadNotificationCount: number;
}

interface State {
  loading: boolean;
  snackbarOpen: boolean;
}

type Props = DispatchProps & StoreProps & RouteComponentProps & WithTranslation<'notifications'>;

const uuidFromNotificationUrl = (url: string) => url.split('/')[2];

const notificationsSkeleton = (n: number) =>
  Array.from(Array(n).keys()).map((idx) => (
    <ListItem key={`skeleton-${idx}`}>
      <ListItemAvatar>
        <Skeleton variant="circle" width={40} height={40} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography>
            <Skeleton variant="text" />
          </Typography>
        }
        secondary={
          <Typography>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Typography>
        }
      />
    </ListItem>
  ));

const READ_NOTIFICATIONS_LIMIT = 20;

export class Notifications extends React.Component<Props, State> {
  readonly state: State = {
    loading: false,
    snackbarOpen: false,
  };

  componentDidMount() {
    this.setState({ loading: true });

    if (_.isEmpty(this.props.notifications)) {
      this.props
        .fetchNotifications({
          limit:
            this.props.unreadNotificationCount > READ_NOTIFICATIONS_LIMIT
              ? this.props.unreadNotificationCount
              : READ_NOTIFICATIONS_LIMIT,
        })
        .finally(() => this.setState({ loading: false }));
    } else {
      this.props
        .fetchNotifications({ timestamp_from: this.props.notifications[0].timestamp })
        .finally(() => this.setState({ loading: false }));
    }
  }

  componentWillUnmount() {
    this.props.markNotificationsAsRead();
  }

  handleLoadMoreClick = () => {
    this.setState({ loading: true });
    this.props
      .fetchNotifications({
        timestamp_to: this.props.notifications.slice(-1)[0].timestamp,
        limit: READ_NOTIFICATIONS_LIMIT,
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <List id="notifications-list" style={{ padding: 0 }}>
          {_.isEmpty(this.props.notifications) && !this.state.loading && (
            <ListItem>{t('noNotificationsInfo')}</ListItem>
          )}
          {this.props.notifications.map((notification: Notification) => (
            <React.Fragment key={`notification-${notification.id}`}>
              {(notification.url && (
                <ListItem
                  button={true}
                  selected={!notification.read}
                  onClick={() => this.props.history.push(notification.url as string)}
                >
                  <ListItemAvatar>
                    {(notification.codename === FollowType.Overpass && <GiOrbit size="32" />) || <MdImage size="32" />}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography dangerouslySetInnerHTML={{ __html: notification.title }} />}
                    secondary={<Typography dangerouslySetInnerHTML={{ __html: notification.body }} />}
                    style={{ paddingRight: 30 }}
                  />
                  <ListItemSecondaryAction>
                    <OptionsMenu>
                      <Button
                        id="unfollow-notifications"
                        color="secondary"
                        onClick={() => {
                          this.props
                            .followObservation(
                              uuidFromNotificationUrl(notification.url as string),
                              notification.codename,
                              FollowAction.Unfollow,
                            )
                            .then(() => {
                              this.setState({ loading: true });
                              this.props.fetchNotifications().finally(() => this.setState({ loading: false }));
                            });
                        }}
                        startIcon={<MdNotificationsOff />}
                        variant="contained"
                      >
                        {t('unfollow', { codename: notification.codename })}
                      </Button>
                    </OptionsMenu>
                  </ListItemSecondaryAction>
                </ListItem>
              )) || (
                <ListItem selected={!notification.read}>
                  <ListItemText
                    primary={<Typography dangerouslySetInnerHTML={{ __html: notification.title }} />}
                    secondary={<Typography dangerouslySetInnerHTML={{ __html: notification.body }} />}
                  />
                </ListItem>
              )}
              <Divider component="li" />
            </React.Fragment>
          ))}
          {this.state.loading && notificationsSkeleton(4)}
        </List>
        {!_.isEmpty(this.props.notifications) && (
          <Button id="loadmore" style={{ width: '100%' }} onClick={this.handleLoadMoreClick}>
            {t('loadMore')}
          </Button>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchNotifications: (parameters?) =>
      dispatch({
        ...notifications.get.request(parameters),
        [WAIT_FOR_ACTION]: notifications.get.SUCCESS,
      }),
    followObservation: (observationUUID: string, dataType: FollowType, action: FollowAction) =>
      dispatch({
        ...observationFollow.request({ observationUUID, dataType, action }),
        [WAIT_FOR_ACTION]: observationFollow.SUCCESS,
      }),
    markNotificationsAsRead: () => dispatch(notifications.post.request()),
  };
};

const mapStoreToProps = (state: ApplicationState): StoreProps => {
  return {
    notifications: notificationsSelector(state),
    unreadNotificationCount: unreadNotificationSelector(state),
  };
};

export default withTranslation('notifications')(
  withRouter(connect(mapStoreToProps, mapDispatchToProps)(Notifications)),
);
