import { createMuiTheme, darken, lighten, Theme } from '@material-ui/core/styles';
import { merge } from 'lodash';
import { keyframes } from 'styled-components';

const TONAL_OFFSET = 0.3;

export interface ThemeProps {
  theme: Theme;
}

interface CustomPalette {
  navigationColor: string;
  contrastElement: string;
  createObservationDisabled: string;
  backdropColor: string;
  searchBackgroundColor: string;
  colorfulText: string;
  contrastText: string;
  mapBackground: string;
  calendarTodayText: string;
  successMessageText: string;
  notFoundElement: string;
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    custom: CustomPalette;
  }

  interface PaletteOptions {
    custom: CustomPalette;
  }
}

const commonThemeSettings = {
  typography: {
    fontFamily: '"Didact Gothic", sans-serif',
    fontWeightBold: 900,
    h1: {
      fontFamily: '"industry-bold", sans-serif',
    },
    h2: {
      fontFamily: '"industry-bold", sans-serif',
    },
    h3: {
      fontFamily: '"industry-bold", sans-serif',
    },
    h4: {
      fontFamily: '"industry-bold", sans-serif',
    },
    h5: {
      fontFamily: '"industry-bold", sans-serif',
    },
    h6: {
      fontFamily: '"industry-bold", sans-serif',
    },
  },
};

export const _lightTheme = createMuiTheme(
  merge({}, commonThemeSettings, {
    palette: {
      type: 'light' as const,
      primary: {
        main: '#1f6c77',
      },
      secondary: {
        main: '#c62828',
      },
      custom: {
        navigationColor: `${darken('#1f6c77', TONAL_OFFSET)}`,
        contrastElement: '#ffffff',
        createObservationDisabled: '#9eb4bb',
        backdropColor: 'rgba(128, 128, 128, 0.26)',
        searchBackgroundColor: '#ffffff',
        colorfulText: '#1f6c77',
        contrastText: '#000000',
        mapBackground: '#d4dadc',
        calendarTodayText: '#b5a448',
        successMessageText: '#585858',
        notFoundElement: '#818198',
      },
    },
  }),
);

export const _darkTheme = createMuiTheme(
  merge({}, commonThemeSettings, {
    palette: {
      type: 'dark' as const,
      primary: {
        main: '#1f6c77',
      },
      secondary: {
        main: '#c62828',
      },
      custom: {
        navigationColor: '#242426',
        contrastElement: '#d1d2d3',
        createObservationDisabled: '#434d52',
        backdropColor: '#242426',
        searchBackgroundColor: '#3a3b3d',
        colorfulText: `${lighten('#1f6c77', TONAL_OFFSET)}`,
        contrastText: '#ffffff',
        mapBackground: '#262626',
        calendarTodayText: '#EEEBAB',
        successMessageText: '#d7d7d7',
        notFoundElement: '#787778',
      },
    },
  }),
);

export const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
