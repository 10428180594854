import { Reducer } from 'redux';
import { Observation, ObservationsState } from './types';
import { observation, activeObservation } from './actions';
import _ from 'lodash';
import { logout } from '../auth/actions';
import { parseISO } from 'date-fns';

const initialState: ObservationsState = {
  data: [],
  activeObservationUUID: undefined,
  errors: undefined,
};

const parseDates = (input: Observation) => {
  const observation = input;

  if (observation.settings.filterParameters) {
    const filterParameters = observation.settings.filterParameters;

    if (filterParameters.date_from) {
      filterParameters.date_from = parseISO(String(filterParameters.date_from));
    }

    if (filterParameters.date_to) {
      filterParameters.date_to = parseISO(String(filterParameters.date_to));
    }

    observation.settings.filterParameters = filterParameters;
    return observation;
  }

  return observation;
};

const reducer: Reducer<ObservationsState> = (state = initialState, action) => {
  switch (action.type) {
    case observation.get.SUCCESS:
    case observation.post.SUCCESS:
    case observation.patch.SUCCESS: {
      const observations = _.orderBy(
        [..._.filter(state.data, (o) => o.id !== action.payload.id), parseDates(action.payload)],
        'modified',
        'desc',
      );
      return { ...state, data: observations };
    }
    case observation.get.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case observation.delete.SUCCESS: {
      return { ...state, data: state.data.filter((o) => o.uuid !== action.request_action.payload.observationUUID) };
    }

    case activeObservation.SET: {
      return { ...state, activeObservationUUID: action.payload };
    }

    case activeObservation.CLEAR: {
      return { ...state, activeObservationUUID: undefined };
    }

    case logout.SUCCESS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { reducer as observationsReducer };
