import React from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { resendVerificationEmail } from '../store/auth/actions';
import { actionInProgressSelector, userSelector, verificationEmailResentSelector } from '../store/auth/selectors';
import { useTranslation } from 'react-i18next';

export const ResendVerificationEmailButton: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('resendVerificationEmailButton');

  const actionInProgress = useSelector(actionInProgressSelector);
  const user = useSelector(userSelector);
  const verificationEmailSent = useSelector(verificationEmailResentSelector);

  const handleClick = () => {
    dispatch(resendVerificationEmail.post.request({ email: user?.email }));
  };

  return (
    <Button
      color="primary"
      disabled={actionInProgress || verificationEmailSent}
      disableElevation={true}
      onClick={handleClick}
    >
      {!actionInProgress && (verificationEmailSent ? t('successMessage') : t('buttonTitle'))}
      {actionInProgress && t('sending')}
    </Button>
  );
};
