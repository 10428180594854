import { Grid, Card, CardActionArea, Box, Typography, useTheme } from '@material-ui/core';
import { formatDistanceStrict } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RecentlyUsed } from '../store/app/types';
import { enUS as en, pl } from 'date-fns/locale';

interface Props {
  recentlyUsedObservation: RecentlyUsed;
  index: number;
}

export const RecentlyUsedCard = ({ recentlyUsedObservation, index }: Props) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation(['channels', 'common', 'observation']);
  const history = useHistory();

  return (
    <Grid item xs style={{ height: 120, marginBottom: 10 }}>
      <Card
        className="recently-used-card"
        style={{
          boxShadow: 'none',
          maxWidth: 280,
          minWidth: 140,
          height: 100,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 5,
          margin: '0 auto',
        }}
      >
        <CardActionArea
          style={{
            padding: '5px 10px',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          onClick={() => history.push(`/observation/${recentlyUsedObservation.uuid}`)}
        >
          <Grid container alignItems="center">
            <Grid xs={1} container item direction="column">
              {[...Array(8)].map((_el, dotIndex) => (
                <Grid key={dotIndex} item>
                  <Box
                    style={{
                      width: 5,
                      height: 5,
                      backgroundColor: theme.palette.primary.main,
                      opacity: 0.1 + index / 10 + dotIndex / 10,
                    }}
                  ></Box>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={11}>
              <Typography
                variant="h6"
                style={{
                  color: theme.palette.text.primary,
                  fontSize: '1.2em',
                  lineHeight: 1.2,
                }}
              >
                {recentlyUsedObservation.name.substring(0, 32) +
                  (recentlyUsedObservation.name.length > 32 ? '...' : '')}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant="caption"
            style={{
              color: theme.palette.text.secondary,
              lineHeight: 1,
              marginTop: 10,
            }}
          >
            {recentlyUsedObservation.created && (
              <>
                {t('observation:created')}{' '}
                {formatDistanceStrict(new Date(recentlyUsedObservation.created), new Date(), {
                  locale: { en, pl }[i18n.language],
                })}{' '}
                {t('common:ago')}
              </>
            )}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
