import { Reducer } from 'redux';
import { AuthState } from './types';
import { login, loginVerify, logout, resendVerificationEmail, signup, user } from './actions';

const initialState: AuthState = {
  actionInProgress: false,
  user: undefined,
  loginErrors: {},
  signupErrors: {},
  verificationEmailResent: false,
};

const reducer: Reducer<AuthState> = (state = initialState, action) => {
  switch (action.type) {
    case login.SUCCESS:
    case signup.SUCCESS:
    case user.get.SUCCESS: {
      const user = { ...action.payload };
      user.id = user.pk;
      user._settings = user.settings;

      delete user.pk;
      delete user.settings;

      return { ...initialState, user };
    }

    case login.FAILURE: {
      return {
        ...state,
        loginErrors: action.payload.errors,
      };
    }

    case signup.FAILURE: {
      return {
        ...state,
        signupErrors: action.payload.errors,
      };
    }

    case loginVerify.FAILURE:
    case logout.SUCCESS: {
      return initialState;
    }

    case resendVerificationEmail.post.REQUEST: {
      return {
        ...state,
        actionInProgress: true,
      };
    }

    case resendVerificationEmail.post.SUCCESS: {
      return {
        ...state,
        actionInProgress: false,
        verificationEmailResent: true,
      };
    }

    case resendVerificationEmail.post.FAILURE: {
      return {
        ...state,
        actionInProgress: false,
        verificationEmailResent: false,
      };
    }

    case user.patch.SUCCESS: {
      const user = { ...action.payload };
      user.id = user.pk;
      user._settings = user.settings;

      delete user.pk;
      delete user.settings;

      return {
        ...state,
        user: user,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as authReducer };
