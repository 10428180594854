import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControlLabel,
  Grid,
  Link,
  List,
  ListItem,
  Switch,
  Tooltip,
  Typography,
  Toolbar,
  isWidthUp,
  withWidth,
  WithWidth,
} from '@material-ui/core';
import { addDays, differenceInHours, eachDayOfInterval, endOfWeek, formatDistanceStrict, startOfWeek } from 'date-fns';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MaterialTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { darken, fade, lighten, useTheme, withTheme } from '@material-ui/core/styles';
import Countdown from 'react-countdown';

import { WallContainer, WallElement, WallItem as DefaultWallItem } from '../../components/wall';
import { DEFAULT_DATETIME_FORMAT, THEME_DARK } from '../../constants';
import { CenteredGrid, WidgetActions, WidgetContainer, WidgetContent } from '../../components/layout';
import { overpassesTotalPagesSelector, upcomingOverpassesSelector } from '../../store/overpasses/selectors';
import { Overpass } from '../../store/overpasses/types';
import { ApplicationState } from '../../store';
import { overpass } from '../../store/overpasses/actions';
import { UserProps } from '../../store/auth/types';
import { formatUTC } from '../../utils/date';
import Skeleton from '../../components/skeleton';
import { Observation } from '../../store/observations/types';
import { satelliteColorSelector } from '../../store/config/selectors';
import { ThemeProps } from '../../theme';
import { withUser } from '../../hoc';
import { WithTranslation, withTranslation } from 'react-i18next';
import { format as formatDate, setDay } from 'date-fns';
import { enUS as en, pl } from 'date-fns/locale';
import { debounce, groupBy, isEqual, upperFirst } from 'lodash';
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { PolygonLayer } from '../../store/app/types';
import { flip } from '@turf/turf';
import { LatLngExpression } from 'leaflet';
import { mapPolygons } from '../../store/app/actions';
import UpgradeTeaser from '../../components/upgrade-teaser';
import { plans } from '../../store/plans/actions';
import { plansSelector } from '../../store/plans/selectors';
import { Plan } from '../../store/plans/types';
import { isTouchDevice } from '../../utils/helpers';

const WallItem = styled(DefaultWallItem)`
  cursor: default;
`;

const Table = styled(MaterialTable)`
  text-alight: center;
`;

const TableCell = styled(({ highlight, ...rest }) => <MaterialTableCell {...rest} />).attrs({
  size: 'small',
  align: 'center',
  padding: 'none',
})`
  z-index: 1 !important;
  padding: 5px !important;
  vertical-align: top !important;

  background-color: ${(props) =>
    props.highlight === 'true' ? darken(useTheme().palette.background.paper, 0.1) : 'initial'};

  border-right: solid 1px
    ${() => {
      const palette = useTheme().palette;
      if (palette.type === THEME_DARK) {
        return darken(fade(palette.divider, 1), 0.68);
      } else {
        return lighten(fade(palette.divider, 1), 0.88);
      }
    }};

  &:nth-child(7) {
    border-right: none;
  }

  > p {
    margin: 0;
  }
`;

const Dot = styled.span<{ backgroundColor: string }>`
  height: 10px;
  width: 10px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px 0 0;
`;

Dot.displayName = 'Dot';

interface ComponentProps {
  observation: Observation;
  calendarView?: boolean;
  onChangeCalendarView?: (value: boolean) => void;
  imagingOnly?: boolean;
  onChangeImagingOnly?: (value: boolean) => void;
}

interface DispatchProps {
  addPolygonToMap: (polygon: PolygonLayer[]) => any;
  clearMapPolygon: (id?: string) => any;
  clearOverpasses: () => void;
  fetchOverpasses: (observationUUID: string, page?: number) => void;
  fetchPlans: () => void;
}

interface StoreProps {
  getSatelliteColor: (satelliteName: string) => string;
  overpasses: Overpass[];
  overpassesTotalPages: number;
  plans?: Plan[];
}

type Props = ComponentProps &
  DispatchProps &
  StoreProps &
  ThemeProps &
  UserProps &
  WithWidth &
  WithTranslation<['common', 'widgets']>;

interface State {
  allOverpassesFetched: boolean;
  calendarView: boolean;
  details: boolean;
  imagingOnly: boolean;
  loading: boolean;
}

export class Overpasses extends React.Component<Props, State> {
  readonly state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      allOverpassesFetched: false,
      calendarView: props.calendarView || false,
      details: false,
      imagingOnly: props.imagingOnly || false,
      loading: true,
    };
  }

  componentDidMount() {
    this.getOverpasses([1]).then();
  }

  componentWillUnmount() {
    this.props.clearOverpasses();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!isEqual(prevProps.observation.satellites, this.props.observation.satellites)) {
      this.getOverpasses([1]).then();
    }

    if ((this.state.details || this.state.calendarView) && !this.state.allOverpassesFetched && !this.state.loading) {
      this.getOverpasses().then();
    }

    if (this.state.details && this.state.details !== prevState.details) {
      this.props.fetchPlans();
    }
  }

  getOverpasses = async (pages?: Array<number>) => {
    if (!pages) {
      pages = Array.from(Array(this.props.overpassesTotalPages + 1).keys()).slice(2);
    }

    this.setState({ loading: true });

    await Promise.all(pages.map((page) => this.props.fetchOverpasses(this.props.observation.uuid, page))).then(() =>
      this.setState({
        allOverpassesFetched: pages !== undefined && pages.slice(-1)[0] === this.props.overpassesTotalPages,
        loading: false,
      }),
    );
  };
  getDateOptions = (options = {}) => {
    return {
      locale: { en, pl }[this.props.i18n.language],
      ...options,
    };
  };

  formatImagingInfo = (overpass: Overpass) => {
    if (overpass.acquisition === true) {
      return this.props.t('common:yes');
    } else if (overpass.acquisition === false) {
      return this.props.t('common:no');
    } else if (overpass.acquisition === null) {
      return (
        <Tooltip title={<h2>{this.props.t('widgets:overpasses.noAcquisitionInfo')}</h2>}>
          <span style={{ textTransform: 'capitalize' }}>{this.props.t('common:maybe')}</span>
        </Tooltip>
      );
    }
  };

  renderTimeRemaining = (date: Date) => {
    const difference = differenceInHours(date, new Date());

    const formattedDate = formatDistanceStrict(date, new Date(), this.getDateOptions());

    return difference >= 1 ? (
      formattedDate
    ) : (
      <Countdown
        date={date}
        renderer={({ completed }) => (completed ? this.props.t('widgets:overpasses.completed') : formattedDate)}
      />
    );
  };

  hasAddedMapPolygons = false;

  onOverpassMouseEnter = debounce((overpass: Overpass) => {
    this.hasAddedMapPolygons = true;

    const polygons = overpass.footprints.features.map((footprint: PolygonLayer, i: number) => {
      return {
        id: `${overpass.satellite}-${overpass.id}-${i}`,
        positions: flip(footprint.geometry).coordinates as LatLngExpression[][],
        type: 'overpass',
        name: `${overpass.satellite}--${footprint.properties.mode.name}-${footprint.properties.mode.sensor_type}`,
      };
    });

    this.props.addPolygonToMap(polygons);
  }, 500);

  onOverpassMouseLeave = (overpass: Overpass) => {
    this.onOverpassMouseEnter.cancel();
    this.hasAddedMapPolygons && this.props.clearMapPolygon(`${overpass.satellite}-${overpass.id}`);
    this.hasAddedMapPolygons = false;
  };

  renderList = (overpasses: Overpass[]) => {
    return overpasses.map((overpass: Overpass) => (
      <WallElement
        grow={true}
        key={`overpass_${overpass.satellite}_${overpass.date.toISOString()}`}
        hovercolor={this.props.theme.palette.secondary.main}
        style={{ cursor: 'default' }}
        onMouseEnter={() => !isTouchDevice() && this.onOverpassMouseEnter(overpass)}
        onMouseLeave={() => !isTouchDevice() && this.onOverpassMouseLeave(overpass)}
      >
        <WallContainer>
          <WallItem xs={4}>{this.props.t('common:satellite', { count: 1 })}</WallItem>
          <WallItem xs={4}>{this.props.t('widgets:overpasses.in')}</WallItem>
          <WallItem xs={4} style={{ textTransform: 'lowercase' }}>
            {this.props.t('widgets:overpasses.imaging')}
          </WallItem>
        </WallContainer>
        <WallContainer>
          <WallItem xs={4} highlight={true}>
            {overpass.satellite}
          </WallItem>
          <WallItem xs={4} highlight={true}>
            <Tooltip title={<h2>{formatUTC(overpass.date, DEFAULT_DATETIME_FORMAT)} UTC</h2>}>
              <span>{this.renderTimeRemaining(overpass.date)}</span>
            </Tooltip>
          </WallItem>
          <WallItem xs={4} highlight={true} style={{ textTransform: 'capitalize' }}>
            {this.formatImagingInfo(overpass)}
          </WallItem>
        </WallContainer>
      </WallElement>
    ));
  };

  renderCalendar = (overpasses: Overpass[]) => {
    if (!overpasses.length) {
      return null;
    }

    const overpassesGroupedByDate = groupBy(overpasses, (o) => {
      const date = new Date(o.date.getTime());
      return date.setHours(0, 0, 0, 0);
    });

    const firstDate = startOfWeek(overpasses[0].date, { weekStartsOn: 1 });
    const lastDate = this.state.details
      ? endOfWeek(overpasses[overpasses.length - 1].date, { weekStartsOn: 1 })
      : addDays(firstDate, 13);
    const dates: Date[] = eachDayOfInterval({ start: firstDate, end: lastDate });

    let prevMonth: number;
    const rows: any = [];
    let cells: any = [];

    dates.forEach((date, idx) => {
      const dayOfMonth = date.getDate();
      const month = date.getMonth();

      const isNewMonth = prevMonth !== month;
      const currentOverpasses = overpassesGroupedByDate[date.getTime()] || [];
      const cellContent = (
        <div>
          {isNewMonth && upperFirst(formatDate(date, 'MMM', this.getDateOptions())) + '.'} {dayOfMonth}
          <br />
          {currentOverpasses.map((o: Overpass, key: number) => (
            <Dot key={`cal_dot_${o.id}_${key}`} backgroundColor={this.props.getSatelliteColor(o.satellite)} />
          ))}
        </div>
      );

      const cellContentWithTooltip = (
        <Tooltip
          arrow={true}
          title={
            <List>
              {currentOverpasses.map((o: Overpass) => (
                <ListItem key={`cal_tooltip_${o.id}_${o.acquisition}`}>
                  <Typography variant="body2">
                    <Dot backgroundColor={this.props.getSatelliteColor(o.satellite)} style={{ marginRight: '4px' }} />
                    {o.satellite}, {formatUTC(o.date, 'HH:mm')} UTC ({this.props.t('widgets:overpasses.imaging')}:{' '}
                    {this.formatImagingInfo(o)})
                  </Typography>
                </ListItem>
              ))}
            </List>
          }
        >
          {cellContent}
        </Tooltip>
      );

      cells.push(
        <TableCell key={`cal_cell_${dayOfMonth}_${month}`} highlight={isNewMonth.toString()}>
          {currentOverpasses.length ? cellContentWithTooltip : cellContent}
        </TableCell>,
      );

      if (cells.length === 7 || idx === dates.length - 1) {
        rows.push(<TableRow key={`cal_row_${Math.floor(dayOfMonth / 7)}_${month}_${idx}`}>{cells}</TableRow>);
        cells = [];
      }

      prevMonth = month;
    });

    return (
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            {Array(7)
              .fill(null)
              .map((_value, index) => {
                const date = setDay(new Date(), index + 1);
                const day = upperFirst(formatDate(date, 'E', this.getDateOptions()));
                return <TableCell key={`cal_day_of_week_${day}`}>{day}</TableCell>;
              })}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  };

  renderNoDataAvailable = () => {
    return (
      <React.Fragment>
        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography>{this.props.t('widgets:overpasses.noData')}</Typography>
        </div>
      </React.Fragment>
    );
  };

  renderActions = () => {
    return (
      <WidgetActions style={{ flexShrink: 0, overflowX: 'hidden', padding: '8px' }}>
        <Grid container justify="center" spacing={2}>
          <CenteredGrid item>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  onChange={(event) => {
                    const checked = event.target.checked;
                    this.setState({ imagingOnly: checked }, () => {
                      this.props.onChangeImagingOnly && this.props.onChangeImagingOnly(checked);
                    });
                  }}
                  checked={this.state.imagingOnly}
                />
              }
              label={<Typography variant="body2">{this.props.t('widgets:overpasses.imagingOnly')}</Typography>}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  onChange={(event) => {
                    const checked = event.target.checked;
                    this.setState({ calendarView: checked }, () => {
                      this.props.onChangeCalendarView && this.props.onChangeCalendarView(checked);
                    });
                  }}
                  checked={this.state.calendarView}
                />
              }
              label={
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {this.props.t('common:calendar')}
                </Typography>
              }
              labelPlacement="end"
            />
          </CenteredGrid>
          <Grid item>
            {!this.state.details ? (
              <Link
                component="button"
                variant="body1"
                onClick={() => this.setState({ details: true })}
                style={{ color: this.props.theme.palette.custom.colorfulText, top: '-2px' }}
              >
                {this.props.t('widgets:more')}
              </Link>
            ) : (
              <Button
                disableElevation
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => this.setState({ details: false })}
              >
                {this.props.t('common:close')}
              </Button>
            )}
          </Grid>
        </Grid>
      </WidgetActions>
    );
  };

  render() {
    let overpasses = [...this.props.overpasses];

    if (this.state.imagingOnly) {
      overpasses = overpasses.filter((overpass: Overpass) => overpass.acquisition);
    }

    if (!this.state.details && !this.state.calendarView) {
      overpasses = overpasses.slice(0, 3);
    }

    const currentUserPlan = this.props.plans?.find((plan: Plan) => plan.id === this.props.user?.plan_id);

    return (
      <React.Fragment>
        <Typography variant="body2" align="center" style={{ fontFamily: '"industry-bold"' }}>
          {this.props.t('widgets:overpasses.upcoming')}
        </Typography>

        <WidgetContainer
          elevation={0}
          detailed={this.state.details}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isWidthUp('md', this.props.width) && this.state.details && <Toolbar />}
          <WidgetContent>
            {this.state.loading && <Skeleton animation="wave" height="100%" variant="rect" />}
            {overpasses.length === 0 && this.renderNoDataAvailable()}
            {!this.state.calendarView && this.renderList(overpasses)}
            {this.state.calendarView && this.renderCalendar(overpasses)}
            {this.state.details && currentUserPlan?.default && <UpgradeTeaser />}
          </WidgetContent>

          {this.props.user && this.renderActions()}
        </WidgetContainer>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  addPolygonToMap: (polygon) => dispatch(mapPolygons.add(polygon)),
  clearMapPolygon: (polygon) => dispatch(mapPolygons.delete(polygon)),
  clearOverpasses: () => dispatch(overpass.delete.request()),
  fetchOverpasses: (observationUUID: string, page?: number) =>
    dispatch({
      ...overpass.get.request({
        observationUUID,
        page,
      }),
      [WAIT_FOR_ACTION]: overpass.get.SUCCESS,
      [ERROR_ACTION]: overpass.get.FAILURE,
    }),
  fetchPlans: () => dispatch(plans.get.request()),
});

const mapStoreToProps = (state: ApplicationState): StoreProps => ({
  getSatelliteColor: satelliteColorSelector(state),
  overpasses: upcomingOverpassesSelector(state),
  overpassesTotalPages: overpassesTotalPagesSelector(state),
  plans: plansSelector(state),
});

export default withTranslation(['common', 'widgets'])(
  withUser(connect(mapStoreToProps, mapDispatchToProps)(withTheme(withWidth()(Overpasses)))),
);
