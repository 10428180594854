export const rotate = (array: any[], index: number): any[] => {
  const arr = [...array];
  arr.push(...arr.splice(0, index));
  return arr;
};

export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const isTouchDevice = () => {
  return window.matchMedia('(pointer:coarse)').matches;
};

export const isFullscreenApiSupported =
  document.fullscreenEnabled ||
  document.mozFullScreenEnabled ||
  document.webkitFullscreenEnabled ||
  document.msFullscreenEnabled;
