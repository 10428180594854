import { createAPIActions, createActions } from '../../utils/redux-actions';

export const user = createAPIActions('AUTH/USER');
export const login = createActions('AUTH/LOGIN');
export const loginVerify = createActions('AUTH/LOGIN_VERIFY');
export const logout = createActions('AUTH/LOGOUT');
export const signup = createActions('AUTH/SIGNUP');
export const emailVerify = createAPIActions('AUTH/EMAIL_VERIFY');
export const loginSocial = createActions('AUTH/LOGIN_SOCIAL');
export const resendVerificationEmail = createAPIActions('AUTH/RESEND_VERIFICATION_EMAIL');
export const resetPasswordConfirm = createActions('AUTH/RESET_PASSWORD_CONFIRM');
export const resetPassword = createAPIActions('AUTH/RESET_PASSWORD');
export const passwordChange = createAPIActions('AUTH/PASSWORD/CHANGE');
