import { call, put, takeEvery } from 'redux-saga/effects';

import { satellite, satellites, satelliteWithTrajectory } from './actions';
import urls from '../urls';
import { callApi } from '../../utils/api';

export function* fetchSatelliteDetails(payload: any): any {
  const satelliteUrl = urls.satelliteDetails(payload);
  const trajectoryUrl = urls.trajectoryDetails(payload);

  const satelliteResult = yield call(callApi, 'get', satelliteUrl);
  const trajectoryResult = yield call(callApi, 'get', trajectoryUrl);

  const result = {
    ...satelliteResult,
    trajectory: trajectoryResult,
  };

  return result;
}

function* fetchSatellite({ payload }: any): any {
  try {
    const result = yield call(fetchSatelliteDetails, payload);
    yield put(satellite.get.success(result));
  } catch (err) {
    yield put(satellite.get.failure(err));
  }
}

function* fetchSatellites(): any {
  try {
    const satellitesResult = yield call(callApi, 'get', urls.satelliteList());
    yield put(satellites.get.success(satellitesResult.features));
  } catch (err) {
    yield put(satellites.get.failure(err));
  }
}

function* fetchSatelliteWithTrajectory({ payload }: any): any {
  if (!payload.satellite.trajectory) {
    try {
      const result = yield call(fetchSatelliteDetails, { satelliteId: payload.satellite.id });
      yield put(satelliteWithTrajectory.get.success({ ...payload, satellite: result }));
    } catch (err) {
      yield put(satelliteWithTrajectory.get.failure(err));
    }
  } else {
    yield put(satelliteWithTrajectory.get.success(payload));
  }
}

function* saga() {
  yield takeEvery(satellite.get.REQUEST, fetchSatellite);
  yield takeEvery(satellites.get.REQUEST, fetchSatellites);
  yield takeEvery(satelliteWithTrajectory.get.REQUEST, fetchSatelliteWithTrajectory);
}

export default saga;
