export interface UserProps {
  user?: User;
}

export enum ThemeMode {
  SYSTEM = 'system',
  DARK = 'dark',
  LIGHT = 'light',
}

export interface UserSettings {
  activeChannelId?: number;
  createOnSearchSelection: boolean;
  theme: ThemeMode;
  defaultImageriesDisplay: import('../../constants').ImageriesDisplayOption;
  language?: string;
  darkMode?: boolean;
}

export interface User extends Model {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  is_social: boolean;
  is_verified: boolean;
  api_key: string;
  newsletter_subscribed: boolean;
  _settings: Partial<UserSettings>;
  plan_id: number;
}

export type LoginErrors = FormErrors<{ email?: string; password?: string }>;
export type SignupErrors = FormErrors<{ email?: string; password1?: string; password2?: string }>;

export interface AuthState {
  readonly actionInProgress: boolean;
  readonly user?: User;
  readonly loginErrors: LoginErrors;
  readonly signupErrors: SignupErrors;
  readonly verificationEmailResent: boolean;
}
