import React, { useEffect, useState } from 'react';
import {
  Typography,
  Tooltip as MuiTooltip,
  Button,
  Paper,
  Fade,
  Grid,
  useTheme,
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core';
import clsx from 'clsx';
import { ApiLinksList } from '../api-links-list';
import SatellitesMenu from '../../containers/satellites-menu';
import ShareMenu from '../share-menu';
import { FaSatellite, FaShareAlt } from 'react-icons/fa';
import LayersController from '../../containers/layers-controller';
import { Layers } from '@material-ui/icons';
import { MdCode, MdExpandLess, MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import styled from 'styled-components';
import { TileLayer, PolygonLayer, MapBounds } from '../../store/app/types';
import { useTranslation } from 'react-i18next';
import { User } from '../../store/auth/types';
import { Satellite } from '../../store/satellites/types';
import { Observation } from '../../store/observations/types';
import { Dialog } from '../layout';
import { isFullscreenApiSupported } from '../../utils/helpers';

const MapButtonsContainer = styled(Grid)`
  .full-screen & {
    @supports (-webkit-touch-callout: none) {
      padding-top: 50px;
    }
  }
`;

export const MapButton = styled(Button).attrs({
  variant: 'outlined',
  size: 'large',
})`
  &.MuiButton-root {
    pointer-events: auto;
    color: ${() => useTheme().palette.text.primary};
    background-color: ${() => useTheme().palette.background.paper};
    border-color: rgba(70, 70, 70, 0.4);
    box-sizing: border-box;
    transition: none;
    width: 68px;
    height: 38px;
    padding: 7px 10px;
    z-index: -1;

    &.open {
      border-radius: 4px 4px 0 0;
      border-bottom-color: ${() => useTheme().palette.background.paper};
    }

    &:hover {
      background-color: ${() => useTheme().palette.background.paper};

      &.Mui-disabled {
        background-color: ${() => useTheme().palette.background.paper};
      }
    }

    &.Mui-disabled {
      pointer-events: auto;
    }
  }
`;

const MapButtonPopup = styled(Paper).attrs({
  elevation: 0,
})`
  &.MuiPaper-root {
    color: ${() => useTheme().palette.text.primary};
    background-color: ${() => useTheme().palette.background.paper};
    padding: 10px;
    pointer-events: auto;
    width: fit-content;
    border-top-left-radius: 0;
    border: 1px solid rgba(70, 70, 70, 0.4);
  }
`;

interface Props {
  isActiveObservation: boolean;
  user: User | undefined;
  mapBounds: MapBounds;
  mapPolygons: PolygonLayer[];
  mapTiles: TileLayer[];
  selectedSatellites: Satellite[];
  observation: Observation | undefined;
}

export const MapButtons = ({
  isActiveObservation,
  user,
  mapBounds,
  mapPolygons,
  mapTiles,
  observation,
  selectedSatellites,
}: Props) => {
  const { t } = useTranslation(['observation', 'common', 'satellitesMenu', 'map']);

  const [showLayersController, setShowLayersController] = useState<boolean>(false);
  const [showShareMenu, setShowShareMenu] = useState<boolean>(false);
  const [showApiLinksDialog, setShowApiLinksDialog] = useState<boolean>(false);
  const [showSatellitesMenu, setShowSatellitesMenu] = useState<boolean>(false);
  const [satellitesLoaded, setSatellitesLoaded] = useState<boolean>(false);
  const [satellitesTooltipTranslation, setSatellitesTooltipTranslation] = useState<string>(t('satellitesMenu:loading'));
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const numberOfPolygonAndTileLayers = mapPolygons.length + mapTiles.length;

  const handleTooltipTranslation = () => {
    if (!user) {
      setSatellitesTooltipTranslation(t('common:signInRequired'));
    } else if (observation && !observation.can_edit) {
      setSatellitesTooltipTranslation(t('satellitesMenu:cannotEditSatellites'));
    }
  };

  const isSatellitesMenuButtonDisabled = !satellitesLoaded || !user || (observation && !observation.can_edit);
  // added 2 sources of truth to mitigate the Fullscreen API delay that won't report fullscreen state until fully active
  const isMapFullScreen = document.fullscreenElement !== null || isFullScreen;

  const handleFullScreenAction = () => {
    const mapContainer = document.getElementById('map-container');

    if (isMapFullScreen) {
      document.exitFullscreen().then(() => {
        setIsFullScreen(false);
      });
    } else if (mapContainer) {
      setIsFullScreen(true);
      mapContainer.requestFullscreen().catch((error) => console.error(error));
    }
  };

  const handleFullScreenEvent = () => {
    if (document.fullscreenElement !== null) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    if (isFullscreenApiSupported) {
      document.addEventListener('fullscreenchange', handleFullScreenEvent);

      return () => {
        document.removeEventListener('fullscreenchange', handleFullScreenEvent);
      };
    }
  }, []);

  return (
    <>
      <MapButtonsContainer container spacing={1} direction="column">
        <ClickAwayListener onClickAway={() => setShowLayersController(false)}>
          <Grid item>
            <MapButton
              id="layers-controller-button"
              onClick={() => setShowLayersController(!showLayersController)}
              className={clsx({ open: showLayersController })}
            >
              {(!showLayersController && <Layers />) || <MdExpandLess size={24} />}
              {numberOfPolygonAndTileLayers > 0 && (
                <Typography style={{ marginLeft: 5 }}>{numberOfPolygonAndTileLayers}</Typography>
              )}
            </MapButton>
            <Fade in={showLayersController}>
              <Grid item={true} xs={12} style={{ height: 0 }}>
                <MapButtonPopup>
                  <LayersController />
                </MapButtonPopup>
              </Grid>
            </Fade>
          </Grid>
        </ClickAwayListener>
        <ClickAwayListener onClickAway={() => setShowSatellitesMenu(false)}>
          <Grid item hidden={isMapFullScreen}>
            <Tooltip
              title={satellitesTooltipTranslation}
              disableHoverListener={!isSatellitesMenuButtonDisabled}
              arrow={true}
              placement="bottom"
            >
              <span>
                <MapButton
                  disabled={isSatellitesMenuButtonDisabled}
                  onClick={() => setShowSatellitesMenu(!showSatellitesMenu)}
                >
                  <FaSatellite size={18} />
                </MapButton>
              </span>
            </Tooltip>
            <SatellitesMenu
              handleSatellitesLoaded={() => {
                setSatellitesLoaded(true);
                handleTooltipTranslation();
              }}
              open={showSatellitesMenu}
              onClose={() => {
                setShowSatellitesMenu(false);
              }}
            />
          </Grid>
        </ClickAwayListener>
        <Grid item>
          <MuiTooltip
            arrow={true}
            disableHoverListener={user !== undefined}
            placement={'bottom'}
            title={t('common:signInRequired')}
          >
            <span>
              <MapButton
                id="api-links-button"
                onClick={() => setShowApiLinksDialog(!showApiLinksDialog)}
                disabled={!user}
              >
                <MdCode size={24} />
              </MapButton>
            </span>
          </MuiTooltip>
        </Grid>
        <Dialog open={showApiLinksDialog} onClose={() => setShowApiLinksDialog(false)}>
          {user && (
            <ApiLinksList
              mapBounds={mapBounds}
              satellites={selectedSatellites}
              apiKey={user.api_key}
              mapTiles={mapTiles}
            />
          )}
        </Dialog>
        <ClickAwayListener onClickAway={() => setShowShareMenu(false)}>
          <Grid item hidden={isMapFullScreen}>
            <MuiTooltip
              arrow={true}
              disableHoverListener={isActiveObservation}
              placement={'bottom'}
              title={!isActiveObservation && t('observation:cannotShare')}
            >
              <span>
                <MapButton
                  id="share-menu-button"
                  onClick={() => setShowShareMenu(!showShareMenu)}
                  disabled={!isActiveObservation}
                  className={clsx({ open: showShareMenu })}
                >
                  <FaShareAlt size={16} />
                </MapButton>
              </span>
            </MuiTooltip>
            <Fade in={showShareMenu}>
              <Grid item={true} xs={12} style={{ height: 0 }}>
                <MapButtonPopup>
                  <ShareMenu></ShareMenu>
                </MapButtonPopup>
              </Grid>
            </Fade>
          </Grid>
        </ClickAwayListener>
        <Grid item hidden={!isFullscreenApiSupported}>
          <MuiTooltip
            arrow={true}
            placement={'bottom'}
            title={!isMapFullScreen ? t('map:fullScreen') : t('map:exitFullScreen')}
          >
            <span>
              <MapButton id="fullscreen-button" onClick={handleFullScreenAction}>
                {isMapFullScreen ? <MdFullscreenExit size={24} /> : <MdFullscreen size={24} />}
              </MapButton>
            </span>
          </MuiTooltip>
        </Grid>
      </MapButtonsContainer>
    </>
  );
};

export default MapButtons;
