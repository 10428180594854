import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { Channel } from '../store/channels/types';
import { useTranslation } from 'react-i18next';

export const EmptyChannelInfo: React.FC = () => (
  <React.Fragment>
    <Typography variant="h6" style={{ padding: 10 }} align="center">
      {useTranslation('channelViewElements').t('emptyChannelInfo.title')}
    </Typography>
    <Typography style={{ padding: 10 }} align="center">
      {useTranslation('channelViewElements').t('emptyChannelInfo.body')}
    </Typography>
  </React.Fragment>
);

interface ChannelDialogProps {
  channel: Channel;
  onNo: () => void;
  onYes?: () => void;
  children?: React.ReactNode;
}

export const DeleteChannelDialog: React.FC<ChannelDialogProps> = (props: ChannelDialogProps) => {
  const { t } = useTranslation(['common', 'channelViewElements']);

  return (
    <React.Fragment>
      <DialogTitle>{t('common:askConfirmation')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('channelViewElements:deleteChannelDialog.deleteInfo', {
            channelName: props.channel.name,
            observationsCount: props.channel.observations.length,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id={'cancel-button'} onClick={props.onNo} color="primary" style={{ textTransform: 'capitalize' }}>
          {t('common:no')}
        </Button>
        <Button id={'confirm-button'} onClick={props.onYes} color="secondary" style={{ textTransform: 'capitalize' }}>
          {t('common:yes')}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export const ChangeChannelNameDialog: React.FC<ChannelDialogProps> = (props: ChannelDialogProps) => {
  const { t } = useTranslation('channelViewElements');

  return (
    <React.Fragment>
      <DialogTitle>{t('changeChannelNameDialog.title')}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </React.Fragment>
  );
};
