import { Reducer } from 'redux';

import { PaymentState } from './types';
import { highResolutionImageryCheckout } from './actions';

const initialState: PaymentState = {
  checkoutUrl: undefined,
};

const reducer: Reducer<PaymentState> = (state = initialState, action) => {
  switch (action.type) {
    case highResolutionImageryCheckout.post.SUCCESS: {
      return { ...state, checkoutUrl: action.payload };
    }

    case highResolutionImageryCheckout.CLEANUP: {
      return { ...state, checkoutUrl: undefined };
    }

    default: {
      return state;
    }
  }
};

export { reducer as paymentReducer };
