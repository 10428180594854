import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button, Input, InputLabel } from './form';
import styled from 'styled-components';
import _, { isEmpty } from 'lodash';
import { ErrorMessage } from './messages';
import { LoginErrors } from '../store/auth/types';
import { useTheme } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

const Form = styled.form`
  text-align: center;
  padding: 10px;
  color: ${() => useTheme().palette.primary.contrastText};
`;
Form.displayName = 'Form';

interface Props {
  onSubmit: (email: string, password: string) => void;
  errors: LoginErrors;
  email?: string | null;
}

interface State {
  email: string;
  password: string;
}

class LoginForm extends React.Component<Props & WithTranslation, State> {
  readonly state: State = {
    email: '',
    password: '',
  };

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const email = this.props.email ? this.props.email : this.state.email;
    const password = this.state.password;
    this.props.onSubmit(email, password);
  }

  render() {
    const { errors, email, t } = this.props;

    return (
      <div>
        {errors.non_field_errors && <ErrorMessage id="login-error">{errors.non_field_errors}</ErrorMessage>}

        <Form onSubmit={(event) => this.onSubmit(event)}>
          <FormControl fullWidth={true} required={true} error={!isEmpty(errors.email)}>
            <InputLabel style={{ textTransform: 'capitalize' }}>{t('login')}</InputLabel>
            <Input
              id="login-email"
              name="email"
              type="email"
              value={email ? email : this.state.email}
              disabled={Boolean(email)}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
            <FormHelperText>{errors.email}</FormHelperText>
          </FormControl>
          <FormControl fullWidth={true} required={true} error={!_.isEmpty(errors.password)}>
            <InputLabel style={{ textTransform: 'capitalize' }}>{t('password')}</InputLabel>
            <Input
              id="login-password"
              name="password"
              type="password"
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
            />
            <FormHelperText>{errors.password}</FormHelperText>
          </FormControl>
          <Button id="login-button" variant="outlined" type="submit" style={{ marginTop: 10 }}>
            {t('signIn')}
          </Button>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(LoginForm);
