import React, { useState } from 'react';
import { IconButton, Popover as MuiPopover, ButtonBase, Paper, Typography } from '@material-ui/core';
import { MdMoreHoriz } from 'react-icons/md';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib/cjs';

type Props = {
  children: React.ReactNode;
};

const Popover = styled(MuiPopover)`
  & .MuiPopover-paper {
    max-width: 30%;
  }
  & .MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
  }
`;

export const OptionsMenu: React.FC<Props> = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <React.Fragment>
      <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
        <MdMoreHoriz size="30" />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={0}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {props.children}
      </Popover>
    </React.Fragment>
  );
};

type OptionsMenuItemProps = {
  onClick: () => void;
  icon: IconType;
  optionTitle: string;
};

export const OptionsMenuItem: React.FC<OptionsMenuItemProps> = (props: OptionsMenuItemProps) => (
  <ButtonBase onClick={props.onClick} style={{ width: '100%' }}>
    <Paper square variant="outlined" style={{ borderTop: 0, padding: '12px 6px', width: '100%' }}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <props.icon size="24" style={{ padding: 9 }} />
        <Typography>{props.optionTitle}</Typography>
      </span>
    </Paper>
  </ButtonBase>
);
