import { createSelector } from 'reselect';

import { ApplicationState } from '..';
import { ConfigState } from './types';
import { CommercialConstellation } from '../satellites/types';

export const configSelector = (state: ApplicationState): ConfigState => state.config;

export const highResolutionImageryPriceSelector = createSelector(
  configSelector,
  (config) =>
    (constellation: CommercialConstellation, size: number): number =>
      Number(config.HIGH_RESOLUTION && config.HIGH_RESOLUTION[`HI_RES_${constellation}_${size}`]),
);

export const highResolutionImageryDiscountSelector = createSelector(
  [configSelector, highResolutionImageryPriceSelector],
  (config, priceSelector) => (constellation: CommercialConstellation, size: number) => {
    const price = priceSelector(constellation, size);
    const lowestSize = config.HIGH_RESOLUTION.HI_RES_SIZES[0];
    const lowestPrice = config.HIGH_RESOLUTION[`HI_RES_${constellation}_${lowestSize}`];

    return Math.floor(100 - (price * 100) / (size * lowestPrice));
  },
);

export const satelliteColorSelector = createSelector(configSelector, (config) => (satelliteName: string): string => {
  return config.SATELLITES.SATELLITES_COLORS[satelliteName] || config.SATELLITES.DEFAULT_SATELLITE_COLOR;
});

export const  highResolutionEnabledSelector =  (state: ApplicationState): boolean => (
    state.config.HIGH_RESOLUTION.HI_RES_ENABLED ?? true
);
