import { Reducer } from 'redux';
import { differenceBy, filter, orderBy } from 'lodash';

import { Satellite, SatellitesState } from './types';
import { satellite, satellites, satelliteWithTrajectory, setSatellitesSelected } from './actions';

const initialState: SatellitesState = {
  data: [],
  errors: undefined,
  fetchedAll: false,
  selected: undefined,
};

const orderSatellitesByName = (satellites: Satellite[]) =>
  orderBy(satellites, (s: Satellite) => s.properties.name.toLowerCase());

const reducer: Reducer<SatellitesState> = (state = initialState, action) => {
  switch (action.type) {
    case satellites.get.SUCCESS: {
      const difference: Satellite[] = differenceBy(action.payload, state.data, (s: Satellite) => s.id);
      const satellites = [...state.data, ...difference];

      return {
        ...state,
        data: orderSatellitesByName(satellites),
        fetchedAll: true,
      };
    }

    case satellite.get.SUCCESS: {
      const satellites = [...filter(state.data, (s: Satellite) => s.id !== action.payload.id), action.payload];
      return { ...state, data: orderSatellitesByName(satellites) };
    }

    case satellite.get.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case satelliteWithTrajectory.get.SUCCESS: {
      const { satellite } = action.payload;

      return {
        ...state,
        data: orderSatellitesByName([...state.data.filter((s) => s.id !== satellite.id), satellite]),
      };
    }

    case setSatellitesSelected.REQUEST: {
      return { ...state, selected: action.payload.satelliteIds };
    }

    default: {
      return state;
    }
  }
};

export { reducer as satellitesReducer };
