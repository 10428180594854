export const TITLE = 'Spectator - Real-Time Satellite Monitoring';
export const DEFAULT_REDIRECT_URL = '/';
export const SIGNIN_URL = '/auth/signin';

export const AUTH_TOKEN_KEY = 'auth_token';
export const AUTH_LOGIN_TYPE_GOOGLE = 'userGoogleLogin';

export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';

export const NOMINATIM_URL = 'https://nominatim.openstreetmap.org';
export const APP_MAP_MIN_ZOOM = 2;
export const APP_MAP_TILES_URL = {
  [THEME_DARK]: 'https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png',
  [THEME_LIGHT]: 'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png',
};
export const APP_MAP_LABELS_URL = {
  [THEME_DARK]: 'https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png',
  [THEME_LIGHT]: 'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png',
};
export const APP_MAP_TILES_SIZE = 256;
export const APP_MAP_TILES_HIGH_RESOLUTION_SIZE = 512;
export const APP_MAP_MAX_BOUNDS = [
  [-90, -300],
  [90, 300],
];
export const APP_MAP_TILER_MIN_ZOOM = 7;
export const APP_MAP_TILER_MAX_ZOOM = 19;

export const SATELLITES_WITH_TILED_PREVIEW = ['Sentinel-2A', 'Sentinel-2B', 'Landsat-8', 'Landsat-9'];

// Default values based on https://medium.com/devseed/cog-talk-4ter-distributed-processes-8ee280f71080
export const DEFAULT_COLOR_FORMULA = 'gamma RGB 3.5, saturation 1.7, sigmoidal RGB 34 0.35';
export const SATELLITE_BANDS_COMBINATIONS = [
  {
    id: 'NATURAL',
    name: 'Natural',
    'Landsat-8': {
      bands: ['B4', 'B3', 'B2'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2A': {
      bands: ['B04', 'B03', 'B02'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2B': {
      bands: ['B04', 'B03', 'B02'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    img: require('./static/images/presets_images/natural_color.jpg'),
    tags: ['true colors', 'rgb', 'natural'],
  },
  {
    id: 'FALSE_COLOR',
    name: 'False Color',
    'Landsat-8': {
      bands: ['B5', 'B4', 'B3'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2A': {
      bands: ['B08', 'B04', 'B03'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2B': {
      bands: ['B08', 'B04', 'B03'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    img: require('./static/images/presets_images/false_color.jpg'),
    tags: ['urban', 'buildings'],
  },
  {
    id: 'FALSE_URBAN',
    name: 'False Urban',
    'Landsat-8': {
      bands: ['B7', 'B6', 'B4'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2A': {
      bands: ['B12', 'B11', 'B04'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2B': {
      bands: ['B12', 'B11', 'B04'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    img: require('./static/images/presets_images/false_urban.jpg'),
    tags: ['urban', 'buildings'],
  },
  {
    id: 'AGRICULTURE',
    name: 'Agriculture',
    'Landsat-8': {
      bands: ['B6', 'B5', 'B2'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2A': {
      bands: ['B11', 'B08', 'B02'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2B': {
      bands: ['B11', 'B08', 'B02'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    img: require('./static/images/presets_images/agriculture.jpg'),
    tags: ['vegetation', 'crops', 'nature'],
  },
  {
    id: 'LAND_WATER',
    name: 'Land/Water',
    'Landsat-8': {
      bands: ['B5', 'B6', 'B4'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2A': {
      bands: ['B08', 'B11', 'B04'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    'Sentinel-2B': {
      bands: ['B08', 'B11', 'B04'],
      color_formula: DEFAULT_COLOR_FORMULA,
    },
    img: require('./static/images/presets_images/land_water.jpg'),
    tags: ['land', 'water', 'sea', 'lake'],
  },
  {
    id: 'NDVI',
    name: 'NDVI',
    'Landsat-8': {
      expression: '(B5 - B4)/(B5 + B4)',
      rescale: '-1,1',
      colormap_name: 'cfastie',
    },
    'Sentinel-2A': {
      expression: '(B08 - B04) / (B08 + B04)',
      rescale: '-1,1',
      colormap_name: 'cfastie',
    },
    'Sentinel-2B': {
      expression: '(B08 - B04) / (B08 + B04)',
      rescale: '-1,1',
      colormap_name: 'cfastie',
    },
    img: require('./static/images/presets_images/land_water.jpg'),
    tags: ['vegetation', 'crops', 'nature', 'greenness', 'agriculture'],
  },
  {
    id: 'NDSI',
    name: 'NDSI',
    'Landsat-8': {
      expression: '(B2 - B5) / (B2 + B5)',
      rescale: '-1,1',
      colormap_name: 'cfastie',
    },
    'Sentinel-2A': {
      expression: '(B02 - B08) / (B02 + B08)',
      rescale: '-1,1',
      colormap_name: 'cfastie',
    },
    'Sentinel-2B': {
      expression: '(B02 - B08) / (B02 + B08)',
      rescale: '-1,1',
      colormap_name: 'cfastie',
    },
    img: require('./static/images/presets_images/land_water.jpg'),
    tags: ['snow', 'winter', 'ice'],
  },
  {
    id: 'NDWI',
    name: 'NDWI',
    'Landsat-8': {
      expression: '(B5 - B6) / (B5 + B6)',
      rescale: '-1,1',
      colormap_name: 'cfastie',
    },
    'Sentinel-2A': {
      expression: '(B08 - B11) / (B08 + B11)',
      rescale: '-1,1',
      colormap_name: 'cfastie',
    },
    'Sentinel-2B': {
      expression: '(B08 - B11) / (B08 + B11)',
      rescale: '-1,1',
      colormap_name: 'cfastie',
    },
    img: require('./static/images/presets_images/land_water.jpg'),
    tags: ['water'],
  },
];

export const DEFAULT_SATELLITE_BANDS_INDEX = 0;
export const DEFAULT_SATELLITE_BANDS = SATELLITE_BANDS_COMBINATIONS[DEFAULT_SATELLITE_BANDS_INDEX];
export const HIGH_RESOLUTION_PICKER_MIN_ZOOM = 8;
export const DEFAULT_DATETIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const APP_SESSION = 'app_session';

export const COOKIES_EXPIRATION_DATE = new Date(2100, 0, 1);
export const DARK_MODE_COOKIE_NAME = 'dark_mode';
export const THEME_COOKIE_NAME = 'theme';

export enum ImageriesDisplayOption {
  Recommended = 'Recommended',
  Filtered = 'Filtered',
  History = 'History',
}

export type Hemisphere = 'northern' | 'southern';

export const M2_TO_KM2_DENOMINATOR = 1000000;
export const MIN_OBSERVATION_AREA = 100000; // in square kilometers
