export interface Plan {
  annual_price: number;
  default: boolean;
  description: string;
  features: Array<string>;
  id: number;
  monthly_price: number;
  name: string;
}

export interface PlanState {
  readonly changeInProgress: boolean;
  readonly checkoutUrl?: string;
  readonly plans: Plan[];
  readonly subscription?: Subscription;
  readonly errors?: string;
}

export enum SubscriptionInterval {
  MONTH = 'month',
  YEAR = 'year',
}

export type UsageName = 'api_throttle';

export interface Subscription {
  plan_id: number;
  plan_name: string;
  interval: SubscriptionInterval;
  start_date: string;
  end_date: string | null;
  reset_date: string;
  usage: Record<UsageName, number[]>;
  billingManagementUrl?: string;
}
